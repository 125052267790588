import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-godown',
  templateUrl: './godown.component.html',
  styleUrls: ['./godown.component.css']
})
export class GodownComponent implements OnInit {
  up: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();

  }

}
