import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-language-settings',
  templateUrl: './language-settings.component.html',
  styleUrls: ['./language-settings.component.css']
})
export class LanguageSettingsComponent implements OnInit {
  menu_pageLoadingImg: boolean;
  menulist: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.getUserPrivilage();
  }

  getUserPrivilage()
  {
    this.menu_pageLoadingImg = true;
    this.apiService.getMenuMaster().subscribe((res) => {
      this.menu_pageLoadingImg = false;
      this.menulist = res['data'];
    });
  }

}
