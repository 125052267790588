import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { FormArray } from '@angular/forms';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-daily-vat-summary',
  templateUrl: './daily-vat-summary.component.html',
  styleUrls: ['./daily-vat-summary.component.css']
})
export class DailyVatSummaryComponent implements OnInit {

  fileName= 'daily_vat_summary.xlsx';

  vat_sum: any;
  pgend: boolean;
  pgstart: boolean;
  pageLoadingImg: boolean;
  values: any;
  curpage: any;
  lastpage: any;
  stndData: any;
  standcurpage: any;
  standlastpage: any;
  standpgend: boolean;
  standpgstart: boolean;
  returnstndData: any;
  returnstandpgend: boolean;
  returnstandpgstart: boolean;
  returnstandcurpage: any;
  returnstandlastpage: any;
  strdfrom: any;
  retstrdfrom: any;
  retstrdnxtpg: any;
  retstrdprevpg: any;
  strdprevpg: any;
  strdnxtpg: any;
  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Purchase' },
    { id: 4, name: 'Imports Vat Paid to Customs' },
    { id: 5, name: 'Imports Vat - reverse change mechanism' }
  ];
  purch_type: any;
  zeropgstart: boolean;
  zeropgend: boolean;
  zeroData: any;
  zerocurpage: any;
  zerolastpage: any;
  returnzerocurpage: any;
  returnzerolastpage: any;
  returnzeroData: any;
  retzerofrom: any;
  retzeronxtpg: any;
  retzeroprevpg: any;
  zeroprevpg: any;
  zeronxtpg: any;
  exemptData: any;
  exemptcurpage: any;
  exemptlastpage: any;
  exemptnxtpg: any;
  exemptprevpg: any;
  returnexemptData: any;
  returnexemptcurpage: any;
  returnexemptlastpage: any;
  retexemptfrom: any;
  exemptfrom: any;
  retexemptnxtpg: any;
  retexemptprevpg: any;
  exemptpgend: boolean;
  exemptpgstart: boolean;
  returnexemptpgend: boolean;
  returnexemptpgstart: boolean;
  imprtCustData: any;
  imprtCustcurpage: any;
  imprtCustlastpage: any;
  imprtCustfrom: any;
  imprtCustnxtpg: any;
  imprtCustprevpg: any;
  returnimprtCustData: any;
  returnimprtCustcurpage: any;
  returnimprtCustlastpage: any;
  retimprtCustfrom: any;
  retimprtCustprevpg: any;
  retimprtCustnxtpg: any;
  imprtCustpgend: boolean;
  imprtCustpgstart: boolean;
  returnimprtCustpgend: boolean;
  returnimprtCustpgstart: boolean;


  apiResponse: any;
  zerofrom: any;
  display_name: any;
  display_tin: any;
  sum_array: any;
  info: any;
  start_date: any;
  start_end: any;
  userType: any;
  branches: any;
  exportLoader: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService) { }


  ngOnInit() {

    this.readProdSumm(1);
    this.userType = this.coreService.getUserData('user_type');
    // console.log( this.userType);
    this.exportLoader = false;

  }
  getBranchByName(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branches = res['data'];
    });
  }
  readProdSumm(pageno) {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pagination_for", '1');

    this.apiService.DailyVatReport(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.apiResponse = res.data.data;
      this.vat_sum = res.data.vat_sum;
      this.sum_array = res.data.sum;
      this.info = res.data.info;
      this.display_name = res.data.branch.branch_display_name;
      this.display_tin = res.data.branch.branch_tin;


      this.start_date = res.data.date_start;
      this.start_end = res.data.date_end;

    });


  }

  standpgEnd(curr, end) {
    if (curr == end)
      this.standpgend = true;
    else
      this.standpgend = false;
  }

  standpgStart(curr) {
    if (curr == 1)
      this.standpgstart = true;
    else
      this.standpgstart = false;
  }

  returnstandpgEnd(curr, end) {
    if (curr == end)
      this.returnstandpgend = true;
    else
      this.returnstandpgend = false;
  }

  returnstandpgStart(curr) {
    if (curr == 1)
      this.returnstandpgstart = true;
    else
      this.returnstandpgstart = false;
  }


  zeropgEnd(curr, end) {
    if (curr == end)
      this.zeropgend = true;
    else
      this.zeropgend = false;
  }

  zeropgStart(curr) {
    if (curr == 1)
      this.zeropgstart = true;
    else
      this.zeropgstart = false;
  }



  returnzeropgEnd(curr, end) {
    if (curr == end)
      this.returnstandpgend = true;
    else
      this.returnstandpgend = false;
  }

  returnzeropgStart(curr) {
    if (curr == 1)
      this.returnstandpgstart = true;
    else
      this.returnstandpgstart = false;
  }



  exemptpgEnd(curr, end) {
    if (curr == end)
      this.exemptpgend = true;
    else
      this.exemptpgend = false;
  }

  exemptpgStart(curr) {
    if (curr == 1)
      this.exemptpgstart = true;
    else
      this.exemptpgstart = false;
  }

  returnexemptpgEnd(curr, end) {
    if (curr == end)
      this.returnexemptpgend = true;
    else
      this.returnexemptpgend = false;
  }

  returnexemptpgStart(curr) {
    if (curr == 1)
      this.returnexemptpgstart = true;
    else
      this.returnexemptpgstart = false;
  }






  imprtCustpgEnd(curr, end) {
    if (curr == end)
      this.imprtCustpgend = true;
    else
      this.imprtCustpgend = false;
  }

  imprtCustpgStart(curr) {
    if (curr == 1)
      this.imprtCustpgstart = true;
    else
      this.imprtCustpgstart = false;
  }

  returnimprtCustpgEnd(curr, end) {
    if (curr == end)
      this.returnimprtCustpgend = true;
    else
      this.returnimprtCustpgend = false;
  }

  returnimprtCustpgStart(curr) {
    if (curr == 1)
      this.returnimprtCustpgstart = true;
    else
      this.returnimprtCustpgstart = false;
  }




  pageNext(formdata: { value: any; }, pageno: any, pagination_for: any) {

    console.log(formdata.value);
    this.stndData = [];

    //    let searchval = new FormData();
    // searchval.append("pagination_for", '1');
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.DailyVatReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.apiResponse = res.data.data;
      this.vat_sum = res.data.vat_sum;
      this.sum_array = res.data.sum;
      this.info = res.data.info;
      this.display_name = res.data.branch.branch_display_name;
      this.display_tin = res.data.branch.branch_tin;

      this.start_date = res.data.date_start;
      this.start_end = res.data.date_end;
      console.log(res.data.branch.branch_display_name);

    });
  }



  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];



  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];




  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

  clearAllData() {
    // standard vat 

    this.stndData = [];
    this.standcurpage = '';
    this.standlastpage = '';
    this.strdfrom = '';

    this.strdnxtpg = '';
    this.strdprevpg = '';


    // standard vat return 

    this.returnstndData = [];
    this.returnstandcurpage = '';
    this.returnstandlastpage = '';
    this.retstrdfrom = '';

    this.retstrdnxtpg = '';
    this.retstrdprevpg = '';


    // zero vat 

    this.zeroData = [];
    this.zerocurpage = '';
    this.zerolastpage = '';
    this.zerofrom = ''

    this.zeronxtpg = '';
    this.zeroprevpg = '';

    // zero vat return 

    this.returnzeroData = [];
    this.returnzerocurpage = '';
    this.returnzerolastpage = '';
    this.retzerofrom = '';

    this.retzeronxtpg = '';
    this.retzeroprevpg = '';

    // exempt vat 

    this.exemptData = [];
    this.exemptcurpage = '';
    this.exemptlastpage = '';
    this.exemptfrom = '';

    this.exemptnxtpg = '';
    this.exemptprevpg = '';

    // exempt vat return 

    this.returnexemptData = [];
    this.returnexemptcurpage = '';
    this.returnexemptlastpage = '';
    this.retexemptfrom = '';

    this.retexemptnxtpg = '';
    this.retexemptprevpg = '';

    // Import vat 

    this.imprtCustData = [];
    this.imprtCustcurpage = '';
    this.imprtCustlastpage = '';
    this.imprtCustfrom = '';

    this.imprtCustnxtpg = '';
    this.imprtCustprevpg = '';

    // Import vat return 

    this.returnimprtCustData = [];
    this.returnimprtCustcurpage = '';
    this.returnimprtCustlastpage = '';
    this.retimprtCustfrom = '';

    this.retimprtCustnxtpg = '';
    this.retimprtCustprevpg = '';

  }


  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('print-vatday');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

}
