import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fixed-header-footer-all-page',
  templateUrl: './fixed-header-footer-all-page.component.html',
  styleUrls: ['./fixed-header-footer-all-page.component.css']
})
export class FixedHeaderFooterAllPageComponent implements OnInit {
  @Input()saleQtnData;
  @Input()print_style;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()sales_settings;
  @Input()footImgUrl;
  noPage = [];
  noRowPerPage = 16;
  ttlPage = 1;
  data = [];
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {
      this.data = this.saleQtnData.sales_qtn_sub;
      this.ttlPage = Math.ceil((this.saleQtnData.sales_qtn_sub.length+4) /this.noRowPerPage);
      this.noPage = this.createRange(this.ttlPage);
      console.log(this.ttlPage)
      console.log(this.noPage)
      console.log(this.data)
    }
  }
  createRange(number,start=0){
    let noPage = [];
    for(var i = start; i < number; i++){
       noPage.push(i);
    }
    return noPage;
  }

}
