import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../service/core.service';


@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements OnInit {
  up: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();

  }

}
