import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { ActivatedRoute  } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
// modal
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-van-transfer',
  templateUrl: './van-transfer.component.html',
  styleUrls: ['./van-transfer.component.css']
})


export class VanTransferComponent implements OnInit {

  @ViewChild('fieldName4', { static: false }) fieldName4: ElementRef;
  @ViewChild('fieldName5', { static: false }) fieldName5: NgSelectComponent;
  @ViewChild('fieldName3', { static: false }) fieldName3: ElementRef;

  taxCategories = [];
  unit_qty: any;
  display_qty: any;
  product_summary: any;
  purch_no: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  vantran_godown_id: any;
  vantran_van_id: any;
  brcode: any;
  prd_stock_statusChecked: any;
  prd_stock_status: any;

  prd_id: any;
  tran_id: number;
  vantran_purch_price: any;
  prd_barcode: String;
  vantransub_qty: any;
  vantran_purch_amount: number;
  vantransub_unit_id: any;
  default_unit: any;
  total_qty = 0;
  NgForm: any;
  total_amount: any;
  sel_gd_id = 0;
  tranArray: any;
  sl_no = 0;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  resultobj: any = {};
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  box = 0;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any;
  preview_items: any[];
  base_rate: number;
  pageLoadingImg: boolean;
  sale_rate: any;
  salestatus: any; data: any;
  showrate: any;
  godown_all = [
    { "gd_id": 0, 'gd_name': 'Shop' }
  ];
  gdid: number;
  errObjArr = {
    tandateErr: null, tanvanErr: null,
    tangdErr: null
  };
  errObjTran = {
    fromErr: null, toErr: null,
    sameErr: null
  };
  rate_status: any;
  rate_status_type: any;
  preview_branch: any;
  base_url: any;
  preview_time: any;
  gddef: any;
  preview_tot_qty: any;
  preview_van_users:any;
  userEncpType: any;
  godownStocksTotal = 0;
  godownStocks: any;
  selctedProd: any;
  total_base_qty: any;
  total_derived_qty: any;
  pageLoadingImg1 = false;
  showPurchImportSection = false;
  showTransfCont = false;
  purch_id_no:any;
  vanTransBtn = false;
  settings: any;
  van_searchType: any;
  usr_hide_purch_cost: any;
  srch_count: number;
  van_notes: any;
  country_dec: string;
  pageLoadingImg2: boolean;
  edit_trnsfer: boolean=false;
  existingArray: any;
  edit_transfer_display: any;
  vanTransBtn1: boolean=false;
  van_difault_search: any;
  barcodesrch: boolean=false;
  van_difault_rate: any;
  total_drvd_qty= <any>0;
  rate_show: boolean;
  unit_wise_avlbleqty: any;
  van_sale_byunit: any;
  van_rate_validation: any;
  cmpny: any;
  no_transfr: string='';
  copyLoading: boolean;
  copyRef: any;
  constructor(private apiService: ApiService, private coreService: CoreService,
    private datePipe: DatePipe, private modalService: NgbModal, private ActiveRoute: ActivatedRoute) { }
  ngOnInit() {
    this.cmpny = this.coreService.getUserData('cmpny');
    this.purch_id_no = this.ActiveRoute.snapshot.paramMap.get('purch_id');
    if (this.purch_id_no && !isNaN(this.purch_id_no)){
      document.getElementById("btnImportPurchase").click();
      this.puch_search();
    }
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });
    this.rate_show=true;
    this.vantran_date = new Date();

    this.prd_name_search = 0;
    this.getNxtVanTranId();
    this.listGodowns();
    this.readVanTransfers();
    this.searchVans(null);
    this.searchProducts(null, null);
    this.base_url = location.origin;
    this.gddef = this.coreService.getUserData('usr_default_godown_id');
    this.userEncpType = this.coreService.getUserData('usr_enc_rate_code');
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    if (this.gddef > 0) {
      this.vantran_godown_id = Number(this.gddef);
    }

this.getSalesSetting();
this.country_dec=this.coreService.setDecimalLength();
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.van_searchType = this.settings.van_searchType;
        this.van_difault_search = this.settings.van_default_search;
        this.van_difault_rate = this.settings.van_default_rate;
        this.van_sale_byunit = this.settings.van_sales_by_unit;
        this.van_rate_validation = this.settings.van_item_rate_validation;

        if(this.van_difault_rate){
          this.rate_status=false;
          this.rate_status_type = 'purch';
        }else{
        this.rate_status = true;
        this.rate_status_type = 'sale';
        }
      } else { 
        this.van_searchType = 0;
        this.van_sale_byunit = 0;

      }
    });

  }

  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];


    });

  }
  // search godown
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      // this.godown_all = res['data'];
      //this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });

  }

  searchTransfer(key) {
    let searchval = new FormData();
    searchval.append("status", '1');
    searchval.append("keyword", key);
    this.apiService.readVanTransfers(searchval, 1).subscribe((res) => {
      this.vanlines = res['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }




  readVanTransfers() {
    this.pageLoadingImg2=true;
    let searchval = new FormData();
    searchval.append("status", '1');
    this.apiService.readVanTransfers(searchval, 1).subscribe((res) => {
      this.pageLoadingImg2=false;
      this.vanlines = res['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg2=true;
    let searchval = new FormData();
    this.apiService.readVanTransfers(searchval, pageno).subscribe((res) => {
      this.pageLoadingImg2=false;
      this.vanlines = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }
  selectGodown(gdid: number) {

    this.gdid = gdid;
  }

  changeRate(rate) {

    if (rate == 'purch') {
     
      // this.vantran_purch_price = this.base_rate;
      this.rate_status_type = 'purch';
      this.convertRate(this.default_unit);

    } else if (rate == 'sale') {
     

      // this.vantran_purch_price = this.sale_rate;
      this.rate_status_type = 'sale';
      this.convertRate(this.default_unit);


    }

  }


  searchVans(search: string) {
    this.van_all = [];
    let searchval = new FormData();
    if (search) {
      searchval.append("van_name", search);
    }

    this.apiService.searchVans(searchval).subscribe((res) => {
      this.van_all = res['data'];

    });
  }

  resetItemsearch() {
    this.vantran_purch_price = 0;

    this.prd_barcode = '';
    this.brcode = '';
    this.default_unit = [];
    this.vantransub_qty = 0;
  }
  barcodeSearch(brcode: string) {
    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();
    searchval.append("brcode", brcode);
    this.apiService.searchVanProducts(searchval).subscribe((res) => {
      // this.prd_all = res['data'];
      this.prd_id = res['data'][0];
      this.setValuesbarcode(res['data'][0]);
      if(this.prd_id){
        this.getStockByUnit(this.prd_id.prd_id,this.prd_id.eanunit?this.prd_id.eanunit.sur_unit_id:this.prd_id.c_unit);
      }
      
     
      
      this.getStockDetails(this.prd_id.prd_id,this.vantran_godown_id);
    });
   
  }

  searchProducts(search: string, brcode: string) {
    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();
    this.alias = $("input[name='prd_name_search']:checked").val()
    if (search) {
      searchval.append("keyword", search);
      searchval.append("alias", this.alias);
      searchval.append("van_id", this.vantran_godown_id);
    }

    this.apiService.searchVanProducts(searchval).subscribe((res) => {
      this.prd_all = res['data'];
      this.srch_count = this.prd_all.length;
    });
  }

  getNxtVanTranId() {
    let searchval = new FormData();
    this.apiService.getNxtVanTranId(searchval).subscribe((res) => {
      this.tran_id = res['data'].tran_id;
    });

  }


  setValues(prd_data) {

  
    if (prd_data) {
    // if(prd_data.prd_id){
      this.getStockDetails(prd_data.prd_id, this.vantran_godown_id);
    }else{
      this.selctedProd=[];
      return false;
    }
    
    this.base_rate = prd_data.bs_prate;
    if (this.userEncpType) {
      if (this.rate_status_type == 'purch') {
        this.vantran_purch_price = this.coreService.splitToDigit(prd_data.bs_srate); // Encrypted Rate
      } else {
        this.vantran_purch_price = prd_data.bs_srate;

      }
      this.sale_rate = prd_data.bs_srate;

    } else {
      this.vantran_purch_price = this.sale_rate = prd_data.bs_srate;
    }
    this.prd_barcode = prd_data.prd_barcode;
    this.prd_units = prd_data.prd_units;
    this.vantransub_qty = '';

    if(prd_data.prd_default_unit){
      this.default_unit = prd_data.prd_default_unit;
      
      
      this.convertRate(this.default_unit)
    }else{
      if (!prd_data.eanunit) {
        this.default_unit = { "unit_code": prd_data.base_unit_code, "unit_name": prd_data.base_unit_name, "sur_unit_id": prd_data.prd_base_unit_id, "unit_base_qty": 1 ,"purch_rate":prd_data.bs_prate,"sale_rate":prd_data.bs_srate};

      
        
      }
      else {
        this.default_unit = prd_data.eanunit;

      

        this.base_rate = prd_data.eanunit.purch_rate;
        if (this.userEncpType) {
          if (this.rate_status_type == 'purch') {
            this.vantran_purch_price = this.coreService.splitToDigit(prd_data.eanunit.sale_rate); // Encrypted Rate
          } else {
            this.vantran_purch_price = prd_data.eanunit.sale_rate;
  
          }
          this.sale_rate = prd_data.eanunit.sale_rate;
        } else {
          this.vantran_purch_price = this.sale_rate = prd_data.eanunit.sale_rate;
        }
        
      }
      this.convertRate(this.default_unit);
    }


    this.fieldName3.nativeElement.focus();
  }

  setValuesbarcode(prd_data) {


    this.barcodesrch=true;
    
      if (this.rate_status_type == 'purch') {
        if (this.userEncpType) {
          this.vantran_purch_price = this.coreService.splitToDigit(prd_data.eanunit.purch_rate); // Encrypted Rate
        }else{
          this.vantran_purch_price=prd_data.eanunit.purch_rate;
        }
        
      } else {
        this.vantran_purch_price = prd_data.eanunit.sale_rate;
  
      }

      if(prd_data.prd_default_unit){
        this.default_unit = prd_data.prd_default_unit;
        if (this.rate_status_type == 'purch') {
          if (this.userEncpType) {
            this.vantran_purch_price = this.coreService.splitToDigit(this.default_unit.purch_rate); // Encrypted Rate
          }else{
            this.vantran_purch_price=this.default_unit.purch_rate;
          }
          
        } else {
          this.vantran_purch_price = this.default_unit.sale_rate;
    
        }

      }else{
        this.default_unit = prd_data.eanunit;
      }
      this.prd_barcode = prd_data.prd_barcode;
      this.prd_units = prd_data.prd_units;


    this.fieldName3.nativeElement.focus();
  }
  deleteItem(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {
      this.total_qty = this.selectedItems.length;
      var type = data.sl_no;
      var i;
      for (i = this.selectedItems.length - 1; i >= 0; --i) {
        if (this.selectedItems[i].sl_no == type) {
          this.selectedItems.splice(i, 1);
        }
      }
      if (this.selectedItems.length == 0){
        this.vanTransBtn1 = false;
        this.vanTransBtn = false;
      }
        $('#gdtran-btn').hide();
        // this.vanTransBtn = false;
      this.findTotalSum();
    }
  }

  validateForm() {

    this.printError = [];
    if ($('#prd_barcode').val() == "" || $('#prd_barcode').val() == undefined) {
      this.printError.push({ 'prd_barcodeErr': 'Required' });
      $('#prd_barcode').css("border", "1px solid red");
    } else {
      $('#prd_barcode').css("border", "1px solid #dedede");
    }
    if (this.vantransub_qty == undefined || this.vantransub_qty == null || this.vantransub_qty <= 0) {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#vantransub_qty').css("border", "1px solid red");
    } else {
      $('#vantransub_qty').css("border", "1px solid #dedede");
    }

    if(this.van_sale_byunit){
      if (this.unit_wise_avlbleqty < this.vantransub_qty) {
        this.printError.push({ 'vantransub_qtyErr': 'Required' });
        $('#vantransub_qty').css("border", "1px solid red");
      } else {
        $('#vantransub_qty').css("border", "1px solid #dedede");
      }
    }
    
    if(this.van_rate_validation){
      if ($('#vantran_purch_price').val() == "" || $('#vantran_purch_price').val() == undefined) {
        this.printError.push({ 'vantran_purch_priceErr': 'Required' });
        $('#vantran_purch_price').css("border", "1px solid red");
      } else {
        if ($('#vantran_purch_price').val() > 0) {
          $('#vantran_purch_price').css("border", "1px solid #dedede");
        } else {
          this.printError.push({ 'vantran_purch_priceErr': 'Required' });
          $('#vantran_purch_price').css("border", "1px solid red");
        }
      }
    }
   

  }

  validateTransfer(formData) {

    this.printError = [];
    if (formData.vantran_date == "" || formData.vantran_date == undefined) {
      this.errObjArr.tandateErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {

      // if (formData.vantran_date > new Date()) {
      //   this.errObjArr.tandateErr = "t";
      //   this.printError.push({ 'fieldErr': 'Required' });
      // } else {
        this.errObjArr.tandateErr = "f";
      // }
    }
    
    if (formData.vantran_van_id == "" || formData.vantran_van_id == undefined) {
      this.errObjArr.tanvanErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.tanvanErr = "f";
    }

    if (formData.vantran_godown_id == undefined) {
      this.errObjArr.tangdErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.tangdErr = "f";
    }
  }

  convertRate(vantransub_unit_id) {
    // console.log(vantransub_unit_id);

    this.getStockByUnit(this.prd_id.prd_id,vantransub_unit_id.unit_id?vantransub_unit_id.unit_id:vantransub_unit_id.sur_unit_id);
    if(this.barcodesrch){

      if (this.rate_status_type == 'purch') {

        if (this.userEncpType) {
          this.vantran_purch_price = this.coreService.splitToDigit(vantransub_unit_id.purch_rate); // Encrypted Rate
        } else {
          this.vantran_purch_price = vantransub_unit_id.purch_rate;
        }
      } else {
        this.vantran_purch_price = vantransub_unit_id.sale_rate;
        // this.rate_status_type = 'sale';
  
      }

    }else{
    if (this.rate_status_type == 'purch') {
      if (this.userEncpType) {
        this.vantran_purch_price = this.coreService.splitToDigit(this.base_rate * (vantransub_unit_id.unit_base_qty)); // Encrypted Rate
      } else {
        this.vantran_purch_price = this.base_rate * (vantransub_unit_id.unit_base_qty);
      }
    } else {
      this.vantran_purch_price = this.sale_rate * (vantransub_unit_id.unit_base_qty);
      // this.rate_status_type = 'sale';

    }
    }
  }

  appendItem(prd_data, prd_barcode, vantransub_unit_id) {

    // console.log(this.brcode+"gim0");
    if (prd_data && !prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];

      });

    }
    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {
      this.box = this.vantransub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (vantransub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);

      }


      this.sl_no = (this.selectedItems.length) + 1;

      this.vantransub_qty = Number(this.vantransub_qty);
      this.vantran_purch_amount = this.vantran_purch_price * this.vantransub_qty;
      // var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {
       
        
        this.existingArray = this.coreService.checkTransferExist(this.selectedItems,
              prd_data.branch_stock_id,
              vantransub_unit_id.sur_unit_id,
              this.vantran_purch_price,
              prd_data.prd_barcode,
              vantransub_unit_id.unit_code, prd_data.prd_id);
        
       
      }


      if (this.existingArray && this.existingArray.length > 0) {

        this.selectedItems.find(x => x.sl_no === this.existingArray[0].sl_no).vantransub_qty += this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === this.existingArray[0].sl_no).item_amount = this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === this.existingArray[0].sl_no).vantran_purch_amount += this.vantran_purch_amount;
      }
      else {

        this.selectedItems.push({
          "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
          "prd_id": prd_data.prd_id,
          "prd_name": prd_data.prd_name,
          "vantransub_qty": this.vantransub_qty,
          "box": this.box,
          "purchase_rate": this.vantran_purch_price,
          "purchase_amount": this.vantran_purch_amount,
          "vantransub_prod_id": prd_data.prd_id,
          "vantransub_stock_id": prd_data.bs_stock_id,
          "vantransub_branch_stock_id": prd_data.branch_stock_id,
          "vantransub_unit_id": vantransub_unit_id.sur_unit_id,
          "vantransub_purch_rate": this.vantran_purch_price,
          "unit_name": vantransub_unit_id.unit_name,
          "unit_base": vantransub_unit_id.unit_base_qty,
          "unit_code": prd_data.base_unit_code,
          "base_unit_name": prd_data.base_unit_name,
          "display_qty": this.display_qty,
          "item_unit_code": vantransub_unit_id.unit_code

        });
      }
      console.log('insert...');
      
      console.log(this.selectedItems);
      
      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.selectedItems.length;
      this.vantransub_qty = this.box;
      this.vanTransBtn = true;
      this.vanTransBtn1 = true;

      this.vantran_purch_price = 0;
      this.prd_barcode = '';
      this.brcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.vantransub_qty = 0;
      this.findTotalSum();
      $('#fieldName4').val('');

      if(this.van_difault_search){
        this.fieldName5.focus();
      }else{
        this.fieldName4.nativeElement.focus();
      }

      
    }
  }

  findTotalSum() {
    this.total_amount = 0;
    this.total_drvd_qty = 0;
    this.selectedItems.forEach(element => {

      this.total_amount += Number(element.purchase_rate * element.vantransub_qty);
      this.total_drvd_qty += Number(element.box);
    });
  }
  checkEnterClicked2(e) {
    if (e.keyCode === 13) {
      $("#plusButton2").click();
    }
  }
  vanTransfer(formData) {
    var confrm = confirm("Are you sure?");
    if (confrm) {
      this.validateTransfer(formData.value);
      if (this.printError.length <= 0) {
        this.pageLoadingImg = true;

        formData.value.items = this.selectedItems;
        formData.value.vantran_price = this.total_amount;
        formData.value.vantran_date = (formData.value.vantran_date) ?
          this.apiService.formatDate(formData.value.vantran_date) : formData.value.vantran_date;
        this.apiService.vanTransfer(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;

          this.coreService.createfunct(formData, this.resultobj, res.message);
          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;

          this.vanTransBtn = false;
          this.ngOnInit();

        });
      }
      else {

      }
    }
  }

  getPreview(data) {

    if(!this.rate_show){
      this.preview_prize=0;
    data.items.forEach((element, index) => {
     
        this.preview_prize += element.sales_rate*element.vantransub_qty;
    });
    }else{
    this.preview_prize = data.vantran_price;
     }

    this.preview_id = data.vantran_id;
    this.preview_date = data.vantran_date;
    this.preview_time = data.created_at;
    this.preview_gd = data.gd;
    this.preview_van = data.van;
    this.preview_branch = data.branch;
    // console.log(this.preview_branch);
    // this.preview_prize = data.vantran_price;
    this.preview_tot_qty = data.tot_qty;
    this.total_base_qty = data.total_base_qty;
    this.total_derived_qty = data.total_derived_qty;
    this.van_notes = data.vantran_notes;

    this.preview_items = data.items;
    this.preview_van_users = data.van_users;

  }

  edittransfer(data){

    
    this.edit_trnsfer=true;
    this.showTransfCont=true;
    this.tran_id=data.vantran_id;
    this.vantran_date=new Date(data.vantran_date);
    this.vantran_godown_id=data.vantran_godown_id;
    this.vantran_van_id=data.vantran_van_id;
    // this.selectedItems=data.items;
    // console.log(this.selectedItems.length +'  helooooo');
    
    this.total_amount=data.vantran_price;
    this.selectedItems=[];
    data.items.forEach((element, index) => {

      if(element.unit_type){
        this.display_qty='';
      }else{
        this.display_qty=element.unit_code_edit;
      }
      this.selectedItems.push({

        "sl_no": index+1, "prd_barcode": element.prd_barcode,
        "prd_id": element.vantransub_prod_id,
        "prd_name": element.prd_name,
        "vantransub_qty": element.vantransub_qty,
        "box": element.unit_base_qty,
        "purchase_rate": element.vantransub_purch_rate,
        "purchase_amount": element.vantransub_purch_rate,
        "vantransub_prod_id": element.vantransub_prod_id,
        "vantransub_stock_id": element.vantransub_stock_id,
        "vantransub_branch_stock_id": element.vantransub_branch_stock_id,
        "vantransub_unit_id": element.vantransub_unit_id,
        "vantransub_purch_rate": element.vantransub_purch_rate,
        // "unit_name": vantransub_unit_id.unit_name,
        "unit_base": element.cubq,
        "unit_code": element.unit_show_code,
        "base_unit_name": element.base_unit_name,
        "display_qty": this.display_qty,
        "item_unit_code": element.unit_show_code
        

      });
    });

    // code to get total derived qty
    this.total_drvd_qty = 0;
    this.selectedItems.forEach(element => {
      this.total_drvd_qty += Number(element.box);
    });
    
    this.total_qty = this.selectedItems.length;
    if(this.selectedItems.length !=0){
      this.vanTransBtn1 = true;
    }
    this.existingArray=this.selectedItems;
  }

  vanTransferUpddate(formData) {
    var confrm = confirm("Are you sure?");
    if (confrm) {
      this.validateTransfer(formData.value);

        this.pageLoadingImg = true;

        formData.value.items = this.selectedItems;
        formData.value.vantran_price = this.total_amount;
        formData.value.vantran_date = (formData.value.vantran_date) ?
          this.apiService.formatDate(formData.value.vantran_date) : formData.value.vantran_date;
        this.apiService.vanTransferEdit(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;
          this.edit_trnsfer=false;
          this.coreService.createfunct(formData, this.resultobj, res.message);
          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;

          this.vanTransBtn1 = false;
          this.ngOnInit();

        });
   
    }
  }

  getGodownWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.godownStocksTotal = 0;

    this.apiService.getGodownWiseStock(searchval).subscribe((res) => {
      this.godownStocks = res['data']['data'];
      this.godownStocksTotal = res['data']['total'];
    });

  }

  getStockDetails(prd_id, gd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];
    });

  }

  getStockByUnit(prd_id, unit_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('unit_id', unit_id);
    searchval.append('gd_id', this.vantran_godown_id);
    this.apiService.stockByUnit(searchval).subscribe((res) => {
      this.unit_wise_avlbleqty = res.avialable_qty;
    });

  }
  gdChange(){
    if(this.prd_id){
      this.getStockDetails(this.prd_id.prd_id, this.vantran_godown_id);
      if(this.vantransub_unit_id){
        this.getStockByUnit(this.prd_id.prd_id,this.vantransub_unit_id.unit_id?this.vantransub_unit_id.unit_id:this.vantransub_unit_id.sur_unit_id);
      }
     
    }
   
  }
  puch_search() {
    this.pageLoadingImg1 = true;
    const searchval = new FormData();
    searchval.append('purch_id', this.purch_id_no);
    this.clearForm();
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
     
      this.pageLoadingImg1 = false;
      this.showTransfCont = true;
      res.data.items.forEach((element,i) => {
        this.vantran_godown_id = element.purchsub_gd_id;
        this.selectedItems.push({
          "sl_no": i, 
          "prd_barcode": element.prd_barcode,
          "prd_id": element.prd_id,
          "prd_name": element.prd_name,
          "vantransub_prod_id": element.prd_id,
          "vantransub_stock_id": element.purchsub_stock_id,
          "vantransub_branch_stock_id": element.purchsub_branch_stock_id,
          "vantransub_qty": element.purchsub_qty,
          "box": element.purchsub_qty / element.punit_base_qty,
          "purchase_rate": element.purchsub_rate,
          "purchase_amount": element.purchsub_rate * element.purchsub_qty,
          "vantransub_unit_id": element.purchsub_unit_id,
          "vantransub_purch_rate": element.purchsub_rate,
          // unit name actually not found in purchase sub please chk if any mistake
          "unit_name": element.unit_name,
          "unit_base": element.punit_base_qty,
          "unit_code": element.unit_code,
          "base_unit_name": element.unit_name,
          "display_qty": (element.punit_base_qty != 1) ? element.punit_code : '',
          "item_unit_code": element.punit_code,

        });
      });
      if(res.data.items.length > 0){
        this.vanTransBtn = true;
      } 
      this.findTotalSum();

    });
  }

  clearForm(){
   
    this.getNxtVanTranId();
    this.vantran_godown_id = Number(this.gddef);
    this.vantran_van_id=0;
    this.no_transfr='';
    this.copyRef='';
    this.selectedItems = [];
    this.vanTransBtn = false;
    this.findTotalSum();
    this.edit_trnsfer=false;
    this.vantran_date = new Date();
    if(this.van_difault_rate){
      this.rate_status=false;
      this.rate_status_type = 'purch';
    }else{
    this.rate_status = true;
    this.rate_status_type = 'sale';
    }
  }

  copyTransfer(refno){

    const searchval = new FormData();
    searchval.append('purch_id', refno);
    this.copyLoading=true;
  
    this.apiService.getTranDetails(searchval).subscribe((res) => {

    if(res){

      this.copyLoading=false;
      this.vanTransBtn = true;
      this.edit_trnsfer=false;

      this.vantran_godown_id=res[0].gd[0].gd_id;
      this.vantran_van_id=res[0].van[0].van_id;
      // this.selectedItems=data.items;
      // console.log(this.selectedItems.length +'  helooooo');
      this.total_amount=res[0].vantran_price;
     
      this.selectedItems=[];
      res[0].items.forEach((element, index) => {
  
        if(element.unit_type){
          this.display_qty='';
        }else{
          this.display_qty=element.unit_code_edit;
        }
        this.selectedItems.push({
  
          "sl_no": index+1, "prd_barcode": element.prd_barcode,
          "prd_id": element.vantransub_prod_id,
          "prd_name": element.prd_name,
          "vantransub_qty": element.vantransub_qty,
          "box": element.unit_base_qty,
          "purchase_rate": element.vantransub_purch_rate,
          "purchase_amount": element.vantransub_purch_rate,
          "vantransub_prod_id": element.vantransub_prod_id,
          "vantransub_stock_id": element.vantransub_stock_id,
          "vantransub_branch_stock_id": element.vantransub_branch_stock_id,
          "vantransub_unit_id": element.vantransub_unit_id,
          "vantransub_purch_rate": element.vantransub_purch_rate,
          // "unit_name": vantransub_unit_id.unit_name,
          "unit_base": element.cubq,
          "unit_code": element.unit_show_code,
          "base_unit_name": element.base_unit_name,
          "display_qty": this.display_qty,
          "item_unit_code": element.unit_show_code
          
  
        });
      });
  
      // code to get total derived qty
      this.total_drvd_qty = 0;
      this.selectedItems.forEach(element => {
        this.total_drvd_qty += Number(element.box);
      });
      
      this.total_qty = this.selectedItems.length;
      if(this.selectedItems.length !=0){
        this.vanTransBtn1 = true;
      }
      this.existingArray=this.selectedItems;

      this.no_transfr='';
    }else{
      this.copyLoading=false;
      this.no_transfr='No Transfer Found ID';
    }
    });
  }

}
