import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';

import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-visiting-rep',
  templateUrl: './visiting-rep.component.html',
  styleUrls: ['./visiting-rep.component.css']
})
export class VisitingRepComponent implements OnInit {

  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  is_storage=[
    { id: 0, name: 'No' },
    { id: 1, name: 'Yes' },
  ];
  vans: any;
  pgend: boolean;
  pgstart: boolean;
  pageLoadingImg: boolean;
  curpage: number;
  lastpage: number;
  repDate: any;
  repDates: any;
  visiting_rpt: any=[];
  currentMonth: any;
  period_type: any;
  from: any;
  ttl_visit: any;
  priceGroupList: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth() + 1;
    this.period_type=this.currentMonth;
    const searchval = new FormData();
    searchval.append("period_type", this.currentMonth);
    this.pageLoadingImg = true;
    this.apiService.vanVisitingReport(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      this.visiting_rpt=res.data;
      this.repDate=res.Date;
      this.repDates=res.Dates;
      this.ttl_visit=res.totl_visitors;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.from = res.from;
    
 
 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
 
     
     });

     this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
  }

  pageNext(formdata: { value: any; },pageno){

    this.pageLoadingImg = true;
    

    this.apiService.vanVisitingReport(formdata.value,pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      // const newItem =res.data;
      // this.visiting_rpt.push(newItem);
      // console.log(this.visiting_rpt);
      
      this.visiting_rpt=res.data;
      this.repDate=res.Date;
      this.repDates=res.Dates;
      this.ttl_visit=res.totl_visitors;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.from = res.from;
    
 
 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
 
     
     });
  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }
}
