import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-qtn-settings',
  templateUrl: './sales-qtn-settings.component.html',
  styleUrls: ['./sales-qtn-settings.component.css']
})
export class SalesQtnSettingsComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '150px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://127.0.0.1:8000/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  pageLoadingImg: boolean;
  settings: any;
  sales_qtn_default_terms = '';
  prod_img=0;
  prod_imgs = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  item_cod = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  ean_cod = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  item_descr = [
    { id: 0, name: 'Hide' },
    { id: 1, name: 'Show' },
  ];
  qtn_item_code: any;
  qtn_ean_code: any;
  qtn_item_descrptn: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getSalesSetting();
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.sales_qtn_default_terms = this.settings.sales_qtn_default_terms;
        this.prod_img = this.settings.prd_img_enb;
        this.qtn_item_code = this.settings.qtn_item_code_disply;
        this.qtn_ean_code = this.settings.qtn_ean_code_disply;
        this.qtn_item_descrptn = this.settings.qtn_item_descrptn;

      } else { 
        this.sales_qtn_default_terms = '';
        this.prod_img= 0;

      }
    });

  }
  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updateSalesQtnSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  langChange(){
    this.translate.get(['Common.Enable', 'Common.Disable']).subscribe((res: string) => {    
     

      this.prod_imgs = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
  
      ];
      this.item_cod = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
  
      ];
      this.ean_cod = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
  
      ];
    });

  }
  
}
