import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-qtn-template-one-print',
  templateUrl: './qtn-template-one-print.component.html',
  styleUrls: ['./qtn-template-one-print.component.css']
})
export class QtnTemplateOnePrintComponent implements OnInit {
  @Input()saleQtnData;
  @Input()print_style;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()sales_settings;
  constructor() { }

  ngOnInit() {
  }

}
