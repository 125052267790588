import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { Manufacture } from '../../../model/manufacture.model';
import { CoreService } from '../../../service/core.service';
// validation
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';




@Component({
  selector: 'app-manufacture',
  templateUrl: './manufacture.component.html',
  styleUrls: ['./manufacture.component.css']
})
export class ManufactureComponent implements OnInit {
  manftr_comp_name: any;
manftr_comp_code: any;
manftr_comp_address: any;
manftr_comp_phone: any;
manftr_comp_mobile: any;
manftr_comp_fax: any;
manftr_comp_email: any;
manftr_comp_contact_person: any;
manftr_comp_website: any;
manftr_comp_notes: any;
mfsall: any;
  selectedData: any = { id: null, error: null,message:null,manftr_comp_flags:null};
  result = '';  
  mfs:string[];

  angForm: FormGroup;
  pageLoadingImg :boolean;
  up: any;

  constructor(private apiService: ApiService,private coreService: CoreService,private fb: FormBuilder) { }

  ngOnInit() {
    this.coreService.getToken(); 
    this.createForm();
    this.up = this.coreService.getUserPrivilage();

  }
    
  createForm() {
    this.angForm = this.fb.group({
       name: ['', Validators.required ]
    });
  }

  createManf(formdata: { value: Manufacture; }) {  
    this.pageLoadingImg = true;

      this.apiService.createManf(formdata.value).subscribe((res: Manufacture) => {
        this.pageLoadingImg = false;

        if (res.error != null) {        
          this.result = res.error;             
        }
        else {
         this.result='';
         this.coreService.createfunct(formdata,this.result,res.message);   
        }

      });
    }
       
  
 updateManf(formdata: { value: Manufacture; })
 {
  this.pageLoadingImg = true;

  if(formdata.value.manftr_comp_flags == true)
  {
  formdata.value.manftr_comp_flags = 1;  
  }else
  {
  formdata.value.manftr_comp_flags = 0;
  }  
    
    this.apiService.updateManf(formdata.value).subscribe((res: Manufacture) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        // console.log(res.error);
        this.result = res.error;
      }
      else {     
        this.result='';
        $("#editmanf").hide();  
        this.coreService.updatefunct(formdata,this.result,res.message);           
      this.mfs=[];
      }

    });
  }
  

  //form search
searchManf(search: string)
{
 let searchval = new FormData();      
      searchval.append("manftr_comp_name", search);    
       this.apiService.getManfbyName(searchval).subscribe((res) => {
        this.mfs = res['data']; 
        $('.prev-list').show();              
       });
}

  //form search
  searchForm(search: string)
  {
   let searchval = new FormData();      
        searchval.append("manftr_comp_name", search);    
         this.apiService.getManfbyName(searchval).subscribe((res) => {
          this.mfs = res['data']; 
          $('.prev-list').hide();              
         });
  }


removeName()
{
 this.mfs = [];
 $('.prev-list').hide(); 
}

 selectManf(manf:Manufacture)
 {
   if (manf)
  {
  $("#editmanf").show(); 
  this.selectedData = manf; 
  this.mfs = []; 
 }
}


 tabClick(tab: { index: number; }) {   
  if(tab.index==1)
this.result = ''; 
}




}
