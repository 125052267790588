import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-purchase-and-return-report-summary',
  templateUrl: './purchase-and-return-report-summary.component.html',
  styleUrls: ['./purchase-and-return-report-summary.component.css']
})
export class PurchaseAndReturnReportSummaryComponent implements OnInit {
  filter_branch_id: number;
  userType: any;
  branch_all: any;
  from: any;
  vch_type: any;
  add_by: any;
  payment_type: any;
  ExpModelData: any;
  fileName = 'purchase_and_return_summary.xlsx';
  exp_report: any;
  exp_from: any;
  exp_purchaseTotal: any;
  exp_purchDiscTotal: any;
  exp_purchInclVat: any;
  exp_totPurchVat: any;
  exp_purchExclVat: any;
  exp_balanceAmt: any;
  exp_reportType: any;
  exp_returnTotal: any;
  exp_returnDiscTotal: any;
  exp_retInclVat: any;
  exp_retVat: any;
  exp_retExclVat: any;
  exp_balanceExclVatAmt: any;
  exp_repDate: any;
  up: any;
  purchDiscTotal: any;
  purchInclVat: any;
  purchVat: any;
  purchExclVat: any;
  returnDiscTotal: any;
  retInclVat: any;
  retVat: any;
  retExclVat: any;
  balanceExclVatAmt: any;
  totPurchVat: any;
  pdf: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  country_dec: string;
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }
  pageLoadingImg:boolean;
  slnum:number;
  curpage:number;
  lastpage:number;
  pgend:boolean;
  pgstart:boolean;
  report:any;
  purchaseTotal;
  returnTotal;
  balanceAmt;
  supplierAll:any;
  values:any;
  resultobj:any;
  period_type:any;
  date1:any;
  date2:any;
  purch_ref_filter:any;
  voucher_filter:any;
  reportType:any;
  repDate:any;
  purch_supp_id:any;

  filter_date_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];



  filter_type = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '2', name: 'Voided' },

  ];

  voucher_type = [
    { id: '1', name: 'Purchase & Return' },
    { id: '2', name: 'Purchase' },
    { id: '3', name: 'Return' },


  ];
  exportLoader: boolean;
  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
    
    this.searchUser('');
    this.searchSupplier('');
    this.up = this.coreService.getUserPrivilage();
    this.exportLoader = false;
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.userType =  this.coreService.getUserData('user_type');
    
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('rep_type','1');
    this.vch_type = '1';
    this.apiService.purchaseAndreturn(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;

      this.report = res.data.data;
      this.from = res.data.from;
      this.pdf = res.data.pdf;
      // this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.purchaseTotal = res.data.totalCount.purchaseTotal;
      this.purchDiscTotal = res.data.totalCount.purchDic;
      this.purchInclVat = res.data.totalCount.purchinclVat;
      this.totPurchVat = res.data.totalCount.purchVat;
      this.purchExclVat = res.data.totalCount.purchExclVat;
      this.balanceAmt = res.data.totalCount.balanceAmt;
      this.reportType = res.data.reportType;
      this.returnTotal = res.data.totalCount.returnTotal;
      this.returnDiscTotal = res.data.totalCount.returnDisc;
      this.retInclVat = res.data.totalCount.retInclVat;
      this.retVat = res.data.totalCount.returnVat;
      this.retExclVat = res.data.totalCount.returnExclVat;
      this.balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
      this.branch_display_name = this.coreService.getUserData('branch_disp_name');
      this.branch_name = this.coreService.getUserData('branch_name');
      this.branch_display_code = this.coreService.getUserData('branch_code');

    });

    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
   
   

  
  }
  generatePdf() {



    var filename = 'purchase_and_return_summary';

    var docDefinition = {
      content: [
        {
          text: this.branch_display_name,
          bold: true,
          fontSize: 13,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: 'PURCHASE AND RETURN SUMMARY',
          bold: true,
          fontSize: 16,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [
              {
                text: (this.repDate && this.repDate.date1 && this.repDate.date2) ? 'Duration : ' + this.repDate.date1 + ' - ' + this.repDate.date2 : '',
                fontSize: 11,
                margin: [0, 0, 0, 12]
              },
              {
                text: 'Net Purchase Amount : ' + Math.round(this.purchaseTotal * 1000) / 1000,

                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Discount : ' + Math.round(this.purchDiscTotal * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Amount Incl. Vat : ' + Math.round(this.purchInclVat * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total VAT Amount : ' + Math.round(this.totPurchVat * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },

              {
                text: 'Total Amount Exclude VAT: ' + Math.round(this.purchExclVat * 1000) / 1000,

                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Balance Amount : ' + Math.round(this.balanceAmt * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Net Return Amount : ' + Math.round(this.returnTotal * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Return Discount : ' + Math.round(this.returnDiscTotal * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Return Incl. Vat : ' + Math.round(this.retInclVat * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Returned Vat Amount : ' + Math.round(this.retVat * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Return Amount Exclude VAT : ' + Math.round(this.retExclVat * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Balance Amount Exclude VAT : ' + Math.round(this.balanceExclVatAmt * 1000) / 1000,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              }
            ],
            [
              // Document definition for Profile pic
            ]
          ],
          columnStyles: { comment: { columnWidth: 'auto' } },
          styles: {
            name: {
              fontSize: 16,
              bold: true,

            },
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 20, 0, 10],
              decoration: 'underline'
            },
            jobTitle: {
              fontSize: 14,
              bold: true,
              italics: true
            },
            tableHeader: {
              bold: true,
            }
          },
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers

            headerRows: 1,
            styles: {
              cellPadding: 0.5,
              fontSize: 12
            },
            //startY: 30, /* if start position is fixed from top */
            tableLineColor: [0, 0, 0], //choose RGB
            tableLineWidth: 0.5, //table border width
            //widths: [ '*', 'auto', '*', '100','*','*',,'*','*','*' ,'*' ],
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: this.pdf,
            bodyStyles: {
              margin: 40,
              fontSize: 10,
              lineWidth: 0.2,
              lineColor: [0, 0, 0]
            },
            theme: 'grid',
            columnStyles: {
              text: {
                cellWidth: 'auto'
              }
            }
          }
        }
      ]
    };
    pdfMake.createPdf(docDefinition).download(filename + ".pdf");



  }

  ngOnDestroy(){
    $('.close').click();
 
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }


  selectType(id: string) {
    if (id == 'c') {
      $('#val1').show();

    } else {
      $('#val1').hide();


    }

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }












  pageNext(formdata: { value: any; }, pageno: any) {
    
    this.pageLoadingImg = true;
    // const searchval = new FormData();
    formdata.value.export = '';
    this.apiService.purchaseAndreturn(formdata.value,pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.pdf = res.data.pdf;
      this.report = res.data.data;
      // this.purchInvSum = res.data.data;
      this.from = res.data.from;

      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.purchaseTotal = res.data.totalCount.purchaseTotal;
      this.purchDiscTotal = res.data.totalCount.purchDic;
      this.purchInclVat = res.data.totalCount.purchinclVat;
      this.totPurchVat = res.data.totalCount.purchVat;
      this.purchExclVat = res.data.totalCount.purchExclVat;
      this.balanceAmt = res.data.totalCount.balanceAmt;
      this.reportType = res.data.reportType;
      this.returnTotal = res.data.totalCount.returnTotal;
      this.returnDiscTotal = res.data.totalCount.returnDisc;
      this.retInclVat = res.data.totalCount.retInclVat;
      this.retVat = res.data.totalCount.returnVat;
      this.retExclVat = res.data.totalCount.returnExclVat;
      this.balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
      this.reportType = res.data.reportType;
      this.repDate = res.data.Date;
    
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
   }

 //form search
 searchUser(search: string) {
  let searchval = new FormData();
  searchval.append("usr_name", search);
  this.apiService.getUserByName(searchval).subscribe((res) => {
    this.values = res['data'];
  });
}
exportexcel(): void {
  /* table id is passed over here */
  let element = document.getElementById('export-group');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
  this.exportLoader = false;

}
export(formdata: { value: any; }) {
  this.exportLoader = true;

  // debugger;
  formdata.value.export = 'export';
  this.apiService.purchaseAndreturn(formdata.value,1).subscribe((res: any) => {
    this.pageLoadingImg = false;

    

    this.exp_report = res.data.data;
    // this.purchInvSum = res.data.data;
    this.exp_from = res.data.from;

    this.exp_purchaseTotal = res.data.totalCount.purchaseTotal;
    this.exp_purchDiscTotal = res.data.totalCount.purchDic;
    this.exp_purchInclVat = res.data.totalCount.purchinclVat;
    this.exp_totPurchVat = res.data.totalCount.purchVat;
    this.exp_purchExclVat = res.data.totalCount.purchExclVat;
    this.exp_balanceAmt = res.data.totalCount.balanceAmt;
    this.exp_reportType = res.data.reportType;
    this.exp_returnTotal = res.data.totalCount.returnTotal;
    this.exp_returnDiscTotal = res.data.totalCount.returnDisc;
    this.exp_retInclVat = res.data.totalCount.retInclVat;
    this.exp_retVat = res.data.totalCount.returnVat;
    this.exp_retExclVat = res.data.totalCount.returnExclVat;
    this.exp_balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
    this.exp_repDate = res.data.Date;
  
    this.slnum = 0;
    this.ExpModelData = true;


    setTimeout(() => {
      this.exportexcel();
    }, 3000);



  });



}


  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
  
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

}
