import { Component, OnInit, ɵConsole, ComponentFactoryResolver } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
  staff_laccount_no: any;
  staff_notes: any;
  tabClick: any;

  resultobj: any =  {};
  ledgerAll: string[];
  staff = {
    staff_laccount_no: 0,
    staff_notes: ''
  };
  ledger: any;
  ledgerId = '';
  pageLoadingImg:boolean;
  pageLoadingImg1:boolean;
  staffs = [];
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.getAllSatff();
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    

  }

  searchLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    
    this.apiService.getLedDetailsByName(searchval).subscribe((res) => {
      this.ledgerAll = res['data'];
    });
  }
  selectFrom(ledger) {
    this.ledgerId = ledger.ledger_id;
  }
  createStaff(formgroup: { value: any; }) {
    this.pageLoadingImg = true;
    // console.log(this.staff);
    formgroup.value.staff_laccount_no = this.ledgerId;
    // console.log(this.staff_laccount_no);
    // console.log(formgroup.value);
    this.apiService.createStaff(formgroup.value).subscribe((res) => {
      this.pageLoadingImg = false;
      // console.log(this.staff);
      if (res.error != null) {
        // console.log('result');
        // console.log(res.error);
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
        console.log(this.resultobj);
      } else {
        this.getAllSatff();
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
      }

    });
  }
  getAllSatff() {
    this.pageLoadingImg1 = true;
    this.apiService.listAllStaff().subscribe((res) => {
      this.staffs = res.data;
      this.pageLoadingImg1 = false;
    });
  }
  activate(id) {
    this.apiService.changeStaffStatus(id).subscribe((res) => {
      this.coreService.showMessage(res.message);
    });
  }
}
