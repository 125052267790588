import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-product-settings',
  templateUrl: './product-settings.component.html',
  styleUrls: ['./product-settings.component.css']
})
export class ProductSettingsComponent implements OnInit {
  types = [
    { id: 1, name: 'Goods' },
    { id: 0, name: 'Services' },
  ];
  default_item_creation: any;
  pageLoadingImg: boolean;
  settings: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }
  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getPrdSetting();
  }
  getPrdSetting() {
    this.apiService.getPrdSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.default_item_creation = this.settings.default_item_creation;
      } else { 
        this.default_item_creation = 1;
      }
    });

  }
  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updatePrdSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  langChange(){
    this.translate.get(['Product.Goods', 'Product.Services']).subscribe((res: string) => {    
     

      this.types = [
        { id: 1, name: res['Product.Goods']},
        { id: 0, name: res['Product.Services'] },
  
      ];
      
    });

  }
}
