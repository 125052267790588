import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-voucher-settings',
  templateUrl: './voucher-settings.component.html',
  styleUrls: ['./voucher-settings.component.css']
})
export class VoucherSettingsComponent implements OnInit {


  display = [
    { id: 0, name: 'Hide' },
    { id: 1, name: 'Display' },
  ];
  pageLoadingImg: boolean;
  vsettings: any;
  jrnl_cb_display: any;
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.getVoucherSetting();
  }
  getVoucherSetting() {

    this.apiService.getVoucherSetting().subscribe((res) => {
      if (res['data']) {
        this.vsettings = res['data'];
        this.jrnl_cb_display = this.vsettings.vs_cash_bank_journal;
       

      }
    });

  }

  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updateVoucherSettings(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

}
