import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-user-privilages',
  templateUrl: './user-privilages.component.html',
  styleUrls: ['./user-privilages.component.css']
})
export class UserPrivilagesComponent implements OnInit {


  selectedData: any;
  items: any;
  pageLoadingImg: boolean;
  menuIds = [];
  subIds = [];
  users: any;
  usr_id: any;
  ledger_id: any;
  privilageRes: any;
  up: any;
  user_id: any;
  selectedUser: any = { usr_id: null, usr_email: null, error: null, message: null, usr_name: null, usr_type: null };
  buttonLoadingImg: boolean;
  stkprd_all: any;
  selecTed: any;
  catagories: any;
  count: any;

  comsn_types = [
    { id: 1, name: 'By Amount' },
    { id: 2, name: 'By Percentage' },
  ];
  showtab: boolean=false;
  allcomision_type: any;
  allprd_commision: any;
  otherPr: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    // console.log(this.up);
    this.getMenuByGroup(1);
    this.user_id = this.coreService.getUserData('usr_id');
    this.searchUser('');
  }


  getMenuByGroup(grp_id) {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("grp_id", grp_id);
    this.items = [];

    this.apiService.getMenuByGroup(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.items = res.data;


    });
  }


  tabClick(tab) {
    
    
    this.tabClickChanges();
    if (this.usr_id) {

      
      let searchval = new FormData();
      searchval.append("usr_id", this.usr_id.usr_id);
      this.apiService.getUserPrivilages(searchval).subscribe((res) => {
        this.menuIds = res['data'];
        this.subIds = res['data'];
      });

      this.ledger_id = this.usr_id.usr_id;
      if(this.otherPr){
        this.selectedUser.usr_enc_rate_code = Number(this.otherPr.usr_enc_rate_code);
        this.selectedUser.usr_hide_purch_cost = Number(this.otherPr.usr_hide_purch_cost);
        this.selectedUser.usr_hide_sale_order_rate = Number(this.otherPr.usr_hide_sale_order_rate);
        this.selectedUser.usr_hide_othr_sales_data = Number(this.otherPr.usr_hide_othr_sales_data);
        this.selectedUser.usr_display_othr_branch_stk = Number(this.otherPr.usr_disply_othr_branch_data);
        this.selectedUser.usr_block_pre_date_select = Number(this.otherPr.usr_block_pre_date_select);
        // this.selectedUser.usr_default_alias = Number(this.otherPr.usr_default_alias);
      }
      
    } else {
      this.ledger_id = 0;
      this.selectedUser.usr_enc_rate_code = 0;
      this.selectedUser.usr_hide_purch_cost = 0;
      this.selectedUser.usr_hide_sale_order_rate = 0;
      this.selectedUser.usr_hide_othr_sales_data = 0;
      this.selectedUser.usr_display_othr_branch_stk = 1;
      this.selectedUser.usr_block_pre_date_select = 0;
      // this.selectedUser.usr_default_alias = 0;

    }

    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append("grp_id", tab.index + 1);
    this.items = [];
    this.apiService.getMenuByGroup(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.items = res.data;


    });



  }

  tabClickChanges(){
    let searchval = new FormData();
    searchval.append("usr_id", this.usr_id.usr_id);
    this.apiService.getUserOtherPrivilage(searchval).subscribe((res) => {
      this.otherPr = res;
      
      
    });
  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }


  updatePrivilage(formgroup, grp) {
    formgroup.value.acc_grp = grp;

    this.apiService.updatePrivilage(formgroup.value).subscribe((res) => {
      this.privilageRes = res['data'];
      // console.log(this.privilageRes);
      let searchval = new FormData();
      searchval.append("usr_id", formgroup.value.ledger_id);
      this.apiService.getUserPrivilages(searchval).subscribe((res) => {
        this.menuIds = res['data'];
      });
      if (this.menuIds) {
        if (this.user_id == formgroup.value.ledger_id) {
          localStorage.removeItem("urpg");
          this.coreService.setLocalStorage('urpg', JSON.stringify(this.menuIds));
        }
      }
      this.coreService.showMessage('Updated Successfully');

    });
    // console.log(formgroup.value);
  }



  updateOtherPrivilage(formgroup) {
    
    this.buttonLoadingImg = false;

    this.apiService.updateRateCodeEncp(formgroup.value).subscribe((user: any) => {
      this.pageLoadingImg = false;
      this.buttonLoadingImg = false;

      console.log(user);

      if (user.error != null) {
        console.log(user.error);
      }
      else {
        // localStorage.removeItem("urpg");
        // this.coreService.setLocalStorage('urpg', JSON.stringify(this.menuIds));
        this.coreService.showMessage('Updated Successfully');


      }

    });

    // console.log(formgroup.value);
  }

  changeSelection(value){
    for (var key in this.menuIds) {
      this.menuIds[key] = value;
   }

  }

  commsnApply(cmsn) {
    if (cmsn) {
      console.log('HEheheeh');
  
      if (this.selecTed && this.selecTed.data) { // Null check
        this.selecTed.data.forEach(element => {
          element.comision_type = this.allcomision_type;
          element.prd_commision = this.allprd_commision;
        });
      }
    }
  }
  selectUser(user) {
    if (user) {
      let searchval = new FormData();
      searchval.append("usr_id", user.usr_id);
      this.apiService.getUserPrivilages(searchval).subscribe((res) => {
        this.menuIds = res['data'];
        this.subIds = res['data'];
      });
      this.getMenuByGroup(1);

      this.ledger_id = user.usr_id;
      this.selectedUser.usr_enc_rate_code = Number(user.usr_enc_rate_code);
      this.selectedUser.usr_hide_purch_cost = Number(user.usr_hide_purch_cost);
      this.selectedUser.usr_hide_sale_order_rate = Number(user.usr_hide_sale_order_rate);
      this.selectedUser.usr_hide_othr_sales_data = Number(user.usr_hide_othr_sales_data);
      this.selectedUser.usr_display_othr_branch_stk = Number(user.usr_disply_othr_branch_data);
      this.selectedUser.usr_block_pre_date_select = Number(user.usr_block_pre_date_select);
      // this.selectedUser.usr_default_alias = Number(user.usr_default_alias);
    } else {
      this.ledger_id = 0;
      this.selectedUser.usr_enc_rate_code = 0;
      this.selectedUser.usr_hide_purch_cost = 0;
      this.selectedUser.usr_hide_sale_order_rate = 0;
      this.selectedUser.usr_hide_othr_sales_data = 0;
      this.selectedUser.usr_display_othr_branch_stk = 1;
      this.selectedUser.usr_block_pre_date_select = 0;
      // this.selectedUser.usr_default_alias = 0;

    }
  }



   //product form search 
   searchStkPrd(search: string)
   {
      let searchval = new FormData();      
      searchval.append("keyword", search);    
      this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
        this.stkprd_all = res['data'];
        this.selecTed = null; 
        
      });
   }

   searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
      this.selecTed = null;
    });

  }


  selectCat(cat_id) {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("prd_cat_id", cat_id);
    searchval.append("user_id", this.usr_id.usr_id);
  
    this.apiService.getProdDetByCat(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.selecTed = res['data'];
      this.count = res['data']['count'];


    });
  }

  selectStockPrd(data){

    let searchval = new FormData();
    searchval.append("prd_id", data.prd_id);
    searchval.append("user_id", this.usr_id.usr_id);
    this.apiService.getProdDet(searchval).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.selecTed = res;
      this.count = 1;


    });
  }

  updatePCPrivilage(product_summary) {
    let searchval = new FormData();
    this.buttonLoadingImg = true;
  //  searchval.append("usr_id", this.usr_id.usr_id);
      product_summary.value.user_id=this.usr_id.usr_id;
    this.apiService.addProductCommision(product_summary.value).subscribe((res) => {
      this.buttonLoadingImg = false;
      if (!res.error) {
      
        this.coreService.showMessage('Updated Successfully');
      }
      else {
      }
    });

  }

}
