import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Reppurchsum } from '../../../../../model/report/reppurchsum.model';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-purchase-summary',
  templateUrl: './purchase-summary.component.html',
  styleUrls: ['./purchase-summary.component.css']
})
export class PurchaseSummaryComponent implements OnInit {
  fileName = 'purchase_summary.xlsx';
  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  period_type: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  purch_supp_id: any;
  payment_type: any;
  purch_type: any;
  showNote: any;
  // products_all:string[];
  // catagories:string[];
  // subcatagories:string[];
  // mfs:string[];
  // cat_id:number;
  // unit_all:string[];
  // features: string[];
  // baseunit:any;
  purchsumms: string[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  supplierAll: string[];
  ModelData: boolean;

  total_purchase: number;
  total_purchase_amount: any;
  total_purch_frieght: any;
  total_purch_discount: any;
  total_tax_amount: any;
  purchase_amount_cash: any;
  purchase_amount_credit: any;
  amount_exclude_tax: any;
  total_supplier: number;
  total_days: number;
  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  repDate: any;
  purchase_types = [
    { id: 0, name: 'ALL' },
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Purchase' },
    { id: 4, name: 'Imports Vat Paid to Customs' },
    { id: 5, name: 'Imports Vat - reverse charge mechanism' }
  ];

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  branch_all: any;
  filter_branch_id: number;
  userType: any;


  Exppurchsumms: any;
  Exptotal_purchase: any;
  Exptotal_purchase_amount: any;
  Exptotal_purch_frieght: any;
  Exptotal_purch_discount: any;
  Exptotal_tax_amount: any;
  Expamount_exclude_tax: any;
  Exptotal_supplier: any;
  Exptotal_days: any;
  Exppurchase_amount_cash: any;
  Exppurchase_amount_credit: any;
  Expreport_type: any;
  ExpModelData: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  exportLoader: boolean;
  pdf: any;
  total_purchase_total: number;
  Exptotal_purchase_total: number;
  Expamount_incl_tax: number;
  amount_incl_tax: number;
  mn_sl_no: boolean;
  mn_purch_ref_no: boolean;
  mn_supp_inv_no: boolean;
  mn_supp: boolean;
  mn_supp_vat_no: boolean;
  mn_pay_type: boolean;
  mn_purch_amnt: boolean;
  mn_purch_tax: boolean;
  mn_purch_total: boolean;
  mn_purch_disc: boolean;
  mn_purch_frieght_amnt: boolean;
  mn_purch_net_amnt: boolean;
  year_rep: boolean;
  previewData: any[];
  is_prew_description: any;
  savedTemplate = {

    logo: false,
    header_type: 1
  }
  logoImgUrl: string;
  tempImgUrl: string;
  users:any;
  country_dec: string;
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.previewData = [];
    this.getTemplete();
    this.selectMenus(true);
    this.exportLoader = false;

    this.userType = this.coreService.getUserData('user_type');
    this.pageLoadingImg = true;
    this.getAllBranch();
    this.filter_branch_id = 1;
    let searchval = new FormData();
    this.apiService.purchaseSummary(searchval, 1).subscribe((res: Reppurchsum) => {
      this.pageLoadingImg = false;
      this.purchsumms = res['data']['data'];
      this.pdf = res['data']['pdf'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];


      this.total_purchase = res['data']['total_purchase'];
      this.total_purchase_amount = Number(res['data']['total_purchase_amount']);
      this.total_purch_frieght = Number(res['data']['total_purch_frieght']);
      this.total_purch_discount = Number(res['data']['total_purch_discount']);
      this.total_tax_amount = Number(res['data']['total_tax_amount']);
      this.amount_exclude_tax = Number(res['data']['amount_exclude_tax']);
      this.total_supplier = Number(res['data']['total_supplier']);
      this.total_days = Number(res['data']['total_days']);
      this.purchase_amount_cash = Number(res['data']['purchase_amount_cash']);
      this.purchase_amount_credit = Number(res['data']['purchase_amount_credit']);
      this.report_type = res['data']['report_type'];
      this.total_purchase_total = Number(res['data']['total_purch_total']);
      this.amount_incl_tax = Number(res['data']['amount_incl_tax']);








      if (this.report_type == 'year') {
        this.is_year_rep = true;
        this.year_rep = true;

      }else{
        this.is_year_rep = false;
        this.year_rep = false;

      }
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

      this.ModelData = false;
      this.purch_type = 0;
    });
    this.searchUser('');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.standard_rated_domestic','Common.zero_rated_domestic','Common.exempt_purchase','Common.imports_vat_paid_custms','Common.imports_vat_rvs_chage_mechnsm','HOME.credit','Common.cash','Common.NonVoided','Production.Voided']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.purchase_types = [
        { id: 0, name: res['Common.all'] },
        { id: 1, name: res['Common.standard_rated_domestic'] },
        { id: 2, name: res['Common.zero_rated_domestic'] },
        { id: 3, name: res['Common.exempt_purchase'] },
        { id: 4, name: res['Common.imports_vat_paid_custms'] },
        { id: 5, name: res['Common.imports_vat_rvs_chage_mechnsm'] }
      ];

      this.filter_paytype = [
        { id: '1', name: res['HOME.credit'] },
        { id: '2', name: res['Common.cash'] },
    
      ];
    
      this.filter_purchtypes = [
        { id: '1', name: res['Common.NonVoided'] },
        { id: '2', name: res['Production.Voided'] },
    
      ];

    });

  }
  searchUser(search: string)
  {
   let searchval = new FormData();      
   searchval.append("usr_name", search);    
   this.apiService.getUserByName(searchval).subscribe((res:any) => {
        this.users = res.data;     
      });
  }
  previewPurchase(id) {
    const searchval = new FormData();
    searchval.append('purch_id', id);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.previewData = res.data;
      this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
      setTimeout(function () { $("#previewButton").click(); }, 1000);

    });
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {

        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }


  generatePdf() {


    this.exportLoader = true;
    var filename = 'Purchase Summary';

    var docDefinition = {
      pageOrientation: 'landscape',
      content: [
        {
          text: this.branch_display_name,
          bold: true,
          fontSize: 13,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: filename,
          bold: true,
          fontSize: 16,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [
              {
                text: (this.repDate && this.repDate.date1 && this.repDate.date2) ? 'Duration : ' + this.repDate.date1 + ' - ' + this.repDate.date2 : '',
                fontSize: 11,
                margin: [0, 0, 0, 12]
              },
              {
                text: 'Total Purchases : ' + this.total_purchase,

                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Days : ' + this.total_days,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Supplier : ' + this.total_supplier,
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Tax Amount : ' + this.currencyPipe.transform(this.total_tax_amount,'', '', this.country_dec),
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },

              {
                text: 'Purchase Amount Excel Tax: ' +this.currencyPipe.transform( (this.total_purchase_amount - this.total_tax_amount),'', '', this.country_dec),

                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Purchase Amount Cash : ' +this.currencyPipe.transform( this.purchase_amount_cash,'', '', this.country_dec),

                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Purchase Amount Credit : ' + this.currencyPipe.transform(this.purchase_amount_credit,'', '', this.country_dec),
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Frieght Amount : ' + this.currencyPipe.transform(this.total_purch_frieght,'', '', this.country_dec),
                fontSize: 11,
                margin: [0, 0, 0, 20]
              },
              {
                text: 'Total Discount Amount : ' + this.currencyPipe.transform(this.total_purch_discount,'', '', this.country_dec),
                fontSize: 11,
                margin: [0, 0, 0, 20]
              }
            ],
            [
              // Document definition for Profile pic
            ]
          ],
          columnStyles: { comment: { columnWidth: 'auto' } },
          styles: {
            name: {
              fontSize: 16,
              bold: true,

            },
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 20, 0, 10],
              decoration: 'underline'
            },
            jobTitle: {
              fontSize: 14,
              bold: true,
              italics: true
            },
            tableHeader: {
              bold: true,
            }
          },
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers

            headerRows: 1,
            styles: {
              cellPadding: 0.5,
              fontSize: 12
            },
            //startY: 30, /* if start position is fixed from top */
            tableLineColor: [0, 0, 0], //choose RGB
            tableLineWidth: 0.5, //table border width
            //widths: [ '*', 'auto', '*', '100','*','*',,'*','*','*' ,'*' ],
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: this.pdf,
            bodyStyles: {
              margin: 40,
              fontSize: 10,
              lineWidth: 0.2,
              lineColor: [0, 0, 0]
            },
            theme: 'grid',
            columnStyles: {
              text: {
                cellWidth: 'auto'
              }
            }
          }
        }
      ]
    };
    pdfMake.createPdf(docDefinition).download(filename + ".pdf");
    this.exportLoader = false;


  }



  ngOnDestroy() {
    $('.close').click();

  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }


  selectType(id: string) {
    if (id == 'c') {
      $('#val1').show();

    } else {
      $('#val1').hide();


    }

  }

  selectMenus(value) {
    this.mn_sl_no = value;
    this.mn_purch_ref_no = value;
    this.mn_supp_inv_no = value;
    this.mn_supp = value;
    this.mn_supp_vat_no = value;
    this.mn_pay_type = value;
    this.mn_purch_amnt = value;
    this.mn_purch_tax = value;
    this.mn_purch_total = value;
    this.mn_purch_disc = value;
    this.mn_purch_frieght_amnt = value;
    this.mn_purch_net_amnt = value;
  }

  // selectRefNo(id:string)
  // {

  //   if(id == 'between' ){
  //     $('#dd1').show();
  //     $('#dd2').show();
  //   }else
  //   {
  //     $('#dd1').show();
  //     $('#dd2').hide();

  //   }  
  // }
  filter_refnum = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_price = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  // selectPrice(id:string)
  // {

  //   if(id == 'between' ){
  //     $('#pp1').show();
  //     $('#pp2').show();
  //   }else
  //   {
  //     $('#pp1').show();
  //     $('#pp2').hide();

  //   }  
  // }

  filter_discount = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  // selectDiscount(id:string)
  // {

  //   if(id == 'between' ){
  //     $('#kk1').show();
  //     $('#kk2').show();
  //   }else
  //   {
  //     $('#kk1').show();
  //     $('#kk2').hide();

  //   }  
  // }
  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }


  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '2', name: 'Voided' },

  ];

  pageNext(formdata: { value: any; }, pageno: any) {

    $('.resultdata').empty();
    this.pageLoadingImg = true;
    formdata.value.export = '';
    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.purchaseSummary(formdata.value, pageno).subscribe((res: Reppurchsum) => {
      this.pageLoadingImg = false;
      this.purchsumms = res['data']['data'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pdf = res['data']['pdf'];
      this.repDate = res['data'].Date;
      this.total_purchase = res['data']['total_purchase'];
      this.total_purchase_amount = Number(res['data']['total_purchase_amount']);
      this.total_purchase_total = Number(res['data']['total_purch_total']);

      this.total_purch_frieght = Number(res['data']['total_purch_frieght']);
      this.total_purch_discount = Number(res['data']['total_purch_discount']);
      this.total_tax_amount = Number(res['data']['total_tax_amount']);
      this.amount_exclude_tax = Number(res['data']['amount_exclude_tax']);
      this.amount_incl_tax = Number(res['data']['amount_incl_tax']);

      this.total_supplier = Number(res['data']['total_supplier']);
      this.total_days = Number(res['data']['total_days']);
      this.purchase_amount_cash = Number(res['data']['purchase_amount_cash']);
      this.purchase_amount_credit = Number(res['data']['purchase_amount_credit']);
      this.report_type = res['data']['report_type'];
      if (this.report_type == 'year') {
        this.is_year_rep = true;
        this.year_rep = true;

      }else{
        this.is_year_rep = false;
        this.year_rep = false;


      }


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  export(formdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    this.apiService.purchaseSummary(formdata.value, 1).subscribe((res: Reppurchsum) => {
      this.pageLoadingImg = false;
      this.Exppurchsumms = res['data']['data'];

      this.Exptotal_purchase = res['data']['total_purchase'];
      this.Exptotal_purchase_amount = Number(res['data']['total_purchase_amount']);
      this.Exptotal_purchase_total = Number(res['data']['total_purch_total']);

      this.Exptotal_purch_frieght = Number(res['data']['total_purch_frieght']);
      this.Exptotal_purch_discount = Number(res['data']['total_purch_discount']);
      this.Exptotal_tax_amount = Number(res['data']['total_tax_amount']);
      this.Expamount_exclude_tax = Number(res['data']['amount_incl_tax']);
      this.Expamount_incl_tax = Number(res['data']['amount_incl_tax']);
      this.Exptotal_supplier = Number(res['data']['total_supplier']);
      this.Exptotal_days = Number(res['data']['total_days']);
      this.Exppurchase_amount_cash = Number(res['data']['purchase_amount_cash']);
      this.Exppurchase_amount_credit = Number(res['data']['purchase_amount_credit']);
      this.Expreport_type = res['data']['report_type'];
      if (this.report_type == 'year') {
        this.is_year_rep = true;
        this.year_rep = true;
      }else{
        this.is_year_rep = false;
        this.year_rep = false;

      }

      this.slnum = 0;
      this.ExpModelData = true;
      this.purch_type = 0;


      setTimeout(() => {
        this.exportexcel();
      }, 3000);



    });



  }



}
