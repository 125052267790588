import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { ApiService } from './api.service';
import { Catagory } from 'src/app/model/catagory.model';
// import * as CryptoJS from 'crypto-js';
import * as SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  codes: any;
  count_dec: any;
  country_dl: string;
  getPurchaseChart(arg0: number) {
    throw new Error("Method not implemented.");
  }
  searchresult: string[];
  create: number;
  token: string;
  result = '';


  constructor(public snackBar: MatSnackBar, public router: Router, private apiService: ApiService) { }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  setDecimalLength(){
    this.count_dec=this.getUserData('branch_deci_length')?this.getUserData('branch_deci_length'):2;
     this.country_dl='1'+ '.'+this.count_dec+'-'+this.count_dec;
     
     return this.country_dl;
  }
  inpCurrencyLength(){
    this.count_dec=this.getUserData('branch_deci_length');
    //  this.country_dl='1'+ '.'+this.count_dec+'-'+this.count_dec;
     
     return this.count_dec;
  }


  removeDumplicateValue(myArray) {
    var newArray = [];

    $.each(myArray, function (key, value) {
      var exists = false;
      $.each(newArray, function (k, val2) {
        if (value.feat_id == val2.feat_id) { exists = true };
      });
      if (exists == false && value.feat_id != "") { newArray.push(value); }
    });

    return newArray;
  }

  removeDumplicateValueunit(myArray) {
    var newArray = [];

    $.each(myArray, function (key, value) {
      var exists = false;
      $.each(newArray, function (k, val2) {
        if (value.unit_id == val2.unit_id) { exists = true };
      });
      if (exists == false && value.unit_id != "") { newArray.push(value); }
    });

    return newArray;
  }

  checkDuplicateValue(myArray, unitid) {
    const { length } = myArray;
    const id = length + 1;
    const found = myArray.some(el => el.unit_id === unitid);
    if (found)
      return (found);

  }

  checkDuplicateValueEan(myArray, dean) {
    const { length } = myArray;
    const id = length + 1;
    const found = myArray.some(el => el.ean_barcode === dean);
    if (found)
      return (found);

  }

  checkDuplicateValueGdUnit(myArray, unitid, prdid) {
    const { length } = myArray;
    const id = length + 1;
    const foundp = myArray.some(el => el.prod_id === prdid);
    const foundu = myArray.some(el => el.prod_unit_id === unitid);
    if (foundu && foundp)
      return (foundu);

  }


  checkDuplicateVanTran(myArray, unitid, prdid) {
    const { length } = myArray;
    const id = length + 1;
    const foundp = myArray.some(el => el.prod_id === prdid);
    const foundu = myArray.some(el => el.prod_unit_id === unitid);
    if (foundu && foundp)
      return (foundu);

  }

  // check duplicate opstock
  checkDuplicateValOpstk(myArray, prdid,batchCode='') {
    console.log('batchCode');
    console.log(batchCode);
    const { length } = myArray;
    const id = length + 1;
    const found = myArray.some(el => el.prd_id === prdid && el.batch_code == batchCode);
    if (found)
      return (found);

  }

  // check duplicate opstock
  checkDuplicateValPchse(myArray, prdid) {
    const { length } = myArray;
    const id = length + 1;
    const found = myArray.some(el => el.purchsub_prd_id === prdid);
    if (found)
      return (found);
  }

  removeDumplicateValueTrim(myArray) {
  }

  createfunct(form, result, message) {
    result= null;
    this.showMessage(message);
    form.reset();
  }

  updatefunct(form, result, message) {
    this.snackBar.open(message, 'Close', {
      duration: 1000,
    });
    form.reset();

  }

  // check duplicate opstock
  checkItem(myArray, prdid, purchsub_rate, purchsub_unit_id,item_desc,batch_code = '') {
    const { length } = myArray;
    const id = length + 1;
    const found = myArray.some(el => el.purchsub_prd_id === prdid);
    const prate = myArray.some(el => el.purchsub_rate === purchsub_rate);
    const unitid = myArray.some(el => el.purchsub_unit_id === purchsub_unit_id);
    const batch = myArray.some(el => el.batch_code === batch_code);
    // const item_descrp = myArray.some(el => el.item_desc === '');
    // console.log(item_desc);
    if (found && prate && unitid && item_desc === '' && batch)
      return (found);
  }

  checkItemPret(myArray, prdid, purchsub_rate, purchsub_unit_id) {
    const { length } = myArray;
    const id = length + 1;
    const found = myArray.some(el => el.purchsub_prd_id === prdid);
    const prate = myArray.some(el => el.purchsub_rate === purchsub_rate);
    const unitid = myArray.some(el => el.purchsub_unit_id === purchsub_unit_id);
    if (found && prate && unitid)
      return (found);
  }


  // check duplicate opstock
  checkSaleItem(myArray, prdid, purchsub_rate, purchsub_unit_id,item_desc, batch_id= '') {
    
    const { length } = myArray;
    const id = length + 1;
    const found = myArray.some(el => el.salesub_prd_id === prdid);
    const prate = myArray.some(el => el.salesub_rate_with_tax === purchsub_rate);
    const unitid = myArray.some(el => el.salesub_unit_id === purchsub_unit_id);
    const batch = myArray.some(el => el.sb_id === batch_id);
    // const item_descrp = myArray.some(el => el.item_desc === '');
    // console.log(item_desc);
    if (found && prate && unitid && (item_desc === '' || item_desc === null) && batch)
      return (found);
  }

  // check duplicate transfer
  checkTransferExist(myArray, vantransub_branch_stock_id,
    vantransub_unit_id, purchase_rate, prd_barcode, item_unit_code,prd_id,batch_id= 0) {

      // console.log(myArray);
    // console.log(prd_barcode + "prd_barcode");


    // console.log( "***vantransub_branch_stock_id==" +vantransub_branch_stock_id + 
    // "***vantransub_unit_id==" + vantransub_unit_id + 
    // "***purchase_rate==" + purchase_rate  +"***prd_barcode=="+prd_barcode
    // +"***item_unit_code=="+item_unit_code+"***prd_id=="+prd_id);
    // var rslt = myArray.filter(
    //   (x => x.vantransub_branch_stock_id === vantransub_branch_stock_id) &&
    //   (y => y.vantransub_unit_id === vantransub_unit_id) &&
    //   (r => r.vantransub_purch_rate === purchase_rate) &&
    //   (v => v.prd_barcode === prd_barcode) &&
    //   (v => v.prd_id === prd_id) &&
    //   (v => v.item_unit_code === item_unit_code)
    // );

    var rslt = myArray.filter(function (item) {
      return ((item.vantransub_branch_stock_id == vantransub_branch_stock_id)
      &&(item.vantransub_unit_id == vantransub_unit_id)
      &&(item.vantransub_purch_rate == purchase_rate)&&(item.prd_barcode == prd_barcode)
      &&(item.item_unit_code == item_unit_code)
      &&(item.batch_id == batch_id)
      );
    });

    return rslt;

  }

  checkTransferReturnExist(myArray, vantransub_branch_stock_id, 
    vantransub_unit_id, vantransub_ret_rate) {


    console.log(vantransub_branch_stock_id + "==unit==" + vantransub_unit_id + "=rate===" + 
    vantransub_ret_rate);

      var rslt = myArray.filter(function (item) {
        return ((item.vantransub_branch_stock_id == vantransub_branch_stock_id)
        &&(item.vantransub_unit_id == vantransub_unit_id)&&
        (item.vantransub_ret_rate == vantransub_ret_rate));
      });

    return rslt;

  }

  findItem(myArray, prdid, purchsub_rate, purchsub_unit_id) {
    var existArray = myArray.filter((x => x.purchsub_prd_id === prdid) && (y => y.purchsub_unit_id === purchsub_unit_id) && (z => z.purchsub_rate === purchsub_rate));
    return (existArray);
  }

  findItemPurchase(myArray, prdid, purchsub_rate, purchsub_unit_id,item_desc, batch_code = '') {
    var existArray = myArray.filter((x => x.purchsub_prd_id === prdid && (x.item_desc == '' || x.item_desc == null) &&  x.purchsub_unit_id === purchsub_unit_id && x.purchsub_rate === purchsub_rate && x.batch_code === batch_code)); 
    return (existArray);
  }

  findItemSales(myArray, prdid, purchsub_rate, purchsub_unit_id,item_desc, batch_id = '') {
    var existArray = myArray.filter((x => x.salesub_prd_id === prdid && (x.item_desc == '' || x.item_desc == null) &&  x.salesub_unit_id === purchsub_unit_id && x.salesub_rate_with_tax === purchsub_rate && x.sb_id === batch_id)); 
    return (existArray);
  }


  // check duplicate opstock
  checkDuplicateValPchseret(myArray, prdid, purchsub_rate, purchsub_unit_name, purchretsub_tax_per) {
    const { length } = myArray;
    const id = length + 1;
    const found = myArray.some(el => el.purchretsub_prd_id === prdid);
    const prate = myArray.some(el => el.purchretsub_rate === purchsub_rate);
    const unitid = myArray.some(el => el.punitname === purchsub_unit_name);
    const taxper = myArray.some(el => el.purchretsub_tax_per === purchretsub_tax_per);
    if (found && prate && unitid && taxper)
      return (found);
  }

  findItemRet(myArray, prdid, purchsub_rate, purchsub_unit_name, purchretsub_tax_per) {
     for (var j = 0; j < myArray.length; j++) {
    if((Number(myArray[j]['purchretsub_prd_id']==prdid))&&(Number(myArray[j]['purchretsub_rate']==purchsub_rate))&& (Number(myArray[j]['punitname']==purchsub_unit_name))&& (Number(myArray[j]['purchretsub_tax_per']==purchretsub_tax_per))) {
    var sl_no =  myArray[j]['sl_no']; 
    return (sl_no); 
    } 
    }
    
  }

  setLocalStorage(key, value) {
    const ls = new SecureLS();
    ls.set(key, value); // set key1
  }

  getLocalStorage(key){
    const ls = new SecureLS();
    if (ls.get(key) !== '') {
      const data = JSON.parse(ls.get(key));
      return data;
    } else {
      return false;
    }
  }

  getUserData(item) {
    const ls = new SecureLS();
    if (ls.get('lcstg') !== '') {
      const data = JSON.parse(ls.get('lcstg'));
      return data[item];
    } else {
      return false;
    }
  }
  
  getUserBranch() {
    const ls = new SecureLS();
    if (ls.get('lcstg') !== '') {
      const data = JSON.parse(ls.get('lcstg'));
      return data.branch_name;
    } else {
      return false;
    }
  }

  getUserBranchId() {
    const ls = new SecureLS();
    if (ls.get('lcstg') !== '') {
      const data = JSON.parse(ls.get('lcstg'));
      return data.branch_id;
    } else {
      return false;
    }
  }

  getUserPrivilage() {
    const ls = new SecureLS();
    if (ls.get('urpg') !== '') {
      const data = JSON.parse(ls.get('urpg'));
      return data;
    } else {
      return false;
    }
  }

  getRateCodes() {
    const ls = new SecureLS();
    if (ls.get('rate_codes') !== '') {
      const data = JSON.parse(ls.get('rate_codes'));
      return data;
    } else {
      return false;
    }
  }

  getToken() {
    if (!localStorage.auth_token) {
      this.router.navigate(['/']);
    }
  }

  getValidation(resultobj: object) {
    for (var value in resultobj) {
      resultobj[value] = resultobj[value].toString();
    }
    return (resultobj);
  }

  showMessage(message,limit = 1000) {
    this.snackBar.open(message, 'Close', {
      duration: limit,
    });
  }


  splitToDigit(n) {

    var num = (n + '').split('').map((i) => { return i; })
    var numArray = [];

    this.codes = this.getRateCodes()

    num.forEach(element => {
      if (element || element == '.') {
        if (element == '.') {
          numArray.push(this.codes[10]);
        } else {
          numArray.push(this.codes[element]);
        }
      }

    });

    return numArray.join('');
  }

}
// end main
