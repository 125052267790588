
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {GlobalConstants} from '../shared/constants/amiri';

@Injectable()
export class ExcelService {
constructor() { }
public exportAsExcelFile(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
   FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
}

getPdfObj() {
  const doc = new jsPDF();
  const regular = GlobalConstants.FONT_CAIRO_REGULAR.trim();
  const bold = GlobalConstants.FONT_CAIRO_BOLD.trim();

  doc.addFileToVFS('Regular.ttf', regular);
  doc.addFileToVFS('Bold.ttf', bold);

  doc.addFont('Bold.ttf', 'AmiriBold', 'bold');
  doc.addFont('Regular.ttf', 'Amiri', 'normal');

  return doc;
}

addTableToPdf(doc: any, top, leftMargin, heads, data, headerImage,footerData = '') {
  var img = new Image();
  img.src = headerImage;

  (doc as any).autoTable({
    head: [heads],
    body: data,
    theme: 'plain',
    headStyles: {font : 'AmiriBold', fillColor: [240, 240, 240] },
    startY:top,
    margin: {left: leftMargin,bottom:10}, 
     didDrawPage: (HookData) => {
      if (HookData.pageNumber === 1) {  
        if(headerImage){
          doc.addImage(img, 'png', 18, 20, 175, 25);
        }
      }  
      // Footer
      doc.setFontSize(8);
      doc.text('page '+HookData.pageNumber, 10, doc.internal.pageSize.height-3 );
      doc.text(footerData,100 - (footerData.length/2),doc.internal.pageSize.height-3);
      doc.text(new Date().toDateString(), 180, doc.internal.pageSize.height-3);
    },

    styles: { font : 'Amiri', fontSize: 8},
    columnStyles: {},
    bodyStyles:{
      lineWidth: 0.1, 
      lineColor: [0, 0, 0]
    },
    headerStyles: {
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
      fillColor: [235, 235, 235],
  }
  });

  return doc;
}

// columnStyles: {
//   0: {textColor: [10, 234, 155]},
// }

}
