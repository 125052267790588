import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-van-settings',
  templateUrl: './van-settings.component.html',
  styleUrls: ['./van-settings.component.css']
})
export class VanSettingsComponent implements OnInit {


  types = [
    { id: 0, name: 'Normal' },
    { id: 1, name: 'Extented' },
  ];
  search_mthd = [
    { id: 0, name: 'By Barcode' },
    { id: 1, name: 'By Product Name' },
  ];
  rates = [
    { id: 0, name: 'Sales Rate' },
    { id: 1, name: 'Purchase Rate' },
  ];

  van_sales = [
    { id: 0, name: 'No' },
    { id: 1, name: 'Yes' },
  ];

  van_rate = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
    { id: 3, name: 'Invoice' }
  ];
  settings: any;
  pageLoadingImg: boolean;
  van_searchType: any;
  dflt_search_methd: any;
  van_default_rate: any;
  van_sales_by_unit: any;
  van_block_cust_creation = 0;
  hide_van_zero_stock_items = 0;
  disc_vanlist:any[];
  disc_types = [
    { id: 0, name: 'No Limit' },
    { id: 1, name: 'Limit' },
  ];
  disc_pageLoadingImg: boolean;
  hide_van_transfr_rate: any;
  van_block_sale_date_change: any;
  van_item_rate_validation: any;
  van_app_reciept_write_off_option: any;
  van_app_invoice_types: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getSalesSetting();
    this.getVanDiscountSetting();
  }
  getVanDiscountSetting()
  {
    this.disc_pageLoadingImg = true;
    this.apiService.getVanDiscountSetting().subscribe((res) => {
      this.disc_pageLoadingImg = false;
      this.disc_vanlist = res['data'];
    });
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.van_searchType = this.settings.van_searchType;
        this.dflt_search_methd = this.settings.van_default_search;
        this.van_default_rate = this.settings.van_default_rate;
        this.van_sales_by_unit = this.settings.van_sales_by_unit;
        this.van_block_cust_creation = this.settings.van_block_cust_creation;
        this.hide_van_zero_stock_items = this.settings.hide_van_zero_stock_items;
        this.hide_van_transfr_rate = this.settings.hide_van_transfr_rate;
        this.van_block_sale_date_change = this.settings.van_block_sale_date_change;
        this.van_item_rate_validation = this.settings.van_item_rate_validation;
        this.van_app_reciept_write_off_option = this.settings.van_app_reciept_write_off_option;
        this.van_app_invoice_types = this.settings.van_app_invoice_types;
      } else { 
        this.van_searchType = 0;
        this.van_sales_by_unit = 0;
      }
    });

  }
  update(formdata) {
    this.pageLoadingImg = true;
    formdata.value.aa = 'value2';
    formdata.value.discounts = JSON.stringify(this.disc_vanlist);
    // formdata.append("selected_deductions", JSON.stringify(this.selected_deductions));
    this.apiService.vanSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  langChange(){
    this.translate.get(['Common.normal', 'Common.extended',]).subscribe((res: string) => {    
     

      this.types = [
        { id: 0, name: res['Common.normal']},
        { id: 1, name: res['Common.extended'] },
        
      ];
    });


  }

}
