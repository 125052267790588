import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';


@Component({
  selector: 'app-rep-godown',
  templateUrl: './rep-godown.component.html',
  styleUrls: ['./rep-godown.component.css']
})
export class RepGodownComponent implements OnInit {
  up: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
  }

}
 