import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';


@Component({
  selector: 'app-van-dash',
  templateUrl: './van-dash.component.html',
  styleUrls: ['./van-dash.component.css']
})
export class VanDashComponent implements OnInit {
  up: any;
  show:boolean=false;
  cmpny: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
  }

}
