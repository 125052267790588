import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  period_type: string | Blob;
  pageLoadingImg: boolean;
  empSum: any;
  summary: any;
  repDate: any;
  opBalance: any;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  fileName= 'Employee.xlsx';


  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_cmptype = [
    { id: '1', name: 'Establishment' },
    { id: '2', name: 'Company' },

  ];

  exportLoader: boolean;
  ExpsalesSum: any;
  Expsummary: any;
  userType: any;
  branch_all: any;
  filter_branch_id: any;
  cmp_type: any;
  ExpAlldet: any;
  Alldet: any;
  cmpExist: boolean;
  country_dec: string;

  
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.userType = this.coreService.getUserData('user_type');
 
    this.period_type = 't';

    this.cmp_type = '1';
    this.cmpExist = true;

    this.changeCmpType(1);

    let searchval = new FormData();
    searchval.append("period_type", 't');
    searchval.append("cmp_type",this.cmp_type);

    this.apiService.employeeReportAuditor(searchval, 1).subscribe((res) => {
      this.pageLoadingImg = false;

      this.empSum = res['data']['data'];
     
      this.summary = res['data']['info'].branch;
      this.repDate = res['data']['info'].Date;
      this.opBalance = res['data'].op_bal;
      this.Alldet = res['data'].Summary;

      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      //  console.log(this.pdf);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  changeCmpType(cmptype) {
    let searchval = new FormData();
    searchval.append("cmp_type", cmptype);
    this.filter_branch_id = [];

    this.apiService.getBranchbyType(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      this.checkBranch();

      // if (this.userType == 'ADMIN') {
      //   this.branch_all.push({ 'branch_id': '', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      // }
    });
  }

  checkBranch(){
    if(this.filter_branch_id.length > 0)
    {
      this.cmpExist = true;
    }else{
      this.cmpExist = false;
    }
  }

  pageNext(formdata: { value: any; },newform: { value: any; }, pageno: any) {
    this.empSum = [];
    this.pageLoadingImg = true;
    formdata.value.datewise =  newform.value.datewise;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      // formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      // formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.employeeReportAuditor(formdata.value, pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      
      this.empSum = res['data']['data'];
      this.summary = res['data']['info'].branch;
      this.opBalance = res['data'].op_bal;
      this.Alldet = res['data'].Summary;

      this.repDate = res['data']['info'].Date;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  




  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      formdata.value.datewise = true;
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        // formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
        // formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
      }
      this.apiService.employeeReportAuditor(formdata.value,1).subscribe((res) => {
        this.pageLoadingImg = false;
        // if(res['data']['data']){
          // this.Expshow = res['data'];
        
        this.ExpsalesSum = res['data']['data'];
       
        this.Expsummary = res['data']['info'].branch;
        this.repDate = res['data']['info'].Date;
        this.opBalance = res['data'].op_bal;
        this.ExpAlldet = res['data'].Summary;
      
        // console.log(this.totalData);
  
      
        // }
      
     
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
   

    });



    }

}
