import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';

import { ExcelService } from '../../../../../service/excel.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-van-customer-transaction',
  templateUrl: './van-customer-transaction.component.html',
  styleUrls: ['./van-customer-transaction.component.css']
})
export class VanCustomerTransactionComponent implements OnInit {
  fileName = 'van_customer_transaction.xlsx';
  VanList: any;
  pageLoadingImg: boolean;
  repData: any;

  inv_type={
    '22': 'Invoice Due Receipt',
    '4': 'Sales',
    '9': 'Sales Return',
  }
  salesPriviewLoader: boolean;
  resultobj: any;
  purchaseItems: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  showdata: boolean;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  vch_type = [
    { id: 4, name: 'Sales' },
    { id: 22, name: 'Invoice Due Reciept' },
    { id: 9, name: 'Sales Return' },
  ];

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: 'P',
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  };
  logoImgUrl: string;
  tempImgUrl: string;
  saleprivewLoadingImg: boolean;
  salesRetPreview: any;
  qr_inv: any;
  recPriviewLoader: boolean;
  printPreview: any;
  selctdVan: any;
  customer: any;
  balance: any;
  branch_display_name: any;
  repDate: any;
  van_data: any;
  branch_name: any;
  cust_name: any;
  opening_blnce: any;
  exportLoader: boolean;
  branch_display_code: any;
  totRes: any;
  branch_address: any;
  branch_phone: any;
  branch_mob: any;
  footImgUrl: string;
  cmpny: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.getTemplete();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address= this.coreService.getUserData('branch_address');
    this.branch_phone= this.coreService.getUserData('branch_phone');
    this.branch_mob= this.coreService.getUserData('branch_mob');
    this.cmpny = this.coreService.getUserData('cmpny');
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.VanList = res['data'];
    });

  }

  selectVan(van) {
    if (van) {
      this.pageLoadingImg = true;
      const searchval = new FormData();
      searchval.append('van_id', van.van_id);

      this.apiService.vanCustTransactionRep(searchval).subscribe((res) => {
        this.repData = res.data;
        this.pageLoadingImg = false;
        this.repDate = res.Date;
        this.van_data = van;
        this.totRes = res.total;
      });

    } else {
      // this.repData = [];

    }
  }

  filterSearch(formdata: { value: any; }){
    this.pageLoadingImg = true;
    formdata.value.van_id=this.selctdVan.van_id;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanCustTransactionRep(formdata.value).subscribe((res) => {
      this.repData = res.data;
      this.pageLoadingImg = false;
      // this.repDate = res.data.Date;
      this.totRes = res.total;
    });
  }

  sale_search(search) {
    this.salesPriviewLoader = true;
   
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
     
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }
  

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: 'P',
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      };
      if (res.data.prnt_header_logo) {
      this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
    } else {
      this.logoImgUrl = '';
    }

      if (res.data.prnt_header_type === 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }
      this.footImgUrl=this.apiService.PHP_API_SERVER + '/' + res.data.prnt_reciept_footer_img;

    });
  }

  getSalesRetPreview(prdn_id) {
    this.saleprivewLoadingImg = true;
    let searchval = new FormData();
    searchval.append("ref_no", prdn_id);
    this.apiService.getsalesReturnPrev(searchval).subscribe((res) => {
      this.saleprivewLoadingImg = false;
      this.salesRetPreview = res;
      this.salesRetPreview.total_vat = Number(res.total_vat);
      this.salesRetPreview.total_amt = Number(res.total_amt);

      this.qr_inv = this.salesRetPreview.qr_link;

      this.apiService.getSettings().subscribe((res) => {
        if (res.data.prnt_header_type == 2) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        } else {
          this.tempImgUrl = '';
        }
  
      });

    });

  }

  viewReceipt(id) {
    this.recPriviewLoader = true;
    this.apiService.getReceipt({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;
      this.printPreview = res;
    });
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
      
      console.log(this.customer);
    });

  }

  selecCust(ledg_id){
    if(ledg_id){
      this.balance = this.customer.find(x => x.ledger_id === ledg_id).balance;
      this.cust_name = this.customer.find(x => x.ledger_id === ledg_id).name;
      this.opening_blnce = this.customer.find(x => x.ledger_id === ledg_id).opening_balance;
    }
    
  }

  exportexcel(): void {
    this.exportLoader = true;
    /* table id is passed over here */
    const element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

}
