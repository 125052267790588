import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';
import { ApiService } from '../../../../service/api.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-rep-top-customers',
  templateUrl: './rep-top-customers.component.html',
  styleUrls: ['./rep-top-customers.component.css']
})
export class RepTopCustomersComponent implements OnInit {

  fileName = 'van_customer_transaction.xlsx';
  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgstart: boolean;
  pgend: boolean;
  datas: string[];
  values: string[];
  isnoData : boolean;
  staffs:string[];
  filterStaffs:any;
  repDate:any;date1:any;date2:any;qty1:any;qty_id:any;qty2:any;crate1:any;comm_rate:any;crate2:any;
  elseBlocknoend:any;period_type:any;elseBlocknostart:any;prd_formula:any;usr_id:any;filter:any;
  formula_all: any;
  vanline_all = [];
  customer = [];
  country_dec: string;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  exportLoader: boolean;
  exp_datas: any;

 constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    this.readIncre(1);
   // this.getAllSatff();
  //  this.listProductFormula();

  this.branch_display_name = this.coreService.getUserData('branch_disp_name');
  this.branch_name = this.coreService.getUserData('branch_name');
  this.branch_display_code = this.coreService.getUserData('branch_code');
  }

  readIncre(pageno)
  {
    this.pageLoadingImg = true;
    this.isnoData = false;
    let searchval = new FormData();
    this.apiService.topCustomer(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.repDate = res['data']['Date'];

      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }

  searchVanlines(search: string) {
    let searchval = new FormData();
    searchval.append("vanline_name", search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      this.vanline_all = res['data'];

    });
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
      console.log(this.customer);
    });

  }

  pageNext(formdata: { value: any; }, pageno: any) {
    formdata.value.filter = 1;   
    this.pageLoadingImg = true;
    this.isnoData = false;
    this.apiService.topCustomer(formdata.value, pageno).subscribe((res: any) => {      
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.repDate = res['data']['Date'];

      this.pageLoadingImg = false;
      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  filterDate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];

   //form search
   searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

  // listProductFormula() { 
  //   this.productionService.getAllProductionFormula(1, 5000).subscribe((res) => {
  //     this.formula_all = res.data.data;
  //   });
  // }
  // searchFormula(search: string) {
  //   const searchval = new FormData();
  //   searchval.append('formula_name', search);
  //   this.productionService.searchProductionFormula(searchval).subscribe((res) => {
  //     this.formula_all = res.data;
  //   });
  // }

  // getAllSatff() {
  //   this.apiService.listAllStaff().subscribe((res) => {
  //     this.staffs = res.data;     
  //   });
  // }

  exportexcel(): void {
   
    /* table id is passed over here */
    const element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
   
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    
    this.apiService.topCustomer(formdata.value, 1).subscribe((res: any) => {    
              
      this.exp_datas = res['data'];      
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
      
    });
  }

}
