import { Component, OnInit,ViewChild, ElementRef, ViewChildren, QueryList } from "@angular/core";
import { ApiService } from "src/app/service/api.service";
import { CoreService } from "src/app/service/core.service";
import * as XLSX from "xlsx";
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: "app-price-list",
  templateUrl: "./price-list.component.html",
  styleUrls: ["./price-list.component.css"],
})
export class PriceListComponent implements OnInit {

  @ViewChild('selectproductId', { static: false }) selectproductId: NgSelectComponent;
  @ViewChildren("selPrdCode") selPrdCode: QueryList<ElementRef>;


  fileName = "price_list.xlsx";
  stkprd_all: any = [];
  stkprdsel: any;
  productunit: any;
  product: any;
  stkprdunit: any;
  no_transfr: string='';
   prdunit: any;
  rate: any;
  selectPrd: any;
  unit_name: any;
  prd: any;
  catagories: any;
  productbarcode: any;
  prd_barcode: null;
  trnfr_to_table = [];
  Notes: string;
  slno = 0;
  tax: any;
  tax_includ_rate: any;
  branch_display_name: any;
  branch_display_code: any;
  note: any;
  country_dec: string;
  prdctloading: boolean;
  catloading:boolean;
  cat_id: any;

  constructor(
    private apiService: ApiService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.branch_display_name = this.coreService.getUserData("branch_disp_name");
    this.branch_display_code = this.coreService.getUserData("branch_code");
    this.country_dec=this.coreService.setDecimalLength();
    this.searchCat('');
    // this.catagories.push({ cat_id: 0, cat_name: 'All' });
    setTimeout(() => {
      this.selPrdCode.last.nativeElement.focus();
    }, 500);
   
  }

  searchCat(search: string) {
    
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
      this.catagories.push({ cat_id: 0, cat_name: 'All' });
    });

  }

  searchStkPrd(search: string) {
    $("#br_err").text("");
    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append("stk_stat", "1");
    this.apiService.getStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res["data"];
    });
  }

  selectStockcategorywise(data){
    if (data ||data ==0) {
    $("#br_err").text("");
    this.catloading=true;
      this.prdctloading=true;
      let searchval = new FormData();
      searchval.append("catId", data); 
      this.apiService.getpricelist(searchval).subscribe((res) => {
        if(res.data){
          this.stkprd_all = res.data;
           console.log(this.stkprd_all);
          // this.note = res.value.Notes;
          this.stkprd_all.forEach((element, index) => {
            this.trnfr_to_table.push({
              sl_no: index+1,
              Product:element.prd_name,
              alias:element.prd_alias,
              Unit:element.unit_name,
              Rate:element.baserate,
              tax:element.bs_tax,
              tax_icld_rate:element.bs_tax_includ_rate,

      });
      this.cat_id=null;
    });
    this.no_transfr='';
    console.log(this.trnfr_to_table);
    this.prdctloading=false;
    this.catloading=false;
    this.productbarcode = null;
  }else{
    this.no_transfr='Unfortunately, there are no products in this category';
    this.prdctloading=false;
    this.catloading=false;
    this.productbarcode = null;
  }
    });



  }
    
  }
  

  selectStockPrd(data) {
    if (data) {
      this.cat_id=null
      this.no_transfr='';
      $("#br_err").text("");
      this.prdctloading=true;
      let searchval = new FormData();
      searchval.append("productId", data);
      this.apiService.getpricelist(searchval).subscribe((res) => {
        this.prdctloading=false;
        this.productbarcode = res.data[0].prd_barcode;
        this.productunit = res.data[0]["units"];
        this.prdunit = res.data[0].select_unit_id;
        this.rate = res.data[0].baserate;
        this.unit_name = res.data[0].unit_name;
        this.stkprd_all = res.data;
        this.tax = res.data[0].bs_tax;
        this.tax_includ_rate = res.data[0].bs_tax_includ_rat
        this.selectproductId.focus()
       
      });
    }
  }

  searchStkPrdBarcode(barcode) {
    this.cat_id=null
    this.stkprd_all = null;
    this.prdunit = null;
    this.rate = null;
    this.product = null;
    this.productunit = null;
    this.unit_name = null;
    this.tax_includ_rate = null;
    this.tax = null;
    this.tax_includ_rate = null;
    if (barcode) {
      this.prdctloading=true;
      $("#br_err").text("");
      this.no_transfr='';
      let searchval = new FormData();
      searchval.append("productbarcode", barcode);
      this.apiService.getpricelist(searchval).subscribe((res) => {
        if (res.data) {
          this.stkprd_all = res.data;
          this.product = res.data[0].prd_id;
          this.productunit = res.data[0]["units"];
          this.prdunit = res.data[0].select_unit_id;
          this.unit_name = res.data[0].unit_name;
          this.rate = res.data[0].baserate;
          this.tax = res.data[0].bs_tax;
          this.tax_includ_rate = res.data[0].bs_tax_includ_rate;
            setTimeout(() => {
              
              $("#listbtn").click();
        }, 500);
        
        } else {
          console.log(" No product found");
          $("#br_err").text("No product found");
          this.stkprd_all = [];
        }
        this.prdctloading=false;
      });
     
    }
    
  }

  unitSelect(prdunitid) {
    if (prdunitid) {
      this.rate = this.productunit.find((x) => x.select_unit_id === prdunitid).rate1;
      this.tax = this.productunit.find((x) => x.select_unit_id === prdunitid).tax;
      this.tax_includ_rate = this.productunit.find((x) => x.select_unit_id === prdunitid).tax_includ_rate;
      this.unit_name = this.productunit.find((x) => x.select_unit_id === prdunitid).unit_name;
     
      $('#listbtn').focus();
    }
  }

  reFreshAll() {
    this.stkprd_all = null;
    this.prdunit = null;
    this.rate = null;
    this.product = null;
    this.productunit = null;
    this.productbarcode = null;
    this.unit_name = null;
    this.tax_includ_rate = null;
    this.tax = null;
    this.tax_includ_rate = null;
  }

  addToList(res: { value: any }) {
 

    if (res.value.product) {
      // this.note = res.value.Notes;
  
      this.slno = this.slno + 1;
      this.trnfr_to_table.push({
        sl_no: this.slno,
        Product: this.stkprd_all[0].prd_name,
        alias: this.stkprd_all[0].prd_alias,
        Unit: res.value.unit_name,
        Rate: res.value.rate,
        tax: res.value.tax,
        tax_icld_rate: res.value.tax_includ_rate,
      });

      this.reFreshAll();
      // setTimeout(() => {
        this.selPrdCode.last.nativeElement.focus();
      // }, 500);
    }
  }

  deleteFromList(data: any) {
   
    var type = data.sl_no,
      i;

    for (i = this.trnfr_to_table.length - 1; i >= 0; --i) {
      if (this.trnfr_to_table[i].sl_no == type) {
        this.trnfr_to_table.splice(i, 1);
      }
    }
  }


  clearall()  {
    if (this.trnfr_to_table.length > 0) {
      if (confirm('Are you sure to clear all ?')) {
        this.trnfr_to_table.splice(0, this.trnfr_to_table.length);
      }
     
    }
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("export-group");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    // this.exportLoader = false;
  }
}
