import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-order-list',
  templateUrl: './sales-order-list.component.html',
  styleUrls: ['./sales-order-list.component.css']
})
export class SalesOrderListComponent implements OnInit {
  pageLoadingImg: boolean;
  info: any;
  date= new Date();

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {

    this.dateWiseSOist();
  }

  dateWiseSOist() {
    this.pageLoadingImg=true;

    this.apiService.dateWiseSOlist({ today_date: this.date }).subscribe((res) => {
      this.pageLoadingImg=false;
      this.info = res['data'];
      // this.from = res.data.from;



    });
  }
}
