import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repcatagory } from '../../../../../model/report/repcatagory.model';

@Component({
  selector: 'app-rep-catagory',
  templateUrl: './rep-catagory.component.html',
  styleUrls: ['./rep-catagory.component.css']
})
export class RepCatagoryComponent implements OnInit {
  selecTed: any = { cat_id: null,cat_name: null};
  rep_cats:any[];
  pageLoadingImg: Boolean
  up: any;
  constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {
    this.pageLoadingImg = true;
    this.readCatRep();
    this.up = this.coreService.getUserPrivilage();
  }

  readCatRep()
 {
  let searchval = new FormData();   
 this.apiService.readCatReport(searchval).subscribe((res) => {
   this.pageLoadingImg =false;
  this.rep_cats = res['data'];
 
});
}
}
