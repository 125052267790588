import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-three-print',
  templateUrl: './invoice-three-print.component.html',
  styleUrls: ['./invoice-three-print.component.css']
})
export class InvoiceThreePrintComponent implements OnInit {
  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  constructor() { }

  ngOnInit() {
  }

}
