import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})
export class DesignationComponent implements OnInit {

  des_name: any;
  alldes: any;
  pageLoadingImg: boolean;

  selectedData: any = { des_id: null, error: null, des_name: null, message: null };
  result = '';
  designations: string[];
  up: any;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.coreService.getToken();
    this.up = this.coreService.getUserPrivilage();
  }
  searchDes(search: string) {
    let searchval = new FormData();
    searchval.append("des_name", search);
    this.apiService.getDesByName(searchval).subscribe((res) => {
      this.designations = res['data'];
    });
    $('.prev-list').show();
  }
  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("des_name", search);
    this.apiService.getDesByName(searchval).subscribe((res) => {
      this.designations = res['data'];
    });
    $('.prev-list').hide();
  }

  //Remove Name Suggestions

  removeName() {
    this.designations = [];
    $('.prev-list').hide();
  }

  selectCat(des: any) {
    if (des) {
      this.result = '';
      $("#editdesf").show();
      this.selectedData = des;
    }
    this.designations = [];
  }

  tabClick(tab: { index: number; }) {
    if (tab.index == 1)
      this.result = '';
  }
  createDes() {
    let searchval = new FormData();
    searchval.append("des_name", this.des_name);
    this.pageLoadingImg = true;

    this.apiService.createDes(searchval).subscribe((des) => {
      this.pageLoadingImg = false;

      if (des.error != null) {
        this.result = des.error;
        console.log(this.result);
      }
      else {
        this.coreService.showMessage(des.message);
        this.des_name = '';
      }

    });
  }
  updateDes() {
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append("des_name", this.selectedData.des_name);
    searchval.append("des_id", this.selectedData.des_id);
    this.apiService.updateDes(searchval).subscribe((des) => {
      this.pageLoadingImg = false;

      if (des.error != null) {
        this.result = des.error;
      }
      else {
        $("#editdesf").hide();
        this.coreService.showMessage(des.message);
        this.designations = [];
      }

    });
  }

}
