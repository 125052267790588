import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CoreService } from '../../../../service/core.service';
import { Chart } from 'chart.js';
import { ApiService } from '../../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rep-sales',
  templateUrl: './rep-sales.component.html',
  styleUrls: ['./rep-sales.component.css']
})
export class RepSalesComponent implements OnInit {

  @ViewChild('canvas', { static: false }) fieldName4: ElementRef;

  chart: any;
  chartData: any;
  cmpny: any;

  constructor(private coreService: CoreService,private apiService: ApiService,private translate: TranslateService) { }
  up: any;

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.apiService.getSalesChart(1).subscribe((res) => {
   
      this.chartData = res.data;
      let temp_max = this.chartData.sales;
      let temp_min = this.chartData.return;
     let weatherDates = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October','November','December'];
     const ctx = document.getElementById("chart") as HTMLCanvasElement;
     this.chart = new Chart(ctx, {
       type: 'line',
   
       data: {
         
         labels: weatherDates,
         datasets: [
           {
             data: temp_max,
             label: this.translate.instant('Sales.Sales_Amount'),
              borderColor: "#008000",
             fill: false
           },
           {
             data: temp_min,
             label: this.translate.instant('HOME.sales_return_amount'),
             borderColor: "#FF0000",
             fill: false
           },
         ]
       },
       options: {
         title: {
           display: true,
           text: this.translate.instant('HOME.sales_and_sales_rtn')
       },
         tooltips: {
           mode: 'index',
           
       },
         legend: {
           
           display: true,
           
         },
         scales: {
           xAxes: [{
             display: true
             
           }],

           
           yAxes: [{
             
             display: true,
             ticks: {
               suggestedMin: 50,
               suggestedMax: 100
           }
           }],
         },
         layout: {
           padding: {
               left: 50,
               right: 0,
               top: 0,
               bottom: 0
           }
       }
       },
 
     });
    });

    // let temp_min = [65, 59, 2, 81, 56, 55, 40, 65, 59, 80, 81, 56];
    // let temp_max = [28, 48, 4, 19, 86, 27, 90, 28, 48, 0, 19, 80];
   
   



  }

}
