import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {
  inv_no="";
  edit_sales_id="";
  constructor() { }
}
