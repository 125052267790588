import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-thermal-bill',
  templateUrl: './sales-thermal-bill.component.html',
  styleUrls: ['./sales-thermal-bill.component.css']
})
export class SalesThermalBillComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
