import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// component
import { VanLineComponent } from './van-line/van-line.component';
import { VanDashComponent } from './van-dash/van-dash.component';
import { VanComponent } from './van-dash/van/van.component';
import { VanTransferComponent } from './van-dash/van-transfer/van-transfer.component';
import { TransferVoidComponent } from './van-dash/transfer-void/transfer-void.component';
import { ReturnTransferComponent } from './van-dash/return-transfer/return-transfer.component';
import { TransferReturnVoidComponent } from './van-dash/transfer-return-void/transfer-return-void.component';
import { VanMissingStockComponent } from './van-dash/van-missing-stock/van-missing-stock.component';
import { VanExcessStockComponent } from './van-dash/van-excess-stock/van-excess-stock.component';
import { VanStockVerifyComponent } from './van-dash/van-stock-verify/van-stock-verify.component';
import { VanStockAsignComponent } from './van-dash/van-stock-asign/van-stock-asign.component';

const routes: Routes = [
  {path: 'van/van_lines', component: VanLineComponent},
  {path: 'van/missing-stock', component: VanMissingStockComponent},
  {path: 'van', component: VanDashComponent},
  {path: 'van/list', component: VanComponent},
  {path: 'van/transfer', component: VanTransferComponent},
  {path: 'van/return', component: ReturnTransferComponent},
  {path: 'van/void', component: TransferVoidComponent},
  {path: 'van/transfer-return-void', component: TransferReturnVoidComponent},
  {path: 'van/excess-stock', component: VanExcessStockComponent},
  {path: 'van/stock-verify', component: VanStockVerifyComponent},
  {path: 'van/stock-assign', component: VanStockAsignComponent},
  {path: 'van/transfer/:purch_id', component: VanTransferComponent},
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class VanRoutingModule {}
