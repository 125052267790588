import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';
import { ApiService } from '../../../../service/api.service';


@Component({
  selector: 'app-rep-stock',
  templateUrl: './rep-stock.component.html',
  styleUrls: ['./rep-stock.component.css']
})
export class RepStockComponent implements OnInit {
  up: any;
  checkBatch =false;
  cmpny: any;

  constructor(private coreService: CoreService,private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });

    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
  }

}
