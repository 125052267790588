import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-goods-reciept-report',
  templateUrl: './goods-reciept-report.component.html',
  styleUrls: ['./goods-reciept-report.component.css']
})
export class GoodsRecieptReportComponent implements OnInit {
  fileName = 'goods_report.xlsx';
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  supplierAll: any;
  Status = [
    { id: '0', name: 'Pending' },
    { id: '1', name: 'Purchased' },
    
  ];
  stats_name = {
    '0': 'Pending',
    '1': 'Purchased', 
  };
  report_type_filter = [
    { id: 'summary', name: 'Summary' },
    { id: 'detailed', name: 'Detailed' },
    // { id: 'detailed_with_profit', name: 'Detailed With Profit' }
  ];
  branchref_no:any;
  supp_id:any;
  gooddetails: any;
  supp_ledger_id: any;
  pgend: boolean;
  pgstart: boolean;
  curpage: number;
  lastpage: number;
  pageFrom: number;
  exportLoader: boolean;
  repDate: any;
  total_reciept: any;
  ExprepDate: any;
  branch_display_name: any;
  branch_display_code:any;
  branch_address:any;
  pageLoadingImg: boolean;
  saled_quantity: any;
  period_type: string;
  cur_lang: string;
  rep_type: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.pageLoadingImg = true;
    this.period_type = 't';
    let searchval = new FormData();
    searchval.append('period_type','t');
    
    this.apiService.goodsRecieptReport(searchval,1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this. total_reciept=res['data']['total']
      this.saled_quantity=res.data.whole_total_quantity
      this.gooddetails=res['data']['data']
    
    
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage); 
      
      
      
  
    });

    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    

  }

  pageNext(formdata: { value: any; }, pageno: any){
    console.log(formdata.value);
    this.pageLoadingImg = true;
    this.apiService.goodsRecieptReport(formdata.value, pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.gooddetails=res['data']['data']
      this. total_reciept=res['data']['total']
      this.saled_quantity=res.data.whole_total_quantity
      // console.log(this.gooddetails);
      
      console.log(this.supplierAll);
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    //   this.pgEnd(this.curpage, this.lastpage);
    // this.pgStart(this.curpage);
    
      


    })

  }

     // form search supplier
     searchSupplier(search: string) {
      const searchval = new FormData();
      searchval.append('supp_name', search);
       if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append("with-alias", "1");
      }
      this.apiService.searchSupplier(searchval).subscribe((res) => {
        this.supplierAll = res.data;
      });
    }

    exportexcel(): void {
      /* table id is passed over here */
      let element = document.getElementById('export-group');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
      this.exportLoader = false;
  
    }

    exportEXCL(Expformdata: { value: any; }) {
      this.exportLoader = true;
  
      // debugger;
      Expformdata.value.export = 'export';
  
      // if (Expformdata.value.period_type === 'c') {
      //   Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      //   Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
      // }
      this.branch_display_name = this.coreService.getUserData('branch_disp_name');
      this.apiService.goodsRecieptReport(Expformdata.value, 1).subscribe((res) => {
        // this.pageLoadingImg = false;
        this.ExprepDate = res.data.Date;
        this.repDate = res['data']['date']
        this.gooddetails=res['data']['data']
        this. total_reciept=res['data']['total']
        this.saled_quantity=res.data.whole_total_quantity
      
      
        
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
  
        Expformdata.value.export = '';
        
    
      });
  
  
    }

    pgEnd(curr, end) {
      if (curr == end)
        this.pgend = true;
      else
        this.pgend = false;
  
    }
  
    pgStart(curr) {
      if (curr == 1)
        this.pgstart = true;
      else
        this.pgstart = false;
    }

    // pdf download
    generatePdf(Expformdata: { value: any; }) {

      this.exportLoader = true;
  
      // debugger;
      Expformdata.value.export = 'export';
  
      // if (Expformdata.value.period_type === 'c') {
      //   Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      //   Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
      // }
      this.branch_display_name = this.coreService.getUserData('branch_disp_name');
      this.apiService.goodsRecieptReport(Expformdata.value, 1).subscribe((res) => {
        // this.pageLoadingImg = false;
        this.ExprepDate = res.data.Date;
        this.repDate = res['data']['date']
        this.gooddetails=res['data']['data']
        this. total_reciept=res['data']['total']
        this.saled_quantity=res.data.whole_total_quantity
      
      
        
        const heads = [
          this.translate.instant('HOME.sl_no'),
          this.translate.instant('Common.reference_nmbr'),
          this.translate.instant('Common.branch_ref_no'),
          this.translate.instant('Table.date'),
          this.translate.instant('Purchase.supplier'),
          this.translate.instant('Common.total_quantity'),
          this.translate.instant('Table.status')
        ];

        if(this.rep_type=='detailed'){
          heads.splice(3,0, this.translate.instant('Common.prd_name'))
        }

          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
  
          this.gooddetails.forEach((item,i)=>{
            const arr=[
              i+1,
              item.gds_rec_id,
              item.gds_rec_id2,
              item.gds_rec_date,
              item.supp_name,
              item.total_qty,
              this.stats_name[item.gds_rec_convert_to_purchase]
            ]
            if(this.rep_type=='detailed'){
              arr.splice(3,0, ' ')
            }

            data.push(arr)

            if(this.rep_type=='detailed'){
              item.items.forEach(item1=>{
                const arr1=[
                  '',
                  '',
                  '',
                  item1.prd_name,
                  '',
                  '',
                  item1.qty + ' ' + item1.unit_name,
                  ''
                ]

                data.push(arr1)
              })
            }

          })
      
          let doc = this.excelService.getPdfObj();
  
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
  
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
  
          if(this.ExprepDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
          }
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Common.goods_reciept_report')}`,  valueXPos, headerHeight + 15);
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.total_good_reciept_number')}`, lblXPos, headerHeight + 20);
          doc.setFontSize(10);
          doc.text(':  ' + this.total_reciept,  valueXPos, headerHeight + 20);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_qty')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.saled_quantity,  valueXPos, headerHeight + 25);
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
      
         
          doc.save(`Goods Reciept Report.pdf`);
  
          this.exportLoader = false;
  
        Expformdata.value.export = '';
        
    
      });

    }

}
