import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import html2canvas from 'html2canvas';
// import * as jspdf from 'jspdf';
import { ExcelService } from '../../../../../service/excel.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-op-balance-summary',
  templateUrl: './op-balance-summary.component.html',
  styleUrls: ['./op-balance-summary.component.css']
})
export class OpBalanceSummaryComponent implements OnInit {
  fileName = 'opening_balance.xlsx';

  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  period_type: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  added_by: any;
  vch_type: any;
  disp_void = 2;
  vch_filter: any;
  ret_ref_filter: any;


  branch_display_code:any;
  branch_address:any;
  branch_display_name:any;

  exportLoader: boolean;

  lang_filter: any;
  in_amount: any;
  out_amount: any;

  dayList: string[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  supplierAll: string[];
  ModelData: boolean;
  // total_purchase:number;
  total_return: any;
  total_purchase_amount: any;

  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  filter_types: any;
  filter_refnum: any;
  filter_price: any;
  filter_discount: any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType: any;
  // selectRefNo:any;
  selectPrice: any;
  // selectDiscount:any;



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '2', name: 'Group wise' },
    { id: '1', name: 'Ledger wise' },
    { id: '3', name: 'Grouped Ledger' },

  ];
  repDate: any;
  voucherType: any;
  ledger_all: any;
  users: any;
  show_notes: any;
  dispnote: any;
  debitAmt: any;
  crdAmt: any;
  group_all: any;
  ledgerList: any;
  total_debit: any;
  total_credit: any;
  reportData: any;
  up: any;
  branch_all: any;
  userType: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private excelService: ExcelService,private translate: TranslateService,private currencyPipe: CurrencyPipe) { }


  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.disp_void = 2;
    this.searchSupplier('');
    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();
    let searchval = new FormData();
    this.apiService.openingbalanceSummaryReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.total_debit = res.info.total_debit;
      this.total_credit = res.info.total_credit;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

      this.ModelData = false;
    });

    this.country_dec=this.coreService.setDecimalLength();
  }


  langChange(){
    this.translate.get(['ACCOUNTS.Group_wise', 'ACCOUNTS.ledger_wise','ACCOUNTS.grpd_ledger']).subscribe((res: string) => {    
     
      
      this.filter_purchtypes = [
        { id: '2', name: res['ACCOUNTS.Group_wise'] },
        { id: '1', name: res['ACCOUNTS.ledger_wise'] },
        { id: '3', name: res['ACCOUNTS.grpd_ledger'] },
    
      ];
    });

  }
  
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      // console.log(  this.branch_all );
   
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
     
    });
  }

  ngOnDestroy() {
    $('.close').click();

  }




  pageNext(formdata: { value: any; }, pageno: any) {




    $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.openingbalanceSummaryReport(formdata.value, pageno).subscribe((res: any) => {

      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];

      this.total_debit = res.info.total_debit;
      this.total_credit = res.info.total_credit;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


      this.repDate = res.info.Date;
      // this.dispnote = res.data.show_notes;
      // this.debitAmt = res.data.debit_amnt;
      // this.crdAmt = res.data.credit_amnt;


      if (!this.disp_void) {
        this.disp_void = 2;
      }

    });
  }






  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res['data'];
    });

  }



  searchVoucherType(search: string) {
    const searchval = new FormData();
    searchval.append('vchtype_name', search);
    this.apiService.searchVoucherType(searchval).subscribe((res) => {
      this.voucherType = res['data'];
    });
  }

  searchLedger(search: string) {
    let searchval = new FormData();
    searchval.append("ledger_name", search);
    this.apiService.getLedByName(searchval).subscribe((res) => {
      this.ledger_all = res['data'];
    });
  }

  searchAccGroup(search: string) {
    let searchval = new FormData();
    searchval.append("name", search);
    this.apiService.getAccGroup(searchval).subscribe((res) => {
      this.group_all = res['data'];

      console.log(this.group_all)
    });
  }



  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }



  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  grpSummaryexport() {

  }

  openPDF() {
    $("#contentToConvert").show();

    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      //208  
      var pageHeight = 495;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/jpeg', 1.0)
      // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF         
      // var position = 0;
      // pdf.addImage(contentDataURL, 'JPG', 0, position, imgWidth, imgHeight);

      // pdf.save('Account Group Summary Report.pdf'); // Generated PDF         

    });

    $("#contentToConvert").hide();


  }


  
  excelExport(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-file'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportExcel() {   
    this.exportLoader = true; 
    setTimeout(() => {
      this.excelExport();
  }, 3000);


  }


  exportAsXLSX(formdata: { value: any; }): void {

    this.apiService.groupSummaryToatalReport(formdata.value).subscribe((res: any) => {
      this.reportData = res['data'];
      console.log(this.reportData);
    });

    this.excelService.exportAsExcelFile(this.reportData, 'Account Group Summary Report');
  }

// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;

  if (formdata.value.period_type === 'c') {
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
  }

  this.apiService.openingbalanceSummaryReport(formdata.value, 1).subscribe((res: any) => {

    this.pageLoadingImg = false;
    this.ledgerList = res.data.data;
    this.curpage = res['data']['current_page'];
    this.lastpage = res['data']['last_page'];

    this.total_debit = res.info.total_debit;
    this.total_credit = res.info.total_credit;
    this.repDate = res.info.Date;


    this.repDate = res.info.Date;
    // this.dispnote = res.data.show_notes;
    // this.debitAmt = res.data.debit_amnt;
    // this.crdAmt = res.data.credit_amnt;


    if (!this.disp_void) {
      this.disp_void = 2;
    }

    const heads = [
      this.translate.instant('ACCOUNTS.Particulars'),
      this.translate.instant('ACCOUNTS.Debit_Amount'),
      this.translate.instant('ACCOUNTS.Credit_Amount')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 35;
      const data = [];
  
      this.ledgerList.forEach(item=>{
        if(this.disp_void!=1 && (item.vch_in||item.vch_out)){

          if(this.translate.currentLang == 'Arabic'){
            this.lang_filter = item.accgrp_name_ar;

           } else if (this.translate.currentLang == 'English'){
             this.lang_filter = item.accgrp_name;
           }

           data.push([
            this.lang_filter, 
            this.currencyPipe.transform(item.vch_in, '', '', this.country_dec),
            this.currencyPipe.transform(item.vch_out, '', '', this.country_dec),
          ])

        }

        item.ledgers.forEach(item1=>{
          if(item1.length != 0 && this.disp_void!=2){
            if(item1.vch_in != 0 || item1.vch_out !=0){

              if(this.translate.currentLang == 'Arabic'){
                this.lang_filter = item1.ledger_alias;
   
               } else if (this.translate.currentLang == 'English'){
                 this.lang_filter = item1.ledger_name;
               }
               
               if(item1.vch_in != 0){
                this.in_amount = this.currencyPipe.transform(item1.vch_in, '', '', this.country_dec)
               } else if(item1.vch_in == 0){
                this.in_amount = ''
               }
  
               if(item1.vch_out != 0){
                this.out_amount = this.currencyPipe.transform(item1.vch_out, '', '', this.country_dec)
               } else if(item1.vch_out == 0){
                this.out_amount = ''
               }
  
               data.push(['                      ' +this.lang_filter, '                     ' +this.in_amount, '                      ' +this.out_amount])
              }
          }
        })

        item.sub_groups.forEach(item2 => {
          if(item2.length != 0){

            if(this.disp_void!=1 &&(item2.vch_in||item2.vch_out)){

              if(this.translate.currentLang == 'Arabic'){
                this.lang_filter = item2.accgrp_name_ar;
   
               } else if (this.translate.currentLang == 'English'){
                 this.lang_filter = item2.accgrp_name;
               }

               if(item2.vch_in != 0){
                this.in_amount = this.currencyPipe.transform(item2.vch_in, '', '', this.country_dec)
               } else if(item2.vch_in == 0){
                this.in_amount = ''
               }
  
               if(item2.vch_out != 0){
                this.out_amount = this.currencyPipe.transform(item2.vch_out, '', '', this.country_dec)
               } else if(item2.vch_out == 0){
                this.out_amount = ''
               }
              
              data.push(['            ' +this.lang_filter, '            ' +this.in_amount, '            ' +this.out_amount])

            }
         
          }

          item2.ledgers.forEach(item3=> {
            if(item3.length != 0 && this.disp_void!=2){
              if(item3.vch_in != 0||item3.vch_out != 0){

                if(this.translate.currentLang == 'Arabic'){
                  this.lang_filter = item3.ledger_alias;
     
                 } else if (this.translate.currentLang == 'English'){
                   this.lang_filter = item3.ledger_name;
                 }
                 
                 if(item3.vch_in != 0){
                  this.in_amount = this.currencyPipe.transform(item3.vch_in, '', '', this.country_dec)
                 } else if(item3.vch_in == 0){
                  this.in_amount = ''
                 }
    
                 if(item3.vch_out != 0){
                  this.out_amount = this.currencyPipe.transform(item3.vch_out, '', '', this.country_dec)
                 } else if(item3.vch_out == 0){
                  this.out_amount = ''
                 }

                data.push(['                      ' +this.lang_filter, '                     ' +this.in_amount, '                      ' +this.out_amount])

              }
            }
          });
        });
      })

      data.push([
        this.translate.instant('HOME.total'),
        this.currencyPipe.transform(this.total_debit, '', '', this.country_dec),
        this.currencyPipe.transform(this.total_credit, '', '', this.country_dec)
      ])
  
      let doc = this.excelService.getPdfObj();
  
      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');
  
      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.op_Balance')}`,  valueXPos, headerHeight + 15);
  
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
  
     
      doc.save(`Opening Balance Summary.pdf`);

      this.exportLoader = false;


  });
  
  
}


}
