import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ExcelService } from '../../../../../service/excel.service';
import { ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-cheque',
  templateUrl: './cheque.component.html',
  styleUrls: ['./cheque.component.css']
})
export class ChequeComponent implements OnInit {

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  fileName = 'post_dated_cheques.xlsx';
  printError = []; errObjArr = {
    bankErr: null, ledgErr: null,
    typeErr: null, chqErr: null, chqDteErr: null, chqAmntErr: null
  };


  curForm = 'Create';
  nextId = '';
  langText = {
    create: '',
    update: ''
  };

  godowns: any;
  nonAccount: any;
  pdc_vchtype_id: any;
  bank_ledger: any;
  ledger_id: any;
  cheq_no: any;
  cheq_date: any;
  pds_amnt: any;
  account = [];
  perpage = 20;
  totalItem = 0;

  info: any;
  curpage = 1;
  lastpage = 1;

  isSubmitInProg: boolean;
  recPriviewLoader: boolean;
  printPreview: any;
  pdc_no: any;
  pdc_date: any;
  pdc_note: '';
  gd_name: any;
  pdc_acc_ledger_id: any;
  pdc_ledger_id: any;
  pdc_description: '';
  cheq_issue_date: any;
  pdc_amount: any;
  date:any;
  bnk_ldg: any;
  gd_id: any;
  pageLoadingImg: boolean;
  accLoading: boolean;
  rec_acc_balance: any;
  rec_acc_bal_sign: any;
  rec_balance: any;
  rec_bal_sign: any;
  private modalRef: NgbModalRef;
  closeResult: string;  

  payment_types = [
    { id: 0, name: 'Payment' },
    { id: 1, name: 'Reciept' },


  ];

  status = {
    '0': 'Deleted',
    '1': 'Pending',
    '2': 'Submited',
    '3': 'Bounced',
    '4': 'Cancelled'
  };
  status_types = [
    { id: 1, name: 'Pending' },
    { id: 2, name: 'Submited' },
    { id: 3, name: 'Bounced' },
    { id: 4, name: 'Cancelled' }

  ];

  type = {
    '0': 'Payment',
    '1': 'Reciept'

  }

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  filterTypes1 = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'd', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  exportLoader: boolean;
  exp_info:[];
  branch_display_name: any;
  branch_display_code: any;
  is_vat: any;
  is_round_off: any;
  today_date: any;
  repDate: any;
  branch_open_date: any;
  logoImgUrl: '';
  payPriviewLoader: boolean;
  imageError: string;
  pdcImgUrl: any;
  pdc_image: any;
  base_url: string;
  country_dec: string;
  
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private excelService: ExcelService,private modalService: NgbModal) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
      // this.up = this.coreService.getUserPrivilage();
      // this.getAllGroup();

    });
    this.listGodowns();
    this.getAllNonAccountLedgers();
    this.getAllBankAccount();
    this.getNextPdcId();
    this.getAllPdc(this.curpage);

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');

    this.base_url = this.apiService.PHP_API_SERVER;

    this.country_dec=this.coreService.setDecimalLength();
  }

  langChange(){
    this.translate.get(['HOME.PAYMENT', 'HOME.RECEIPT']).subscribe((res: string) => {    
     
      
      this.payment_types = [
        { id: 0, name: res['HOME.PAYMENT'] },
        { id: 1, name: res['HOME.RECEIPT'] },
    
    
      ];
    });

  }

  validateAndSubmit(formgroup) {

   
    this.date = (this.date) ? this.apiService.formatDate(this.date) : this.date;
    this.cheq_date = (this.cheq_date) ? this.apiService.formatDate(this.cheq_date) : this.cheq_date;
    
    let searchval= new FormData(formgroup);

    this.printError = [];
    if (this.pdc_vchtype_id != 0 && this.pdc_vchtype_id != 1) {
      this.errObjArr.typeErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.typeErr = "f";
    }

    if (this.bnk_ldg == "" || this.bnk_ldg == undefined) {
      this.errObjArr.bankErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.bankErr = "f";
    }

    if (this.ledger_id == "" || this.ledger_id == undefined) {
      this.errObjArr.ledgErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    } else {
      this.errObjArr.ledgErr = "f";

    }
    if (this.cheq_no == "" || this.cheq_no == undefined) {
      this.errObjArr.chqErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    } else {
      this.errObjArr.chqErr = "f";

    }

    if (this.cheq_date == "" || this.cheq_date == undefined) {
      this.errObjArr.chqDteErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    } else {
      this.errObjArr.chqDteErr = "f";

    }

    if (this.pds_amnt == "" || this.pds_amnt == undefined) {
      this.errObjArr.chqAmntErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    } else {
      this.errObjArr.chqAmntErr = "f";

    }

    if (this.printError.length <= 0) {

      if (confirm('Do you wish to continue?')) {

        this.isSubmitInProg = true;
        let callFunction = '';
        if (this.curForm === this.langText.create) {
          callFunction = 'createPDC';
        }
        if (this.curForm === this.langText.update) {
          callFunction = 'updatePDC';
        }
        
        searchval.append("pdc_no", this.pdc_no);
        searchval.append("pdc_acc_ledger_id", this.bnk_ldg);
        searchval.append("cheq_issue_date", this.cheq_date);
        searchval.append("pdc_date", this.date);
        searchval.append("pdc_ledger_id", this.ledger_id);
        searchval.append("pdc_vchtype_id", this.pdc_vchtype_id);
        searchval.append("godown_id", this.gd_id);
        
        
        this.apiService[callFunction](searchval).subscribe((res) => {

        this.isSubmitInProg = false;

          if (res.error != null) {
            this.errObjArr = res.error;
          } else {
            this.curForm = '';
            this.closeModal.nativeElement.click();
            this.clearForm();
            this.getAllPdc(this.curpage);
            this.getNextPdcId();
            this.apiService.updatePDCNotification();
          }





        });
      }

    }
  }



  getNextPdcId() {
    this.apiService.getNextPdcId().subscribe((res) => {
      this.nextId = res.data.pdc_no;
    });
  }

  getAllBankAccount() {
    this.apiService.getAllBankAccount().subscribe((res) => {
      this.account = res.data;
    });
  }


  listGodowns() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];

    });
  }

  getAllNonAccountLedgers() {
    this.apiService.getAllNonAccountLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }


  getAccLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.bnk_ldg }).subscribe((res) => {
      this.accLoading = false;
      this.rec_acc_balance = res.data.bal_without_sign;
      this.rec_acc_bal_sign = res.data.bal_sign;
    });
  }


  getLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.ledger_id }).subscribe((res) => {
      this.accLoading = false;
      this.rec_balance = res.data.bal_without_sign;
      this.rec_bal_sign = res.data.bal_sign;
    });
  }

  getAllPdc(pageNo) {
    this.pageLoadingImg = true;

    this.apiService.listPdc(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  getAllPdcByFilter(form, pageNo) {
    this.pageLoadingImg = true;
    this.perpage = 20;
    form.value.export = '';

    this.apiService.listPdcByFilter(form.value, pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    

    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
  }


  searchInp(e) {
    let searchval = new FormData();
    searchval.append("search", e);
    this.pageLoadingImg = true;
    this.apiService.filterPDC(searchval, 1, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  createPay() {
    this.curForm = 'Create';
    this.clearForm();
    this.getNextPdcId();
    this.pdcImgUrl='';
  }

  viewPDC(pdc_no) {
    this.payPriviewLoader = true;

    this.apiService.getPDCview({ pdc_no: pdc_no }).subscribe((res) => {
      this.payPriviewLoader = false;

      this.printPreview = res;

     

    });

  }
  voidPDC(pdc_no) {

    if (confirm('Are you sure to void this record ?')) {
      this.isSubmitInProg=true;
      this.apiService.voidPDC({ pdc_no: pdc_no }).subscribe((res) => {
        
        this.isSubmitInProg = false;
        this.getAllPdc(this.curpage);
        this.apiService.updatePDCNotification();

      });
    }

  }

  clearForm() {
    this.date = new Date();
    this.pdc_vchtype_id = null;
    this.pdc_note = '';
    this.gd_id = null;
    this.bnk_ldg = null;
    this.ledger_id = null;
    this.pdc_description = '';
    this.cheq_no = '';
    this.cheq_date = '';
    this.pds_amnt = '';
    this.pdcImgUrl='';
    this.pdc_image =null;
  }

  editVoucher(pdc_no) {
    this.nextId = '';
    this.curForm = this.langText.update;
    this.clearForm();
    this.printError = [];
    this.apiService.getPDCview({ pdc_no: pdc_no }).subscribe((res) => {
      this.nextId = res.pdc_no;
      this.pdc_no = res.pdc_no;
      
      this.date = new Date(res.pdc_datetime);
      // const result = this.account.filter(acc => acc.ledger_id == res.rec_acc_ledger_id);
      // this.rec.rec_acc = result[0];
      
      this.pdc_vchtype_id = res.pdc_vchtype_id;
      this.pdc_note = res.pdc_note;
      this.gd_id = res.pdc_godown_id;
      this.bnk_ldg = res.acc_ledger.ledger_id;
      this.ledger_id = res.pdc_ledger_id;
      this.pdc_description = res.pdc_description;
      this.cheq_no = res.cheq_no;
      this.cheq_date = new Date(res.cheq_issue_date);
      this.pds_amnt = res.pdc_amount;
      this.pdcImgUrl = this.base_url + '/' + res.cheq_img;
      this.getAccLedgerBalance();
        this.getLedgerBalance();
      // res.particulars.forEach((element,i) => {
      //   const result = this.nonAccount.filter(nonacc => nonacc.ledger_id == element.ledger_id);
      //   var tmpRow = {
      //     sub_id: element.recsub_id,
      //     ledger: result[0],
      //     amount: element.recsub_amount,
      //     narration: element.recsub_narration,
      //     vatIncExc: element.recsub_vat_inc,
      //     vat_per: element.recsub_vat_per,
      //     vat_amount: element.recsub_vat_amt,
      //     actlAmount: element.recsub_amount - element.recsub_vat_amt,
      //     // be care fully check if tax iclude excule option integrating
      //     fnlAmount: element.recsub_amount,
      //     rec_balance: 0,
      //     rec_bal_sign: '',
      //     accLoading: true
      //   }
      //   this.rec.ledgers.push(tmpRow);
      
      // });
      // this.updateTotoal();
    });
  }



  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(form) {
    this.exportLoader = true;
    form.value.export = 'export';


    this.apiService.listPdcByFilter(form.value, 1, '').subscribe((res: any) => {
   

      this.exportLoader = false;

      this.exp_info = res.data;
          
      // this.exp_totalRes = res.data.totalDetails;
      // this.reportBy = res.data.reportBy;
      // this.reportType = res.data.reportType;
      // this.repDate = res.data.Date;
      // this.from = res.data.from; 
      setTimeout(() => {
        this.exportexcel();
      }, 3000);

    });
  }



  // pdf download
  exportToPdf(form) {

    form.value.export = 'export';

    this.exportLoader = true;

    const lblXPos = 18;
    const valueXPos = 55;

    const heads = [

      this.translate.instant('Table.pdc_no'),
      this.translate.instant('Table.pdc_date'),
      this.translate.instant('Table.cheq_issue_date'),
      this.translate.instant('Table.cheq_no'),
      this.translate.instant('HOME.LEDGER'),
      this.translate.instant('Table.amnt'),
      this.translate.instant('Table.status'),
      this.translate.instant('HOME.TYPE'),

    ];
    const rows = [];
    const headerHeight = 25;


    this.apiService.listPdcByFilter(form.value, 1, '').subscribe((res: any) => {

      if (res.data) {
        
        res.data.forEach((data: any) => {
          const arr = [
          data.pdc_no,
          // data.pdc_date,
          this.datePipe.transform(data.pdc_date, 'dd/MM/yyyy'),
          this.datePipe.transform(data.cheq_issue_date, 'dd/MM/yyyy'),
          // data.cheq_issue_date,
          data.cheq_no,
          data.ledger_name,
          this.currencyPipe.transform(data.pdc_amount,'', '', this.country_dec),
          this.status[data.pdc_flags],
          this.type[data.pdc_vchtype_id],
          ];

          rows.push(arr);

        });
      }




      let doc = this.excelService.getPdfObj();

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text('POST DATED CHEQUES', 85, headerHeight - 2);


      //
      doc.setFontSize(10);
      doc.text('Branch', lblXPos, headerHeight + 5);
      doc.setFontSize(11);
      doc.text(':  ' + this.branch_display_name + ' (' + this.branch_display_code + ')', valueXPos, headerHeight + 5);

      // if (this.repDate) {
      //   doc.setFontSize(10);
      //   doc.text(`Period`, lblXPos, headerHeight + 10);
      //   doc.text(`:  ${this.repDate.date1} To ${this.repDate.date2}`, valueXPos, headerHeight + 10);
      // } else {
      //   doc.setFontSize(10);
      //   doc.text(`Period`, lblXPos, headerHeight + 10);
      //   doc.text(`:  ${this.branch_open_date} To ${this.today_date}`, valueXPos, headerHeight + 10);

      // }

      doc.setFontSize(10);

      // doc.text(`${this.translate.instant('Common.vat')}`, lblXPos, headerHeight + 25);
      // doc.text(':  ' + this.currencyPipe.transform(this.total_no, ' '),  valueXPos, headerHeight + 25);

      doc = this.excelService.addTableToPdf(doc, headerHeight + 27, lblXPos, heads, rows, this.logoImgUrl, this.branch_display_name);
      doc.save(`post_dated_cheque.pdf`);
      this.exportLoader = false;

    });
    // if(this.is_notes){
    //   heads.splice(5,0, this.translate.instant('Purchase.notes'))
    // }

  }

  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.pdc_image = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.pdcImgUrl = event.target.result;
    }

  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg'];
      // const max_height = 250;
      // const max_width = 250;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }



      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          // const img_height = rs.currentTarget['height'];
          // const img_width = rs.currentTarget['width'];

          // console.log(img_height, img_width);


          // if (img_height > max_height || img_width > max_width) {
          //   this.imageError =
          //     'Maximum dimentions allowed ' +
          //     max_height +
          //     ' X ' +
          //     max_width +
          //     'px';
          //   return false;
          // } else {
            this.pdcImgUrl = e.target.result;
        // }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);




    }
  }

  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
