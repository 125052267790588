import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.css']
})
export class ClientSettingsComponent implements OnInit {

  constructor(private apiService: ApiService, private coreService: CoreService) { }
  clientSettings = [];
  pageLoadingImg = false;
  pagesubLoadingImg = false;
  ngOnInit() {
    this.getClientSetting();
  }

  getClientSetting() {
    this.pageLoadingImg = true;
    this.apiService.getClientSetting().subscribe((res) => {
      if (res['data']) {
        this.clientSettings = res['data'];
      } 
      this.pageLoadingImg = false;
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  update(){
    this.pagesubLoadingImg = true;
    this.apiService.updateClientSettings({settings: this.clientSettings}).subscribe((res) => {
      this.pagesubLoadingImg = false;
      if (res.data == 'success') {
        this.coreService.showMessage('Settings Updated Successfully');
      } else if (res.data == 'falied') {
        this.coreService.showMessage('Failed to Update Settings');
      }
    }, (error) => {
      this.pagesubLoadingImg = false;
    });
  }
}
