import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rep-customers',
  templateUrl: './rep-customers.component.html',
  styleUrls: ['./rep-customers.component.css']
})
export class RepCustomersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
