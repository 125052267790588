import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { CoreService } from '../../service/core.service';
@Component({
  selector: 'app-price-cheker',
  templateUrl: './price-cheker.component.html',
  styleUrls: ['./price-cheker.component.css']
})
export class PriceChekerComponent implements OnInit {


  godown_all: any;
  stkprd_all: any;
  cust_types = [
    { id: 1, name: 'New', disabled: true },
    { id: 2, name: 'Registered' }
  ];

  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
    { id: 3, name: 'Invoice' },
  ];

  vat_types = [
    { id: 1, name: 'Excl. Vat' },
    { id: 2, name: 'Incl. Vat' }
  ];

  item_disc_types = [
    { id: 1, name: 'Rate' },
    { id: 2, name: 'Percentage' }
  ];

  customer: any;
  cust_type: any;
  inv_type: any;
  print_style: any;
  disable_sales_without_stock: any;
  sales_print_name: any;
  cust_code: any;
  cust_addr: any;
  cntct_num: any;
  vat_no: any;
  godownStocks: any;
  branchStocks: any;
  selctedProd: any;
  excl_vat: number;
  item_disc_type: any;
  taxProdCategories: any;
  taxpercentage: any;
  rate: number;
  cur_code =  'SAR';
  prd_tax_cat_id: any;
  unit: any;
  selectedProdDetails: any;
  qty: any;
  itemDisc: any;
  purchAmnt: any;
  tax_amnt: any;
  taxprice: number;
  grnd_totl: any;
  disc_amnt: any;
  itemTotal: any;
  printError: any;
  gd_id: any;
  stkprdsel: any;
  base_qty: any;
  unit_name: any;
  salesItems = [];
  item_dicrptn: any;
  resDup: any;
  taxvaltotqty: number;
  totItemprice = 0;
  totItemDisc = 0;
  totVatAmnt = 0;
  net_disc = 0;
  lastInvoice: any;
  cust_id: any;
  barcode: string;
  cust_name: string;
  saleqt_date: any;
  selectError: boolean;
  qt_no: string | Blob;
  saleRefNo: any;
  cmpny: any;

  savedTemplate = {
    title_text: 'TAX INVOICE  فاتورة ضريبية فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  saleData: any;
  itemRate: number;

  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Sales' }
  ];
  purch_type_taxcat_id: any;
  taxListCategories: any;
  purchtypesel: any;
  zeroType: boolean;
  godownStocksTotal = 0;
  branchStocksTotal = 0;
  lastCust = 0;
  draftLoader: boolean;
  draftList: any;
  due_date: any;
  sale_agent: any;
  sqdrf_id: any;
  listing: boolean;
  list_sales: any;
  pgend: boolean;
  pgstart: boolean;
  curpage: any;
  lastpage: any;
  from: any;
  importLoader: boolean;
  imporDrafttLoader: boolean;
  sq_inv_no: any;
  isEdit: boolean;
  sl_no: any;
  saleQtnData: any;
  previewLoader: boolean;
  sales_note: any;

  salesPayType = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank / Card' }


  ];
  payTypes = ['Cash', 'Credit', 'Bank / Card'];
  defLedger: any;
  sale_pay_type: number;
  sale_acc_ledger_id: string;
  delivry_addr: any;
  valid_till_date: any;
  inv_amnt: number;
  recivd_amnt: number;
  bal_amnt: number;
  sales_agents: any;
  is_description: any[];
  is_prew_description: any[];
  pageLoadingImg: boolean;
  editLogpreviewLoader: boolean;
  saleEditLog: any;
  editMessage: boolean;
  closeResult: string;
  noCustomer: boolean;
  edit_sales_inv_no_from_ds: any;
  qr_inv: any;
  sales_settings: any;
  userdefltGwn: any;
  prodUnitLoading = false;
  validationMsg = '';

  cust_balance: 0;
  cust_bal_sign: '';
  up: any;
  pr_name :any;
  pr_alias :any;
  base_url: string;
  prdImgUrl: string;
  prdImgUrlDef = '';
  settings:any;
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.base_url = this.apiService.PHP_API_SERVER;
    this.getPriceChekerSettings();
  }
  getPriceChekerSettings() {
    this.apiService.getPriceChekerSettings().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        if (this.settings.cmp_price_checker_img) {
          this.prdImgUrlDef = this.base_url + '/' + this.settings.cmp_price_checker_img;
        }

      }
    });

  }
  searchStkPrdBarcode(search: any) {
    if (!search) {
      $("#br_err").text("Enter Barcode");
      return false;
    } else {
      $("#br_err").text("");
    }
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.prodUnitLoading = true;
    this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
      this.barcode = '';
      // this.stkprd_all = res['data'];
      if (res.data) {

        $("#br_err").text("");
        this.stkprdsel = res.data;
        this.pr_name = res.data.prd_name;
        this.pr_alias = res.data.prd_alias;
        this.selectedProduct(res.data.prd_id, this.gd_id, this.stkprdsel);

      } else {
        $("#br_err").text("No product found");
        this.stkprdsel = {
          prd_name: null
        };
        this.stkprdsel = "";
        this.selctedProd = {};
        this.qty = '';
        this.rate = 0;
        this.unit = '';
        this.disc_amnt = 0;
        // this.prd_tax_cat_id = '';
        this.tax_amnt = '';
        this.grnd_totl = '';
        this.item_dicrptn = '';
        this.godownStocks = [];
        this.branchStocks = [];
        this.lastInvoice = [];
        this.isEdit = false;
        this.sl_no = '';
        this.base_qty = '';
        this.unit_name = '';
      }

    });
  }
  selectedProduct(prd_id, gd_id, stkprdsel) {
    this.printError = [];

    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }

    gd_id = 0;
    this.getStockDetails(prd_id, gd_id);

    
  }
  getStockDetails(prd_id, gd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];
      this.cur_code = this.selctedProd.currency_code;
      this.unit = (this.stkprdsel.produnit_unit_id ? this.stkprdsel.produnit_unit_id : this.selctedProd.prd_base_unit_id);
      // this.barcode = this.selctedProd.prd_barcode;
      this.selectedUnit(this.unit);
      // this.calcRates();


    });

  }
  selectedUnit(unit) {
    const prdUnts = this.selctedProd.prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === unit);
    this.rate = (prdUnts[0].sur_unit_rate ? prdUnts[0].sur_unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);
    this.base_qty = prdUnts[0].unit_base_qty;
    this.unit_name = prdUnts[0].unit_name;
console.log(this.selctedProd.prd_img_url);

    this.prdImgUrl = this.selctedProd.prd_img_url ? this.base_url + '/' + this.selctedProd.prd_img_url:this.prdImgUrlDef;
    
    
    this.prodUnitLoading = false;
    this.calcRates();
  }
  calcRates() {

    this.qty = this.qty ? this.qty : 1;
    this.rate = this.rate ? this.rate : 0;
    this.tax_amnt = (this.rate*this.selctedProd.prd_tax)/100;
    this.rate = this.rate+this.tax_amnt;
    this.rate = Math.round(this.rate * 100) / 100;
    this.itemDisc = this.disc_amnt ? this.disc_amnt : 0;
    this.barcode = '';
  }
}
