import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-ledger-privilages',
  templateUrl: './ledger-privilages.component.html',
  styleUrls: ['./ledger-privilages.component.css']
})
export class LedgerPrivilagesComponent implements OnInit {
  users: any;
  LedgIds: any;
  ledger_id: any;
  accgrp_all: any;
  items: any;
  pageLoadingImg: boolean;
  usr_id: any;
  buttonLoadingImg: boolean;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.items = [];
    this.pageLoadingImg = false;
  }


  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }


  selectUser(user) {
    if (user) {
      let searchval = new FormData();
      searchval.append("usr_id", user.usr_id);
      this.apiService.getLedgerPrivilages(searchval).subscribe((res) => {
        this.LedgIds = res['data'];
        // this.subIds  =  res['data'];               
      });
      // this.getLedgersByGroup(1);

      this.ledger_id = user.usr_id;

    } else {
      this.ledger_id = 0;
    }
    // console.log(this.items);

  }

  //product form search 
  searchAccGrp(search: string) {
    let searchval = new FormData();
    searchval.append("accgrp_name", search);
    this.apiService.getGroupbyName(searchval).subscribe((res) => {
      this.accgrp_all = res['data'];

    });
  }

  selectledgerGrp(grp) {
    if (grp) {
      this.selectUser(this.usr_id);
      this.pageLoadingImg = true;
      let searchval = new FormData();
      searchval.append("ledger_grp", grp.accgrp_id);
      this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
        this.items = res['data'];
        this.pageLoadingImg = false;
      });
    }
    // console.log(this.items);
  }

  changeSelection(value){
    for (var key in this.LedgIds) {
      this.LedgIds[key] = value;
   }

  }



  updatePrivilage(formgroup, grp) {
    formgroup.value.acc_grp = grp;
    this.buttonLoadingImg = true;

    this.apiService.updateLedgerPrivilage(formgroup.value).subscribe((res) => {
      // console.log(res);
      // this.privilageRes = res['data'];
      //   // console.log(this.privilageRes);
      //   let searchval = new FormData();  
      //   searchval.append("usr_id",formgroup.value.ledger_id);  
      //   this.apiService.getUserPrivilages(searchval).subscribe((res) => {
      //     this.menuIds = res['data'];
      //   });
      //   if(this.menuIds){
      //   localStorage.removeItem("urpg");
      //   this.coreService.setLocalStorage('urpg', JSON.stringify(this.menuIds));
      // }
      this.buttonLoadingImg = false;

      this.coreService.showMessage('Updated Successfully');

    });
    // console.log(formgroup.value);
  }

}
