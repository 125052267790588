import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.css']
})
export class PayrollComponent implements OnInit {

  show: boolean = false;
  pageLoadingImg: boolean;
  dataLoadingImg: boolean;
  date_err: any = '';
  date: any = '';
  result = '';
  employees: any;
  err: any = [];
  payroll_data: any;
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
  }
  getEmpPayroll() {
    $("#date_err").text('');
    if (!this.date) {
      $("#date_err").text('Select a month');
      return false;
    } else {
      this.dataLoadingImg = true;
      let searchval = new FormData();
      this.date = (this.date) ? this.apiService.formatDate(this.date) : this.date;
      searchval.append("date", this.date);
      this.apiService.getEmpPayroll(searchval).subscribe((res) => {
        this.show = true;
        this.employees = res['data'];
        console.log(this.employees);
        
        this.dataLoadingImg = false;
      });
    }
  }
  viewPayroll(data){
    this.payroll_data = data;
  }

}
