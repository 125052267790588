import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-enable-disable-module',
  templateUrl: './enable-disable-module.component.html',
  styleUrls: ['./enable-disable-module.component.css']
})
export class EnableDisableModuleComponent implements OnInit {

  constructor(private apiService: ApiService, private coreService: CoreService) { }
  moduleSettings = [];
  pageLoadingImg = false;
  pagesubLoadingImg = false;
  ngOnInit() {
    this.getEnbaleDisableModuleSetting();
  }

  getEnbaleDisableModuleSetting() {
    this.pageLoadingImg = true;
    this.apiService.getEnbaleDisableModuleSetting().subscribe((res) => {
      if (res['data']) {
        this.moduleSettings = res['data'];
      } 
      this.pageLoadingImg = false;
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  update(){
    this.pagesubLoadingImg = true;
    this.apiService.updateEnbaleDisableModuleSettings({settings: this.moduleSettings}).subscribe((res) => {
      this.pagesubLoadingImg = false;
      if (res.data == 'success') {
        this.coreService.showMessage('Settings Updated Successfully');
      } else if (res.data == 'falied') {
        this.coreService.showMessage('Failed to Update Settings');
      }
    }, (error) => {
      this.pagesubLoadingImg = false;
    });
  }

}
