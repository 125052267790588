import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-price-checker-settings',
  templateUrl: './price-checker-settings.component.html',
  styleUrls: ['./price-checker-settings.component.css']
})
export class PriceCheckerSettingsComponent implements OnInit {
  cmp_price_checker_img: any;
  settings: any;
  imageError = '';
  prdImgUrl = '';
  is_selected = false;
  base_url: string;
  pageLoadingImg: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.base_url = this.apiService.PHP_API_SERVER;
    this.getPriceChekerSettings();
  }
  getPriceChekerSettings() {

    this.apiService.getPriceChekerSettings().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.cmp_price_checker_img = this.base_url + '/' + this.settings.cmp_price_checker_img;
        if (this.settings.cmp_price_checker_img) {
          this.prdImgUrl = this.base_url + '/' + this.settings.cmp_price_checker_img;
        }

      } else {
        this.cmp_price_checker_img = '';

      }
    });

  }
  fileChangeEvent(fileInput: any) {
    this.is_selected = false;
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 500;
      const max_width = 500;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > max_height || img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              ' X ' +
              max_width +
              'px';
            return false;
          } else {
            this.is_selected = true;
            this.prdImgUrl = e.target.result;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  update(form) {

    let searchval = new FormData(form);
    this.pageLoadingImg = true;
    this.imageError = '';
    console.log(searchval);
    
    if(!this.is_selected){
      this.imageError = 'Image required';
      this.pageLoadingImg = false;
      return false;
    }
    this.apiService.updatePriceChekerSettings(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

}
