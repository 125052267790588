import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {

  dep_name: any;
  alldep: any;
  pageLoadingImg: boolean;

  selectedData: any = { dep_id: null, error: null, dep_name: null, message: null };
  result = '';
  departments: string[];
  up: any;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.coreService.getToken();
    this.up = this.coreService.getUserPrivilage();
  }
  searchDep(search: string) {
    let searchval = new FormData();
    searchval.append("dep_name", search);
    this.apiService.getDepByName(searchval).subscribe((res) => {
      this.departments = res['data'];
    });
    $('.prev-list').show();
  }
  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("dep_name", search);
    this.apiService.getDepByName(searchval).subscribe((res) => {
      this.departments = res['data'];
    });
    $('.prev-list').hide();
  }

  //Remove Name Suggestions

  removeName() {
    this.departments = [];
    $('.prev-list').hide();
  }

  selectCat(dep: any) {
    if (dep) {
      this.result = '';
      $("#editdepf").show();
      this.selectedData = dep;
    }
    this.departments = [];
  }

  tabClick(tab: { index: number; }) {
    if (tab.index == 1)
      this.result = '';
  }
  createDep() {
    let searchval = new FormData();
    searchval.append("dep_name", this.dep_name);
    this.pageLoadingImg = true;

    this.apiService.createDep(searchval).subscribe((dep) => {
      this.pageLoadingImg = false;

      if (dep.error != null) {
        this.result = dep.error;
        console.log(this.result);
      }
      else {
        this.coreService.showMessage(dep.message);
        this.dep_name = '';
      }

    });
  }
  updateDep() {
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append("dep_name", this.selectedData.dep_name);
    searchval.append("dep_id", this.selectedData.dep_id);
    this.apiService.updateDep(searchval).subscribe((dep) => {
      this.pageLoadingImg = false;

      if (dep.error != null) {
        this.result = dep.error;
      }
      else {
        $("#editdepf").hide();
        this.coreService.showMessage(dep.message);
        this.departments = [];
      }

    });
  }

}
