import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-emp-report',
  templateUrl: './emp-report.component.html',
  styleUrls: ['./emp-report.component.css']
})
export class EmpReportComponent implements OnInit {

  constructor(private apiService: ApiService, private coreService: CoreService, private datePipe: DatePipe) { }
  fileName= 'employee_report.xlsx';
  departments: any;
  designations: any;
  rep: any;
  pageLoadingImg: boolean;
  exportLoader: boolean;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  dep_id: any;
  des_id: any;
  doc_type: any;
  days: any;
  days_filter_all = [
    { id: 'all', name: 'All' },
    { id: '7', name: '7 Days' },
    { id: '14', name: '14 Days' },
    { id: '30', name: '30 Days' },
    { id: '60', name: '60 Days' },
    { id: '90', name: '90 Days' }
  ];
  dcos_all = [
    { id: '', name: 'None' },
    { id: 'iqama_exp', name: 'Iqama' },
    { id: 'passport_exp', name: 'Passport' },
    { id: 'baladiyah_exp', name: 'Baladiyah' },
    { id: 'licence_exp', name: 'Licence' }
  ];
  ngOnInit() {
    this.rep = [];
    this.coreService.getToken();
    this.pageLoadingImg = false;
    this.exportLoader = false;
    this.listRep();
  }
  listRep() {
    let searchval = new FormData();
    
    this.apiService.getEmpReport(searchval, 1).subscribe((res) => {

      this.rep = res['data']['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }

  searchDep(search: string) {
    let searchval = new FormData();
    searchval.append("dep_name", search);
    this.apiService.getDepByName(searchval).subscribe((res) => {
      this.departments = res['data'];
    });
  }
  searchDes(search: string) {
    let searchval = new FormData();
    searchval.append("des_name", search);
    this.apiService.getDesByName(searchval).subscribe((res) => {
      this.designations = res['data'];
    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formgroup) {

    let searchval = new FormData(formgroup);
    searchval.append("department", this.dep_id);
    searchval.append("designation", this.des_id);
    searchval.append("doc_type", this.doc_type);
    searchval.append("days", this.days);
    this.apiService.getEmpReport(searchval, 1).subscribe((res) => {
      this.rep = res['data']['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
  this.exportLoader = true;
   
  setTimeout(() => {
    this.exportexcel();
}, 3000);

  }

}
