import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-product-location',
  templateUrl: './product-location.component.html',
  styleUrls: ['./product-location.component.css']
})
export class ProductLocationComponent implements OnInit {
  fileName = 'Product Location.xlsx';
  
  selecTed: any;
  prd_cat: any;
  catagories: any;
  page_limit: any;
  count: number;
  pageLoadingImg: boolean;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  ErrorMsg: boolean;
  exportLoader: boolean;
  ExpselecTed: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.page_limit = 30;
    this.count = 0;
  }


  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
      this.selecTed = null;
    });

  }

  selectCat(cat_id) {
    this.pageNext(cat_id, 1);
  }


  pageNext(cat_id, pageno: any) {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("prd_cat_id", cat_id);
    searchval.append("page_limit", this.page_limit);
    this.apiService.getProdDetByCat(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.selecTed = res['data'];
      this.pageFrom = res['data']['from'];
      this.count = res['data']['count'];


      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  updateProdslocation(product_summary) {
    let searchval = new FormData();
    this.pageLoadingImg = true;

    this.apiService.addProdLocation(product_summary.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (!res.error) {
        this.ErrorMsg = false;
        this.coreService.showMessage('Updated Successfully');
      }
      else {
        this.ErrorMsg = true;
      }
    });

  }


  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(cat_id) {
    this.exportLoader = true;

    // debugger;
    let searchval = new FormData();

    searchval.append("prd_cat_id", cat_id);
    searchval.append("export", 'export');
    this.apiService.getProdDetByCat(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ExpselecTed = res['data'];

      setTimeout(() => {
        this.exportexcel();
      }, 3000);


    });



  }
  searchInp(e,f) {
    let searchval = new FormData();
    
    searchval.append("search", e);
 
    this.pageLoadingImg = true;
    this.apiService.getProdBylocation(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.selecTed = res['data'];
      this.count = res['data']['count'];
      this.pageFrom = res['data']['from'];
    });
  }

  
}
