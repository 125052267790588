import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';

@Component({
  selector: 'app-sales-agent',
  templateUrl: './sales-agent.component.html',
  styleUrls: ['./sales-agent.component.css']
})
export class SalesAgentComponent implements OnInit {
  ledgerAll: any;
  pageLoadingImg: boolean;
  resultobj: any =  {};
  ledgerId: any;
  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  pageLoadingImg1 = false;
  info = [];
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.getInfo(this.curpage);
  }

  getInfo(pageNo) {
    this.pageLoadingImg1 = true;
    this.apiService.getAllSalesAgent(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      console.log(this.info)
      this.pageLoadingImg1 = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  activate(id) {
    this.apiService.changeSalesAgentStatus(id).subscribe((res) => {
      this.coreService.showMessage(res.message);
    });
  }

  searchLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    searchval.append('acc_nature', ""+['1','2','3']); // Assets
    this.apiService.getLedByNature(searchval).subscribe((res) => {
      this.ledgerAll = res['data'];
    });
  }

  selectFrom(ledger) {
    this.ledgerId = ledger.ledger_id;
  }



  createStaff(formgroup: { value: any; }) {
    this.pageLoadingImg = true;
    // console.log(this.staff);
    formgroup.value.agent_ledger_id = this.ledgerId;
    // console.log(this.staff_laccount_no);
    // console.log(formgroup.value);
    this.apiService.addAgent(formgroup.value).subscribe((res) => {
      this.pageLoadingImg = false;
      // console.log(this.staff);
      if (res.error != null) {
        // console.log('result');
        // console.log(res.error);
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
        console.log(this.resultobj);
      } else {
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
      }

    });
  }

}
