// stock-routing.module.ts

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { ImportdashComponent } from './importdash/importdash.component';
import { ProductComponent } from './importdash/product/product.component';
import { CustomerComponent } from './importdash/customer/customer.component';
import { UserPrivilegesComponent } from './importdash/user-privileges/user-privileges.component';
import { RateSettingsComponent } from '../settings/rate-settings/rate-settings.component';


const routes: Routes = [
    {path: 'erp_settings', component: ImportdashComponent},
    {path: 'import_products', component: ProductComponent},
    {path: 'import_customer', component: CustomerComponent},
    {path: 'user_privileges', component: UserPrivilegesComponent},
    {path: 'rate_settings', component: RateSettingsComponent},
    

];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ExcelImportRoutingModule { }
