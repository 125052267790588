import { Component, OnInit } from '@angular/core';
import { Branch } from 'src/app/model/branch.model';
import { Feature } from 'src/app/model/feature.model';
import { Repprddet } from 'src/app/model/report/repprddet.model';
import { Subcatagory } from 'src/app/model/subcatagory.model';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-branch-stock',
  templateUrl: './branch-stock.component.html',
  styleUrls: ['./branch-stock.component.css']
})
export class BranchStockComponent implements OnInit {
  branches: any;
  Selbranch: string[];
  pageLoadingImg: boolean;
  up: any;
  prodsumms: any;
  totalCat: any;
  totalProducts: any;
  curpage: number;
  pageFrom: any;
  lastpage: number;
  pgend: boolean;
  pgstart: boolean;
  catagories: string[];
  products: any;
  cat_id: number;
  subcatagories: string[];
  changeBox: boolean;
  features: string[];
  branchlist: any;
  branchList: any;
  totalprdcts: any;
  exportLoader: boolean;
  fileName= 'barnch_wise_stock.xlsx';
  stockMatrix: any;
  expstockMatrix: any;
  expprodsumms: any;
  expbranchlist: any;
  exptotalprdcts: any;
  exptotalCat: any;
  constructor(private apiService:ApiService,private coreService: CoreService) { }

  ngOnInit() {
    let searchval = new FormData();
    this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
      searchval.append("Selbranch",branch['selected']);
      this.branches = branch['data'];
      this.Selbranch  = branch['selected'];
    });

    this.up = this.coreService.getUserPrivilage();
    this.pageLoadingImg = true;
    this.apiService.branchStock(searchval,1).subscribe((res) => {
      this.pageLoadingImg = false;
     
      this.prodsumms=res['data']['data']   
     this.branchlist=res['data']['branches']
     this.totalprdcts=res['data']['total_products']
     this.pageFrom = res['data']['from'];

     this.totalCat = res['data']['total_categories'];

     this.stockMatrix = this.prodsumms.map(item => {
      const row = this.branchlist.map(branch => {
        const stockData = item.branch_stocks.find(stk => stk.bs_branch_id === branch.branch_id);
        return stockData ? stockData.stock : 0;
      });
      return row;
    });
    
      this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
     this.pgEnd(this.curpage, this.lastpage);
     this.pgStart(this.curpage);
 
    });
   
    


  }
  ngAfterContentInit(){

    this.searchCat('');
    this.reeadFeat();
    // this.searchManf('');
    // this.searchUnit('');
    

  }
  ngOnDestroy(){
    $('.close').click();
 
  }

    //form search
    searchForm(search: string) {
      let searchval = new FormData();
      searchval.append("cat_name", search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.catagories = res['data'];
      });
  
    }
    // search product
selectProd(name: string) {

  const searchval = new FormData();
  searchval.append('prod_name', name);
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.products = res.data;
    console.log(this.products);
    
  });
}
searchCat(search: string) {
  const searchval = new FormData();
  searchval.append('cat_name', search);
  this.apiService.getCatbyName(searchval).subscribe((res) => {
    this.catagories = res['data'];
  });

}

//Remove Name Suggestions

removeName() {
  this.catagories = [];
  this.reeadFeat();
}


selectCat(cat_id: number) {
  this.cat_id = cat_id;

}
getBranchByName(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res) => {
    this.branches = res['data'];
    
    
  });
}

pageNext(formdata: { value: any; }, pageno: any) {
  $('.resultdata').empty();
  //  this.pageLoadingImg = true;

   let searchval = new FormData();
   searchval.append("pageno", pageno);
     this.pageLoadingImg = true;
     this.apiService.branchStock(formdata.value, pageno).subscribe((res) => {
     this.pageLoadingImg = false;
    
     this.prodsumms=res['data']['data']   
     this.branchlist=res['data']['branches']
     this.totalprdcts=res['data']['total_products']
     this.pageFrom = res['data']['from'];

     this.totalCat = res['data']['total_categories'];

     this.stockMatrix = this.prodsumms.map(item => {
      const row = this.branchlist.map(branch => {
        const stockData = item.branch_stocks.find(stk => stk.bs_branch_id === branch.branch_id);
        return stockData ? stockData.stock : 0;
      });
      return row;
    });
    
      this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
     this.pgEnd(this.curpage, this.lastpage);
     this.pgStart(this.curpage);

   });
 }

   // search sub category

   searchSubcat(search: string)
   {
         let searchval = new FormData();      
         searchval.append("subcat_name", search);    
          this.apiService.getSubcatByName(searchval).subscribe((res) => {
           this.subcatagories = res['data'];                
          });
          $('.prev-list').show(); 
   }




selectSubCats(catid: number) {
  this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
    this.subcatagories = res['data'];
    if (this.changeBox)
      this.changeBox = false;
    else
      this.changeBox = true;

  });
}

reeadFeat() {
  this.apiService.readFeat().subscribe((res: Feature[]) => {
    this.features = res['data'];

  });
}




  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }
  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  export(formdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      formdata.value.export = 'export';
      this.apiService.branchStock(formdata.value, 1).subscribe((res) => {

        this.expprodsumms=res['data']['data']   
        this.expbranchlist=res['data']['branches']
        this.exptotalprdcts=res['data']['total_products']
        this.exptotalCat = res['data']['total_categories'];
   
        this.expstockMatrix = this.prodsumms.map(item => {
         const row = this.branchlist.map(branch => {
           const stockData = item.branch_stocks.find(stk => stk.bs_branch_id === branch.branch_id);
           return stockData ? stockData.stock : 0;
         });
         return row;
       });
       
            
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
    formdata.value.export = '';
   

    });



    }


  
  
   

}
