import { Time } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { NgForm } from '@angular/forms';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-stock-verify',
  templateUrl: './stock-verify.component.html',
  styleUrls: ['./stock-verify.component.css']
})
export class StockVerifyComponent implements OnInit {
  fileName = 'invalid_items_from_log.xlsx';

  godown_all = [
    { "gd_id": 0, 'gd_name': 'Shop' }
  ];
  printError = []; errObjArr = {
    date: null, prd_id: null,
    gd_id: null, avl_stk: null
  };

  errObjArrLog = {
    date: null, prd_id: null,
    gd_id: null, avl_stk: null
  };

  printErrorLog = [];

  stkprd_all: any;
  stkprdsel: any;
  gsl_from: number;
  stock: {
    prd_name: string,
    prd_barcode: any,
    prd_ean: any,
    qty: any,
    qty_1: any,
    prd_id: number,
    branch_stock_id: number,
    stock_id: number
  };
  diff_stk: any;

  avl_stk: any;
  sv_date: any;
  trans_stocks = [];
  resDup: any;
  slno = 0;
  sv_notes: string;
  add_excess: boolean;
  add_missing: boolean;
  stck_vrfy_ref: 0;
  stck_vrfy_hstry: any;
  stockVerfdList: any;
  curpage: any;
  lastpage: any;
  slnum: number;
  pgend: boolean;
  pgstart: boolean;
  pageLoadingImg: boolean;
  stkpageLoadingImg: boolean;
  noStkTxtShow: boolean;
  log_file: any;
  tempFilePath: any;
  resultobj: any;
  log_stocks: any;
  logDatapageLoadingImg: boolean;
  notes: any;
  pageLoadingImgLog: boolean;
  no_stock: any[];
  exportLoader: boolean;
  from: any;
  loaduploadtext: boolean;
  ubcurpage: any;
  ublastpage: any;
  ubfrom: any;
  ubpgend: boolean;
  uploadedBarcodeList: any;
  ubpgstart: boolean;
  barcodeLoader: boolean;
  ubresultobj: any;
  ubloaduploadtext: boolean;
  barcodeDetList: any;
  previewLoader: boolean;
  count: any;
  cmpny: any;
  catagories: any;
  units_all: any;
  stkprdunit: any;
  unit_name: any;
  constructor(private apiService: ApiService, private coreService: CoreService) { }
  selecTed = [];
  date: any;
  gd_id: any;
  prd_alias: any;

  ngOnInit() {
    this.cmpny = this.coreService.getUserData('cmpny');
    this.stkpageLoadingImg = false;
    this.getGodownList();
    this.gsl_from = 0;
    this.stock = {
      prd_name: '',
      prd_barcode: '',
      prd_ean: '',
      qty: 0,
      qty_1: 0,
      prd_id: 0,
      branch_stock_id: 0,
      stock_id: 0
    };
    // this.avl_stk = 0;
    this.sv_date = new Date();
    this.date = new Date();
    this.getStckVrfyRefNum();
    this.stck_vrfy_hstry = null;
    this.getVerifiedStockList(1);
    this.getUploadBarcodeList(1);
    this.noStkTxtShow = false;
  }

  getStckVrfyRefNum() {

    this.apiService.getStckVrfyRefNum().subscribe((res: any) => {
      this.stck_vrfy_ref = res.ref_no;
      // console.log(this.stck_vrfy_ref);


    });

  }

  getStckVrfyHistory(prd_id,gd_id) {
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    searchval.append("gd_id", gd_id);
    this.apiService.getStckVrfyhstry(searchval).subscribe((res: any) => {
      this.stck_vrfy_hstry = res.data;
      // console.log(this.stck_vrfy_hstry);


    });

  }



  getGodown(search: string) {
    var prd_id = (this.stkprdsel.prd_id ? this.stkprdsel.prd_id : 0);
    if (prd_id) {
      let searchval = new FormData();
      searchval.append("gd_name", search);
      searchval.append("prd_id", prd_id);
      this.apiService.getGodownItemwise(searchval).subscribe((res) => {
        this.godown_all = res['data'];
        this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

      });
    }
  }

  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];

    });
  }


  searchStkPrd(search: string) {

    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append('stk_stat', '1');
    this.apiService.getStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  searchStkPrdBarcode(barcode) {
    this.stkpageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("barcode", barcode);
    // searchval.append('stk_stat', '1');
    this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.stkprdsel = res['data'];
      if (this.stkprd_all) {
        this.getStockbyGodown(this.stkprd_all.prd_id, this.gsl_from);
      } else {
        this.reFreshAll();
      }

    });
  }

  getStockbyGodown(prd_id, gd_id) {
    let searchval = new FormData();
    searchval.append("gd_id", gd_id);
    searchval.append("prd_id", prd_id);
    this.apiService.getStockbyGodown(searchval).subscribe((res) => {
      this.stkpageLoadingImg = false;
      this.noStkTxtShow = true;

      if (res['data']){
        this.stock = res['data'];
        this.stkprdunit=res['data'].base_unit_id;
      
      }
      else
        this.stock.prd_id = 0;

      // console.log(this.avl_stk);
      this.getStckVrfyHistory(prd_id,gd_id);
      this.avl_stk = '';
      this.diff_stk = 0;
    });

  }

  unitSelect(unt_id){
   
    if(unt_id){

      this.unit_name = this.units_all.find(x => x.unit_id === unt_id).unit_name;
      let  bas_qty = this.units_all.find(x => x.unit_id === unt_id).unit_base_qty;
      let  req_qty = (this.stock.qty_1/bas_qty);
      this.stock.qty = req_qty;
     
  }
}
  selectGd(gsl_from) {
    this.stkpageLoadingImg = true;

    if (gsl_from >= 0 && gsl_from != null) {
      // console.log(gsl_from);
      if (this.stkprdsel && this.stkprdsel.prd_id){
        this.getStockbyGodown(this.stkprdsel.prd_id, gsl_from);
      }else{
        this.stkpageLoadingImg = false;
        this.godownPrdStock(gsl_from);
      }
        
    } else {
      this.stkpageLoadingImg = false;
      this.reFreshAll();
    }
  }

  updateDiff(avl_stk) {
    if (avl_stk) {
      this.diff_stk = avl_stk - this.stock.qty;
      // this.diff_stk = Math.abs(this.diff_stk);
    }
    else
      this.diff_stk = 0;

    // console.log(avl_stk);
  }


  selectStockPrd(search) {
    this.stock.prd_id = 0;
    if (search) {
      this.getGodown('');
      this.stkpageLoadingImg = true;
      this.getStockbyGodown(search.prd_id, this.gsl_from);

      this.getUnits(search.prd_id);
    } else {
      this.getGodownList();
      this.stkpageLoadingImg = false;
      this.reFreshAll();
    }

  }
  getUnits(prd_id) {
    const searchval = new FormData();
    searchval.append("prd_id",prd_id);
    this.apiService.unitPrdtWise(searchval).subscribe((res) => {
      this.units_all = res['data'];

    });

  }


  addToList(res: { value: any; }) {

    console.log(res.value);
    this.validateForm();
    if (this.printError.length <= 0) {
      // filter array
      // var prod_id_inp = res.value.prd_id;
      // var filterObj = this.trans_stocks.filter(function (e) {
      //   return e.prod_id == prod_id_inp;
      // });
      res.value.sv_date = (res.value.sv_date) ?
        this.apiService.formatDate(res.value.sv_date) : res.value.sv_date;
      // check duplicates
      this.resDup = this.coreService.checkDuplicateValOpstk(this.trans_stocks, res.value.prd_id);
      if (typeof this.resDup == 'undefined') {
        this.slno = this.slno + 1;
        this.trans_stocks.push({
          "sl_no": this.slno, "prd_id": res.value.prd_id, "prod_name": res.value.prd_name, "branch_stock_id": res.value.branch_stock_id,"stkvrfy_unit_id": res.value.stkprdunit, "unit_name": res.value.unit_name,
          "stock_id": res.value.stock_id, "stkvrfy_date": this.apiService.formatDate(res.value.sv_date), "stkvrfy_req_qty": res.value.req_stk, "stkvrfy_existing_qty": res.value.avl_stk, "stkvrfy_diff_qty": res.value.diff_stk, "stkvrfy_notes": res.value.gt_notes, "stkvrfy_godown_id": res.value.gsl_from,
          "purch_rate": res.value.purch_rate, "base_unit_id": res.value.base_unit_id, "add_excess": res.value.add_excess, "add_missing": res.value.add_missing,
        });
        this.reFreshAll();

      } else {
        this.printError.push({ 'repeatErr': 'Already exists , Pleae remove from the table' });
      }
    }
  }

  deleteFromList(data: any) {
    var type = data.sl_no,
      i;
    for (i = this.trans_stocks.length - 1; i >= 0; --i) {
      if (this.trans_stocks[i].sl_no == type) {
        this.trans_stocks.splice(i, 1);
      }
    }
  }

  validateForm() {
    this.printError = [];


    if (this.sv_date == undefined || this.sv_date == null) {
      this.errObjArr.date = "t";
      this.printError.push({ 'date': 'Required' });
    }
    else {
      this.errObjArr.date = "";
    }

    if (this.stkprdsel == undefined || this.stkprdsel == null || this.stkprdsel == {}) {
      this.errObjArr.prd_id = "t";
      this.printError.push({ 'prd_id': 'Required' });
    }
    else {
      this.errObjArr.prd_id = "";
    }

    if (this.gsl_from == undefined || this.gsl_from == null) {
      this.errObjArr.gd_id = "t";
      this.printError.push({ 'gd_id': 'Required' });
    }
    else {
      this.errObjArr.gd_id = "";
    }

    if (!(this.avl_stk <= 0 || this.avl_stk >= 0)) {
      this.errObjArr.avl_stk = "t";
      this.printError.push({ 'avl_stk': 'Required' });
    }
    else {
      this.errObjArr.avl_stk = "";
    }

  }

  validateLogForm() {
    this.printErrorLog = [];


    if (this.date == undefined || this.date == null) {
      this.errObjArrLog.date = "t";
      this.printErrorLog.push({ 'date': 'Required' });
    }
    else {
      this.errObjArrLog.date = "";
    }


    if (this.gd_id == undefined || this.gd_id == null) {
      this.errObjArrLog.gd_id = "t";
      this.printErrorLog.push({ 'gd_id': 'Required' });
    }
    else {
      this.errObjArrLog.gd_id = "";
    }


  }

  reFreshAll() {
    // this.sv_date = new Date();
    this.stkprd_all = [];
    this.stkprdsel = this.stkprd_all[0];
    this.gsl_from = 0;
    this.stock = {
      prd_name: '',
      prd_barcode: '',
      prd_ean: '',
      qty: 0,
      qty_1: 0,
      prd_id: 0,
      branch_stock_id: 0,
      stock_id: 0
    };
    this.avl_stk = '';
    this.diff_stk = '';
    this.sv_notes = '';
    this.add_missing = false;
    this.add_excess = false;
    this.stck_vrfy_hstry = [];
    this.stkpageLoadingImg = false;
    this.noStkTxtShow = false;
    this.stkprdunit='';



  }

  submitVerification(form: NgForm) {
    var cnfrm = confirm("Are You Sure To Continue ?");
    if (cnfrm) {
      if (this.trans_stocks.length > 0) {
        this.pageLoadingImg = true;
        // let searchval = new FormData();
        form.value.items = this.trans_stocks;
        // console.log(form.value);
        this.apiService.stockVerify(form.value).subscribe((res) => {
          this.pageLoadingImg = false;
          // console.log(res);
          if (res.message) {
            this.reFreshAll();
            this.trans_stocks = [];
            this.getStckVrfyRefNum();
            this.getVerifiedStockList(1);

            this.coreService.showMessage('Verified Successfully');
          } else {
            this.printError.push({ 'repeatErr': 'Error Occured While Submitting,Please try Again' });

          }

        });

      } else {
        this.printError.push({ 'repeatErr': 'Please Add Atleast One Item' });
      }
    }
  }

  getVerifiedStockList(pagno) {

    let searchval = new FormData();
    this.apiService.getVerifiedStockList(searchval, pagno).subscribe((res) => {
      this.stockVerfdList = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.log_file = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.tempFilePath = event.target.result;
    }

  }

  uploadLogFile(form) {
    this.validateLogForm();
    // console.log(this.printErrorLog);
    if (this.printErrorLog.length <= 0) {
      var cnfrm = confirm("Are You Sure To Continue ?");
      if (cnfrm) {
        // if (this.trans_stocks.length > 0) {
        this.logDatapageLoadingImg = true;
        // form.value.stck_vrfy_ref = this.stck_vrfy_ref;

        let searchval = new FormData(form);
        searchval.append("gd_id", this.gd_id);
        searchval.append("note", this.notes);
        searchval.append("date", this.apiService.formatDate(this.date));

        this.log_stocks = [];
        this.no_stock = [];
        setTimeout(() => {
          this.loaduploadtext = true;
        }, 5000);
        this.apiService.stockVerifyByLog(searchval).subscribe((res) => {
          this.logDatapageLoadingImg = false;
          this.loaduploadtext = false;
          setTimeout(() => {
            this.loaduploadtext = false;
          }, 5000);


          if (res.error) {
            this.resultobj = res.error;
          } else {
            this.resultobj = [];
            this.log_stocks = res.data.in_stock;
            this.no_stock = res.data.no_stock;


          }
          // console.log(res);


        });


      }
    }
  }

  changeMisngStkStatus(value, id) {
    this.log_stocks[id].add_missing = value;
    if (value)
      this.log_stocks[id].add_excess = !value;
  }
  changeExcessStkStatus(value, id) {
    this.log_stocks[id].add_excess = value;
    if (value)
      this.log_stocks[id].add_missing = !value;
  }

  updateAvalStkLog(value, id) {
    this.log_stocks[id].stkvrfy_existing_qty = value;
    this.log_stocks[id].stkvrfy_diff_qty = this.log_stocks[id].stkvrfy_existing_qty - this.log_stocks[id].stkvrfy_req_qty;

    if (this.log_stocks[id].stkvrfy_diff_qty > 0) {
      this.changeExcessStkStatus(true, id);

    } else if (this.log_stocks[id].stkvrfy_diff_qty < 0) {
      this.changeMisngStkStatus(true, id);

    } else {
      this.changeMisngStkStatus(false, id);
      this.changeExcessStkStatus(false, id);

    }

  }


  deleteFromLogList(data: any) {
    var cnfrm = confirm("Remove Item From List ?");
    if (cnfrm) {
      var type = data.sl_no,
        i;
      for (i = this.log_stocks.length - 1; i >= 0; --i) {
        if (this.log_stocks[i].sl_no == type) {
          this.log_stocks.splice(i, 1);
        }
      }
    }
  }



  submitVerificationLog(form: NgForm) {
    var cnfrm = confirm("Are You Sure To Continue ?");
    if (cnfrm) {
      if (this.log_stocks.length > 0) {
        this.pageLoadingImgLog = true;
        // let searchval = new FormData();
        form.value.items = this.log_stocks;
        // console.log(form.value);
        this.apiService.stockVerify(form.value).subscribe((res) => {
          this.pageLoadingImgLog = false;
          // console.log(res);
          if (res.message) {
            this.reFreshAll();
            this.log_stocks = [];
            this.no_stock = [];

            this.getStckVrfyRefNum();
            this.getVerifiedStockList(1);

            this.coreService.showMessage('Verified Successfully');
          } else {
            this.printErrorLog.push({ 'repeatErr': 'Error Occured While Submitting,Please try Again' });

          }

        });

      } else {
        this.printErrorLog.push({ 'repeatErr': 'Please Add Atleast One Item' });
      }
    }
  }


  markAllMissing(value) {

    for (let i = 0; i < this.log_stocks.length; i++) {

      if (this.log_stocks[i].stkvrfy_existing_qty - this.log_stocks[i].stkvrfy_req_qty < 0) {
        this.log_stocks[i].add_missing = value;
      }

    }

  }

  markAllExcess(value) {

    for (let i = 0; i < this.log_stocks.length; i++) {

      if (this.log_stocks[i].stkvrfy_existing_qty - this.log_stocks[i].stkvrfy_req_qty > 0) {
        this.log_stocks[i].add_excess = value;
      }

    }

  }


  resetLog() {
    this.log_stocks = [];
    this.no_stock = [];
    $('#log_file').val('');
    this.notes = '';
    this.gd_id = 0;

  }


  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  // Barcode upload section

  getUploadBarcodeList(pagno) {

    let searchval = new FormData();
    this.apiService.getUploadedBarcodeList(searchval, pagno).subscribe((res) => {
      this.uploadedBarcodeList = res.data.data;
      this.ubcurpage = res.data['current_page'];
      this.ublastpage = res.data['last_page'];
      this.ubfrom = res.data['from'];
      this.BrcpgEnd(this.ubcurpage, this.ublastpage);
      this.BrcpgStart(this.ubcurpage);
      this.slnum = 0;

    });
  }

  BrcpgEnd(curr, end) {
    if (curr == end)
      this.ubpgend = true;
    else
      this.ubpgend = false;

  }

  BrcpgStart(curr) {
    if (curr == 1)
      this.ubpgstart = true;
    else
      this.ubpgstart = false;
  }

  uploadBarcodeFile(form) {

    var cnfrm = confirm("Are You Sure To Continue ?");
    if (cnfrm) {
      this.barcodeLoader = true;
      setTimeout(() => {
        this.ubloaduploadtext = true;
      }, 5000);
      let searchval = new FormData(form);
      this.ubresultobj = [];
      this.apiService.uploadBarcodeFile(searchval).subscribe((res) => {
        this.barcodeLoader = false;
        setTimeout(() => {
          this.ubloaduploadtext = false;
        }, 5000);

        if (res.error) {
          this.ubresultobj = res.error;
        } else {
          this.getUploadBarcodeList(1);

          this.ubresultobj = [];
          this.coreService.showMessage(res.message);
          form.reset();
          $('.close').click();

        }

      });

    }

  }

  openBarcodeList(id){
    this.previewLoader = true;

    if(id){
      let searchval = new FormData();
      searchval.append("ref_id", id);

      this.apiService.getBarcodeList(searchval).subscribe((res) => {
      this.previewLoader = false;

        if(res.data){
          this.barcodeDetList = res.data;
        }
      });

    }else{
      this.previewLoader = false;

    }

  }

  godownPrdStock(godwn){
    let searchval = new FormData();
    searchval.append("godown", godwn);
    this.stkpageLoadingImg = true;
    this.apiService.godwnPrdoductStock(searchval,1).subscribe((res) => {
      this.trans_stocks=res.data['data'];
      this.count = res.data['count'];
      this.from=res.data['from'];
      this.curpage = res.data['current_page'];  
      this.lastpage= res.data['last_page']; 
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
      this.stkpageLoadingImg = false;
      
      });
  }
  selectUnits1(i,unitid){
    if(unitid){
      this.trans_stocks[i].unit_id = this.trans_stocks[i].prod_units.find(x => x.unit_id === unitid).unit_id;
      this.trans_stocks[i].qty = this.trans_stocks[i].prod_units.find(x => x.unit_id === unitid).required_stock;

      this.calcStock(i);
    }
  }

  calcStock(i) {

    this.trans_stocks[i].diff_stk=0;
    this.trans_stocks[i].rq_qty=this.trans_stocks[i].qty;
    // this.unit_base_qty = this.trans_stocks[i].units.find(x => x.unit_id === this.trans_stocks[i].unit_id).unit_base_qty;
     
    // this.trans_stocks[i].vanqty=this.trans_stocks[i].vanqty/this.unit_base_qty;
    if(this.trans_stocks[i].existing_qty){
      this.trans_stocks[i].diff_stk = Number(this.trans_stocks[i].existing_qty)-Number((this.trans_stocks[i].rq_qty));
    }
    
      
    if(this.trans_stocks[i].diff_stk<0){
      this.trans_stocks[i].add_missing=true;
      this.trans_stocks[i].add_excess=false;

      }else if(this.trans_stocks[i].diff_stk>0){
        this.trans_stocks[i].add_excess=true;
        this.trans_stocks[i].add_missing=false;

      }else{
        this.trans_stocks[i].add_excess=false;
        this.trans_stocks[i].add_missing=false;

      }
  }
  pageNext(formdata: { value: any; }, pageno:any){
    this.stkpageLoadingImg = true;
    this.apiService.godwnPrdoductStock(formdata.value,pageno).subscribe((res) => {
      this.trans_stocks=res.data['data'];
      this.count = res.data['count'];
      this.from=res.data['from'];
      this.curpage = res.data['current_page'];  
      this.lastpage= res.data['last_page']; 
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
      this.stkpageLoadingImg = false;
      
      });
  }

    //form search
    searchForm(search: string) {
      let searchval = new FormData();
      searchval.append("cat_name", search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.catagories = res['data'];
      });
  
    }
    selectCats(catid,godwn){
      let searchval = new FormData();
      searchval.append("godown", godwn);
      searchval.append("cat_id", catid);
      this.stkpageLoadingImg = true;
      this.apiService.godwnPrdoductStock(searchval,1).subscribe((res) => {
        this.trans_stocks=res.data['data'];
        this.count = res.data['count'];
        this.from=res.data['from'];
        this.curpage = res.data['current_page'];  
        this.lastpage= res.data['last_page']; 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
        this.stkpageLoadingImg = false;
        
        });
    }
}








// }
