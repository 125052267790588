import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repcatagory } from '../../../../../model/report/repcatagory.model';

@Component({
  selector: 'app-rep-man-summary',
  templateUrl: './rep-man-summary.component.html',
  styleUrls: ['./rep-man-summary.component.css']
})
export class RepManSummaryComponent implements OnInit {
  res_data:string[];
  curpage:number;
  lastpage:number;
  pgstart:boolean;
  pgend:boolean;
  pageLoadingImg:boolean;
  up: any;

  constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() { 
    this.pageLoadingImg = true; 
    this.pageNext(1);
    this.up = this.coreService.getUserPrivilage();
  }

  pageNext(pageno)
  {
   let searchval = new FormData();   
  this.apiService.ManSumPage(searchval,pageno).subscribe((res) => {
    this.pageLoadingImg =false;
   this.res_data = res['data']['data'];
   this.curpage = res['data']['current_page'];  
   this.lastpage= res['data']['last_page'];
 
   this.pgEnd(this.curpage,this.lastpage); 
   this.pgStart(this.curpage);
 });
 }

 pgEnd(curr,end)
{
if(curr==end)
this.pgend = true;
else
this.pgend = false;

}

pgStart(curr)
{
if(curr==1)
this.pgstart = true;
else
this.pgstart = false;
}

}
