import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-cust-movment-rpt',
  templateUrl: './cust-movment-rpt.component.html',
  styleUrls: ['./cust-movment-rpt.component.css']
})
export class CustMovmentRptComponent implements OnInit {
  @ViewChild('printBtn',{static: true}) printBtn: ElementRef<HTMLElement>;

  fileName = 'customer_movement.xlsx';

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: 'a', name: 'All' }

  ];

  trans_types = [
    { id: 0, name: 'No Tarnsaction' },
    { id: 1, name: 'Sales' },
    { id: 2, name: 'Sales Return' },
    { id: 3, name: 'Reciept' },
    { id: 4, name: 'Payment' },
    { id: 5, name: 'Credit Note' },
   
  ];
  pageLoadingImg: boolean;
  pgend: boolean;
  pgstart: boolean;
  curpage: any;
  lastpage: any;
  show: any;
  customer: any;
  repDate: any;
  total_customer: any;
  report_type: any;
  period_type: string;
  exportLoader: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  print: boolean;
  vanline_all: any;
  date1: any;
  date2: any;
  country_dec: string;
  pageFrom: any;
  exp_customer: any;
  exp_repDate: any;
  exp_total_customer: any;
  exp_report_type: any;
  trans_type: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    if(this.period_type !='c'){
      this.date1='11';
      this.date2='22';
    }
    this.period_type='lm';
    this.pageLoadingImg=true;
    let searchval = new FormData();
    searchval.append("period_type", 'lm');
    this.apiService.custMovmentRpt(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
     

      this.customer = res.data['data'];
      this.repDate = res.Date;
      this.total_customer = res.total_customer;
      this.report_type = res.label;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.pageFrom = res.data.from;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
     
    });
    this.country_dec=this.coreService.setDecimalLength();
    this.searchVanlines('');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.SALES','HOME.sales_return','HOME.RECEIPT','HOME.PAYMENT','HOME.CREDIT_NOTE','Common.no_trsctn']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: 'a', name: res['Common.all'] }
        
      ];

      this.trans_types = [
        { id: 0, name: res['Common.no_trsctn'] },
        { id: 1, name: res['HOME.SALES'] },
        { id: 2, name: res['HOME.sales_return']},
        { id: 3, name: res['HOME.RECEIPT'] },
        { id: 4, name: res['HOME.PAYMENT']  },
        { id: 5, name: res['HOME.CREDIT_NOTE'] },
       
      ];
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
  
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
     
    }
    this.apiService.custMovmentRpt(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
     

      this.customer = res.data['data'];
      this.repDate = res.Date;
      this.total_customer = res.total_customer;
      this.report_type = res.label;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.pageFrom = res.data.from;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
     
    });
    if(this.period_type !='c'){
      this.date1='11';
      this.date2='22';
    }
  
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;

      // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
       
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    
      }
      this.apiService.custMovmentRpt(formdata.value, 1).subscribe((res: any) => {
       
  
        this.exp_customer = res.data['data'];
        this.exp_repDate = res.Date;
        this.exp_total_customer = res.total_customer;
        this.exp_report_type = res.label;
        formdata.value.export='';

        setTimeout(() => {
          this.exportexcel();
        }, 3000);
      });
    
  }


  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  saveAndPrint(formdata: { value: any; }){
    this.exportLoader = true;

    // debugger;
      formdata.value.export = 'export';
  if (formdata.value.period_type === 'c') {
     
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
  
    }
    this.apiService.custMovmentRpt(formdata.value, 1).subscribe((res: any) => {
     

      this.exp_customer = res.data['data'];
      this.exp_repDate = res.Date;
      this.exp_total_customer = res.total_customer;
      this.exp_report_type = res.label;
      formdata.value.export='';
      this.exportLoader = false;
      let el: HTMLElement = this.printBtn.nativeElement;
      el.click();

    
    });

  }

  searchVanlines(search: string) {
    let searchval = new FormData();
    searchval.append("vanline_name", search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      this.vanline_all = res['data'];
   
    });
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

      // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
       
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    
      }
      this.apiService.custMovmentRpt(formdata.value, 1).subscribe((res: any) => {
       
  
        this.exp_customer = res.data['data'];
        this.exp_repDate = res.Date;
        this.exp_total_customer = res.total_customer;
        this.exp_report_type = res.label;
        formdata.value.export='';

        const heads = [
          this.translate.instant('Table.sl_no'),
          this.translate.instant('Common.customer_name'),
          this.translate.instant('Common.alias'),
          this.translate.instant('Table.mob'),
          this.translate.instant('Common.vanroute'),
          this.translate.instant('Common.balance_Amount')
        ];

        if(this.trans_type==1){
          heads.splice(5,0, 'Sale Amount'),
          heads.splice(5,0, 'Sale Return Amount'),
          heads.splice(5,0, 'Reciept Amount'),
          heads.splice(5,0, 'Payment Amount'),
          heads.splice(5,0, 'Credit Note Amount')
        }

          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
    
          this.exp_customer.forEach((item,i)=>{
            const arr=[
              i+1,
              item.name,
              item.alias,
              item.mobile,
              this.currencyPipe.transform(item.balance.bal_amt, '', '', this.country_dec)
            ]

            if(item.van_route){
              arr.splice(4,0, item.van_route.vanline_name)
            } else {
              arr.splice(4,0, ' ')
            }

            if(this.trans_type==1){
              arr.splice(5,0, this.currencyPipe.transform(item.sale_amnt.sale_amnt, '', '', this.country_dec))
            }

            if(this.trans_type==2){
              arr.splice(5,0, this.currencyPipe.transform(item.sale_rtn_amnt.sale_rtn_amnt, '', '', this.country_dec))
            }

            if(this.trans_type==3){
              arr.splice(5,0, this.currencyPipe.transform(item.data.recpt_amnt.recpt_amnt, '', '', this.country_dec))
            }

            if(this.trans_type==4){
              arr.splice(5,0, this.currencyPipe.transform(item.data.pay_amnt.pay_amnt, '', '', this.country_dec))
            }

            if(this.trans_type==5){
              arr.splice(5,0, this.currencyPipe.transform(item.redt_amnt.credt_amnt, '', '', this.country_dec))
            }

            data.push(arr)
          })
      
          let doc = this.excelService.getPdfObj();
    
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
    
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
    
          if(this.exp_repDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.exp_repDate.date1 + '   to   ' + this.exp_repDate.date2  ,  valueXPos, headerHeight + 10);
          }
    
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Sales.cust_movment_rpt')}`,  valueXPos, headerHeight + 15);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Total_customer')}`, lblXPos, headerHeight + 20);
          doc.setFontSize(10);
          doc.text(':  ' + this.total_customer,  valueXPos, headerHeight + 20);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.rpt_type')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.report_type,  valueXPos, headerHeight + 25);
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
         
          doc.save(`Customer Movement Report.pdf`);

          this.exportLoader = false;

      });
    
  }

}
