import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';

@Component({
  selector: 'app-rep-vat-invoice',
  templateUrl: './rep-vat-invoice.component.html',
  styleUrls: ['./rep-vat-invoice.component.css']
})
export class RepVatInvoiceComponent implements OnInit {

  constructor(private coreService: CoreService) { }
  up:any;

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();

  }

}
