import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-production-dash',
  templateUrl: './production-dash.component.html',
  styleUrls: ['./production-dash.component.css']
})
export class ProductionDashComponent implements OnInit {
  up: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    // this.cmpny = this.coreService.getUserData('cmpny');

  }

}
