import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Manufacture } from '../../../../../model/manufacture.model';

@Component({
  selector: 'app-manf-details',
  templateUrl: './manf-details.component.html',
  styleUrls: ['./manf-details.component.css']
})
export class ManfDetailsComponent implements OnInit {

  manftr_comp_name: any;
  manftr_comp_code: any ;
  manftr_comp_address: any ;
  manftr_comp_phone: any ;
  manftr_comp_mobile: any ;
  manftr_comp_fax: any ;
  manftr_comp_website: any ;
  manftr_comp_contact_person: any ;
  created_at: any ;
  manftr_comp_notes: any ;
  product: any ;
  manufctr: any ;
  elseBlocknoouterwrap: any ;
  selecTed: any = {
    id: null,
    error: null,
    message: null,
    manftr_comp_flags: null,

  };
  manufDet: [];
  products: [];
  up: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    $("#report-wrapper").hide();
  }

  //Manufacture search 
  searchMAnuf(search: string) {

   
    $("#report-wrapper").hide();
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.manufDet = res['data'];
      this.selecTed = null;

    });
  }

  //form search select
  selectStockPrd(mid: string) {
   
    if (mid) {
      $("#report-wrapper").show();
      let searchval = new FormData();
      searchval.append('mnfct_id', mid);

      this.apiService.manfDetailReport(searchval).subscribe((res) => {
        this.selecTed = res['data'];
        this.products = res['data']['product'];
    
      });


    }
  }






}
