import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-van-stockverify-rep',
  templateUrl: './van-stockverify-rep.component.html',
  styleUrls: ['./van-stockverify-rep.component.css']
})
export class VanStockverifyRepComponent implements OnInit {

  fileName = 'van_stock_verification_report.xlsx';
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  userType: any;
  branch_all: any;
  users: any;
  vans: any;
  products: any[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  pageLoadingImg: boolean;
  repDate: any;
  from: any;
  summary: any;
  tot_items: any;
  tot_req_qty: any;
  total_vrfy_qty: any;
  exportLoader: boolean;
  expsummary: any;
  exptot_items: any;
  exptot_req_qty: any;
  exptotal_vrfy_qty: any;
  branch_display_name: any;
  branch_display_code: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private modalService: NgbModal,private translate: TranslateService) { }

  ngOnInit() {

    let searchval=new FormData();

    this.pageLoadingImg = true;
    this.apiService.vanStockverifyRep(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
        
      this.summary = res.data.data;
      this.tot_items=res.total.tot_prods;
      this.tot_req_qty=res.total.tot_req_qty;
      this.total_vrfy_qty=res.total.total_vrfy_qty;

        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.repDate = res.Date;
        this.from = res.data.from;
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
  
  
      });

    this.userType = this.coreService.getUserData('user_type');
    this.searchUser('');
    this.searchVans('');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }

    // search product
    selectProd(name: string) {

      const searchval = new FormData();
      searchval.append('prod_name', name);
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }

    pageNext(formdata: { value: any; }, pageno: any) {

      this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

      this.apiService.vanStockverifyRep(formdata.value, pageno).subscribe((res: any) => {
        this.pageLoadingImg = false;
          
        this.summary = res.data.data;
        this.tot_items=res.total.tot_prods;
        this.tot_req_qty=res.total.tot_req_qty;
        this.total_vrfy_qty=res.total.total_vrfy_qty;

          this.curpage = res.data.current_page;
          this.lastpage = res.data.last_page;
          this.repDate = res.Date;
          this.from = res.data.from;
    
    
          this.pgEnd(this.curpage, this.lastpage);
          this.pgStart(this.curpage);
          
    
    
        });
    }

    
  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  exportEXCL(formdata: { value: any; }, pageno = 1) {
    formdata.value.export = 'export';
   
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
   
    this.exportLoader = true;

    this.apiService.vanStockverifyRep(formdata.value, pageno).subscribe((res: any) => {
      
        
      this.expsummary = res.data.data;
      this.exptot_items=res.total.tot_prods;
      this.exptot_req_qty=res.total.tot_req_qty;
      this.exptotal_vrfy_qty=res.total.total_vrfy_qty;

        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.repDate = res.Date;
        this.from = res.data.from;

        
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
      });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }
}
