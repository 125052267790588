import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { ExcelService } from '../../../../../service/excel.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  branch_id: any;
  prodctnId: any;
  prdn_date = new Date();
  step3 = false;
  step2 = false;
  step1 = false;
  searchExisting = true;
  calculationDisplay = true;
  netcost: any;
  prodform_id: any;
  ttlCommAmount: any;
  output_qty: number;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};
  formula_all: any;
  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};
  previewData = [];
  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};
  stocktr_amount = 0;
  prodFormula = {
    prdn_date: new Date(),
    productnId: 0,
    productnDate: 0,
    formula_id: '',
    formula_name: '',
    productOut: [],
    productIngd: [],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    ttlPurchase: 0,
    miscExp: 0,
  };
  formula_list = [];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 5;
  totalItem = 0;
  godown_all = [];
  rate_all = [];
  curForm = 'Create';


  unit_qty: any;
  display_qty: any;
  product_summary: any;
  purch_no: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  vantran_godown_id: any;
  vantran_van_id: any;
  brcode: any;
  prd_stock_statusChecked: any;
  prd_stock_status: any;


  tran_purch_price: any;
  transub_qty: number;
  tran_purch_amount: number;
  transub_unit_id: any;


  prd_id: any;
  tran_id: number;
  vantran_purch_price: any;
  prd_barcode: String;
  vantransub_qty: any;
  vantran_purch_amount: number;
  vantransub_unit_id: any;
  default_unit: any;
  total_qty = 0;
  NgForm: any;
  total_amount: any;
  sel_gd_id = 0;
  tranArray: any;
  sl_no = 0;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  box = 0;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any[];
  preview_items: any[];
  base_rate: number;
  transferdate: any;

  accepted_list = [];
  rejected_list = [];
  previewDataitems = [];
  sale_rate: any;
  salestatus: any;
  type_id = 1;

  gdid: number;
  errObjArr = {
    tandateErr: null, tanvanErr: null,
    tangdErr: null
  };
  errObjTran = {
    fromErr: null, toErr: null,
    sameErr: null
  };
  rate_status: any;
  rate_status_type: any;

  searchStkBatch: any;
  acurpage: any;
  alastpage: any;
  apgend: boolean;
  apgstart: boolean;
  afrom: any;
  rpgstart: boolean;
  rpgend: boolean;
  accpgstart: boolean;
  accpgend: boolean;
  from: any;
  rfrom: any;
  balance: number;
  batch_name: number;
  gd_id: 0;
  godowns: any;
  branchto: string | number | string[];
  reportData: any;
  total_amount_transfer=0;
  users: any;
  show_note: number;
  taxledger: any;
  exportLoader: boolean;
  country_dec: string;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;

  constructor(private apiService: ApiService,private translate: TranslateService,
    private coreService: CoreService, private excelService: ExcelService,private datePipe: DatePipe,private currencyPipe: CurrencyPipe) { }
  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Transfer Summary.xlsx');
    this.exportLoader = false;

  }
  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
  
    this.country_dec=this.coreService.setDecimalLength();
    this.exportLoader = false;
   

      let searchval = new FormData();
      searchval.append("leedger", '1');
      this.apiService.getTransferLedgers(searchval).subscribe((res) => {
        this.taxledger = res['data'];
      });
    this.prodform_id = '';
    this.prdn_date = new Date();
    this.type_id = 1;
    this.show_note = 1;
    this.listTransfers();


    $('#branchid').css("border", "1px solid #dedede");
    $('#branch_from').css("border", "1px solid #dedede");


    if (this.gd_id > 0) {

    }
    else {
      this.gd_id = 0;
      console.log(this.gd_id);
    }

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }
  ngOnDestroy() {
    $('.close').click();

  }


  listAccepted() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '1');
    this.apiService.listSendTransfers(searchval, 1).subscribe((res) => {

      this.accepted_list = res['data'];


      this.pageLoadingImg = false;
      this.acurpage = res['current_page'];
      this.alastpage = res['last_page'];
      this.afrom = res['from'];

      this.apgEnd(this.acurpage, this.alastpage);
      this.apgStart(this.acurpage);
      this.slnum = 0;
    });
  }



  acceptpageNext(status, pageno: any) {


    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listSendTransfers(searchval, pageno).subscribe((res) => {


      this.formula_list = res['data'];




      this.pageLoadingImg = false;
      this.acurpage = res['current_page'];
      this.alastpage = res['last_page'];
      this.afrom = res['from'];

      this.apgEnd(this.acurpage, this.alastpage);
      this.apgStart(this.acurpage);
      this.slnum = 0;


    });
  }

  apgEnd(curr, end) {
    if (curr == end)
      this.apgend = true;
    else
      this.apgend = false;

  }

  apgStart(curr) {
    if (curr == 1)
      this.apgstart = true;
    else
      this.apgstart = false;



  }
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '' , name: 'All' }
    
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_refnum = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  exportasExcel() {


    let searchval = new FormData();
    this.apiService.readbranchTransfersItemReportAll(searchval, 1).subscribe((res) => {

      this.reportData = res['data'];
      console.log(this.reportData);
    });

    this.excelService.exportAsExcelFile(this.reportData, 'Stock Transfer  Summary Report');
  }



  productionPreview(prdn_id) {

    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.transferPreview(searchval).subscribe((res) => {
      this.previewData = res;
      // this.branch_from = res.from_branch;
      this.previewDataitems = res.items;


      this.stocktr_amount = res.stocktr_amount;
      $('#brtran-btn').show();


      this.transferdate = res.stocktr_date;

      // this.transferdate = new Date(this.transferdate); 

      var to = this.transferdate.split("/");
      this.transferdate = new Date(to[2], to[1] - 1, to[0]);


      // this.transferdate = new Date(res.stocktr_date);


    });

  }




  listTransfers() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.total_amount =0;
    searchval.append("status", '0');
    this.apiService.listSendTransfersSummary(searchval, 1).subscribe((res) => {
      this.total_amount = res['total_amount'];

      console.log(this.total_amount);
      this.formula_list = res['data'];


      this.pageLoadingImg = false;
      this.curpage = res['current_page'];
      this.from = res['from'];
     
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }



  pageNext(formdata: { value: any; }, pageno: any) {
    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listSendTransfersSummary(formdata.value, pageno).subscribe((res) => {

      this.total_amount = res['total_amount'];
      this.formula_list = res['data'];




      this.pageLoadingImg = false;
      this.curpage = res['current_page'];
      this.from = res['from'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;

  }








  accpgEnd(curr, end) {
    if (curr == end)
      this.accpgend = true;
    else
      this.accpgend = false;

  }

  accpgStart(curr) {
    if (curr == 1)
      this.accpgstart = true;
    else
      this.accpgstart = false;

  }

  // pdf download
  generatePdf() {

    console.log(this.formula_list);
    
  
    const heads = [
      this.translate.instant('Table.sl_no'),
      this.translate.instant('transaction.Transferd_Id'),
      this.translate.instant('Van.Transfer_Date'),
      this.translate.instant('transaction.Transferd_To'),
      this.translate.instant('Common.Voucher_no'),
      this.translate.instant('Common.Transfer_Amount')
    ];

    if(this.show_note){
      heads.splice(6,0, this.translate.instant('Common.note'))
    }

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.formula_list.forEach((item,i)=>{
        const arr=[
          i+1,
          item.stocktr_id,
          this.datePipe.transform(item.stocktr_date, 'dd/MM/yyyy'),
          item.branch.branch_name,
          item.voucher_no,
          this.currencyPipe.transform(item.stocktr_amount, '', '', this.country_dec)
        ]

        if(this.show_note){
          arr.splice(6,0, item.stocktr_notes)
        }

        data.push(arr)
      })

      if(this.formula_list.length > 0){
        data.push([
          this.translate.instant('HOME.total'),
          '',
          '',
          '',
          '',
          this.currencyPipe.transform(this.total_amount, '', '', this.country_dec),
          ''
        ])
      }
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.transfer_summary')}`,  valueXPos, headerHeight + 10);

      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 15, lblXPos, heads, data, null);
  
     
      doc.save(`Transfer Summary.pdf`);


    
  }

}