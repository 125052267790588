import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-category-report',
  templateUrl: './category-report.component.html',
  styleUrls: ['./category-report.component.css']
})
export class CategoryReportComponent implements OnInit {

  fileName = 'product_category_report.xlsx';
  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  catagories: string[];


  cats: any;
  ttl_amount: any;
  ttl_disc_amount: any;
  resultobj: any;

  exportLoader: boolean;
  exp_cats: any;
  exp_ttl_amount: any;
  exp_ttl_disc_amount: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;

  country_dec: string;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  repDate: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private translate: TranslateService) { }

  ngOnInit() {

    let searchval = new FormData();
    this.pageLoadingImg = true;
    

    this.apiService.productCatgoryReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.cats = res.data.data.filter(data =>
        data.amount != 0
      );
      this.ttl_amount = res.data.total_sale_amount;
      this.ttl_disc_amount = res.data.total_disc_amount;
      this.repDate = res.data.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
   
  }
  ngAfterContentInit() {
    this.searchCat('');
  }
  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }



  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;



    }
    this.apiService.productCatgoryReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;

      // this.cats = res.data.data;

      this.cats = res.data.data.filter(data =>
        data.amount != 0
      );

      this.ttl_amount = res.data.total_sale_amount;
      this.ttl_disc_amount = res.data.total_disc_amount;
      this.repDate = res.data.Date;
     

      this.repDate = res.data.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }




  exportEXCL(formdata: { value: any; }) {
    this.exportLoader = true;
    setTimeout(() => {
      this.exportexcel();
    }, 1000);
    // formdata.value.export = 'export';


    // this.apiService.productCatgoryReport(formdata.value, 1).subscribe((res: any) => {
    //   this.exportLoader = false;
    //   this.exp_cats = res.data.data;
    //   this.exp_ttl_amount = res.data.total_sale_amount;
    //   this.exp_ttl_disc_amount = res.data.total_disc_amount;
    //   setTimeout(() => {
    //     this.exportexcel();
    //   }, 3000);

    // });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  // pdf download
  generatePdf() {
  
    console.log(this.cats);
    
    const heads = [
        this.translate.instant('HOME.sl_no'),
        this.translate.instant('HOME.CATAGORY'),
        this.translate.instant('Table.amnt')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.cats.forEach((item,i)=>{
        data.push([
          i + 1,
          item.cat_name,
          this.currencyPipe.transform(item.amount, '', '', this.country_dec)
        ])
      })

      data.push([
        '',
        this.translate.instant('Table.total_amount') + ':',
        this.currencyPipe.transform(this.ttl_amount, '', '', this.country_dec),
      ]),

      data.push([
        '',
        this.translate.instant('Table.totol_disc') + ':',
        this.currencyPipe.transform(this.ttl_disc_amount, '', '', this.country_dec),
      ]),

      data.push([
        '',
        this.translate.instant('Common.net_total') + ':',
        this.currencyPipe.transform((this.ttl_amount - this.ttl_disc_amount), '', '', this.country_dec),
      ])

  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('HOME.categort_report')}`,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.ttl_amount, '', '', this.country_dec),  valueXPos, headerHeight + 20);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 25, lblXPos, heads, data, null);
       
      doc.save(`Sales Report By Category.pdf`);


    
  }

}
