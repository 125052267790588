import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-additions',
  templateUrl: './additions.component.html',
  styleUrls: ['./additions.component.css']
})
export class AdditionsComponent implements OnInit {

  add_name: any;
  alladd: any;
  pageLoadingImg: boolean;

  selectedData: any = { add_id: null, error: null, add_name: null, message: null };
  result = '';
  additions: string[];
  up: any;
  add_id: any;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.getadditions();
    this.coreService.getToken();
    this.up = this.coreService.getUserPrivilage();
  }
  getadditions() {
    let searchval = new FormData();
    searchval.append("add_name", '');
    this.apiService.getAddByName(searchval).subscribe((res) => {
      this.additions = res['data'];
    });
  }
  activate(id) {
    this.apiService.changeAddStatus(id).subscribe((res) => {
      this.coreService.showMessage(res.message);
    });
  }

  setValues(add_id, vanline_name,) {
    this.add_id = add_id;
    this.add_name = vanline_name;
  }
 
  searchAdd(search: string) {
    let searchval = new FormData();
    searchval.append("add_name", search);
    this.apiService.getAddByName(searchval).subscribe((res) => {
      this.additions = res['data'];
    });
    $('.prev-list').show();
  }
  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("add_name", search);
    this.apiService.getAddByName(searchval).subscribe((res) => {
      this.additions = res['data'];
    });
    $('.prev-list').hide();
  }

  //Remove Name Suggestions

  removeName() {
    this.additions = [];
    $('.prev-list').hide();
  }

  selectCat(add: any) {
    if (add) {
      this.result = '';
      $("#editaddf").show();
      this.selectedData = add;
    }
    this.additions = [];
  }

  tabClick(tab: { index: number; }) {
    if (tab.index == 1)
      this.result = '';
  }
  addAdd(formgroup: { value: any; }) {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.apiService.createAdd(formgroup.value).subscribe((add) => {
      this.pageLoadingImg = false;

      if (add.error != null) {
        this.result = add.error;
        console.log(this.result);
      }
      else {
        this.add_name = '';
        document.getElementById('closeAddMdl').click();
        this.getadditions();
        this.coreService.createfunct(searchval, this.result, add.message);
      }

    });
  }
  updateAdd(formgroup: { value: any; }) {
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.apiService.updateAdd(formgroup.value).subscribe((add) => {
      this.pageLoadingImg = false;

      if (add.error != null) {
        this.result = add.error;
      }
      else {
        this.add_name = '';
        document.getElementById('closeEditMdl').click();
        this.getadditions();
        this.coreService.updatefunct(searchval, this.result, add.message);
      }

    });
  }

}
