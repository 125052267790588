
import { Component, OnInit } from '@angular/core';
import { Branch } from '../../../model/branch.model';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  branchData: any;
  result = '';
  pageLoadingImg: boolean;
  country: any;

  constructor(private datePipe: DatePipe, private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.coreService.getToken();
    this.selectCountry('');
    this.getBranchData();
  }



  createBranch(formgroup: { value: Branch; }) {
    this.pageLoadingImg = true;

    this.apiService.createBranch(formgroup.value).subscribe((res: Branch) => {
      this.pageLoadingImg = false;


      if (res.error != null) {
        this.result = res.error;
      }
      else {
        this.result = '';
        this.coreService.createfunct(formgroup, this.result, res.message);
      }

    });
  }


  updateBranch(formgroup: { value: Branch; }) {
    this.pageLoadingImg = true;

    // console.log(formgroup.value);
    this.apiService.updateCompany(formgroup.value).subscribe((res: Branch) => {
      this.pageLoadingImg = false;
      this.getBranchData();
      if (res.error != null) {
        this.result = res.error;
      }
      else {
        this.coreService.updatefunct(formgroup, this.result, res.message);
        $('#edit-branch').hide();
        this.result = '';
      }

    });

  }



  getBranchData() {
    let searchval = new FormData();
    this.apiService.getCompanyData(searchval).subscribe((res) => {
      this.branchData = res['data'];

      this.branchData.cmp_finyear_start = this.datePipe.transform(this.branchData.cmp_finyear_start, 'dd/MM/yyyy');
      this.branchData.cmp_finyear_end = this.datePipe.transform(this.branchData.cmp_finyear_end, 'dd/MM/yyyy');
      var from = this.branchData.cmp_finyear_start.split("/");
      this.branchData.cmp_finyear_start = new Date(from[2], from[1] - 1, from[0]);


      var to = this.branchData.cmp_finyear_end.split("/");
      this.branchData.cmp_finyear_end = new Date(to[2], to[1] - 1, to[0]);

      // console.log(this.branchData.cmp_finyear_start );
      // console.log(this.branchData.cmp_finyear_end );

    });
  }

  changeEndDate(start_date) {

    start_date = new Date(start_date);
    var year = start_date.getFullYear();
    var month = start_date.getMonth();
    var day = start_date.getDate();
    var end_date = new Date(year + 1, month, day)
    var day_val = end_date.getDate() - 1;
    this.branchData.cmp_finyear_end = new Date(year + 1, month, day_val);


  }

  // get decimel length
  getLength(country){
    let searchval = new FormData();
    searchval.append("country_id", country);
    this.apiService.getLength(searchval).subscribe((res) => {
      this.branchData.rate_decimal_length = res.data.br_decimel_length?res.data.br_decimel_length:2;
    });

   
  }

   // branch country
   selectCountry(search: string) {
    let searchval = new FormData();
    searchval.append("country_name", search);
    this.apiService.getCountry(searchval).subscribe((res) => {
      this.country = res['data'];
    });

   
    
  }


}

