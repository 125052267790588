import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { Employee } from '../../../model/employee.model';
import { CoreService } from '../../../service/core.service';
import { DatePipe } from '@angular/common';
// import {NgxPrintModule} from 'ngx-print';
import { MatDatepickerModule } from
  '@angular/material/datepicker';
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  emp_id: any;
  first_name: any;
  last_name: any;
  ledger_id: any = ''; dep_id: any = ''; des_id: any = '';
  nationality: any;
  birth_date: any;
  doj: any;
  emp_status: any = '';
  emp_type: any = '';
  work_phone: any;
  qualification: any;
  marital_status: any = '';
  gender: any = '';

  emp_photo: any;
  emp_photo_url: any;

  iqama_name: any;
  iqama_no: any;
  profession: any;
  iqama_doi: any;
  issue_place: any;
  iqama_exp: any;
  iqama_lic_no: any;
  iqama_doc: any;
  iqama_doc_url: any;
  passport_no: any;
  passport_issue_place: any;
  passport_doi: any;
  passport_exp: any;
  passport_doc: any;
  passport_doc_url: any;
  baladiyah_no: any;
  baladiyah_issue_place: any;
  baladiyah_doi: any;
  baladiyah_exp: any;
  baladiyah_doc: any;
  baladiyah_doc_url: any;
  licence_no: any; licence_issue_place: any; licence_doi: any; licence_exp: any; licence_doc: any; licence_doc_url: any;

  contact_work_phone: any; contact_work_email: any; contact_home_phone: any; contact_mobile: any; contact_email: any; resi_address: any; resi_state: any; resi_country: any; perm_address: any; perm_state: any; perm_country: any; perm_pin: any;


  selectedEmp: any = {
    emp_id: '', first_name: '', error: null, message: null, last_name: '', ledger_id: 0, dep_id: '', des_id: '', nationality: '', birth_date: '', doj: '', emp_status: '', emp_type: '', work_phone: '', qualification: '', marital_status: '', gender: '', emp_photo: '', iqama_name: '', iqama_no: '', profession: '', iqama_doi: '', issue_place: '', iqama_exp: '', iqama_lic_no: '', iqama_doc: '', passport_no: '', passport_issue_place: '', passport_doi: '', passport_exp: '', passport_doc: '', baladiyah_no: '', baladiyah_issue_place: '', baladiyah_doi: '', baladiyah_exp: '', baladiyah_doc: '', licence_no: '', licence_issue_place: '', licence_doi: '', licence_exp: '', licence_doc: '', contact_work_phone: '', contact_work_email: '', contact_home_phone: '', contact_mobile: '', contact_email: '', resi_address: '', resi_state: '', resi_country: '', perm_address: '', perm_state: '', perm_country: '', perm_pin: ''

  };


  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  users: any;


  ledger_name: any;
  usr_type: any;




  departments: any;
  designations: any;
  gender_all = [
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' }
  ];
  emp_type_all = [
    { id: 'permanent', name: 'Permanent' },
    { id: 'on_contract', name: 'On contract' },
    { id: 'temporary', name: 'Temporary' },
    { id: 'trainee', name: 'Trainee' }
  ];
  emp_status_all = [
    { id: 'active', name: 'Active' },
    { id: 'terminated', name: 'Terminated' },
    { id: 'resigned', name: 'Resigned' }
  ];
  marital_status_all = [
    { id: 'single', name: 'Single' },
    { id: 'married', name: 'Married' }
  ];
  iqama_doc_err: string;
  passport_doc_err: string;
  baladiyah_doc_err: string;
  licence_doc_err: string;
  emp_photo_err: string;


  usr_mobile: any;
  usr_email: any;
  usr_address: any;
  usersel: any;
  pageLoadingImg: boolean;
  rempageLoadingImg: boolean;
  result = '';

  values: string[];
  usertypes: number[];
  dep_all: string[];
  des_all: string[];
  resultobj: any = {};
  defLedger: any;
  usr_cash_ledger_id = 0;
  up: any;
  godowns: any = [];
  usr_default_godown_id = -1;
  base_url: string;
  additions: any = [];
  selected_additions: any = [];
  edit_additions: any = [];
  deductions: any = [];
  selected_deductions: any = [];
  edit_deductions: any = [];
  basic_salary : any;
  company_working_days: any;
  employee_working_days: any;
  settings: any;

  filterAdditions = [];
  selAdditions: any = {};

  filterDeductions = [];
  selDeductions: any = {};
  add_ded_amount_types = [
    { id: 0, name: 'Flat' },
    { id: 1, name: '% Of Basic Salary' }
  ];
  
  constructor(private apiService: ApiService, private coreService: CoreService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.coreService.getToken();
    this.pageLoadingImg = false;
    this.rempageLoadingImg = false;
    this.listEmployees();
    this.getadditions();
    this.getDeductions();
    this.up = this.coreService.getUserPrivilage();
    this.searchDefaultLedger("");
    this.searchDep("");
    this.searchDes("");
    this.base_url = this.apiService.PHP_API_SERVER;
    this.getPayrollSetting();
  }
  getPayrollSetting() {
    this.apiService.getPayrollSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.employee_working_days = this.settings.s_company_working_days;
      }
    });
  }
  addMoreAdditions() {
    this.selected_additions.push({
      addition: null,
      amount: 0,
      amount_type: 0,
      perc: 0,
    });
  }

  addMoreEditAdditions() {
    this.edit_additions.push({
      addition: null,
      amount: 0,
      amount_type: 0,
      perc: 0,
    });
  }

  addMoreDeductions() {
    this.selected_deductions.push({
      deduction: null,
      amount: 0,
      amount_type: 0,
      perc: 0,
    });
  }
  addMoreEditDeductions() {
    this.edit_deductions.push({
      deduction: null,
      amount: 0,
      amount_type: 0,
      perc: 0,
    });
  }
  removeAddition(index) {
    this.selected_additions.splice(index, 1);
    delete this.selAdditions[index];
    this.filterAddition();
  }
  removeEditAddition(index) {
    this.edit_additions.splice(index, 1);
    delete this.selAdditions[index];
    this.filterAddition();
  }
  removeDeduction(index) {
    this.selected_deductions.splice(index, 1);
    delete this.selDeductions[index];
    this.filterDeduction();
  }
  removeEditDeduction(index) {
    this.edit_deductions.splice(index, 1);
    delete this.selDeductions[index];
    this.filterDeduction();
  }
  filterAddition() {
    this.filterAdditions = this.additions.filter(e => {
      if (Object.values(this.selAdditions).indexOf(e.add_id) < 0) {
        return true;
      }
    });
  }
  filterDeduction() {
    this.filterDeductions = this.deductions.filter(e => {
      if (Object.values(this.selDeductions).indexOf(e.ded_id) < 0) {
        return true;
      }
    });
  }
  setAddition(additionId, index) {
    if (additionId == null) {
      delete this.selAdditions[index];
    } else {
      this.selAdditions[index] = additionId;
    }
    this.filterAddition();
  }
  setDeduction(deductionId, index) {
    if (deductionId == null) {
      delete this.selDeductions[index];
    } else {
      this.selDeductions[index] = deductionId;
    }
    this.filterDeduction();
  }
  amtTypeChange(type,index){
    if(type=="ded"){
      this.selected_deductions[index].ded_amt = 0;
      this.selected_deductions[index].perc = 0;
    }else{
      this.selected_additions[index].add_amt = 0;
      this.selected_additions[index].perc = 0;
    }
  }
  amtEditTypeChange(type,index){
    if(type=="ded"){
      this.edit_deductions[index].ded_amt = 0;
      this.edit_deductions[index].perc = 0;
    }else{
      this.edit_additions[index].add_amt = 0;
      this.edit_additions[index].perc = 0;
    }
  }

  percChange(type,index){
    const sal = this.basic_salary ? this.basic_salary : 0    
    if(type=="ded"){
      const per = this.selected_deductions[index].perc ? this.selected_deductions[index].perc : 0
      this.selected_deductions[index].ded_amt = (sal*per)/100;
    }else{
      const per = this.selected_additions[index].perc ? this.selected_additions[index].perc : 0
      this.selected_additions[index].add_amt = (sal*per)/100;
    }
  }
  percEditChange(type,index){
    const sal = this.selectedEmp.basic_salary ? this.selectedEmp.basic_salary : 0    
    if(type=="ded"){
      const per = this.edit_deductions[index].perc ? this.edit_deductions[index].perc : 0
      this.edit_deductions[index].ded_amt = (sal*per)/100;
    }else{
      const per = this.edit_additions[index].perc ? this.edit_additions[index].perc : 0
      this.edit_additions[index].add_amt = (sal*per)/100;
    }
  }
  setPercAmt(){
    const sal = this.basic_salary ? this.basic_salary : 0    
    this.selected_deductions.forEach((element, index) => {
      const amt_type = this.selected_deductions[index].amount_type;
      if(amt_type){
        const per = this.selected_deductions[index].perc ? this.selected_deductions[index].perc : 0
        this.selected_deductions[index].ded_amt = (sal*per)/100;
      }
    });

    this.selected_additions.forEach((element, index) => {
      const amt_type = this.selected_additions[index].amount_type;
      if(amt_type){
        const per = this.selected_additions[index].perc ? this.selected_additions[index].perc : 0
        this.selected_additions[index].add_amt = (sal*per)/100;
      }
    });
  }
  setEditPercAmt(){
    const sal = this.selectedEmp.basic_salary ? this.selectedEmp.basic_salary : 0    
    this.edit_deductions.forEach((element, index) => {
      const amt_type = this.edit_deductions[index].amount_type;
      if(amt_type){
        const per = this.edit_deductions[index].perc ? this.edit_deductions[index].perc : 0
        this.edit_deductions[index].ded_amt = (sal*per)/100;
      }
    });

    this.edit_additions.forEach((element, index) => {
      const amt_type = this.edit_additions[index].amount_type;
      console.log(amt_type);
      
      if(amt_type){
        const per = this.edit_additions[index].perc ? this.edit_additions[index].perc : 0
        this.edit_additions[index].add_amt = (sal*per)/100;
      }
    });
  }

  getadditions() {
    let searchval = new FormData();
    searchval.append("add_name", '');
    this.apiService.getAddByName(searchval).subscribe((res) => {
      this.additions = res['data'];
      this.filterAddition();
    });
  }
  getDeductions() {
    let searchval = new FormData();
    searchval.append("ded_name", '');
    this.apiService.getDedByName(searchval).subscribe((res) => {
      this.deductions = res['data'];
      this.filterDeduction();
    });
  }
  searchDefaultLedger(search: string) {
    let searchval = new FormData();
    searchval.append("ledger_name", search);
    searchval.append("ledger_grp", 'all');

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data'];
    });
  }
  searchDep(search: string) {
    let searchval = new FormData();
    searchval.append("dep_name", search);
    this.apiService.getDepByName(searchval).subscribe((res) => {
      this.departments = res['data'];
    });
  }
  searchDes(search: string) {
    let searchval = new FormData();
    searchval.append("des_name", search);
    this.apiService.getDesByName(searchval).subscribe((res) => {
      this.designations = res['data'];
    });
  }
  iqamaChangeEvent(fileInput: any) {
    this.iqama_doc_err = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      const max_height = 200;
      const max_width = 200;
      if (fileInput.target.files[0].size > max_size) {
        this.iqama_doc_err =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.iqama_doc_err = 'Only Images and pdf are allowed ( JPG | PNG | PDF)';
        return false;
      }

    }
  }
  passportChangeEvent(fileInput: any) {
    this.passport_doc_err = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      const max_height = 200;
      const max_width = 200;
      if (fileInput.target.files[0].size > max_size) {
        this.passport_doc_err =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        console.log(fileInput.target.files[0].type);

        this.passport_doc_err = 'Only Images and pdf are allowed ( JPG | PNG | PDF)';
        return false;
      }

    }
  }
  baladiyahChangeEvent(fileInput: any) {
    this.baladiyah_doc_err = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      const max_height = 200;
      const max_width = 200;
      if (fileInput.target.files[0].size > max_size) {
        this.baladiyah_doc_err =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.baladiyah_doc_err = 'Only Images and pdf are allowed ( JPG | PNG | PDF)';
        return false;
      }

    }
  }
  licenceChangeEvent(fileInput: any) {
    this.licence_doc_err = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      const max_height = 200;
      const max_width = 200;
      if (fileInput.target.files[0].size > max_size) {
        this.licence_doc_err =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.licence_doc_err = 'Only Images and pdf are allowed ( JPG | PNG | PDF)';
        return false;
      }

    }
  }
  photoChangeEvent(fileInput: any) {
    this.emp_photo_err = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 200;
      const max_width = 200;
      if (fileInput.target.files[0].size > max_size) {
        this.emp_photo_err =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.emp_photo_err = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      this.emp_photo = fileInput.target.files[0];

    }
  }

  createEmployee(formgroup) {
    this.pageLoadingImg = true;


    let searchval = new FormData(formgroup);
    searchval.append("dep_id", this.dep_id);
    searchval.append("des_id", this.des_id);
    searchval.append("gender", this.gender);
    searchval.append("ledger_id", this.ledger_id);
    searchval.append("marital_status", this.marital_status);
    searchval.append("emp_status", this.emp_status);
    searchval.append("emp_type", this.emp_type);
    searchval.append("selected_additions", JSON.stringify(this.selected_additions));
    searchval.append("selected_deductions", JSON.stringify(this.selected_deductions));
    
    // console.log(searchval);

    this.apiService.createEmployee(searchval).subscribe((employee: Employee) => {
      this.pageLoadingImg = false;

      if (employee.error != null) {
        this.result = employee.error;
      }
      else {
        this.result = '';
        formgroup.reset();
        this.listEmployees();
        this.selected_additions = [];
        this.selected_deductions = [];
        this.coreService.createfunct(searchval, this.result, 'Employee Successfully Added');
        //  $('#ueditf').hide();  
        
      }

    });

  }
  updateEmployee(formgroup) {
    this.pageLoadingImg = true;


    let searchval = new FormData(formgroup);
    searchval.append("dep_id", this.selectedEmp.dep_id);
    searchval.append("des_id", this.selectedEmp.des_id);
    searchval.append("gender", this.selectedEmp.gender);
    searchval.append("ledger_id", this.selectedEmp.ledger_id);
    searchval.append("marital_status", this.selectedEmp.marital_status);
    searchval.append("emp_status", this.selectedEmp.emp_status);
    searchval.append("emp_type", this.selectedEmp.emp_type);
    searchval.append("selected_additions", JSON.stringify(this.edit_additions));
    searchval.append("selected_deductions", JSON.stringify(this.edit_deductions));
    console.log(searchval);

    this.apiService.updateEmployee(searchval).subscribe((employee: Employee) => {
      this.pageLoadingImg = false;

      if (employee.error != null) {
        this.result = employee.error;
      }
      else {
        $('.close').click();
        $('#editEmp').hide();
        // formgroup.reset();
        this.listEmployees();
        this.coreService.createfunct(searchval, this.result, 'Employee Successfully Updated');
        // $('.close').click();
        // $('#editEmp').hide();
        this.result = '';
      }

    });

  }
  listEmployees() {
    let searchval = new FormData();
    this.apiService.getEmployeesList(searchval, 1).subscribe((res) => {

      this.users = res['data']['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    let searchval = new FormData();
    this.apiService.getEmployeesList(searchval, 1).subscribe((res) => {
      this.users = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }
  deleteEmployee(employee) {

    this.rempageLoadingImg = true;

    if (confirm('Do you wish to continue?')) {
      let searchval = new FormData();
      searchval.append("id", employee);
      this.apiService.deleteEmployee(searchval).subscribe((res) => {
        this.coreService.showMessage('Removed Successfully');

        this.listEmployees();
        this.rempageLoadingImg = false;

      });
    } else {
      this.rempageLoadingImg = false;

    }
  }
  tabClick(tab: { index: number; }) {
    this.result = '';
    this.selected_additions = [];
    this.selected_deductions = [];
  }
  setValues(data) {
    this.selectedEmp = data;
    this.edit_additions = data.additions;
    this.edit_deductions = data.deductions;
    this.selectedEmp.ledger_id = Number(data.ledger_id);
    this.emp_photo_url = this.selectedEmp.emp_photo ? this.base_url + '/' + this.selectedEmp.emp_photo : '';
    this.iqama_doc_url = this.selectedEmp.iqama_doc ? this.base_url + '/' + this.selectedEmp.iqama_doc : '';
    this.passport_doc_url = this.selectedEmp.passport_doc ? this.base_url + '/' + this.selectedEmp.passport_doc : '';
    this.baladiyah_doc_url = this.selectedEmp.baladiyah_doc ? this.base_url + '/' + this.selectedEmp.baladiyah_doc : '';
    this.licence_doc_url = this.selectedEmp.licence_doc ? this.base_url + '/' + this.selectedEmp.licence_doc : '';
  }
  onPrint(divName) {
    const printContents = document.getElementById(divName).innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

}
