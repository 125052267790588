import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-van-stock-asign',
  templateUrl: './van-stock-asign.component.html',
  styleUrls: ['./van-stock-asign.component.css']
})
export class VanStockAsignComponent implements OnInit {
  vans: any;
  pageLoadingImg: boolean;
  list_products: any;
  resultobj: any;
  van_id: any;
  all_check: any;
  removeLoading: boolean;
  totalproducts: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.searchVans('');
  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];


    });

  }
  selectVanPrdct(van) {
    let searchval = new FormData();
   if(van){
    searchval.append("van_id", van);
    this.pageLoadingImg=true;
    this.apiService.getUnAsignedStock(searchval).subscribe((res) => {
     this.pageLoadingImg=false;
     this.list_products = res['data']['data']; 
     this.totalproducts = res['data']['total']; 
  
     if(this.list_products!= undefined && this.list_products.length > 0)
     {$('#opstk-list-tble').show();}
     else
     {$('#opstk-list-tble').hide();} 
   
  
  });
  }
}

remove(index) {
  this.list_products.splice(index, 1);
}

setStockAll(formdata: { value: any; },data){
  if(this.all_check){
      
    var remopstk = confirm("Are you want to set this stocks to van?");
    if(remopstk)
    {
      this.removeLoading=true;
    formdata.value.products = this.list_products;
    formdata.value.van_id = this.van_id;
    formdata.value.date = new Date();
    formdata.value.date=this.apiService.formatDate(formdata.value.date);
    this.apiService.assignStockToVan(formdata.value).subscribe((res: any) => {
      this.removeLoading=false;

      if (res && res.error != null) {
          
        this.resultobj = this.coreService.getValidation(this.resultobj);  
      }
      else {

        if(this.van_id){

          let searchval = new FormData();
          searchval.append("van_id", this.van_id);
          // this.pageLoadingImg=true;
          this.apiService.getUnAsignedStock(searchval).subscribe((res) => {
            // this.pageLoadingImg=false;
            this.list_products = res['data']['data']; 
            this.totalproducts = res['data']['total']; 
            if(this.list_products!= undefined && this.list_products.length > 0)
           {$('#opstk-list-tble').show();}
           else
           {$('#opstk-list-tble').hide();} 
         
        
        });
        }
        // else{
        //   this.selectVanPrdct(formdata.value.van_id); 
        // }
        this.coreService.createfunct(formdata,this.resultobj,'Updated');    
      }
    });

  }
  }
}
}
