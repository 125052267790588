import { Component, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-remove-inactive-ledger',
  templateUrl: './remove-inactive-ledger.component.html',
  styleUrls: ['./remove-inactive-ledger.component.css']
})
export class RemoveInactiveLedgerComponent implements OnInit {
  fileName= 'inactive_ledgers.xlsx';
  analysLoadingImg: boolean;
  start: string | Blob;
  limit: string | Blob;
  ledgerDetails: any[];
  totalledgers: any;
  removeLoadingImg: boolean;
  unWantedLedgers: any;

  

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {

    this.ledgerDetails = [];
  }

  goBack(stepper: MatStepper){
    stepper.previous();
  }

  goNext(stepper: MatStepper){
    
    stepper.next();
  }

  resetData(){
  
    this.ledgerDetails = [];
    this.totalledgers = 0;
    
  }

  analyseProducts(type){
  
   
      this.analysLoadingImg = true;
  
      let searchval = new FormData();
      searchval.append('start',this.start);
      searchval.append('limit',this.limit);
      this.apiService.analyseInactiveLedgers(searchval).subscribe((res: any) => {
      this.analysLoadingImg = false;
        
        this.ledgerDetails = res.data;
        this.totalledgers = res.total_ledger;
        this.unWantedLedgers = res.unwanted_ledgers;
      
        if(type == 'export'){
          setTimeout(() => {
            this.exportexcel();
          }, 3000);
        }
      
      });    
  
  }

  removeInactiveLedger(stepper){
    if (confirm("Are you sure you want to truncate all Ledgers?")) {
    this.ledgerDetails = [];
    this.totalledgers = 0;
      this.removeLoadingImg = true;
  
      let searchval = new FormData();
      searchval.append('unwanted_ledgers',this.unWantedLedgers);
      this.apiService.removeInactiveLedger(searchval).subscribe((res: any) => {
      // console.log(res);
      this.removeLoadingImg = false;
      this.coreService.showMessage('All Ledgers Removed Successfully');
      stepper.reset();
      });
  
    }
  
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    // this.exportLoader = false;
  
  }

}
