import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notification: any = '';
  pageLoadingImg: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService) { }
  ngOnInit() {
    this.getNotification();
  }
  getNotification() {
    this.apiService.getAdminNotification().subscribe((res) => {
      if (res['data']) {
        let settings = res['data'];
        this.notification = settings.cmp_notification;
      }
    });
  }
  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;
    this.apiService.updateAdminNotification(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Notification Updated');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }
}
