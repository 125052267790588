import { Component, OnInit, ɵConsole } from '@angular/core';
import { Catagory } from 'src/app/model/catagory.model';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';

@Component({
  selector: 'app-user-privileges',
  templateUrl: './user-privileges.component.html',
  styleUrls: ['./user-privileges.component.css']
})
export class UserPrivilegesComponent implements OnInit {
  cat_name: any;
  cat_remarks: any;
  cat_pos: any;
  allcat: any;
  pageLoadingImg:boolean;
  
    selectedData: any = { cat_id: null, error: null, cat_name: null,message:null,cat_pos:null};
    result = '';
    catagories:string[];
    
    
  
    constructor(private apiService: ApiService,
      private coreService: CoreService) { }
  
    ngOnInit() { 
      this.coreService.getToken(); 
    }
  
    createCat(formdata: { value: Catagory; }) {   
      this.pageLoadingImg = true;
  
        this.apiService.createCat(formdata.value).subscribe((catagory: Catagory) => {
          this.pageLoadingImg = false;
  
          if (catagory.error != null) {        
            this.result = catagory.error;  
            console.log(this.result);     
          }
          else {
          this.coreService.createfunct(formdata,this.result,catagory.message);
          }
  
        });
      }
  
    updateCat(formdata: { value: Catagory; })
    {  
      this.pageLoadingImg = true;
  
    // if(formdata.value.cat_pos){
    // (formdata.value.cat_pos.isChecked === true )? 0 : 1; }          
      this.apiService.updateCat(formdata.value).subscribe((catagory: Catagory) => {
        this.pageLoadingImg = false;
  
  
        if (catagory.error != null) {
          console.log(catagory.error);
          this.result = catagory.error;
        }
        else {
          $("#editcatf").hide();   
          this.coreService.updatefunct(formdata,this.result,catagory.message);            
        this.catagories=[];
        }
  
      });
    }
   
    //form search
      searchCat(search: string)
  {
   let searchval = new FormData();      
        searchval.append("cat_name", search);    
         this.apiService.getCatbyName(searchval).subscribe((res) => {
          this.catagories = res['data'];                
         });
         $('.prev-list').show(); 
  }
  
  
   //form search
   searchForm(search: string)
   {
    let searchval = new FormData();      
         searchval.append("cat_name", search);    
          this.apiService.getCatbyName(searchval).subscribe((res) => {
           this.catagories = res['data'];                
          });
          $('.prev-list').hide(); 
   }
  
  //Remove Name Suggestions
  
  removeName()
  {
    this.catagories = [];
    $('.prev-list').hide(); 
  }
  
    selectCat(cat: Catagory) {
      if(cat){
      this.result = '';
      $("#editcatf").show();
      this.selectedData = cat; 
      } 
      this.catagories = []; 
    }
  
    tabClick(tab: { index: number; }) {   
      if(tab.index==1)  
    this.result = ''; 
  }

}
