import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { Catagory } from '../../../../../model/catagory.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { DatePipe, formatDate } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-godown-stock-datewise',
  templateUrl: './godown-stock-datewise.component.html',
  styleUrls: ['./godown-stock-datewise.component.css']
})
export class GodownStockDatewiseComponent implements OnInit {
  mrpDef = 0;
  calc_mode: string;
  Expcalc_mode: any;
  fileName = 'godown_stock_datewise.xlsx';
  remark: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  prd_id: any;
  prd_cat_id: any;
  prd_manufact_id: any;
  period_type: any;
  bar_filter: any;
  bar_val: any;
  bar_val2: any;
  stkeanbarcode: any;
  ean_key: any;

  prodsumms: string[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  catagories: string;
  date1: any;
  date2: any;
  stkprd_all: string[];
  mfs: string[];
  uspDef = 0;
  totalproducts: number;
  totalstock: number;
  totalpurchase: any;
  supplierAll: string[];
  pageLoadingImg: boolean;
  repDate: any;
  userType: any;
  branch_all: any;
  filter_branch_id: number;
  exportLoader: boolean;
  Expprodsumms: any;
  Expremark: any;
  Exptotalproducts: any;
  Exptotalstock: any;
  Exptotalpurchase: any;
  ExprepDate: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  subcatagories: any;
  usr_hide_purch_cost: any;
  godown_all: any;
  country_dec: string;

  constructor(private apiService: ApiService, private coreService: CoreService, private datePipe: DatePipe,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.getAllBranch();
    this.filter_branch_id = 1;
    this.userType = this.coreService.getUserData('user_type');
    this.pageLoadingImg = true;
    // let searchval = new FormData();
    // this.apiService.godownRepPeriod(searchval, 1).subscribe((res: Repprddet) => {
    //   this.pageLoadingImg = false;
    //   this.prodsumms = res['data']['data'];
    //   this.curpage = res['data']['current_page'];
    //   this.lastpage = res['data']['last_page'];
    //   this.totalproducts = res['data']['total'].total_item;
    //   this.totalstock = res['data']['total'].total_stock;
    //   this.totalpurchase = res['data']['total'].total_amount;
    //   this.totalpurchase = this.totalpurchase.toFixed(2);
    //   this.calc_mode = res['data']['calc_mode'];
    //   this.remark = res['data']['total'].remark;
    //   this.repDate = res['data'].Date;

    //   this.pgEnd(this.curpage, this.lastpage);
    //   this.pgStart(this.curpage);
    // });
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.purchase_rate','Table.sales_rate_mrp','Common.last_purchase_rate','Common.average_purchase_rate','Common.all','HOME.CUSTOM']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_rate = [
        { id: 0, name: res['Common.purchase_rate'] },
        { id: 1, name: res['Table.sales_rate_mrp'] }
      ];
      this.filter_prate = [
        { id: 0, name: res['Common.last_purchase_rate'] },
        { id: 1, name: res['Common.average_purchase_rate']  }
      ];
    
      // Barcode and Ean
      this.stk_ean_barcode = [
        { id: 'all', name:  res['Common.all'] },
        { id: 'between', name: res['HOME.CUSTOM'] }
      ];
    });

  }
  selectRate(rateid: number) {
    if (rateid == 1) { $('#useprate').hide(); }
    else {
      $('#useprate').show();
    }
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      $('#filterSubmit').click(); 
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  ngAfterContentInit() {

    this.searchCat('');
    this.searchManf('');


  }
  ngOnDestroy() {
    $('.close').click();

  }

  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  // filter  types
  bar_filter_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];


  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  selectType(id: string) {
    if (id == 'c') {
      $('#val1').show();
      $('#val2').show();
    } else {
      $('#val1').hide();
      $('#val2').hide();

    }

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
  getGodownbybranch(bid)
  {
    console.log(bid);
    let searchval = new FormData();      
    searchval.append("bid", bid);
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.mrp == 1)
    formdata.value.avg_rate = undefined;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.godownRepPeriod(formdata.value, pageno).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;

      this.prodsumms = res['data']['data'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.remark = res['data']['total'].remark;
      this.repDate = res['data'].Date;

      this.totalproducts = res['data']['total'].total_item;
      this.totalstock = res['data']['total'].total_stock;
      this.totalpurchase = res['data']['total'].total_amount;
      this.totalpurchase = this.totalpurchase.toFixed(2);
      this.calc_mode = res['data']['calc_mode'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  //form search  catagory
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
    $('.prev-list').hide();
  }

  changeDate(dateString) {
    dateString = this.datePipe.transform(dateString, 'yy-MM-dd');
    return dateString;
  }

  //product form search 
  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }
  filter_rate = [
    { id: 0, name: 'Purchase Rate' },
    { id: 1, name: 'Sales Rate -(M.R. P)' }
  ];
  filter_prate = [
    { id: 0, name: 'Last Purchase Rate' },
    { id: 1, name: 'Avg. Purchase rate' }
  ];

  // Barcode and Ean
  stk_ean_barcode = [
    { id: 'all', name: 'All' },
    { id: 'between', name: 'Custom' }
  ];

  selectBarcodeFilter(id: string) {
    if (id == 'between') {
      $('#bar_val2').show();
    }
    else {
      $('#bar_val2').hide();
    }
  }

  selectStkEanBarcode(id: string) {
    if (id == 'between') {
      $('#ean_key').show();
    }
    else {
      $('#ean_key').hide();
    }
  }





  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(formdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.godownRepPeriod(formdata.value, 1).subscribe((res: Repprddet) => {
      formdata.value.export = '';

      this.Expprodsumms = res['data']['data'];

      this.Expremark = res['data']['total'].remark;
      this.ExprepDate = res['data'].Date;

      this.Exptotalproducts = res['data']['total'].total_item;
      this.Exptotalstock = res['data']['total'].total_stock;
      this.Exptotalpurchase = res['data']['total'].total_amount;
      this.Exptotalpurchase = this.Exptotalpurchase.toFixed(2);
      this.Expcalc_mode = res['data']['calc_mode'];


      setTimeout(() => {
        this.exportexcel();
      }, 3000);


    });



  }
  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'SHOP' });
    });

  }


// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.godownRepPeriod(formdata.value, 1).subscribe((res: Repprddet) => {
      formdata.value.export = '';

      this.Expprodsumms = res['data']['data'];

      this.Expremark = res['data']['total'].remark;
      this.ExprepDate = res['data'].Date;

      this.Exptotalproducts = res['data']['total'].total_item;
      this.Exptotalstock = res['data']['total'].total_stock;
      this.Exptotalpurchase = res['data']['total'].total_amount;
      this.Exptotalpurchase = this.Exptotalpurchase.toFixed(2);
      this.Expcalc_mode = res['data']['calc_mode'];


      const heads = [];

      if(this.calc_mode=='purchase_rate'){
        heads.splice(0,0, this.translate.instant('Common.prd_name'))
        heads.splice(1,0, this.translate.instant('Table.barcode'))
        heads.splice(2,0, this.translate.instant('openingstock'))
        heads.splice(3,0, this.translate.instant('currentstock'))
        heads.splice(4,0, this.translate.instant('closingstock'))

        if(!this.usr_hide_purch_cost){
          heads.splice(5,0, this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.rate'))
          heads.splice(6,0, this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.amnt'))
        }

      } else {
        heads.splice(0,0, this.translate.instant('Common.prd_name'))
        heads.splice(1,0, this.translate.instant('Table.ean_barCode'))
        heads.splice(2,0, this.translate.instant('openingstock'))
        heads.splice(3,0, this.translate.instant('currentstock'))
        heads.splice(4,0, this.translate.instant('closingstock'))
        heads.splice(5,0, 'Sales Rate (M.R.P)')
        heads.splice(6,0, 'Sales Price (M.R.P)')
      }

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
    
        this.Expprodsumms.forEach(item=>{
          const arr=[]
          
          if(this.calc_mode=='purchase_rate'){
            arr.splice(0,0, item.prd_data.prd_name)
            arr.splice(1,0, item.prd_data.prd_barcode)
            arr.splice(2,0, this.currencyPipe.transform(item.openingStock, '', '', '1.1-1'))
            arr.splice(3,0, this.currencyPipe.transform(item.currentStock, '', '', '1.1-1'))
            arr.splice(4,0, this.currencyPipe.transform(item.closingStock, '', '', '1.1-1'))

            if(!this.usr_hide_purch_cost){
              if(item.rate_info){
                arr.splice(5,0, this.currencyPipe.transform(item.rate_info.rate, '', '', this.country_dec))
                arr.splice(6,0, this.currencyPipe.transform(item.stock * item.rate_info.rate, '', '', this.country_dec))
              } else {
                arr.splice(5,0, ' ')
                arr.splice(6,0, ' ')
              }
            }
          } else {
            arr.splice(0,0, item.prd_data.prd_name)
            arr.splice(1,0, item.prd_data.prd_barcode)
            arr.splice(2,0, this.currencyPipe.transform(item.openingStock, '', '', '1.1-1'))
            arr.splice(3,0, this.currencyPipe.transform(item.currentStock, '', '', '1.1-1'))
            arr.splice(4,0, this.currencyPipe.transform(item.closingStock, '', '', '1.1-1'))
              if(item.rate_info){
                arr.splice(5,0, this.currencyPipe.transform(item.rate_info.rate, '', '', this.country_dec))
                arr.splice(6,0, this.currencyPipe.transform(item.stock * item.rate_info.rate, '', '', this.country_dec))
              } else {
                arr.splice(5,0, ' ')
                arr.splice(6,0, ' ')
              }
          }

          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();
    
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
    
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
    
        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Common.Godown_Stock_Datewise')}`,  valueXPos, headerHeight + 15);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalproducts,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.STOCK')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.totalstock, '', '', '1.1-1'),  valueXPos, headerHeight + 25);

        if(this.calc_mode!='purchase_rate' ||!this.usr_hide_purch_cost ){
          if(this.calc_mode=='purchase_rate'){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 30);
          } else if (this.calc_mode!='purchase_rate'){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.Total_Sale_Amount')}`, lblXPos, headerHeight + 30);
          }

          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.totalpurchase, '', '', this.country_dec),  valueXPos, headerHeight + 30);
        }

        if(this.calc_mode=='purchase_rate'){
          doc.setFontSize(10);
          doc.text(this.remark, lblXPos, headerHeight + 35);
        }
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
    
       
        doc.save(`Godown Stock Datewise.pdf`);

        this.exportLoader = false;

    });
  
}


}

