import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-qtn-general-print',
  templateUrl: './qtn-general-print.component.html',
  styleUrls: ['./qtn-general-print.component.css']
})
export class QtnGeneralPrintComponent implements OnInit {
  @Input()saleQtnData;
  @Input()print_style;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()sales_settings;
  @Input()base_url;

  constructor() { }

  ngOnInit() {
  }

}
