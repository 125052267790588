import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-van-missing-stock',
  templateUrl: './van-missing-stock.component.html',
  styleUrls: ['./van-missing-stock.component.css']
})
export class VanMissingStockComponent implements OnInit {
  fileName= 'van_missing_stock.xlsx';
  vans: any;
  products: any[];
  catagories: any;
  users: any;
  branch_all: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  pageLoadingImg: boolean;
  repDate: any;
  van_misng_rpt: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  tot_items: any;
  total_qty: any;
  tot_purch_amt: any;
  pgend: boolean;
  pgstart: boolean;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  exp_van_misng_rpt: any;
  country_dec: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();

    this.country_dec=this.coreService.setDecimalLength();
    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.apiService.vanMissingStkRep(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.van_misng_rpt = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage =  res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      
      this.tot_items =  res['total']['tot_items'];
      this.total_qty = res['total']['total_qty'];
      this.tot_purch_amt =  res['total']['tot_purch_amt'];
     
      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    
    });

    this.vans=this.searchVans('');
    
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanMissingStkRep(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.van_misng_rpt = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage =  res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      
      this.tot_items =  res['total']['tot_items'];
      this.total_qty = res['total']['total_qty'];
      this.tot_purch_amt =  res['total']['tot_purch_amt'];
     
      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    console.log(this.van_misng_rpt);
    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }

  
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportEXCL(formdata: { value: any; }) {

    this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanMissingStkRep(formdata.value, 1).subscribe((res: any) => {
      this.exportLoader = true;
      this.repDate = res.data.Date;
      this.exp_van_misng_rpt = res['data'];
      // this.pageFrom = res['data']['from'];
      // this.curpage =  res['data']['current_page'];
      // this.lastpage = res['data']['last_page'];
      
      this.tot_items =  res['total']['tot_items'];
      this.total_qty = res['total']['total_qty'];
      this.tot_purch_amt =  res['total']['tot_purch_amt'];
     
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
  
      
    
    });

  }
  // getAllVanList() {
  //   let searchval = new FormData();

  //   this.apiService.getAllVanList(searchval).subscribe((res) => {
  //     this.vans = res['data'];
  //     this.vans.push({ "gd_id": '', 'gd_name': 'All' });

  //   });
  // }

    // search product
    selectProd(name: string) {

      const searchval = new FormData();
      searchval.append('prod_name', name);
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }

    searchCat(search: string) {
      const searchval = new FormData();
      searchval.append('cat_name', search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.catagories = res['data'];
      });
  
    }
    searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.users = res['data'];                  
        });
 }

 searchBranch(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res) => {
    this.branch_all = res['data'];
    this.branch_all.push({'branch_id':'','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

}
