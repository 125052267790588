import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';

@Component({
  selector: 'app-payment-dash',
  templateUrl: './payment-dash.component.html',
  styleUrls: ['./payment-dash.component.css']
})
export class PaymentDashComponent implements OnInit {
  up: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
  
  }

}
