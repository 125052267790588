import { Component, OnInit } from '@angular/core';
import { Feature } from '../../../model/feature.model';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {
  feat_name: any;
feat_description: any;
allfeat: any;
  selectedData: any = { feat_id: null, error: null, feat_name: null, feat_description: null,message:null};
  result = '';
  features: [];
  pageLoadingImg : boolean;
  up: any;

  constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {
    this.coreService.getToken(); 
    this.up = this.coreService.getUserPrivilage();

  }

  createFeat(formfeat: { value: Feature; }) {  
    this.pageLoadingImg = true;

      // console.log(formfeat.value);
      this.apiService.createFeat(formfeat.value).subscribe((feat: Feature) => {
        this.pageLoadingImg = false;


        if (feat.error != null) {        
          this.result = feat.error;         
        }
        else {
          this.result='';
          this.coreService.createfunct(formfeat,this.result,feat.message);          
        }

      });
    }

 updateFeat(formfeat: { value: Feature; })
 {
  this.pageLoadingImg = true;

    this.apiService.updateFeat(formfeat.value).subscribe((feat: Feature) => {
      this.pageLoadingImg = false;


      if (feat.error != null) {
        console.log(feat.error);
        this.result = feat.error;
      }
      else {
        this.result='';
        $("#editfeatf").hide(); 
        this.coreService.updatefunct(formfeat,this.result,feat.message);            
        this.apiService.readFeat().subscribe((features: Feature[]) => {
         this.features = features['data'];
        });
      }

    });
  }
 



  selectFeat(feat: Feature) {
    this.result = '';
    $("#editfeatf").show();
    this.selectedData = feat;
  }

  tabClick(tab: { index: number; }) {  
    if(tab.index==1)
  {
    this.apiService.readFeat().subscribe((features: Feature[]) => {
      this.features = features['data'];
    });
  }
  this.result = ''; 
}
  
}
