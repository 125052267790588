import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-baladiyah',
  templateUrl: './baladiyah.component.html',
  styleUrls: ['./baladiyah.component.css']
})
export class BaladiyahComponent implements OnInit {
  constructor(private apiService: ApiService,
    private coreService: CoreService, private datePipe: DatePipe) { }
  pageLoadingImg: boolean;
  docs: any;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  filter: any;
  filter_all = [
    { id: 'all', name: 'All' },
    { id: '7', name: '7 Days' },
    { id: '14', name: '14 Days' },
    { id: '30', name: '30 Days' },
    { id: '60', name: '60 Days' },
    { id: '90', name: '90 Days' }
  ];

  base_url: string;
  doc_url: any;
  selectedDoc: any = {
    ed_id: null, baladiyah_exp: null, baladiyah_doc: null
  };

  ngOnInit() {
    this.base_url = this.apiService.PHP_API_SERVER;
    this.docs = [];
    this.coreService.getToken();
    this.pageLoadingImg = false;
    this.filter = 'all';
    this.listDocs();

  }
  filterList(){
    let searchval = new FormData();
    searchval.append("type", 'baladiyah');
    searchval.append("filter", this.filter);
    
    this.apiService.getDocList(searchval, 1).subscribe((res) => {

      this.docs = res['data']['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  listDocs() {
    let searchval = new FormData();
    searchval.append("type", 'baladiyah');
    searchval.append("filter", this.filter);
    
    this.apiService.getDocList(searchval, 1).subscribe((res) => {

      this.docs = res['data']['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  setValues(data) {
    this.selectedDoc = data;
    this.doc_url = this.base_url + '/' + this.selectedDoc.baladiyah_doc;
  }
  updateExp(formgroup) {
    this.pageLoadingImg = true;
    let searchval = new FormData(formgroup);
    console.log(searchval);

    this.apiService.updateDoc(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
        $('.close').click();
        $('#updateExpiry').hide();
        this.listDocs();
        this.coreService.createfunct(formgroup, res, 'Expiry Successfully Updated');
    });

  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    let searchval = new FormData();
    this.apiService.getDocList(searchval, 1).subscribe((res) => {
      this.docs = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }

}
