import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { Group } from '../../../../../model/group.model';
import { Ledger } from '../../../../../model/ledger.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  log_info = [];
  rec = {
    rec_no: '',
    rec_date: new Date(),
    rec_acc: { ledger_id : 0 },
    note: '',
    ttlAmount: 0,
    grandTtlAmount: 0,
    ttlVatAmount: 0,
    rec_balance: 0,
    rec_bal_sign: '',
    godown_id: 0,
    ledgers: [{
      sub_id: '',
      ledger: <any>{ ledger_id : 0 },
      amount: <any>'',
      narration: '',
      vatIncExc: 0,
      vat_per: 0,
      vat_amount: 0,
      actlAmount: 0,
      fnlAmount: 0,
      rec_balance: 0,
      rec_bal_sign: '',
      accLoading: false
    }]
  };
  account = [];
  nonAccount = [];
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;

  log_curpage = 1;
  log_lastpage = 1;
  log_perpage = 20;
  log_totalItem = 0;

  curForm = 'Create';
  pageLoadingImg = false;
  log_pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  accLoading = false;
  nextId = '';
  printPreview: any;
  log_printPreview: any;
  recPriviewLoader: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  branch_tin: any;
  branch_reg_no: any;
  base_url: string;
  up: any;
  cmpny = '';
  userType = '';
  pageLoadingImg1 = false;
isSubmitInProg1 = false;
  customer = {
    is_supplier:false,
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    op_bal_type: true,
    brch_id:0,
    usr_type:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  ledger_name: any;
  ledger_accgrp_id: any;
  ledger_branch_id: any;
  ledger_alias: any;
  ledger_code: any;
  ledger_email: any;
  ledger_mobile: any;
  ledger_due: any;
  ledger_tin: any;
  ledger_address: any;
  ledger_notes: any;
  ledgersel: any;
  selData: any = { ledger_id: null, error: null, ledger_name: null, message: null, };
  group_all: string[];
  ledger_all: string[];
  branch_all: string[];
  leadger: any;
  private modalRef: NgbModalRef;
  closeResult: string;  
  customer_type = false;
  printError: any;
  searchleadger: any;
  supplier = false;
  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  godowns: any;
  priceGroupList = [];
  ledger_pricegrp_id = 1;
  isCostCategoryLedger = false;
  costcenterList:any;
  isCCContentLoading = false;
  ccindex:any;
  country_dec: string;
  footImgUrl: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    
      this.up = this.coreService.getUserPrivilage();
    this.getAllGroup();
    
    });
    this.getTemplete();
    this.getNextReceiptId();
    this.getAllAccount();
    this.getAllNonAccountLedgers();
    this.getInfo(this.curpage);
    this.getLogInfo(this.log_curpage);
    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType = this.coreService.getUserData('user_type');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address= this.coreService.getUserData('branch_address');
    this.img_url= this.coreService.getUserData('img_url');
    this.branch_phone= this.coreService.getUserData('branch_phone');
    this.branch_mob= this.coreService.getUserData('branch_mob');
    this.branch_tin= this.coreService.getUserData('branch_tin');
    this.branch_reg_no= this.coreService.getUserData('branch_reg_no');
    this.base_url = location.origin;
    this.listGodowns();
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });

    this.country_dec=this.coreService.setDecimalLength();
  }

  listGodowns() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];

    });
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }
      this.footImgUrl=this.apiService.PHP_API_SERVER + '/' + res.data.prnt_reciept_footer_img;
    });
  }

    searchInp(e) {
      let searchval = new FormData();      
      searchval.append("search", e);  
      this.pageLoadingImg = true;
      this.apiService.filterReceipt(searchval,1, this.perpage).subscribe((res) => {
        this.info = res.data.data;
        this.pageLoadingImg = false;
        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.totalItem = res.data.total;
      });
    }


  getNextReceiptId() {
    this.apiService.getNextReceiptId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }

  getAccLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_acc.ledger_id }).subscribe((res) => {
      this.accLoading = false;
      this.rec.rec_balance = res.data.bal_without_sign;
      this.rec.rec_bal_sign = res.data.bal_sign;
    });
  }

  getLedgerBalance(i, recno=0) {
    if(this.rec.ledgers[i].ledger && this.rec.ledgers[i].ledger.is_cost_category){
      this.isCostCategoryLedger = true;
      this.isCCContentLoading = true;
      this.ccindex = i;
      this.apiService.getCostCenter({ledger_id:this.rec.ledgers[i].ledger.ledger_id, refno: recno, vch_type:1}).subscribe((res) => {
        this.costcenterList = res.data;
        this.isCCContentLoading = false;
      });
    } else{
      this.isCostCategoryLedger = false;
      this.costcenterList = [];
      this.isCCContentLoading = false;
      this.ccindex = null;
    }
    this.rec.ledgers[i].accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.rec.ledgers[i].ledger.ledger_id }).subscribe((res) => {
      this.rec.ledgers[i].accLoading = false;
      this.rec.ledgers[i].rec_balance = res.data.bal_without_sign;
      this.rec.ledgers[i].rec_bal_sign = res.data.bal_sign;
    });
  }

  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllReceipt(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  getLogInfo(pageNo) {
    this.log_pageLoadingImg = true;
    this.apiService.getReceiptLog(pageNo, this.log_perpage).subscribe((res) => {
      this.log_info = res.data.data;
      this.log_pageLoadingImg = false;
      this.log_curpage = res.data.current_page;
      this.log_lastpage = res.data.last_page;
      this.log_totalItem = res.data.total;
    });
  }
  searchLogInp(e) {
    let searchval = new FormData();      
    searchval.append("search", e);  
    this.pageLoadingImg = true;
    this.apiService.filterReceiptLog(searchval,1, this.log_perpage).subscribe((res) => {
      this.log_info = res.data.data;
      this.log_pageLoadingImg = false;
      this.log_curpage = res.data.current_page;
      this.log_lastpage = res.data.last_page;
      this.log_totalItem = res.data.total;
    });
  }
  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getAllNonAccountLedgers() {
    this.apiService.getAllNonAccountLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }
  addMore() {
    this.rec.ledgers.push({
      sub_id: '',
      ledger: { ledger_id : 0 },
      amount:<any> '',
      narration: '',
      vatIncExc: 0,
      vat_per: 0,
      vat_amount: 0,
      actlAmount: 0,
      fnlAmount: 0,
      rec_balance: 0,
      rec_bal_sign: '',
      accLoading: false
    });
  }

  checkTabClicked(keycode) {
    // console.log(keycode);
    if (keycode.keyCode === 9) {
      this.addMore();
    }
  }
  remove(index) {
      // if cost category ledger remove, then clear cost center section
      if(this.ccindex == index){
        this.isCostCategoryLedger = false;
        this.costcenterList = [];
        this.isCCContentLoading = false;
        this.ccindex = null;
      }
    this.rec.ledgers.splice(index, 1);
    this.updateTotoal();
  }
  createRec(){
    this.curForm = this.langText.create;
    this.nextId = '';
    this.getNextReceiptId();
    this.clearForm();
  }
  clearForm() {
    this.rec = {
      rec_no: '',
      rec_date: new Date(),
      rec_acc: { ledger_id : 0 },
      note: '',
      ttlAmount: 0,
      grandTtlAmount: 0,
      ttlVatAmount: 0,
      rec_balance: 0,
      rec_bal_sign: '',
      godown_id: 0,
      ledgers: [{
        sub_id: '',
        ledger: { ledger_id : 0 },
        amount: '',
        narration: '',
        vatIncExc: 0,
        vat_per: 0,
        vat_amount: 0,
        actlAmount: 0,
        fnlAmount: 0,
        rec_balance: 0,
        rec_bal_sign: '',
        accLoading: false
      }]
    };
    this.isCostCategoryLedger = false;
    this.costcenterList = [];
    this.isCCContentLoading = false;
    this.ccindex = null;
  }

  vatCalculate(index) {
    this.rec.ledgers[index].amount = this.rec.ledgers[index].amount || 0;
    if (this.rec.ledgers[index].vatIncExc == 0) {
      // this.rec.ledgers[index].fnlAmount = this.rec.ledgers[index].amount;
      this.rec.ledgers[index].vat_amount = (this.rec.ledgers[index].amount / 100 ) * this.rec.ledgers[index].vat_per;
      this.rec.ledgers[index].fnlAmount = Number(this.rec.ledgers[index].amount) + Number(this.rec.ledgers[index].vat_amount);
      this.rec.ledgers[index].actlAmount = this.rec.ledgers[index].amount;
    } else {
      this.rec.ledgers[index].actlAmount = this.rec.ledgers[index].amount / ( 1 + (this.rec.ledgers[index].vat_per / 100));
      this.rec.ledgers[index].vat_amount = (this.rec.ledgers[index].actlAmount  / 100 ) * this.rec.ledgers[index].vat_per;
      this.rec.ledgers[index].fnlAmount = this.rec.ledgers[index].amount;
    }
    this.updateTotoal();
    // alert(this.rec.ledgers[index].amount);

    // alert(this.rec.ledgers[index].vatIncExc);
    // alert(this.rec.ledgers[index].vat_per);
  }

  updateTotoal(){
    this.rec.ttlAmount = 0;
    this.rec.ttlVatAmount = 0;
    this.rec.grandTtlAmount = 0;
    this.rec.ledgers.forEach(element => {
      this.rec.grandTtlAmount += Number(element.fnlAmount);
      this.rec.ttlVatAmount += Number(element.vat_amount);
      this.rec.ttlAmount += Number(element.actlAmount);
    });
  }

  voidReceipt(id){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.apiService.voidReceipt({rec_no: id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          $('.close').click();
        }

      });
    }
  }

  editVoucher(rec_no){
    this.nextId = '';
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getReceipt({rec_no: rec_no}).subscribe((res) => {
        this.nextId = res.rec_no;
        this.rec.rec_no = res.rec_no;
        console.log(res);
        this.rec.rec_date = new Date(res.rec_datetime);
        const result = this.account.filter(acc => acc.ledger_id == res.rec_acc_ledger_id);
        this.rec.rec_acc = result[0];
        this.getAccLedgerBalance();
        this.rec.note = res.rec_note;
        this.rec.godown_id = res.godown_id;
        this.rec.ledgers = [];
        res.particulars.forEach((element,i) => {
          const result = this.nonAccount.filter(nonacc => nonacc.ledger_id == element.ledger_id);
          var tmpRow = {
            sub_id: element.recsub_id,
            ledger: result[0],
            amount: element.recsub_amount,
            narration: element.recsub_narration,
            vatIncExc: element.recsub_vat_inc,
            vat_per: element.recsub_vat_per,
            vat_amount: element.recsub_vat_amt,
            actlAmount: element.recsub_amount - element.recsub_vat_amt,
            // be care fully check if tax iclude excule option integrating
            fnlAmount: element.recsub_amount,
            rec_balance: 0,
            rec_bal_sign: '',
            accLoading: true
          }
          this.rec.ledgers.push(tmpRow);
          this.getLedgerBalance(i, rec_no);
        });
        this.updateTotoal();
    });
  }

  viewReceipt(id){
    this.recPriviewLoader = true;
    
      this.apiService.getReceipt({rec_no: id}).subscribe((res) => {
    this.recPriviewLoader = false;
       
      this.printPreview = res;
      
      // console.log(this.printPreview.rec_ttl_amount);

      });

  }

  viewLogReceipt(res){
    this.log_printPreview = res;
  }

  
  validateAndSubmit() {

    this.resultobj = [];

    if (this.rec.rec_date == undefined) {
      this.resultobj.rec_date = "t";
      this.resultobj.push({ 'rec_date': 'Required' });
    }
    else {
      if (this.rec.rec_date > new Date()) {
        this.resultobj.rec_date = "t";
        this.resultobj.push({ 'rec_date': 'Required' });
      } else {
        this.resultobj.rec_date = "f";
      }
    }
    if (this.resultobj.length <= 0) {

    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createReceipt';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateReceipt';
      }
      if(this.ccindex != null){
        this.rec.ledgers[this.ccindex].ledger.cc = this.costcenterList;
      }
      this.apiService[callFunction](this.rec).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.getNextReceiptId();
          if(callFunction == 'updateReceipt'){
            this.getLogInfo(this.log_curpage);
          }
          this.resultobj = {};
        }

      });
    }
  }
}
removeName() {
  this.ledger_all = [];
  $('.prev-list').hide();
}

getAllGroup() {
  this.apiService.readAllGrp().subscribe((res: Group[]) => {
    // console.log(res['data']);
    const LedgGroup = res['data'].filter((group) => group.accgrp_id !== 21);
    this.group_all = LedgGroup;
  });
}
openModal(content: any, size) {

  this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
  this.modalRef.result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });

}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

validateAndSubmit1(){
  this.isSubmitInProg1 = true;
  let callFunction = 'createCustomer';
  
  if(this.userType =='ADMIN'){
    this.customer.usr_type = 1;
  }else{
    this.customer.usr_type = 0;
  
  }
  this.customer.is_supplier = this.supplier;
  this.apiService[callFunction](this.customer).subscribe((res) => {
    // console.log(this.staff);
    this.isSubmitInProg1 = false;
    if (res.error != null) {
      this.resultobj = res.error;
    } else {
      
      this.coreService.showMessage(res.message);
      // this.clearForm();
      this.resultobj = {};
      this.modalRef.close();
      this.clearCustForm();
      this.supplier = false;
      this.customer_type = false;
      this.getAllNonAccountLedgers();
    }

  });
}
createSupplier(formgroup: { value: any; }) {
  this.validatesupplier(formgroup.value);
  if (this.printError.length <= 0) {
    this.pageLoadingImg1 = true;

  this.apiService.createSupplier(formgroup.value).subscribe((res) => {
    this.pageLoadingImg1=false;

    if (res.error != null) {
      this.resultobj = res.error;
      this.resultobj = this.coreService.getValidation(this.resultobj);
    } else {
      this.coreService.createfunct(formgroup, this.resultobj, res.message);
      this.resultobj = {};
      this.modalRef.close();
      this.supplier = false;
      this.getAllNonAccountLedgers();
    }

  });
}
}
validatesupplier(list) {
 
  this.printError = [];
  if (!list.supp_name) {
    this.printError.push({ 'supp_name': 'Required' });
    $('.supp_name').css("border", "1px solid red");
    $(".supp_name_err").text("Supplier name required");
  } else {
    $('.supp_name').css("border", "1px solid #dedede");
    $(".supp_name_err").text("");
  }
}
createLedger(formgroup: { value: any; }) {
this.pageLoadingImg1 = true;
this.apiService.createLedgerPopup(formgroup.value).subscribe((res: Ledger) => {
  this.pageLoadingImg1 = false;

  if (res.error != null) {
    this.resultobj = res.error;
    this.resultobj = this.coreService.getValidation(this.resultobj);
  }
  else {
    this.coreService.createfunct(formgroup, this.resultobj, res.message);
    this.resultobj = {};
    this.modalRef.close();
    this.getAllNonAccountLedgers();
  }

});
}
searchLedger(search: string) {
let searchval = new FormData();
searchval.append("search", search);
this.apiService.searchLedgerList(searchval, 1).subscribe((res) => {
  this.searchleadger = res['data']['data'];
  // console.log(this.searchleadger);
});
$('.prev-list').show();
}
clearCustForm() {
this.customer = {
  is_supplier:false,
  id: '',
  ledger_id: '',
  name: '',
  alias: '',
  code: '',
  email : '',
  mobile: '',
  vat_no: '',
  op_bal: 0,
  op_bal_type: true,
  brch_id:0,
  usr_type:0,
  add: {
    addr: '',
    zip: '',
    city: '',
    country: '',
    state: '',
    state_code: '',
    fax: ''
  },
  del: {
    addr: '',
    zip: '',
    city: '',
    country: '',
    state: '',
    state_code: '',
    fax: ''
  },
  due_day: '',
  van_line: 0,
  price_group: 1,
  note: ''
};
}

}
