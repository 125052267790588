import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { ActivatedRoute } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs; 
pdfMake.fonts = { 'Roboto': { normal: 'Roboto-Regular.ttf', bold: 'Roboto-Medium.ttf', italics: 'Roboto-Italic.ttf', bolditalics: 'Roboto-Italic.ttf' },
 'THSarabunNew': { normal: 'THSarabunNew.ttf' }, 'code128': { normal: 'code128.ttf' } }
 pdfMake.vfs = pdfFonts.pdfMake.vfs;
 
@Component({
  selector: 'app-purch-invoice-due',
  templateUrl: './purch-invoice-due.component.html',
  styleUrls: ['./purch-invoice-due.component.css']
})
export class PurchInvoiceDueComponent implements OnInit {

  fileName = 'purchase_invoice_due_summary.xlsx';
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
  users: any;
  supplierAll: any;
  pageLoadingImg: boolean;
  summary: any=[];
  total_bal_amnt: any;
  total_inv_amnt: any;
  total_recieved_amnt: any;
  repDate: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  totalData: any;
  pgend: boolean;
  pgstart: boolean;
  exportLoader: boolean;
  Expsummary: any;
  ExprepDate: any;
  Exptotal_bal_amnt: any;
  Exptotal_inv_amnt: any;
  Exptotal_recievd_amnt: any;
  totalcount: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  pdf: any;
  supp_id: any;
  aging: any;
  country_dec: string;
  cur_lang: string;


  constructor(private apiService: ApiService, private coreService: CoreService,private activatedRoute: ActivatedRoute,private translate: TranslateService) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    let searchval = new FormData();

    this.activatedRoute.params.subscribe(params => {
      this.supp_id = params['id'];
      this.aging = params['aging'];
      if (this.supp_id && this.aging) {
        searchval.append("supp_id", this.supp_id);
        searchval.append("aging", this.aging);
      }
      searchval.append("supp_id", this.supp_id);
      searchval.append("aging", this.aging);
    
    });
    this.pageLoadingImg = true;
    this.apiService.purchaseInvDueummary(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.total_inv_amnt = res['data']['total_inv_amnt'];
      this.total_recieved_amnt = res['data']['total_recieved_amnt'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      this.totalcount=this.summary.length;
      // console.log(this.totalData);
      this.pdf = res['data']['pdf'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });
    this.searchUser('');

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  pageNext(formdata: { value: any; }, pageno: any){

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.pageLoadingImg = true;
    this.apiService.purchaseInvDueummary(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.total_inv_amnt = res['data']['total_inv_amnt'];
      this.total_recieved_amnt = res['data']['total_recieved_amnt'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      this.totalcount=this.summary.length;
      this.pdf = res['data']['pdf'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

    // form search supplier
    searchSupplier(search: string) {
      const searchval = new FormData();
      searchval.append('supp_name', search);
       if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append("with-alias", "1");
      }
      this.apiService.searchSupplier(searchval).subscribe((res) => {
        this.supplierAll = res.data;
      });
    }

    exportexcel(): void {
      /* table id is passed over here */
      let element = document.getElementById('export-group');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
      this.exportLoader = false;
  
    }
  
  
    exportEXCL(Expformdata: { value: any; }) {
  
      // debugger;
      Expformdata.value.datewise = true;
      Expformdata.value.export = 'export';
      if (Expformdata.value.period_type === 'c') {
        Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
        Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
        Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
        Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
      }
      this.exportLoader = true;
    
      this.apiService.purchaseInvDueummary(Expformdata.value, 1).subscribe((res: any) => {
  
        // if(res['data']['data']){
  
        this.Expsummary = res['data']['data'];
        this.ExprepDate = res['data'].Date;
        this.Exptotal_bal_amnt = res['data']['total_bal_amnt'];
        this.Exptotal_inv_amnt = res['data']['total_inv_amnt'];
        this.Exptotal_recievd_amnt = res['data']['total_recieved_amnt'];
  
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
  
      });
      Expformdata.value.export = '';
  
    }

    generatePdf() {
                 
      this.exportLoader = true   
      var filename = 'Purchase Due Summary';
      
    
    // Declaring your layout
      var myTableLayouts = {
      exampleLayout: {
        fontSize: 11,
         bold: false,
      }
      };
    
    
      var docDefinition = {
          content: [
           {
              text: this.branch_display_name,
              bold: true,
              fontSize: 13,
              alignment: 'center',
              margin: [0, 0, 0, 20]
            },
            {
              text: filename,
              bold: true,
              fontSize: 16,
              alignment: 'center',
              margin: [0, 0, 0, 20]
            },
            {
              columns: [
                [
                  {
                    text: 'Report Period : '+this.repDate.date1+' to '+this.repDate.date2,
                    fontSize: 11,
                    margin: [0, 0, 0, 12]
                  },
                {
                  text: 'Total Invoice Amount : '+this.total_inv_amnt.toFixed(3),
                  fontSize: 11,
                  margin: [0, 0, 0, 12]
                },
                {
                  text: 'Total Recieved Amount : '+this.total_recieved_amnt.toFixed(3),
                  fontSize: 11,
                  margin: [0, 0, 0, 12]
                },
                {
                  text: 'Total Balance Amount : '+this.total_bal_amnt.toFixed(3),
                  fontSize: 11,
                  margin: [0, 0, 0, 12]
                },
      
                ],
                [
                  // Document definition for Profile pic
                ]
              ],
              columnStyles: { comment: { columnWidth: 'auto' } },
              styles: {
                font: 'THSarabuNew',
                name: {
                  fontSize: 11,
                },
                tableExample: {
                  fontSize: 6,
                },
              },
            },
          
            {
              style: 'tableExample',
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
               
                fontSize: 11,
                headerRows: 1,
                styles: {
                  cellPadding: 0.5,
                  fontSize: 7
              },
              // startY: 30, /* if start position is fixed from top */
              tableLineColor: [0, 0, 0], // choose RGB
              tableLineWidth: 0.5, // table border width
               // widths: [ '*', 'auto', '*', '100','*','*',,'*','*','*' ,'*' ],
                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto','auto', 'auto','auto'],
                body: this.pdf,
                bodyStyles: {
                  margin: 30,
                  fontSize: 5,
                  lineWidth: 0.1,
                  lineColor: [0, 0, 0]
              }, 
              theme: 'grid',
              columnStyles: {
                text: {
                    cellWidth: 'auto',
                    fontSize: 5,
                }
            }
              }
            }
          ]
        };
    
      pdfMake.createPdf(docDefinition).download(filename+".pdf");
    
      this.exportLoader = false;
    
      }
}
