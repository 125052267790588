import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-contra',
  templateUrl: './contra.component.html',
  styleUrls: ['./contra.component.css']
})
export class ContraComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;
  nonAccount = [];
  rec = {
    rec_no: '',
    rec_date: new Date(),
    rec_type: 0,
    rec_bankacc: '',
    from_balance: 0,
    from_sign: '',
    to_balance: 0,
    to_sign: '',
    rec_toAcc: '',
    amount: <any>'',
    note: '',
    godown_id: 0

  };
  contraType = [
    { id: 0, name: 'Deposit' },
    { id: 1, name: 'Withdraw' }
  ];
  bankAccount = [];
  cashAccount: any;
  toaccLoading: boolean;

  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  branch_tin: any;
  branch_reg_no: any;
  base_url: string;

  isSubmitInProg = false;



  langText = {
    create: '',
    update: ''
  };
  accLoading = false;
  nextId = '';
  printPreview: any;
  recPriviewLoader: boolean;
  up: any;
  cmpny = '';
  userType = '';
  godowns: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    });
    this.getnextContraId();
    this.getAllAccount();
    this.getAllNonAccountLedgers();
    this.getAllCashAccount();
    this.getInfo(this.curpage);

    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType = this.coreService.getUserData('user_type');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.img_url = this.coreService.getUserData('img_url');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.branch_tin = this.coreService.getUserData('branch_tin');
    this.branch_reg_no = this.coreService.getUserData('branch_reg_no');
    this.base_url = location.origin;

    this.up = this.coreService.getUserPrivilage();
    this.listGodowns();

    this.country_dec=this.coreService.setDecimalLength();
  }

  listGodowns() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];

    });
  }

  getAccLedgerBalance(ledger, on) {
    if (ledger) {
      if (on == 'from') {
        this.accLoading = true;
      } else if (on == 'to') {
        this.toaccLoading = true;
      }
      this.apiService.getLedgerBalance({ ledger_id: ledger.ledger_id }).subscribe((res) => {
        if (on == 'from') {
          this.accLoading = false;
        } else if (on == 'to') {
          this.toaccLoading = false;
        }
        if (on == 'from') {
          this.rec.from_balance = res.data.bal_without_sign;
          this.rec.from_sign = res.data.bal_sign;
        } else if (on == 'to') {
          this.rec.to_balance = res.data.bal_without_sign;
          this.rec.to_sign = res.data.bal_sign;
        }
      });
    }
  }




  getnextContraId() {
    this.apiService.getnextContraId().subscribe((res) => {
      this.nextId = res.data.cont_ref_no;
    });
  }



  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllcontra(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  getAllAccount() {
    this.apiService.getAllBankLedgers().subscribe((res) => {
      this.bankAccount = res.data;
    });
  }


  getAllCashAccount() {
    this.apiService.getAllCashAccount().subscribe((res) => {
      this.cashAccount = res.data;
    });
  }
  getAllNonAccountLedgers() {
    this.apiService.getAllNonAccountLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }

  searchInp(e) {
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('search', e);
    this.apiService.searchContraList(searchval).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  voidContra(id) {
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.apiService.voidContra({ rec_no: id }).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          $('.close').click();
        }

      });
    }
  }

  viewContra(id) {
    this.recPriviewLoader = true;

    this.apiService.getContra({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;

      this.printPreview = res;

      // console.log(this.printPreview.rec_ttl_amount);

    });

  }


  editVoucher(ref_no){
    this.nextId = '';
    this.curForm = this.langText.update;
    this.clear();
    this.resultobj = [];
    this.apiService.getContra({rec_no: ref_no}).subscribe((res) => {
      console.log(res);
      this.nextId = res.cont_ref_no;
      this.rec.rec_no = res.cont_ref_no;
      this.rec.rec_date = new Date(res.cont_datetime);
      this.rec.rec_type = res.cont_type;
      this.rec.godown_id = res.godown_id;
      const result = this.bankAccount.filter(nonacc => nonacc.ledger_id == res.cont_from_ledger_id);
      const result1 = this.cashAccount.filter(nonacc => nonacc.ledger_id == res.cont_to_ledger_id);
      this.rec.rec_bankacc =  result[0];
      this.rec.rec_toAcc = result1[0];
      this.rec.amount =  res.cont_amount;
      this.rec.note =  res.cont_note;
      this.getAccLedgerBalance({ledger_id: res.cont_from_ledger_id}, 'from');
      this.getAccLedgerBalance({ledger_id: res.cont_to_ledger_id}, 'to');
    });
  }

  createContra(){
    this.curForm = this.langText.create;
    this.nextId = '';
    this.clear();
    this.getnextContraId();
  }
  clear() {
    this.rec = {
      rec_no: '',
      rec_date: new Date(),
      rec_type: 0,
      rec_bankacc: '',
      from_balance: 0,
      from_sign: '',
      to_balance: 0,
      to_sign: '',
      rec_toAcc: '',
      amount: '',
      note: '',
      godown_id: 0

    };
  }



  validateAndSubmit() {

    this.resultobj = [];

    if (this.rec.rec_date == undefined) {
      this.resultobj.rec_date = "t";
      this.resultobj.push({ 'rec_date': 'Required' });
    }
    else {
      if (this.rec.rec_date > new Date()) {
        this.resultobj.rec_date = "t";
        this.resultobj.push({ 'rec_date': 'Required' });
      } else {
        this.resultobj.rec_date = "f";
      }
    }
    if (this.resultobj.length <= 0) {

      if (confirm('Do you wish to continue?')) {
        this.isSubmitInProg = true;
        let callFunction = '';
        if (this.curForm === this.langText.create) {
          callFunction = 'createContra';
        }
        if (this.curForm === this.langText.update) {
          callFunction = 'updateContra';
        }
        console.log(this.rec);
        this.apiService[callFunction](this.rec).subscribe((res) => {
          // console.log(this.staff);
          this.isSubmitInProg = false;
          if (res.error != null) {
            this.resultobj = res.error;
          } else {
            this.closeModal.nativeElement.click();
            this.getInfo(this.curpage);
            this.coreService.showMessage(res.message);
            this.clear();
            this.getnextContraId();
            this.resultobj = {};
          }

        });
      }
    }
  }


}
