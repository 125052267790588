import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sale-detail-rep',
  templateUrl: './sale-detail-rep.component.html',
  styleUrls: ['./sale-detail-rep.component.css']
})
export class SaleDetailRepComponent implements OnInit {

  fileName= 'sales_detail.xlsx';

  pageLoadingImg: boolean;
  up: any;
  userType: any;
  salesDetSum: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  total_amount: any;
  total_discount_amount: any;
  pgend: boolean;
  pgstart: boolean;
  filter_branch_id: number;
  branch_all: any;
  users: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  sortType = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
paytype = ['Cash','Credit','Bank','Multi'];

  filter_paytype = [
    { id: 1, name: 'Credit' },
    { id: 0, name: 'Cash' },
    { id: 2, name: 'Bank' },
    { id: 3, name: 'Multi' }

  ];
  below_mrp = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' }
  ];
  customer: any;
  total_res: any;
  period_type: string;
  repDate: any;
  exportLoader: boolean;
  ExpsalesDetSum: any;
  Exptotal_res: any;
  ExprepDate: any;
  godowns: any;
  vans: any;
  country_dec: string;
  products: any[];
  van_lines: any;
  priceGroupList: any;
  customer_category: any;
  cur_lang: string;
  branch_address:any;
  branch_display_name: any;
  branch_display_code: any;
  spliprofit: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_address = this.coreService.getUserData('branch_address');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.getAllVanlines();
    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.period_type = 't';
    let searchval = new FormData();
    searchval.append("period_type", 't');

    this.apiService.salesDetailAuditor(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.salesDetSum = res.data.data;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;

      this.total_res =  res.data.totalRes;
      this.repDate = res.data.Date;

      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  this.searchUser('');

  this.apiService.getAllPriceGroup().subscribe((res) => {
    this.priceGroupList = res.data;
  });
  this.getAllCustomerCategory();
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
  
  
  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.credit','Common.cash','HOME.Bank','settings.Multi','Common.this_month']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_paytype = [
        { id: 1, name: res['HOME.credit'] },
        { id: 0, name: res['Common.cash'] },
        { id: 2, name: res['HOME.Bank'] },
        { id: 3, name: res['settings.Multi'] }
    
      ];
    });

  }

  ngAfterContentInit(){
    
    this.searchVans('');
    this.getGodownList();

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    this.vans.push({ "van_id": 'All', 'van_name': 'All' });
    });

  }

  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';
    this.salesDetSum = [];
    this.apiService.salesDetailAuditor(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.salesDetSum = res.data.data;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_res =  res.data.totalRes;
      this.repDate = res.data.Date;

 

      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }


  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }

  searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.users = res['data'];                  
        });
 }

 searchCustomer(search: string) {
  const searchval = new FormData();
  searchval.append('cust_name', search);
   if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append("with-alias", "1");
  }
  this.apiService.searchCustomer(searchval).subscribe((res) => {
    this.customer = res['data'];
  });

}

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(expformdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      expformdata.value.export = 'export';
      

    this.apiService.salesDetailAuditor(expformdata.value, 1).subscribe((res: any) => {

      this.ExpsalesDetSum = res.data.data;
      
      this.Exptotal_res =  res.data.totalRes;
      this.ExprepDate = res.data.Date;

 
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);


    });

    }

    // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }
  getAllVanlines() {
    this.apiService.getAllVanlines().subscribe((res) => {
      this.van_lines = res['data'];

    });
  }

  vanLineslct(vanline){

      const searchval = new FormData();
      searchval.append('van_line', vanline);
      this.apiService.searchVans(searchval).subscribe((res) => {
        this.vans = res['data'];
      this.vans.push({ "van_id": 'All', 'van_name': 'All' });
      });
  
    

  }

  getAllCustomerCategory()
  {
    this.apiService.listCustCategory().subscribe((res) => {
      this.customer_category = res['data'];
    });
  }

  // pdf download
  generatePdf(expformdata: { value: any; }) {

    this.exportLoader = true;
     
    // debugger;
    expformdata.value.export = 'export';
    

  this.apiService.salesDetailAuditor(expformdata.value, 1).subscribe((res: any) => {

    this.ExpsalesDetSum = res.data.data;
    this.Exptotal_res =  res.data.totalRes;
    this.ExprepDate = res.data.Date;

    console.log(this.ExpsalesDetSum);
    console.log(this.Exptotal_res);

    const heads = [
      this.translate.instant('HOME.Invoice_no'),
      this.translate.instant('Common.Pay_Type'),
      this.translate.instant('Common.date'),
      this.translate.instant('Table.description'),
      this.translate.instant('Table.item_cost'),
      this.translate.instant('Table.rate'),
      this.translate.instant('Table.qty'),
      this.translate.instant('Sales.Item_Disc'),
      this.translate.instant('Auditor.gross_amount'),
      this.translate.instant('HOME.difference'),
      this.translate.instant('Purchase.vat'),
      this.translate.instant('Common.Total_Amount')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.ExpsalesDetSum.forEach(item=>{
        data.push([
          item.sales_inv_no + ' [' + item.sales_branch_inv + ']',
          this.paytype[item.sales_pay_type],
          this.datePipe.transform(item.sales_date, 'dd/MM/yyyy') + '\n' + item.sales_time,
          item.sales_cust_name,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ])

          item.sales_sub.forEach(item1=>{
            data.push([
              '',
              '',
              item1.ean,
              item1.prd_name,
              this.currencyPipe.transform(item1.item_cost, '', '', this.country_dec),
              this.currencyPipe.transform(item1.salesub_rate, '', '', this.country_dec),
              item1.salesub_qty,
              this.currencyPipe.transform(item1.salesub_discount, '', '', this.country_dec),
              this.currencyPipe.transform(item1.sale_total, '', '', this.country_dec),
              this.currencyPipe.transform(item1.diff, '', '', this.country_dec),
              this.currencyPipe.transform(item1.salesub_tax_rate, '', '', this.country_dec),
              this.currencyPipe.transform(item1.sale_total_amount, '', '', this.country_dec)
            ])
          }),
  
          data.push([
            '',
            '',
            '',
            '',
            '',
            this.translate.instant('HOME.total') + ':',
            item.totqty,
            this.currencyPipe.transform(item.totdisc, '', '', this.country_dec),
            this.currencyPipe.transform(item.totgross, '', '', this.country_dec),
            this.currencyPipe.transform(item.cost_total, '', '', this.country_dec),
            this.currencyPipe.transform(item.tot_taxrate, '', '', this.country_dec),
            this.currencyPipe.transform(item.totamt, '', '', this.country_dec)
          ]),

          data.push([
            '',
            '',
            '',
            '',
            '',
            this.translate.instant('Common.Disc') + ':',
            '',
            item.sales_discount,
            '',
            this.translate.instant('Table.Net_Amt') + ':',
            '',
            this.currencyPipe.transform(item.sales_total, '', '', this.country_dec)
          ]),

          data.push([
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            this.translate.instant('Common.bill_profit') + ':',
            '',
            this.currencyPipe.transform(item.tot_profit, '', '', this.country_dec)
          ])
      })
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.ExprepDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Auditor.sales_detail_report')}`,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.total_inv_amnt')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.inv_amt, '', '', this.country_dec),  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.Vat_Amount')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.vat_amt, '', '', this.country_dec),  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Auditor.gross_amount')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.gross_amt, '', '', this.country_dec),  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.total_difference')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.diff_amt, '', '', this.country_dec),  valueXPos, headerHeight + 35);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Item_Discount')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.disc_amt, '', '', this.country_dec),  valueXPos, headerHeight + 40);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.total_discount_amount')}`, lblXPos, headerHeight + 45);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.disc_tot, '', '', this.country_dec),  valueXPos, headerHeight + 45);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.total_profit')}`, lblXPos, headerHeight + 50);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.total_profit, '', '', this.country_dec),  valueXPos, headerHeight + 50);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.total_quantity')}`, lblXPos, headerHeight + 55);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.total_qty, '', '', this.country_dec),  valueXPos, headerHeight + 55);

      if(this.spliprofit){
        doc.setFontSize(10);
        doc.text(`Profit above 0`, lblXPos, headerHeight + 60);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.total_high_profit, '', '', this.country_dec),  valueXPos, headerHeight + 60);

        doc.setFontSize(10);
        doc.text(`Profit below 0`, lblXPos, headerHeight + 65);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.Exptotal_res.total_low_profit, '', '', this.country_dec),  valueXPos, headerHeight + 65);
      }
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 70, lblXPos, heads, data, null);
  
     
      doc.save(`Sales Detail Report.pdf`);

      this.exportLoader = false;

  });
    
  }

}
