import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { ExcelService } from '../../../../../service/excel.service';
import { TranslateService } from '@ngx-translate/core';
import {LangChangeEvent } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ActivatedRoute } from '@angular/router';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-reciept-summary',
  templateUrl: './reciept-summary.component.html',
  styleUrls: ['./reciept-summary.component.css']
})

export class RecieptSummaryComponent implements OnInit {

  fileName = 'reciept_summary_report.xlsx';
  pageLoadingImg: boolean;
  info: any;
  curpage: any;
  lastpage: any;
  totalItem: any;
  pageFrom: any;
  total_amount: any;
  repDate: any;
  pgend: boolean;
  pgstart: boolean;
  userType = '';
  branch_all: any;
filter_branch_id: number;

  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  sortType = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
  account: any;
  nonAccount: any;
  group_all: any;
  period_type: string;
  branch_display_name: any;
  branch_name: any;
  branch_display_cod: any;
  exportLoader: boolean;
  exp_info: any;
  exp_total_amount: any;
  ExprepDate: any;
  today_date: any;
  branch_open_date: any;
  logoImgUrl: '';
  country_dec: string;
  custid: any;
  ptype: any;
  date1: string;
  date2: string;

  constructor(private apiService: ApiService, private coreService: CoreService ,private translate: TranslateService, private excelService: ExcelService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe,private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.pageLoadingImg = true;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.userType = this.coreService.getUserData('user_type');
    this.country_dec=this.coreService.setDecimalLength();
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.getAllAccount();
    this.getAllNonAccountLedgers();
    this.period_type='t'
    let searchval = new FormData();
    searchval.append("period_type", 't');
    this.activatedRoute.params.subscribe(params => {
      this.custid = params['custid'];
      this.ptype = params['ptype'];
      if (this.custid && this.ptype) {
             
        searchval.append("credit_acc", this.custid);
        if(this.ptype=='c'){
          
          this.date1= this.apiService.formatDate(params['d1']);
          this.date2= this.apiService.formatDate(params['d2']);
          searchval.append("period_type", this.ptype);
          searchval.append("date1", this.date1);
          searchval.append("date2", this.date2);
        }else{
          searchval.append("period_type", this.ptype);
        }
        
      }
    });
    this.apiService.recieptReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.info = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pageFrom = res.data.from;
      this.total_amount = res.data.total_amount;
      this.repDate = res.data.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });


    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_cod = this.coreService.getUserData('branch_code');
    this.branch_open_date = this.coreService.getUserData('branch_open_date');
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';
    this.apiService.recieptReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.info = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.total_amount = res.data.total_amount;
      this.pageFrom = res.data.from;
      this.repDate = res.data.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getAllNonAccountLedgers() {
    this.apiService.getAllNonAccountLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }

  searchAccGroup(search: string) {
    let searchval = new FormData();
    searchval.append("name", search);
    this.apiService.getAccGroup(searchval).subscribe((res) => {
      this.group_all = res['data'];
    });
  }

  exportEXCL(expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    expformdata.value.export = 'export';

    this.apiService.recieptReport(expformdata.value, 1).subscribe((res: any) => {
      this.exp_info = res.data.data;
      this.exp_total_amount = res.data.total_amount;

      this.ExprepDate =  res.data.Date;


      setTimeout(() => {
        this.exportexcel();
      }, 3000);


    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }



  // pdf download
exportToPdf(formdata: any) {

  formdata.value.export = 'export';

  this.exportLoader = true;
  
  const lblXPos = 18;
  const valueXPos = 55;

  const heads = [
    
    this.translate.instant('transaction.Rec_No'),
    this.translate.instant('transaction.Rec_Date'),
    this.translate.instant('transaction.Branch_Rec_No'),
    this.translate.instant('HOME.debit'),
    this.translate.instant('HOME.credit'),
    this.translate.instant('Table.amnt'), 
    this.translate.instant('Common.notes'),
   
  ];

  const rows = [];
  const headerHeight = 25;


  this.apiService.recieptReport(formdata.value, 1).subscribe((res: any) => {
    this.today_date=res.data.date2;
    if (res.data && res.data.data) {
      // this.Expdispnote = res.data.show_notes;
      res.data.data.forEach((data: any) => {
        const arr =[ data.rec_no, this.datePipe.transform(data.rec_date, 'dd/MM/yyyy'),data.branch_rec_no,data.debitor, data.creditor,this.currencyPipe.transform(data.recsub_amount, '', '', this.country_dec),data.rec_note];

      
        rows.push(arr);

      });
    }

    


    let doc = this.excelService.getPdfObj();

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text('RECIEPT SUMMERY', 85, headerHeight - 2);


  //
    doc.setFontSize(10);
    doc.text('Branch', lblXPos, headerHeight + 5);
    doc.setFontSize(11);
    doc.text(':  ' + this.branch_display_name+' ('+this.branch_display_cod+')',  valueXPos, headerHeight + 5);

    if (this.repDate) {
    doc.setFontSize(10);
    doc.text(`Period`, lblXPos, headerHeight + 10);
    doc.text(`:  ${this.repDate.date1} To ${this.repDate.date2}`,  valueXPos, headerHeight + 10);
  }else{
    doc.setFontSize(10);
    doc.text(`Period`, lblXPos, headerHeight + 10);
    doc.text(`:  ${this.branch_open_date} To ${this.today_date}`,  valueXPos, headerHeight + 10);

  }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 15);
    doc.text(':  ' + this.currencyPipe.transform(this.total_amount, '', '', this.country_dec),  valueXPos, headerHeight + 15);

    doc = this.excelService.addTableToPdf(doc, headerHeight + 27, lblXPos, heads, rows, this.logoImgUrl, this.branch_display_name);
    doc.save(`reciept_summery.pdf`);
    this.exportLoader = false;

  });
  // if(this.is_notes){
  //   heads.splice(5,0, this.translate.instant('Purchase.notes'))
  // }

}

getAllBranch() {
  this.apiService.readAllBranch().subscribe((res:any) => {
    this.branch_all = res.data;
    this.filter_branch_id = res.selected;

    // console.log(  this.branch_all );
  //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

searchBranch(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res:any) => {
    this.branch_all = res.data;
    // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}
}
