import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-rep-product-commision',
  templateUrl: './rep-product-commision.component.html',
  styleUrls: ['./rep-product-commision.component.css']
})
export class RepProductCommisionComponent implements OnInit {
  fileName = 'product_commision_report.xlsx';
  users: any;
  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  Report_type = [
    { id: 0, name: 'Summary' },
    { id: 1, name: 'Detailed' },

  ];
  prdcmsion: any;
  rpt_type: number;
  ttl_prdcmsion: any;
  reprt_type: any;
  salesPriviewLoader: boolean;
  resultobj: any;
  showdata: boolean;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  purchaseItems: any[];
  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: any = '';
  tempImgUrl: any;
  payType = ['Cash', 'Credit'];
  exportLoader: boolean;
  exp_reprt_type: any;
  exp_prdcmsion: any;
  exp_ttl_prdcmsion: any;
  branch_display_name: any;
  branch_display_code: any;

  payment_types = [
    { id: 2, name: 'Cash' },
    { id: 1, name: 'Credit' },

  ];

  discount_types = [
    { id: 1, name: 'With Discount' },
    { id: 2, name: 'Without Discount'},

  ];
  
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  repDate: any;
  priceGroupList: any;
  ttl_prdqty: any;
  exp_ttl_prdcqty: any;
  user_name: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.getTemplete();
    this.rpt_type=0;
    let searchval = new FormData();
    this.pageLoadingImg = true;

    this.apiService.productCommisionReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prdcmsion=res.data.data;
      this.ttl_prdcmsion=res.data.total_commision;
      this.ttl_prdqty=res.data.total_quantity;
      this.user_name=res.data.user;
      this.reprt_type=res.data.report_type;
      this.repDate=res.data.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.searchUser('');
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
  }


  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
   
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    // console.log("before : "+form.value.vch_id);
     


    }
    this.apiService.productCommisionReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prdcmsion=res.data.data;
      this.ttl_prdcmsion=res.data.total_commision;
      this.ttl_prdqty=res.data.total_quantity;
      this.user_name=res.data.user;
      this.reprt_type=res.data.report_type;
      this.repDate=res.data.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  searchUser(search: string)
  {
   let searchval = new FormData();      
   searchval.append("usr_name", search);    
   this.apiService.getUserByName(searchval).subscribe((res:any) => {
        this.users = res.data;     
      });
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }

  sale_search(search) {
    this.salesPriviewLoader = true;
  
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
    
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }

  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.export = 'export';
   

    this.apiService.productCommisionReport(formdata.value, 1).subscribe((res: any) => {
      this.exportLoader = false;
        
      this.exp_prdcmsion=res.data.data;
      this.exp_ttl_prdcmsion=res.data.total_commision;
      this.exp_ttl_prdcqty=res.data.total_quantity;
      this.exp_reprt_type=res.data.report_type;
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
      });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

}
