import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';
import { Chart } from 'chart.js';
import { ApiService } from '../../../service/api.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  userType: any;
  up: any;
  chart: any;
  chartData: any;
  PurchaseData: any;
  outstndgRec: any;
  productData: any;
  customers: any;
  totalSale = {
    today: 0,
    month: 0,
    total: 0,
  };

  totalPurchase = {
    today: 0,
    month: 0,
    total: 0,
  };
  totalStock = 0;
  totalCahInHand = 0;
  totalCahInacountant = 0;
  InputVat = 0;
  OutputVat = 0;
  reciptCount = 0;
  transferCount = 0;
  totalLowStock = 0;
  totalExcessStock = 0;
  totalReceipt = 0;
  totalPayment = 0;
  dashboard_type: any;
  admin_dashboard_type: any;
  settings: any;
  country_dec: string;
  totalqty: any;
  ttl_qty_disply: any;
  cmpny: any;
  admin_dashboard_data: any[];
  constructor(private coreService: CoreService, private apiService: ApiService, public router: Router, private translate: TranslateService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
    // this.userType = this.coreService.getUserData('user_type');
    this.userType = this.coreService.getUserData('user_type');
    this.cmpny = this.coreService.getUserData('cmpny');
    this.getDasboardSetting();
    this.SalesAnalysis();
    this.getOutstandingRecivable();
    this.PurchaseAnalysis();
    this.topProduct();
    this.topCustomers();
    this.topSuppliers();
    this.getTotalSale();
    this.getTotalQuantity();
    this.getTotalPurchase();
    this.getTotalStock();
    this.getTotalCashinHand();
    this.getTotalReceiptAndPayment();
    this.vat();
    this.getNotificationDash();
    if (this.userType != 'ADMIN') {
      this.getLowStock();
      this.getExcessStock();
    }
    // console.log(this.admin_dashboard_type);
    
    if(this.userType =='ADMIN'){
      this.getAdminDashboard();
    }


  }
  getAdminDashboard() {
    this.apiService.getAdminDashboard().subscribe((res) => {
      this.admin_dashboard_data = res['data']['data'];

      var ctx = document.getElementById("admin_chart") as HTMLCanvasElement;
      var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: res['data']['branches'],

          datasets: [
            {
              label: 'Sales',
              data: res['data']['s_data'],
              backgroundColor: 'rgb(0, 0, 0)',
            },
            {
              label: 'Purchase',
              data: res['data']['p_data'],
              backgroundColor: 'rgb(0, 255, 0)',
            },
            {
              label: 'Expenses',
              data: res['data']['e_data'],
              backgroundColor: 'rgb(180, 180, 180)',
            },
          ]
        },
      
        options: {
          title: {
            display: true,
            text: ''
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              ticks: {
                maxRotation: 90,
                minRotation: 80
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    });
  }
  getDasboardSetting() {

    this.apiService.getDasboardSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.dashboard_type = this.settings.ds_type;
        this.admin_dashboard_type = this.settings.ds_admin_type;
        this.ttl_qty_disply = this.settings.ds_total_qty_disply;
      } else {
        this.dashboard_type = 1;
        this.admin_dashboard_type = 1;
      }
    });

  }
  getTotalReceiptAndPayment() {
    this.apiService.getTotalReceiptAndPayment().subscribe((res) => {
      this.totalReceipt = res.data.Receipt;
      this.totalPayment = res.data.Payment;

    });
  }
  getTotalSale() {
    this.apiService.getTotalSale().subscribe((res) => {
      this.totalSale = res.data.sales;

    });
  }

  getTotalQuantity() {
    this.apiService.getTotalQuantity().subscribe((res) => {
      this.totalqty = res.data;
      console.log(this.totalqty);
      

    });
  }
  getTotalPurchase() {
    this.apiService.getTotalPurchase().subscribe((res) => {
      this.totalPurchase = res.data.purchase;

    });
  }

  getTotalStock() {
    this.apiService.getTotalStock().subscribe((res) => {
      this.totalStock = res.data.stock.total;

    });
  }

  getTotalCashinHand() {
    this.apiService.getTotalCashinHand().subscribe((res) => {
      this.totalCahInHand = res.data.cashInHand.total.cash_in_hand;
      this.totalCahInacountant= res.data.cashInHand.ledgertotal;

    });
  }



  SalesAnalysis() {

    this.apiService.saleAnalysis().subscribe((res) => {

      this.chartData = res.data;


      let weatherDates = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const ctx = document.getElementById("sale_analysis") as HTMLCanvasElement;
      this.chart = new Chart(ctx, {
        type: 'line',

        data: {

          labels: weatherDates,
          datasets: [
            {
              data: [this.chartData[1], this.chartData[2], this.chartData[3], this.chartData[4], this.chartData[5], this.chartData[6], this.chartData[7], this.chartData[8], this.chartData[9], this.chartData[10], this.chartData[11], this.chartData[12]],
              label: this.translate.instant('Sales.Sales_Amount'),
              borderColor: "#008000",
              fill: false
            }
          ]
        },
        options: {
          title: {
            display: true,
            text: this.translate.instant('Common.sales_analysis')
          },
          tooltips: {
            mode: 'index',

          },
          legend: {

            display: true,

          },
          scales: {
            xAxes: [{
              display: true

            }],


            yAxes: [{

              display: true,
              ticks: {
                suggestedMin: 50,
                suggestedMax: 100
              }
            }],
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          }
        },

      });
    });

  }



  PurchaseAnalysis() {

    this.apiService.purchaseAnalysis().subscribe((res) => {

      this.PurchaseData = res.data;


      let weatherDates = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const ctx = document.getElementById("purchase_analysis") as HTMLCanvasElement;
      this.chart = new Chart(ctx, {
        type: 'line',

        data: {

          labels: weatherDates,
          datasets: [
            {
              data: [this.PurchaseData[1], this.PurchaseData[2], this.PurchaseData[3], this.PurchaseData[4], this.PurchaseData[5], this.PurchaseData[6], this.PurchaseData[7], this.PurchaseData[8], this.PurchaseData[9], this.PurchaseData[10], this.PurchaseData[11], this.PurchaseData[12]],
              label: this.translate.instant('HOME.Purchase_Amount'),
              borderColor: "#800000",
              fill: false
            }
          ]
        },
        options: {
          title: {
            display: true,
            text: this.translate.instant('Common.purchase_analysis')
            
          },
          tooltips: {
            mode: 'index',

          },
          legend: {

            display: true,

          },
          scales: {
            xAxes: [{
              display: true

            }],


            yAxes: [{

              display: true,
              ticks: {
                suggestedMin: 50,
                suggestedMax: 100
              }
            }],
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          }
        },

      });
    });

  }




  getOutstandingRecivable() {
    this.apiService.aging().subscribe((res) => {
      this.outstndgRec = res.data.outstanding;

      var ctx = document.getElementById("outstanding_reciveble") as HTMLCanvasElement;
      var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ["No Due yet", "0 - 30 Days", "31 - 60 Days ", "61 - 90 Days", "91 - 120 Days", "120 +"],
          datasets: [{
            label: this.translate.instant('Common.outstanding_receivables'),
            data: this.outstndgRec,
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'

            ],
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'

            ],
            borderWidth: 1
          }]
        },
        options: {
          title: {
            display: true,
            text: this.translate.instant('Common.outstanding_receivables')
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              ticks: {
                maxRotation: 90,
                minRotation: 80
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });


    });
  }



  topCustomers() {
    this.apiService.getTopCustomers().subscribe((res) => {
      this.customers = res.data;

      var ctx = document.getElementById("top_customers") as HTMLCanvasElement;
      var myChart = new Chart(ctx, {
        type: 'horizontalBar',
        data: {
          labels: this.customers.map(({ customer }) => customer),
          datasets: [{
            label: this.translate.instant('Common.top_selling_customers'),
            data: this.customers.map(({ amount }) => parseFloat(amount.toFixed(3))),
            backgroundColor: [
              '#2dceae',
              '#2dceae',
              '#2dceae',
              '#2dceae',
              '#2dceae'

            ]
          }]
        },
        options: {

          title: {
            display: true,
            text: this.translate.instant('Common.top_selling_customers')
          },
          scales: {
            xAxes: [{
              ticks: {
                min: 60
              }
            }],
            yAxes: [{
              stacked: true
            }]
          }

        }
      });


    });
  }

  topSuppliers() {
    this.apiService.getTopSuppliers().subscribe((res) => {
      this.customers = res.data;

      var ctx = document.getElementById("top_suppliers") as HTMLCanvasElement;
      var myChart = new Chart(ctx, {
        type: 'horizontalBar',
        data: {
          labels: this.customers.map(({ customer }) => customer),
          datasets: [ {
            label: this.translate.instant('HOME.qty'),
            data: this.customers.map(({ qty }) => qty),
            backgroundColor: 'orange' // Color for qty
          },
          {
            label: this.translate.instant('Table.amnt'),
            data: this.customers.map(({ amount }) => parseFloat(amount.toFixed(3))),
            backgroundColor: '#2dceae' // Color for amount
          }]
        },
        options: {

          title: {
            display: true,
            text: this.translate.instant('Common.top_purchasing_supplrs')
          },
          scales: {
            xAxes: [{
              ticks: {
                min: 60
              }
            }],
            yAxes: [{
              stacked: true
            }]
          }

        }
      });


    });
  }




  topProduct() {

    this.apiService.topProduct().subscribe((res) => {

      this.productData = res.data;
      // console.log(this.productData.map(({ product }) => product));
      const ctx = document.getElementById("top_product") as HTMLCanvasElement;
      this.chart = new Chart(ctx, {
        type: 'doughnut',

        data: {

          labels: this.productData.map(({ product }) => product),
          datasets: [
            {
              data: this.productData.map(({ qty }) => qty),
              label: this.translate.instant('Common.top_selling_products'),
              backgroundColor: [
                "#2ecc71",
                "#3498db",
                "#95a5a6",
                "#ff6384",
                "#f1c40f",
                "#e74c3c",
                "#34495e"
              ],
            }
          ]
        },
        options: {
          title: {
            display: true,
            text: this.translate.instant('Common.top_selling_products')
          },
          tooltips: {
            mode: 'index',

          },
          legend: {

            display: true,

          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          }
        },

      });
    });

  }

  vat() {
    this.apiService.getVat().subscribe((res) => {

      this.InputVat = res.data.inputTax.toFixed(2);
      this.OutputVat = res.data.outputTax.toFixed(2);
      var lbl = "Vat Payable";
      if(this.OutputVat - this.InputVat < 0){
        lbl = "Vat Receivable";
      }



      const ctx1 = document.getElementById("vat_grp") as HTMLCanvasElement;
      this.chart = new Chart(ctx1, {
        type: 'doughnut',

        data: {

          labels: ["Input Vat", "Output Vat", lbl],
          datasets: [
            {
              data: [this.InputVat,this.OutputVat,this.OutputVat - this.InputVat],
              label: this.translate.instant('Common.vat'),
              backgroundColor: [
                "#ff6384",
                "#f1c40f",
                "#e74c3c",
              ],
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.translate.instant('Common.vat')
          },
          tooltips: {
            mode: 'index',

          },
          legend: {

            display: true,

          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          }
        },

      });
    });
  }

  getNotificationDash() {
    this.apiService.getDashNotification().subscribe((res) => {

      this.reciptCount = res.data.recipt;
      this.transferCount = res.data.transfer;

    });
  }

  getLowStock() {
    this.apiService.getLowStock().subscribe((res) => {
      this.totalLowStock = res.data;

    });
  }

  getExcessStock() {
    this.apiService.getExcessStock().subscribe((res) => {
      this.totalExcessStock = res.data;

    });
  }

  redirectTO(path){
    this.router.navigate(['/' + path]);
  }
}