import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-weight-scale-items',
  templateUrl: './weight-scale-items.component.html',
  styleUrls: ['./weight-scale-items.component.css']
})
export class WeightScaleItemsComponent implements OnInit {

  fileName = 'weight_scale_items.xlsx';

  search: any;
  prdCat: any[];
  categories: any;
  cat_id: any;
  prd_id: string;
  pageLoadingImg: boolean;
  pgend: boolean;
  pgstart: boolean;
  slnum: number;
  prdlist: any;
  curpage: number;
  lastpage: number;
  prd_ean: string;
  barcode: string;
  total_prds: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  ExptotalProducts: any;
  Expprdlist: any;
  from: any;
  subcatagories: any;
  changeBox: any;
  sub_cat_id: any;


  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    const searchval = new FormData();
    this.apiService.weightScaleItems(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prdlist = res['data'];
      this.curpage = Number(res.current_page);
      this.lastpage = Number(res.last_page);
      this.total_prds = res.total;
      this.from = res['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
  }
  prdUntDetail(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.weightScaleItems(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prdlist = res['data'];
      this.curpage = Number(res.current_page);
      this.lastpage = Number(res.last_page);
      this.total_prds = res.total;
      this.from = res['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  selectProd(name: string) {
    if (this.prd_id) {
      this.barcode = '';
      this.prd_ean = '';
    }
    let searchval = new FormData();
    searchval.append("prod_name", name);
    searchval.append("flag[]", "1");
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.search = res['data'];
    });
  }
  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.categories = res['data'];
    });
  }
  searchSubcat(search: string) {
    let searchval = new FormData();
    searchval.append("subcat_name", search);
    this.apiService.getSubcatByName(searchval).subscribe((res) => {
      this.subcatagories = res['data'];
    });
    $('.prev-list').show();
  }
  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res) => {
      this.subcatagories = res['data'];
    });
  }
  listPrduct(cat_id) {
    if (this.cat_id) {
      this.barcode = '';
      this.prd_ean = '';
    }
    this.sub_cat_id = null;
    this.apiService.getPrdByCatId(cat_id).subscribe((res) => {
      this.search = res['data'];

      this.selectSubCats(cat_id);
    });
  }
  selectedprd(prdId: number) {
    this.sub_cat_id = null;
    this.cat_id = null;
    this.apiService.getCatByPrdId(prdId).subscribe((res) => {
      this.categories = res['data'];
    });
  }
  pgEnd(curr, end) {
    if (curr == end || end==0){
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }
  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }
  exportexcel(): void {
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;
  }

  exportEXCL(Expformdata: { value: any; }) {
    this.exportLoader = true;
    Expformdata.value.export = 'export';
    this.apiService.weightScaleItems(Expformdata.value, 1).subscribe((res: any) => {
      this.Expprdlist = res['data'];
      this.ExptotalProducts = res.total;
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
      Expformdata.value.export = '';
    });
  }
}
