import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
@Component({
  selector: 'app-deductions',
  templateUrl: './deductions.component.html',
  styleUrls: ['./deductions.component.css']
})
export class DeductionsComponent implements OnInit {

  ded_name: any;
  allded: any;
  pageLoadingImg: boolean;

  selectedData: any = { ded_id: null, error: null, ded_name: null, message: null };
  result = '';
  deductions: string[];
  up: any;
  ded_id: any;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.getDeductions();
    this.coreService.getToken();
    this.up = this.coreService.getUserPrivilage();
  }
  getDeductions() {
    let searchval = new FormData();
    searchval.append("ded_name", '');
    this.apiService.getDedByName(searchval).subscribe((res) => {
      this.deductions = res['data'];
    });
  }
  activate(id) {
    this.apiService.changeDedStatus(id).subscribe((res) => {
      this.coreService.showMessage(res.message);
    });
  }

  setValues(ded_id, vanline_name,) {
    this.ded_id = ded_id;
    this.ded_name = vanline_name;
  }
 
  searchDed(search: string) {
    let searchval = new FormData();
    searchval.append("ded_name", search);
    this.apiService.getDedByName(searchval).subscribe((res) => {
      this.deductions = res['data'];
    });
    $('.prev-list').show();
  }
  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("ded_name", search);
    this.apiService.getDedByName(searchval).subscribe((res) => {
      this.deductions = res['data'];
    });
    $('.prev-list').hide();
  }

  //Remove Name Suggestions

  removeName() {
    this.deductions = [];
    $('.prev-list').hide();
  }

  selectCat(ded: any) {
    if (ded) {
      this.result = '';
      $("#editdedf").show();
      this.selectedData = ded;
    }
    this.deductions = [];
  }

  tabClick(tab: { index: number; }) {
    if (tab.index == 1)
      this.result = '';
  }
  addDed(formgroup: { value: any; }) {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.apiService.createDed(formgroup.value).subscribe((ded) => {
      this.pageLoadingImg = false;

      if (ded.error != null) {
        this.result = ded.error;
        console.log(this.result);
      }
      else {
        this.ded_name = '';
        document.getElementById('closeAddMdl').click();
        this.getDeductions();
        this.coreService.createfunct(searchval, this.result, ded.message);
      }

    });
  }
  updateDed(formgroup: { value: any; }) {
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.apiService.updateDed(formgroup.value).subscribe((ded) => {
      this.pageLoadingImg = false;

      if (ded.error != null) {
        this.result = ded.error;
      }
      else {
        this.ded_name = '';
        document.getElementById('closeEditMdl').click();
        this.getDeductions();
        this.coreService.updatefunct(searchval, this.result, ded.message);
      }

    });
  }

}
