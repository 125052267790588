import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-rep-top-suppliers',
  templateUrl: './rep-top-suppliers.component.html',
  styleUrls: ['./rep-top-suppliers.component.css']
})
export class RepTopSuppliersComponent implements OnInit {

  filterDate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  country_dec: string;
  supplierAll: any;
  pageLoadingImg: boolean;
  isnoData: boolean;
  datas: any;
  curpage: any;
  lastpage: any;
  repDate: any;
  pgend: boolean;
  pgstart: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.readIncre(1);
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filterDate = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  readIncre(pageno)
  {
    this.pageLoadingImg = true;
    this.isnoData = false;
    let searchval = new FormData();
    this.apiService.topSupplier(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.repDate = res['data']['Date'];

      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    formdata.value.filter = 1;   
    this.pageLoadingImg = true;
    this.isnoData = false;
    this.apiService.topSupplier(formdata.value, pageno).subscribe((res: any) => {      
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.repDate = res['data']['Date'];

      this.pageLoadingImg = false;
      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
 // form search supplier
 searchSupplier(search: string) {
  const searchval = new FormData();
  searchval.append('supp_name', search);
  this.apiService.searchSupplier(searchval).subscribe((res) => {
    this.supplierAll = res.data;
    // console.log(this.supplierAll);
  });
}

}
