import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-customersales',
  templateUrl: './customersales.component.html',
  styleUrls: ['./customersales.component.css']
})
export class CustomersalesComponent implements OnInit {
  fileName= 'sold_to_customer.xlsx';
  searchval:any;
  pageLoadingImg:boolean;
  salesSum:any;
  pageFrom:any;
  curpage:any;
  lastpage:any;
  pgend:any;
  pgstart:any;
  repDate:any;
  period_type:any;
  date1:any;
  date2:any;
  inv_filter:any;
  inv_val1:any;
  inv_val2:any;
  vat_filter:any;
  vat_val1:any;
  vat_val2:any;
  gd_id:any;
  mfs: any;prd_manufact_id:any;

  // purch_qty_filter:any;
  // purch_qty:any;
  // purch_qty2:any;
  // barcode_filter:any;
  // bar_val1:any;
  // bar_val2:any;
  prd_id:any;
  cat_id:any;
  added_by:any;
  products:any;catagories:any;
  users:any;


  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  summary: any;
  godowns: any;

  rept_type = [
    { id: 1, name: 'Sold & Returns' },
    { id: 2, name: 'Sold' },
    { id: 3, name: 'Returns' },
  ];

  disp_type = [
    { id: 1, name: 'Date & Inv no wise' },
    { id: 2, name: 'Product Name & Quantity Wise' },
  ];
  rep_type: number;
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  subcatagories: any;
  up: any;
  customer: any;
  disp_mod: number;
  country_dec: string;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  expsalesSum: any;
  expsummary: any;
  cur_lang: string;
  SoldProdToCust: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.rep_type = 2;
    this.up = this.coreService.getUserPrivilage();
  
    this.disp_mod = 1;
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append('rep_type','2');
    this.salesSum = [];

    this.apiService.salesCustomerReport(searchval, 1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.salesSum = res['data']['data'];
      this.summary = res['data'];

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.searchUser('');
    this.getGodownList();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.sold_rtn','Common.sold','Van.Return','Sales.date_inv_no_wise','Sales.product_name_quantity_wise']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.rept_type = [
        { id: 1, name: res['Common.sold_rtn'] },
        { id: 2, name: res['Common.sold'] },
        { id: 3, name: res['Van.Return'] },
      ];
    
      this.disp_type = [
        { id: 1, name: res['Sales.date_inv_no_wise'] },
        { id: 2, name: res['Sales.product_name_quantity_wise'] },
      ];
    });

  }
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }

    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }


  ngOnDestroy(){
    $('.close').click();
 
  }


  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.salesSum = [];

    this.apiService.salesCustomerReport(formdata.value, pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.salesSum = res['data']['data'];
      if(0){ 
        this.apiService.getsaleReturnTotal(formdata.value).subscribe((tot) => { // to get summary of report
        this.summary = tot['data'];
        });
      }else{
        this.summary = res['data'];

      }
      this.pageFrom = res['data']['from'];
      this.curpage =  res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
  
  
  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      this.subcatagories.push({ "subcat_id": 57, 'subcat_name': 'NONE' });
    });
  }

  searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.users = res['data'];                  
        });
 }

 getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
         });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    // this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    
    formdata.value.export = 'export';
    this.apiService.salesCustomerReport(formdata.value, 1).subscribe((res) => {
      
      this.repDate = res.data.Date;
      this.expsalesSum = res['data']['data'];
      if(0){ 
        this.apiService.getsaleReturnTotal(formdata.value).subscribe((tot) => { // to get summary of report
        this.expsummary = tot['data'];
        });
      }else{
        this.expsummary = res['data'];

      }
   

      setTimeout(() => {
        this.exportexcel();
      }, 3000);
    });

  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
    
     this.exportLoader = false
     let element = document.getElementById('export-group');
    
      
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
    // this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    
    formdata.value.export = 'export';
    this.apiService.salesCustomerReport(formdata.value, 1).subscribe((res) => {
      
      this.repDate = res.data.Date;
      this.expsalesSum = res['data']['data'];
      this.SoldProdToCust = res.data;
      console.log(this.SoldProdToCust);

      if(0){ 
        this.apiService.getsaleReturnTotal(formdata.value).subscribe((tot) => { // to get summary of report
        this.expsummary = tot['data'];
        });

      }else{
        this.expsummary = res['data'];

      }

      const heads = [
        this.translate.instant('Common.prd_name'),
        this.translate.instant('Table.barcode'),
        this.translate.instant('Table.rate'),
        this.translate.instant('Purchase.qty')
      ];

      if(this.disp_mod==1) {
        heads.splice(2,0, this.translate.instant('Sales.inv_no'))
      }

      if(this.disp_mod==1) {
        heads.splice(3,0, this.translate.instant('transaction.Branch_Inv_No'))
      }

      if(this.disp_mod==1) {
        heads.splice(4,0, this.translate.instant('Table.date'))
      }

      if(this.rep_type==1 || this.rep_type==2) {
        heads.splice(7,0, this.translate.instant('Sales.Sales_Amt'))
      }

      if(this.rep_type==3 || this.rep_type==1) {
        heads.splice(8,0, this.translate.instant('Purchase.Return_Amount'))
      }
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.SoldProdToCust.data.forEach(item=>{
          item.items.forEach(item1=>{

            const arr = [
              item1.prd_name,
              item1.prd_barcode,
              item1.salesub_qty
          ];

            if(this.disp_mod==1){
              arr.splice(2,0, item1.inv_no)
              arr.splice(3,0, item1.branch_inv_no)
              arr.splice(4,0, item1.salesub_date)
              arr.splice(5,0, this.currencyPipe.transform(item1.salesub_rate, '', '', this.country_dec))
            }

            if(this.disp_mod==2){
              arr.splice(2,0, this.currencyPipe.transform(item1.sale_avg_amount, '', '', this.country_dec))
            }

            if(this.rep_type==1||this.rep_type==2){
              arr.splice(7,0, this.currencyPipe.transform(item1.sale_amount, '', '', this.country_dec))
            }

            if(this.rep_type==3||this.rep_type==1){
              arr.splice(8,0, this.currencyPipe.transform(item1.return_amnt, '', '', this.country_dec))
            }

            data.push(arr);
        });
        });
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Sales.sold_product_to_customer')}`,  valueXPos, headerHeight + 15);

        if(this.SoldProdToCust.custname){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.customer_name')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.SoldProdToCust.custname,  valueXPos, headerHeight + 20);
        }

        if(this.SoldProdToCust.reportType){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.rpt_type')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.SoldProdToCust.reportType,  valueXPos, headerHeight + 25);
        }

        if(this.disp_mod==1){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('ACCOUNTS.Display_Type')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Sales.date_inv_no_wise')}`,  valueXPos, headerHeight + 30);
        }

        if(this.disp_mod==2){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('ACCOUNTS.Display_Type')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Sales.product_name_quantity_wise')}`,  valueXPos, headerHeight + 30);
        }

        if((this.rep_type==1 || this.rep_type==2) && this.SoldProdToCust.tot_inv_amt){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.total_sold_amount')}`, lblXPos, headerHeight + 35);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.SoldProdToCust.tot_inv_amt, '', '', this.country_dec),  valueXPos, headerHeight + 35);
        }

        if((this.rep_type==1 || this.rep_type==3) && this.SoldProdToCust.tot_return_amnt){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Returned_Amount')}`, lblXPos, headerHeight + 40);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.SoldProdToCust.tot_return_amnt, '', '', this.country_dec),  valueXPos, headerHeight + 40);
        }

        if((this.rep_type==1) && this.SoldProdToCust.tot_inv_amt){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Purchase.net_amount')}`, lblXPos, headerHeight + 45);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.SoldProdToCust.tot_inv_amt - (this.SoldProdToCust.tot_return_amnt), '', '', this.country_dec),  valueXPos, headerHeight + 45);
        }

        if((this.rep_type==1 || this.rep_type==2) && this.SoldProdToCust.tot_sale_qty){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.total_sold_qty')}`, lblXPos, headerHeight + 50);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.SoldProdToCust.tot_sale_qty, '', '', this.country_dec),  valueXPos, headerHeight + 50);
        }

        if((this.rep_type==1 || this.rep_type==3) && this.SoldProdToCust.tot_ret_qty){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.total_returned_qty')}`, lblXPos, headerHeight + 55);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.SoldProdToCust.tot_ret_qty, '', '', this.country_dec),  valueXPos, headerHeight + 55);
        }

        if((this.rep_type==1) && this.SoldProdToCust.tot_net_qty){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Table.net_qty')}`, lblXPos, headerHeight + 60);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.SoldProdToCust.tot_sale_qty - this.SoldProdToCust.tot_ret_qty, '', '', this.country_dec),  valueXPos, headerHeight + 60);
        }
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 65, lblXPos, heads, data, null);
    
       
        doc.save(`Sold Product To Customer.pdf`);

        this.exportLoader = false;
   
    });
    
  }

}
