import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';



@Component({
  selector: 'app-stock-dash',
  templateUrl: './stock-dash.component.html',
  styleUrls: ['./stock-dash.component.css']
})
export class StockDashComponent implements OnInit {
  up: any;
  userType: any;
  cmpny: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.cmpny = this.coreService.getUserData('cmpny');
  }

}
