import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-godown-transfer',
  templateUrl: './godown-transfer.component.html',
  styleUrls: ['./godown-transfer.component.css']
})
export class GodownTransferComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
