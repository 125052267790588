import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  fileName = 'customer_list.xlsx';
  Expsumms: any;
  srchVal: string = "";
  exportLoader: boolean;
  storage = {
    '0': 'No',
    '1': 'Yes'
   
  };
  customer = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    cr_no: '',
    op_bal: 0,
    brch_id:0,
    usr_type:0,

    op_bal_type: true,
    inv_limit_type: false,
    storage_eqp: false,
    inv_limit: null,
    default_currency_id:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: '',
    credit_limit: 0,
    default_sales_agent:0,
    default_cus_category:0,
    enable_sale_below_mrp:0
  };
  isSameAddr = false;
  resultobj: any = {};

  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 100;
  totalItem = 0;
  curForm = 'Create';
  vanLineList: any =  [];
  priceGroupList: any =  [];
  up: any;
  delete_status: any;
  delete_ledger_id: any;
  is_sup: any;
  userType: any;
  branch_all: any;
  last_cust_code : any = '';
  sales_agents: any;
  customer_category :any;
  sale_agent: any;
  enable_disable_sales_below_mrp = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  country_dec: string;
  sales_settings: any;
  inv_limit: any;
  equipment = [
    { id: 0, name: 'No' },
    { id: 1, name: 'yes' },


  ];

  cr_limit = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Greater Than 0' },
  ];

  due_limits = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Greater Than 0' },
  ];
  van_lines: any;
  currencies: any;
  cmpny: any;
  agents: any;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.is_sup =0;
    this.getInfo(this.curpage);
    this.getVanLine();
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.searchBranch('');
    this.getAllAgents();
    this.getSalesSetting();
    this.getAllCustomerCategory();
    this.getAllVanlines();
    this.apiService.listAllCurrency().subscribe((res) => {
      this.currencies = res.data;
    });
  }
  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }

  getAllCustomerCategory()
  {
    this.apiService.listCustCategory().subscribe((res) => {
      this.customer_category = res['data'];
    });
  }

  searchAgent(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.agents = res['data'];
    });
  }

  searchCustCategory(search: string) {
    const searchval = new FormData();

    console.log("key");
    console.log(search);
     searchval.append('cat_name', search);
    this.apiService.searchCustCategory(searchval).subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }

  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }
  getSingleItem(id) {
    this.clearForm();
    this.customer.id = id;
    this.isSubmitInProg = true;
    this.curForm = 'Update';
    this.apiService.getCustomer(id).subscribe((res) => {
      this.isSubmitInProg = false;
      this.is_sup = res.data.is_sup;
      const data = res.data;

      // console.log("aaaaaaa");
      // console.log(data.cust_category_id);

      
      // console.log(data);
      this.customer = {
        id: data.cust_id,
        ledger_id: data.ledger_id,
        name: data.name,
        alias: data.alias,
        code: data.cust_code,
        email : data.email,
        mobile: data.mobile,
        vat_no: data.vat_no,
        cr_no: data.cr_no,
        op_bal: Math.abs(data.opening_balance),
        op_bal_type: (data.opening_balance < 0) ? true : false,
        inv_limit_type:  (data.inv_due_limit_no)?true:false,
        storage_eqp:  (data.is_storage_eqp)?true:false,
        inv_limit:(data.inv_due_limit_no)?data.inv_due_limit_no:'',
        default_currency_id:data.default_currency_id,
        brch_id:Number(data.branch_id),
        usr_type:0,
        add: {
          addr: data.cust_home_addr,
          zip: data.zip,
          city: data.city,
          country: data.country,
          state: data.state,
          state_code: data.state_code,
          fax: data.fax
        },
        del: {
          addr: data.dflt_delvry_addr,
          zip: data.dflt_delvry_zip,
          city: data.dflt_delvry_city,
          country: data.dflt_delvry_country,
          state: data.dflt_delvry_state,
          state_code: data.dflt_delvry_state_code,
          fax: data.dflt_delvry_fax
        },
        due_day: data.due_days,
        van_line: data.van_line_id,
        price_group: data.price_group_id,
        note: data.note,
        credit_limit: data.cust_credit_limit,
        default_sales_agent:data.default_sales_agent,
        default_cus_category:data.cust_category_id,
        enable_sale_below_mrp:data.enable_sale_below_mrp,
      };
      
    });
    // console.log(id);
    let searchval = new FormData();

    searchval.append("cust_id", id);
    this.apiService.isCustomerDeletable(searchval).subscribe((res) => {
      this.delete_ledger_id = res['ledger_id'];
      // console.log(this.delete_ledger_id);
    });


  }

  validateAndSubmit(){
    this.isSubmitInProg = true;
    let callFunction = '';
    if (this.curForm === 'Create') {
      callFunction = 'createCustomer';
    }
    if (this.curForm === 'Update') {
      callFunction = 'updateCustomer';
    }
if(this.userType =='ADMIN'){
  this.customer.usr_type = 1;
}else{
  this.customer.usr_type = 0;

}

    this.apiService[callFunction](this.customer).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        if (this.curForm === 'Create') {
          this.last_cust_code = res.last_cust_code;
        }
        this.closeModal.nativeElement.click();
        this.getInfo(this.curpage);
        this.coreService.showMessage(res.message);
        this.clearForm();
        this.resultobj = {};
      }

    });
  }
  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getCustomers(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.last_cust_code = res.last_cust_code;
    });
  }
  getVanLine() {
    this.apiService.getAllVanlines().subscribe((res) => {
      this.vanLineList = res.data;
    });
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
  }
  searchVanLine(search: string) {
    const searchval = new FormData();
    searchval.append('vanline_name', search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      // console.log(res);
      this.vanLineList = res.data;
    });
  }
  updateAddress() {
    if (this.isSameAddr) {
      this.customer.del = JSON.parse(JSON.stringify(this.customer.add));
    } else {
      // this.customer.del = {
      //   addr: '',
      //   zip: '',
      //   city: '',
      //   country: '',
      //   state: '',
      //   state_code: '',
      //   fax: ''
      // }
    }
  }

  deleteCustomer(ledger_id, formgroup: { value: any; }) {
    this.isSubmitInProg = true;
    let searchval = new FormData();
    searchval.append("ledger_id", ledger_id);
    this.apiService.DeleteCustomer(searchval).subscribe((res) => {

      this.isSubmitInProg = false;

        
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.closeModal.nativeElement.click();

        this.coreService.showMessage(res.message);
        this.resultobj = {};
    
        this.getInfo(this.curpage);
        this.coreService.showMessage(res.message);
        this.clearForm();
       // $("#closepopup").click();
      


      }




      
    });
  }

  deleteLedger(ledger_id, formgroup: { value: any; }) {
    this.isSubmitInProg = true;
    let searchval = new FormData();
    searchval.append("ledger_id", ledger_id);
    this.apiService.DeleteLedger(searchval).subscribe((res) => {

      this.isSubmitInProg = false;

        
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.closeModal.nativeElement.click();

        this.coreService.showMessage(res.message);
        this.resultobj = {};
    
        this.getInfo(this.curpage);
        this.coreService.showMessage(res.message);
        this.clearForm();
       // $("#closepopup").click();
      


      }




      
    });
  }


  addAsSupplier(customer_id, formgroup: { value: any; }) {
    this.isSubmitInProg = true;
    let searchval = new FormData();
    searchval.append("customer_id", customer_id);
    this.apiService.addAsSupplier(searchval).subscribe((res) => {

      this.isSubmitInProg = false;

        
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.closeModal.nativeElement.click();

        this.coreService.showMessage(res.message);
        this.resultobj = {};
    

        this.clearForm();

      } 
    });
  }

  clearForm() {
    this.curForm = 'Create';
    this.customer = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email : '',
      mobile: '',
      vat_no: '',
      cr_no: '',
      op_bal: 0,
      brch_id:0,
    usr_type:0,

      op_bal_type: true,
      inv_limit_type: false,
      storage_eqp: false,
      inv_limit: null,
      default_currency_id:0,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: '',
      credit_limit: 0,
      default_sales_agent:0,
      default_cus_category:0,
      enable_sale_below_mrp : 0
    };
  }
  searchInp(e) {
    const searchval = new FormData();
    searchval.append('search', e);
    this.apiService.searchCustomerList(searchval).subscribe((res) => {
      this.info = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
     });
  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  export() {
    this.exportLoader = true;
    const fdata = new FormData();
    fdata.append('export', 'export');
    fdata.append('search', this.srchVal);
    
    // debugger;
    this.apiService.searchCustomerList(fdata).subscribe((res) => {
      this.pageLoadingImg = false;
      this.Expsumms = res['data'].data;
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
    });
  }

  langChange(){
    this.translate.get(['Common.Disable', 'Common.Enable']).subscribe((res: string) => {    
     
      
      this.enable_disable_sales_below_mrp = [
        { id: 0, name:  res['Common.Disable'] },
        { id: 1, name:  res['Common.Enable'] },
        
      ];
    });

  }
  getAllVanlines() {
    this.apiService.getAllVanlines().subscribe((res) => {
      this.van_lines = res['data'];

    });
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
       
        this.inv_limit=this.sales_settings.inv_due_limit;
      } else {
       
      }
      // this.updatePayment();
    });

  }
  searchCust(formdata: { value: any;},pageno: any){
    this.pageLoadingImg = true;
    this.apiService.filterCustomer(formdata.value,pageno).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.last_cust_code = res.last_cust_code;
    });
  }
}
