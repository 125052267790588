import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { CoreService } from '../../service/core.service';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.css']
})
export class LedgerComponent implements OnInit {
  up: any;
 

  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();

    this.coreService.getToken(); 
  }

}
