import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { Catagory } from '../../../../../model/catagory.model';
import { Feature } from '../../../../../model/feature.model';


@Component({
  selector: 'app-editlog',
  templateUrl: './editlog.component.html',
  styleUrls: ['./editlog.component.css']
})
export class EditlogComponent implements OnInit {
  prd_sub_cat_id: any ;
  elseBlocknostart: any ;
  elseBlocknoend: any ;
  manufacture_id: any ;
  unitid: any ;
  feat_id: any ;
  feat_val: any ;
  vat_type: any ;
  vat_val_1: any ;
  vat_val_2: any ;
  selectFeat: any ;
  allfeat: any ;
  selectType: any ;
  id: any ;
  prd_ean:any;
  products:any;
  products_all: string[];
  catagories: string[];
  subcatagories: string[];
  mfs: string[];
  cat_id: number;
  prodsumms: string[];
  unit_all: string[];
  features: string[];
  baseunit: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  pageLoadingImg:boolean;
  sub_cat_id: any;
  product_summary:any;
  pageFrom:any;
  totalProducts:any;
  totalCat:any;
  prd_id:any;
  up: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }


  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.apiService.edilogSumPage(searchval, 1).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data']['data'];

      this.totalCat   = res['data']['total_categories'];
      this.totalProducts = res['data']['total_products'];

      
      

      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  ngAfterContentInit(){

    this.searchCat('');
    this.reeadFeat();
    this.searchManf('');
    this.searchUnit('');
    

  }

  ngOnDestroy(){
    $('.close').click();
 
  }


  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }
// search product
selectProd(name: string) {

  const searchval = new FormData();
  searchval.append('prod_name', name);
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.products = res.data;
  });
}
  
  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  //Remove Name Suggestions

  removeName() {
    this.catagories = [];
    this.reeadFeat();
  }


  selectCat(cat_id: number) {
    this.cat_id = cat_id;

  }



  pageNext(formdata: { value: any; }, pageno: any) {
   $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    this.apiService.edilogSumPage(formdata.value, pageno).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.totalProducts = res['data']['total_products'];
      this.totalCat = res['data']['total_categories'];

      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  //form search unit
  searchUnit(search: string) {
    let searchval = new FormData();
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];

    });

  }

  // vat value
  // unit types
  vat_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  reeadFeat() {
    this.apiService.readFeat().subscribe((res: Feature[]) => {
      this.features = res['data'];

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


}

