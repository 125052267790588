import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { Godown} from '../../../../model/godown.model';

@Component({
  selector: 'app-godown-manage',
  templateUrl: './godown-manage.component.html',
  styleUrls: ['./godown-manage.component.css']
})
export class GodownManageComponent implements OnInit {
  gd_name: any ;
  gd_cntct_person: any ;
  gd_cntct_num: any ;
  gd_code: any ;
  gd_address: any ;
  gd_desc: any ;
  gdnsel: any ;
  selecTed: any = { gd_id: null, error: null, gd_name: null,message:null,gd_code:null};
  resultobj: any = {};
  godown_all:[];
  pageLoadingImg:boolean;
  up: any;


  constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.searchForm('');
  }

  createGodown(formgroup: { value: any; }) { 
    this.pageLoadingImg = true;   
    this.apiService.createGodown(formgroup.value,).subscribe((res: Godown) => {
    this.pageLoadingImg = false;   

      if (res.error != null) {
       this.resultobj = res.error;     
        this.resultobj = this.coreService.getValidation(this.resultobj);     
      }
      else {
        this.resultobj={};
        this.coreService.createfunct(formgroup,this.resultobj,res.message);          
      }     
  
    });
  }

  updateGodown(formgroup: { value: any; }) {    
    this.pageLoadingImg = true;   

    this.apiService.updateGodown(formgroup.value,).subscribe((res: Godown) => {  
    this.pageLoadingImg = false;   

      if (res.error != null) {                  
        this.resultobj = res.error;     
        this.resultobj = this.coreService.getValidation(this.resultobj);     
      }
      else {
        this.resultobj={};
        this.coreService.updatefunct(formgroup,this.resultobj,res.message);  
        this.godown_all=[];  
        $('#editgdf').hide();     
      }     
  
    });
  }


    //form search
searchGodown(search: string)
{
 let searchval = new FormData();      
      searchval.append("gd_name", search);    
       this.apiService.getGodownByName(searchval).subscribe((res) => {
        this.godown_all = res['data'];             
       });
       $('.prev-list').show(); 
}

searchForm(search: string)
{
 let searchval = new FormData();      
      searchval.append("gd_name", search);    
       this.apiService.getGodownByName(searchval).subscribe((res) => {
        this.godown_all = res['data'];             
       });
       $('.prev-list').hide(); 
}

removeName()
{
  this.godown_all = [];
  $('.prev-list').hide(); 
}

//form search select
selectGodown(res:Godown)
{
  if(res){
  this.selecTed = res;
  $('#editgdf').show();
  }
} 

tabClick(tab: any) {    
  this.resultobj = {}; 
}


}
