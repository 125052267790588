import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-purchase-return-void',
  templateUrl: './purchase-return-void.component.html',
  styleUrls: ['./purchase-return-void.component.css']
})
export class PurchaseReturnVoidComponent implements OnInit {
  resultobj: any = {};
  purchData: any = {};
  purchDataItems: [];
  netPrice: any;
  netAmount: any;
  netTax: any;
  totalAmount: any;
  visibility = true;
  purch_amount: any;
  pageLoadingImg:boolean;
  up: any;
  country_dec: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private datePipe: DatePipe) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();

    this.country_dec=this.coreService.setDecimalLength();
  }
  // form search
  purchase_search(search: string) {
    const searchval = new FormData();
    searchval.append('purchret_id', search);
    this.apiService.getpurchaseRetDetails(searchval).subscribe((res) => {     
      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
        this.visibility = true;
        $('#pret-void-table').hide();
      } else {
        // console.log(res['data'][0]);
        this.resultobj = {};
        if (res['data'][0]) {
          $('#pret-void-table').show();
          this.visibility = true;
          this.purchData = res['data'][0];
          this.purchData.purchret_date = this.datePipe.transform(this.purchData.purchret_date,"dd-MM-yyyy");  
          this.purchDataItems = res['data'][0]['items'];
          this.netPrice = 0;
          this.netAmount = 0;
          this.netTax = 0;
          this.totalAmount = 0;
          for (var j = 0; j < this.purchDataItems.length; j++) {
            this.netPrice = this.netPrice + Number(this.purchDataItems[j]['purchase_amount']);
            // this.netTax = this.netTax + (Number(this.purchDataItems[j]['purchretsub_tax']) * this.purchDataItems[j]['purchretsub_qty']);
          }
          this.netTax = this.purchData.purchret_tax
          this.totalAmount = Number(this.netPrice) + Number(this.netTax)-Number(this.purchData.purchret_discount);
        } else {
          $('#pret-void-table').hide();        
          this.visibility = false;
          this.purchData = [];

        }
      }
    });
    this.apiService.updatebatchExNotification();
  }

  voidPurchaseRet(purchId: any) {

    var voidstk = confirm("Are you sure you want to Void this purchase  return ? ");
    if (voidstk) {
    this.pageLoadingImg = true;
      
      // console.log(purchId);
      const searchval = new FormData();
      searchval.append('purchret_id', purchId);
      this.apiService.voidPurchaseRet(searchval).subscribe((res) => {
        this.pageLoadingImg = false;

        this.coreService.showMessage('Purchase Return Void Success');
        this.purchData.items = [];
        $('#pret-void-table').hide();
      });
      this.apiService.updatebatchExNotification();
    }
  }

}
