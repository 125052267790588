import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductionService } from '../../../../../service/production.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-sales-ret-without-inv',
  templateUrl: './sales-ret-without-inv.component.html',
  styleUrls: ['./sales-ret-without-inv.component.css']
})

export class SalesRetWithoutInvComponent implements OnInit {
  @ViewChild('inputRate', { static: false }) inputRate: ElementRef;
  @ViewChild('inputBrCode', { static: false }) inputBrCode: ElementRef;
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  type_id: any;
  ret_godown_id: any;
  gd_id: any;
  cust_id: any;
  total_amount: any;
  base_unit_code: any;
  purchase_rate: any;
  total_vat = 0;
  net_amount = 0;
  sub_qty: any;
  total_qty: any;
  errObjArr: any;
  vantran_purch_price: any;
  printError: any;
  display_qty: any;
  vantransub_qty: any;
  box: any;
  rate: any;
  vat: any;
  unit_qty: any;
  sl_no: any;
  vantran_purch_amount: any;
  item_amount: any;

  selectedItems: any;
  rtnId: any;
  ret_date = new Date();
  step3 = false;
  step2 = false;
  step1 = false;
  searchExisting = true;
  calculationDisplay = true;
  netcost: any;
  prodform_id: any;
  ttlCommAmount: any;
  output_qty: number;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};
  formula_all: any;
  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};

  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};

  prodFormula = {
    // ret_date: new Date(),
    productnId: 0,
    productnDate: 0,
    formula_id: '',
    formula_name: '',
    productOut: [],
    productIngd: [],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    ttlPurchase: 0,
    miscExp: 0,
  };
  formula_list = [];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 5;
  totalItem = 0;
  godown_all = [];
  curForm = 'Create';
  // payment_types = [];
  // customer_types = [];
  searchStkBatch: any;
  ledger:any;

  salestatus = 1;

  base_rate: any;
  // sale_price: any;

  prd_barcode: any;
  prd_units: any;
  return_qty: any;
  default_unit: any;
  nonAccount: any;
  rec = {
    rec_no: '',
    rec_date: new Date(),
    rec_acc: 0,
    rec_acc_balance: 0,
    rec_acc_bal_sign: '',
    rec_cust_ledger: '',
    rec_cust_balance: 0,
    rec_cust_bal_sign: '',
    note: '',
    invs: [],
    ttl_amount: 0,
    ttl_input_amount: 0,
    ttl_due: 0,
    payType: 2,
    ledger_tin: 0
  };
  ledgerLoading = false;
  accLoading = false;
  invLoading = false;
  pendingInvs = JSON.stringify([]);
  rowLoadingImg: any;
  nextId = '';
  total_sold = 0;
  total_retuned = 0;
  max_returnable = 0;
  prd_id: any;
  from: any;
  typeflag: number;
  defLedger: any;
  cash_in_account: string;
  showNew: boolean;
  taxCategories = [];
  prd_tax_cat_id = 0;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  branch_tin: any;
  branch_reg_no: any;
  base_url: string;
  salesRetPreview: any;
  saleprivewLoadingImg: boolean;
  transf_id: string;
  purch_note: string;
  brcode : any;
  tempImgUrl: string;
  qr_inv: any;
  sales_print_name: any;
  sr_print_style: any;
  checkBatch =false;

  payment_types = [
    { type: 0, name: 'Cash' },
    { type: 1, name: 'Credit' },
    { type: 3, name: 'Bank / Card' },

  ];

  customer_types = [
    { c_id: 1, name: 'New' },
    { c_id: 2, name: 'Registered' },
    

  ];
  country_dec: string;
  batchAll = [];
  selBatch:any;
  batchLoading: boolean;
  price_group_id: any = 1;
  sales_return: any = 1;
  customer:any;
  footImgUrl: string;
  minDate:Date;
  usr_block_pre_date_select: any;
  constructor(private productionService: ProductionService, private datePipe: DatePipe, private apiService: ApiService,
    private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.langChange();
    });
    this.langChange();
    this.type_id = 1;
    this.changeType(this.type_id);
    this.cust_id = 2;
    this.getAllSatff();
    this.getInfo(this.curpage);
    this.getReturnId();
    // this.setPayTypes();
    this.listGodowns();

    // this.selectedItems = [];
    this.clearForm();
    this.selectedItems = [];
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });


    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address= this.coreService.getUserData('branch_address');
    this.img_url= this.coreService.getUserData('img_url');
    this.branch_phone= this.coreService.getUserData('branch_phone');
    this.branch_mob= this.coreService.getUserData('branch_mob');
    this.branch_tin= this.coreService.getUserData('branch_tin');
    this.branch_reg_no= this.coreService.getUserData('branch_reg_no');
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }
    this.base_url = location.origin;

    this.searchDefaultLedger('');
    this.sales_print_name=1;
    this.getSalesSetting();
this.searchDefaultLedger('');

this.country_dec=this.coreService.setDecimalLength();
  }
  getSalesSetting() {
    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {        
        this.sales_print_name=res['data'].sales_print_name;
        this.sr_print_style=res['data'].sale_rtn_print_style;
      } else {
        this.sales_print_name=1;
        this.sr_print_style=1;

      }
    });
  }


  langChange(){
    this.translate.get(['Common.registerd', 'Common.new','Common.cash', 'HOME.credit','HOME.bank_card']).subscribe((res: string) => {    
     
      
      this.payment_types = [
        { type: 0, name: res['Common.cash'] },
        { type: 1, name: res['HOME.credit'] },
        { type: 3, name: res['HOME.bank_card'] },
    
      ];
    
      this.customer_types = [
        { c_id: 1, name: res['Common.new'] },
        { c_id: 2, name: res['Common.registerd'] },
        
    
      ];
    });

  }
  clearForm() {

    this.selectedItems = [];
    this.total_amount = this.total_vat = this.net_amount = 0;
    this.prd_id = '';
    this.prd_barcode = '';
    this.return_qty = '';
    this.default_unit = '';
    this.rate = '';
    this.vat = '';
    this.cash_in_account = '';
    this.ret_date = new Date();
    this.transf_id = '';
    this.purch_note = '';

    this.gd_id = 0;
    this.type_id = 1;
    this.cust_id = 2;
    this.prd_tax_cat_id = 0;
    this.rec = {
      rec_no: '',
      rec_date: new Date(),
      rec_acc: 0,
      rec_acc_balance: 0,
      rec_acc_bal_sign: '',
      rec_cust_ledger: '',
      rec_cust_balance: 0,
      rec_cust_bal_sign: '',
      note: '',
      invs: [],
      ttl_amount: 0,
      ttl_input_amount: 0,
      ttl_due: 0,
      payType: 2,
      ledger_tin: 0
    };
    this.typeflag = 1;
    this.defLedger = [];
    this.type_id = 1;
    this.cust_id = 2;
  // console.log(this.gd_id);
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });
  }
  searchCustomeralias(search: string){
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }

  getLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_acc }).subscribe((res) => {
      this.accLoading = false;
      this.rec.rec_acc_balance = res.data.bal_without_sign;
      this.rec.rec_acc_bal_sign = res.data.bal_sign;
    });
    // this.ShowInvDetails();
  }


  getReturnId() {
    let searchval = new FormData();
    this.apiService.geSalesReturnId(searchval).subscribe((res) => {
      this.rtnId = res['data']['ret_id'];
    });
  }
  setPayTypes() {

    this.type_id = 1;
    this.cust_id = 2;
    // console.log(this.type_id);
    this.payment_types.push({ "type": 0, 'name': 'Cash' },
      { "type": 1, 'name': 'Credit' },
      { "type": 3, 'name': 'Bank / Card' }
      // { "type_id": 3, 'name': 'Credit/Bank' }
      );

    this.customer_types.push({ "c_id": 1, 'name': 'New' },
      { "c_id": 2, 'name': 'Registered' },
    );
    this.typeflag = 1;

  }



  changeCustomer(cust_id){
    // console.log(cust_id);

    if(cust_id == 1){
      this.showNew = true;
    }else{
      this.showNew = false;
    }
// console.log(this.showNew);
  }


  ngOnDestroy() {
    $('.close').click();

  }


  listGodowns() {
    let searchval = new FormData();
    this.godown_all = [{ gd_id: 0, gd_name: 'Shop' }];
    this.gd_id = 0;
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });


    });

  }

  viewsale(){

  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
      this.apiService.salesReturnList(formdata.value,pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      });

    }

    searchInp(key){
      let searchval = new FormData();
    searchval.append("keyword", key);
    this.formula_list = [];

    this.pageLoadingImg = true;
    this.apiService.salesReturnList(searchval,1).subscribe((res) => {
      this.formula_list = res.data.data;
      this.pageLoadingImg = false;
      this.from = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });

    
    }



    getsalesReturnPrev(prdn_id) {
      this.saleprivewLoadingImg = true;
// console.log(prdn_id);
    let searchval = new FormData();
    searchval.append("ref_no", prdn_id);
    this.apiService.getsalesReturnPrev(searchval).subscribe((res) => {
      this.saleprivewLoadingImg = false;
      this.salesRetPreview = res;
      this.salesRetPreview.total_vat = Number(res.total_vat);
      this.salesRetPreview.total_amt = Number(res.total_amt);

      //this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });
      this.qr_inv = this.salesRetPreview.qr_link;
      this.apiService.getSettings().subscribe((res) => {
        if (res.data.prnt_header_type == 2) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
          this.footImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_footer_img_path;
        } else {
          this.tempImgUrl = '';
          this.footImgUrl = '';
        }
  
      });

    });

  }



  changeUnit(qty, index) {
    //this.prodFormula.productIngd[index]['product']['shop'] = this.prodFormula.productIngd[index]['product']['shop_available'] * qty;
    this.prodFormula.productIngd[index]['qty'] *= qty;
  }
  getInfo(pageNo) {
    const searchval = new FormData();
    this.pageLoadingImg = true;
    this.apiService.salesReturnList(searchval,pageNo).subscribe((res) => {
      this.formula_list = res.data.data;
      this.pageLoadingImg = false;
      this.from = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  searchProducts(search: string) {
    const searchval = new FormData();
    if(this.commAll==1){
      searchval.append('alias_search', '1');
    }
      searchval.append('keyword', search);
      this.apiService.searchSoldProducts(searchval).subscribe((res) => {
        this.prdAll = res['data'];
        this.filterProducts();
      });
  }

  



  filterProducts() {
    this.filterProdAll = this.prdAll.filter(e => {
      if (Object.values(this.selProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }

  addMore() {
    this.prodFormula.productOut.push({
      product: null,
      qty: 0,
      qty_expected: 0,
      percntg: 0,
      qty_received: 0,
      rate_expected: 0,
      net_rate: 0,
      exp_date: 0,
      manf_date: 0,
      batch_code: ''
    });
  }

  removeItem(index) {
    this.prodFormula.productOut.splice(index, 1);
    delete this.selProdIds[index];
    this.filterProducts();
  }





  addMoreIngrd() {
    this.prodFormula.productIngd.push({
      product: null,
      qty_show: 0,
      qty: 0,
      amount: 0,
    });
  }

  removeIngd(index) {
    if (this.prodFormula.productIngd.length > 1) {
      this.prodFormula.productIngd.splice(index, 1);
    } else {
      // error atleast 1 item required
    }
    delete this.selIngProdIds[index];

  }

  commissionChk(value) {
    if (value === 0) {
      this.prodFormula.staffs = [];
      this.prodFormula.ttlComm = 0;
    }
  }

  setStaff(staffId, index) {
    if (staffId == null) {
      delete this.selStaffs[index];
    } else {
      this.selStaffs[index] = staffId;
    }
    this.filterStaff();
  }

  filterStaff() {
    this.filterStaffs = this.staffs.filter(e => {
      if (Object.values(this.selStaffs).indexOf(e.ledger_id) < 0) {
        return true;
      }
    });
  }

  getAllSatff() {
    this.apiService.listAllStaff().subscribe((res) => {
      this.staffs = res.data;
      this.filterStaff();
    });
  }

  addMoreStaff() {
    this.prodFormula.staffs.push({
      staff: null,
      comm: 0,
    });
  }

  removeStaff(index) {

    this.prodFormula.staffs.splice(index, 1);
    delete this.selStaffs[index];
    this.filterStaff();
    this.updateCommsnTtl();
  }

  showAllStaff() {
    this.selStaffs = {};
    this.prodFormula.staffs = [];
    this.staffs.forEach((element, index) => {
      this.prodFormula.staffs.push({
        staff: element,
        comm: 0,
      });
      this.setStaff(element.ledger_id, index);
    });
    this.updateCommsnTtl();
  }

  showIndivStaff() {
    this.selStaffs = {};
    this.filterStaff();
    this.prodFormula.staffs = [{
      staff: {},
      comm: 0,
    }];
  }
  updateOutPutProducts(qty, index) {

  }
  updateCommsnTtl() {
    this.prodFormula.ttlComm = 0;
    this.prodFormula.staffs.forEach(element => {
      if (parseFloat(element.comm.toString()) > 0) {
        this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      }

    });
  }
  updatePurchaseTtl() {
    this.prodFormula.ttlPurchase = 0;
    this.prodFormula.productIngd.forEach(element => {
      this.prodFormula.ttlPurchase += element.amount;
    });
  }

  setIndvComsn() {

    this.prodFormula.staffs.forEach((element, index) => {
      this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      this.prodFormula.staffs[index].comm = this.comsnCommAmnt;
    });
    this.updateCommsnTtl();
  }
  validateAndSubmit() {
    this.isSubmitInProg = true;
    let callFunction = '';
    if (this.curForm === 'Create') {
      callFunction = 'createProduction';
    }
    if (this.curForm === 'Update') {
      callFunction = 'updateProduction';
    }

    this.prodFormula.productOut.forEach((element, index) => {
      this.prodFormula.productOut[index]['rate_expected'] = ((this.prodFormula.ttlComm * 1) + (this.prodFormula.ttlPurchase) + (this.prodFormula.miscExp * 1)) * (this.prodFormula.productOut[index].percntg / (100 * this.prodFormula.productOut[index].qty_expected));
      this.prodFormula.productOut[index]['net_rate'] = ((this.prodFormula.ttlComm * 1) + (this.prodFormula.ttlPurchase) + (this.prodFormula.miscExp * 1)) * (this.prodFormula.productOut[index].percntg / (100 * this.prodFormula.productOut[index].qty_received));
    });

    this.productionService[callFunction](this.prodFormula).subscribe((res) => {

      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        //this.closeModal.nativeElement.click();
        //this.clearPopUp();
        this.prodFormula.productnId = res.preview.prdn_id;
        this.prodFormula.productnDate = res.preview.prdn_date;
        this.getInfo(this.curpage);
        this.coreService.showMessage(res.message);

        this.resultobj = {};
      }

    });
  }

  // updateToatalPurchaseAmount(qty, rate, base_qty, index) {

  //   // this.prodFormula.productIngd[index]['product']['bs_prate'] = rate;
  //   this.prodFormula.productIngd[index]['qty'] = qty * base_qty;
  //   this.prodFormula.productIngd[index]['amount'] = qty * rate * base_qty;
  //   this.updatePurchaseTtl();

  // }
  // expectedQuantityChange(qty, index) {

  //   this.prodFormula.productOut[index]['qty_received'] = qty;
  //   this.prodFormula.productOut[index]['net_rate'] = ((this.prodFormula.ttlComm * 1) + (this.prodFormula.ttlPurchase) + (this.prodFormula.miscExp * 1)) * (this.prodFormula.productOut[index].percntg / (100 * this.prodFormula.productOut[index].qty));
  //   // console.log(  this.prodFormula.productOut[index]['qty_received']);
  //   // console.log(  this.prodFormula.productOut[index]['net_rate']+")))");

  // }
  // expectedRateChange(rate, index) {

  //   this.prodFormula.productOut[index]['net_rate'] = rate;
  // }

  // newProduction() {
  //   this.closeModal.nativeElement.click();
  //   this.step3 = false;
  //   this.step2 = false;
  //   this.calculationDisplay = true;

  // }

  updateTaxPerc(taxCatId) {
    if(taxCatId){
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.vat = selTax[0].taxcat_tax_per;
  }
  } 

  getAllbatches(prd_data){
    this.batchAll = [];
    this.selBatch = null;
    if(prd_data.prd_id){
      this.apiService.getAllBatchOfProduct({prd_id : prd_data.prd_id}).subscribe((res) => {
        this.batchAll = res['data'];
      });
    } 
  }
  
  setValues(prd_data) {
    this.getAllbatches(prd_data);
    if(prd_data){
    this.salestatus = 1;
    // console.log(prd_data);
    // this.base_rate = prd_data.bs_prate;
    // this.sale_price = prd_data.bs_srate;

    this.prd_barcode = prd_data.prd_barcode;
    this.prd_units = prd_data.prd_units;
    this.return_qty = 1;
    this.total_sold = prd_data.total_sold;
    this.total_retuned = prd_data.total_retuned;
    this.max_returnable = prd_data.max_returnable;
    // this.prd_tax_cat_id =prd_data.prd_tax_cat_id;


    // console.log(this.prd_tax_cat_id);
    this.base_unit_code = prd_data.base_unit_code;
    this.default_unit = { "unit_code": prd_data.base_unit_code, "unit_name": prd_data.base_unit_name, "sur_unit_id": prd_data.prd_base_unit_id, "unit_base_qty": 1 };

    this.rate = prd_data.bs_srate;
    this.vat = prd_data.prd_tax;
    this.prd_tax_cat_id = prd_data.prd_tax_cat_id;
    this.inputRate.nativeElement.focus();
    }else{
      this.salestatus = 0;
 
    this.prd_barcode = '';
    this.prd_units = '';
    this.return_qty = '';
    this.total_sold = 0;
    this.total_retuned = 0;
    this.max_returnable = 0;
    this.prd_tax_cat_id = 1;


    this.base_unit_code = '';
    this.default_unit = { };

    this.rate = 0;
    this.vat = 0;
    this.prd_tax_cat_id = 0;
    }
  }
  barcodeSearch(brcode: string) {
    // this.resetItemsearch();
    this.prdAll = [];
    let searchval = new FormData();
    searchval.append("brcode", brcode);
    this.apiService.searchSoldProducts(searchval).subscribe((res) => {
      // this.prd_all = res['data'];
      // this.prd_id = res['data'][0];
      if(res['data'].length > 0){
        this.setValues(res['data'][0]);
        this.prd_id = res['data'][0];
        // this.prdAll = res['data'][0];
        // console.log(this.prdAll);
        // this.filterProducts();
      // this.setValues(res['data'][0]);
    }
    });
  }

  appendItem(prd_data, prd_barcode, vantransub_unit_id) {


    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];

      });

    }
    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }


    this.validateForm();

    if (this.printError.length <= 0) {

      this.box = this.return_qty;
      this.display_qty = '';
      this.unit_qty = '';
      this.vantransub_qty = this.box;
      if (vantransub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);

      }

      this.sl_no = (this.selectedItems.length) + 1;

      this.vantransub_qty = Number(this.vantransub_qty);
      this.vantran_purch_amount = this.vantran_purch_price * this.vantransub_qty;
      var existingArray: any;

      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.selectedItems,
          prd_data.branch_stock_id,
          vantransub_unit_id.sur_unit_id,
          this.rate,
          prd_data.prd_barcode,
          vantransub_unit_id.unit_code, prd_data.prd_id, this.selBatch || 0);
      }

      if (existingArray && existingArray.length > 0) {

        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.vantran_purch_amount;
      }
      else {


        this.selectedItems.push({
          "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
          "prd_id": prd_data.prd_id,
          "prd_name": prd_data.prd_name,
          "return_qty": this.vantransub_qty,
          "vantransub_qty": this.vantransub_qty,
          "box": this.box,
          "rate": this.rate,
          "vat": this.vat,
          "gd_id": this.gd_id,
          "purchase_rate": this.rate,
          "purchase_amount": this.return_qty * this.rate,
          "vat_amount": (this.return_qty * this.rate) * (this.vat / 100),
          "vantransub_prod_id": prd_data.prd_id,
          "vantransub_stock_id": prd_data.bs_stock_id,
          "vantransub_branch_stock_id": prd_data.branch_stock_id,
          "vantransub_unit_id": vantransub_unit_id.sur_unit_id,
          "vantransub_purch_rate": this.rate,
          "unit_name": vantransub_unit_id.unit_name,
          "unit_base": vantransub_unit_id.unit_base_qty,
          "unit_code": prd_data.base_unit_code,
          "base_unit_name": prd_data.base_unit_name,
          "display_qty": this.display_qty,
          "item_unit_code": vantransub_unit_id.unit_code,
         // "taxcat_id": prd_data.prd_tax_cat_id
         // to binf selected tax category instead of product tax category 
          "taxcat_id": this.prd_tax_cat_id,
          "batch_id": this.selBatch || 0

        });


      }

// console.log(this.selectedItems);




      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.selectedItems.length;
      this.sub_qty = this.box;
    

      this.vantran_purch_price = 0;
      this.prd_barcode = '';
      // this.prd_id = '';
      this.default_unit = [];
      this.vantransub_qty = 0;
      this.findTotalSum();
      this.return_qty=this.rate=this.vat=0;
      this.brcode='';
      this.prd_id='';
      this.total_sold = 0;
      this.base_unit_code = '';
      this.max_returnable = 0;
      this.prd_tax_cat_id = 0;
      this.batchAll = [];
      this.selBatch = null;
      this.inputBrCode.nativeElement.focus();
    }
  }
  validateForm() {
    // console.log("aa");
    this.printError = [];
    if ($('#prd_barcode').val() == "" || $('#prd_barcode').val() == undefined) {
      
      this.printError.push({ 'prd_barcodeErr': 'Required' });
      $('#prd_barcode').css("border", "1px solid red");
    } else {
      // console.log("a54bba");
      $('#prd_barcode').css("border", "1px solid #dedede");
    }
      if (this.default_unit == undefined || this.default_unit == null) {
      // console.log("111");
      this.printError.push({ 'ret_unit_idErr': 'Required' });
      $('#ret_unit_id').css("border", "1px solid red");
    } else {
      // console.log("65561qq");
      $('#ret_unit_id').css("border", "1px solid #dedede");
    }

    if ($('#rate').val() == "" || $('#rate').val() == undefined) {
 
      this.printError.push({ 'rateErr': 'Required' });
      $('#rate').css("border", "1px solid red");
    } else {

      $('#rate').css("border", "1px solid #dedede");
    }

    if (this.return_qty == undefined || this.return_qty == null || this.return_qty < 1) {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#return_qty').css("border", "1px solid red");
    } else {
      $('#return_qty').css("border", "1px solid #dedede");
    }

    if (this.gd_id == undefined || this.gd_id == null) {
      this.printError.push({ 'ret_godown_idErr': 'Required' });
      $('#ret_godown_id').css("border", "1px solid red");
    } else {
      $('#ret_godown_id').css("border", "1px solid #dedede");
    }


  }

  validateReturn(formData) {
    // console.log(formData);

    this.printError = [];
    if (formData.ret_date == "" || formData.ret_date == undefined) {
      $('#ret_date').css("border", "1px solid red");
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      $('#ret_date').css("border", "1px solid #dedede");
    }

    if (formData.ledger == "" || formData.ledger == undefined) {
      $('#ledger').css("border", "1px solid red");
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      $('#ledger').css("border", "1px solid #dedede");
    }

    if (formData.salesret_pay_type == undefined) {
      $('#salesret_pay_type').css("border", "1px solid red");
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      $('#salesret_pay_type').css("border", "1px solid #dedede");
    }

    if ( (formData.salesret_pay_type == 0 ||  formData.salesret_pay_type == 3) && (formData.cash_in_account == "" || formData.cash_in_account == undefined)) {
      $('#cash_in_account').css("border", "1px solid red");
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      $('#cash_in_account').css("border", "1px solid #dedede");
    }

    if (formData.customer_type == "" || formData.customer_type == undefined) {
      $('#customer_typesel').css("border", "1px solid red");
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      $('#customer_typesel').css("border", "1px solid #dedede");
    }


  }
  changeType(type){
    this.cash_in_account = '';
    this.defLedger = [];
    if(type == 0 || type == 3 ){
      this.cust_id = 1;
      this.typeflag = 0;

      if (type === 0) {
        if (this.cash_in_account != '0') {
          this.cash_in_account = this.coreService.getUserData('usr_cash_ledger_id');
          if (!this.cash_in_account) {  
            this.apiService.getBranchCashAccount().subscribe((led_res) => {
              this.defLedger = led_res.data.ledger;
              this.cash_in_account = led_res.data.taxled_ledger_id;
            });
          }
        }
        this.ledger = 'Cash Customer';
      }

    }else{
      this.cust_id = 2;
      this.typeflag = 1;
    }

    if(this.cust_id == 1){
      this.showNew = true;
    }else{
      this.showNew = false;
    }

  }

  salesItemReturn(formData) {

    
    if (this.selectedItems.length == 0) 
    {
      alert("Please add item to continue");
    }
    else
    {
      var confrm = confirm("Are you sure to return the items?");
      if (confrm) {
        this.validateReturn(formData.value);
  
        // 
        if (this.printError.length <= 0) {
    
          this.pageLoadingImg = true;
  
          formData.value.items = this.selectedItems;
          formData.value.total_amount = this.total_amount;
          formData.value.total_vat = this.total_vat;
          formData.value.net_amount = this.net_amount;
          formData.value.vantran_price = this.total_amount;
          this.apiService.salesItemReturn(formData.value).subscribe((res) => {
            this.pageLoadingImg = false;
  
            // this.coreService.createfunct(formData, this.resultobj, res.message);
            this.coreService.showMessage(res.message);
            this.selectedItems = [];
            this.total_qty = 0;
            this.total_amount = 0;
  
  
    this.clearForm();
    this.getReturnId();
    this.getInfo(this.curpage);

  
          });
          this.apiService.updatebatchExNotification();
        }
        else {
          // console.log("ss44");
  
        }
      }
    }
 
  }
  deleteItem(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {
      this.total_qty = this.selectedItems.length;
      var type = data.sl_no;
      var i;
      for (i = this.selectedItems.length - 1; i >= 0; --i) {
        if (this.selectedItems[i].sl_no == type) {
          this.selectedItems.splice(i, 1);
        }
      }
      if (this.selectedItems.length == 0)
        $('#gdtran-btn').hide();
      this.findTotalSum();
    }
  }
  findTotalSum() {
    this.total_amount = 0;
    this.total_vat = 0;
    this.selectedItems.forEach(element => {

      this.total_amount += Number(element.purchase_amount);
      this.total_vat += Number(element.vat_amount);

    });
    this.net_amount = this.total_amount + this.total_vat;
  }

  getBalance(ledger) {
    if(ledger){
  //  console.log(ledger);
    this.rec.ledger_tin = ledger.ledger_tin;
    this.ledgerLoading = true;
    this.apiService.getCustomerBalance({ cust_ledger_id: ledger.ledger_id }).subscribe((res) => {
      this.ledgerLoading = false;
      this.rec.rec_cust_balance = res.data.balance;
      this.rec.rec_cust_bal_sign = res.data.bal_sign;
    
    });
  }
    // this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
    //   this.ledgerLoading = false;
    //   this.rec.rec_cust_balance = res.data.bal_without_sign;
    //   this.rec.rec_cust_bal_sign = res.data.bal_sign;
    // });

  }

  convertRate(unit_id) {
    if(unit_id)

    // console.log("aaaaaa");
    // console.log(unit_id);
    this.rate = unit_id.sur_unit_rate;

    // console.log(unit_id);

  }


  searchDefaultLedger(search: string){
    let searchval = new FormData();      
    searchval.append("ledger_name", search); 
    // searchval.append("ledger_grp", '3');    
    if (this.type_id === 3)
      searchval.append('ledger_grp', '4');
    else if (this.type_id === 0)
      searchval.append('ledger_grp', '3');
  
     this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data']; 
      console.log(this.defLedger.length);
      if(this.defLedger.length == 1){
      console.log(this.defLedger[0]);

       this.cash_in_account = this.defLedger[0].ledger_id;
       console.log(this.cash_in_account);
      }                 
     });
  
  }

  updateStock(prd_data,prd_barcode,default_unit){
    if(this.selBatch){
      // this.selctedProd.stock_qty = 333
      // getGodownBatchStock(){}

   
      const searchval = new FormData();
      searchval.append('prd_id', prd_data.prd_id);
      searchval.append('gd_id', this.gd_id);
      searchval.append('price_group_id', this.price_group_id);
      searchval.append('batch_id', this.selBatch);
      // searchval.append('sales_return', this.sales_return);
      this.batchLoading = true;
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
        this.batchLoading = false;
        this.prd_units =resp['data']['prod_units'];
        this.convertRate(default_unit);
        this.rate = resp['data']['prod_units'][0]['sur_unit_rate'];
    

      });
    } else{
      // this.selctedProd.stock_qty = this.stock_qty;
    }
  }

}