import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';

@Component({
  selector: 'app-item-tracking-rep',
  templateUrl: './item-tracking-rep.component.html',
  styleUrls: ['./item-tracking-rep.component.css']
})
export class ItemTrackingRepComponent implements OnInit {

  stkprdsel: any;
  elseBlocknoouterwrap: any;
  selecTed: any = null;
  stkprd_all: string[];
  units: any[];
  total_stocks = [];
  branch_stocks: any[];
  branch_stksbool: boolean;
  id: any;
  prd_date: any;
  formula: any;
  reqqty: any;
  total_purch_amount: any;
  misc_exp: any;
  commission: any;
  sum_total_purch_amount: any;
  prdn_inspection_staff: any;
  addedby: any;
  purchId: any;
  up: any;
  pageLoadingImg = false;
  ttlIn = 0;
  ttlout = 0;
  purchQtyTtl = 0;
  purchPrcTtl = 0;
  saleQtyTtl = 0;
  salePrcTtl = 0;
  saleRetQtyTtl = 0;
  saleRetPrcTtl = 0;
  transQtyTtl = 0;
  transPrcTtl = 0;
  transRecQtyTtl = 0;
  transRecPrcTtl = 0;
  prodQtyTtl = 0;
  prodPrcTtl = 0;
  purchRetQtyTtl = 0;
  purchRetPrcTtl = 0;
  stockdata = [];
  vanTtl = 0;
  shopGdTtl = 0;
  ttlStock = 0;

  prodQtyTtl1 = 0;
  prodPrcTtl1 = 0;


  extTransQtyTtl = 0;
  extTransPrcTtl = 0;

  extRecQtyTtl = 0;
  extRecPrcTtl = 0;

  vanTransQtyTtl = 0;
  vanTransPrcTtl = 0;

  vanRetQtyTtl = 0;
  vanRetPrcTtl = 0;

  gdTransQtyTtl = 0

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  status_types = [
    { id: 1, name: 'PURCHASES' },
    { id: 2, name: 'PURCHASES RETURNS' },
    { id: 3, name: 'SALES' },
    { id: 4, name: 'SALES RETURNS' },
    { id: 5, name: 'INTER BRANCH TRANSFER' },
    { id: 6, name: 'INTER BRANCH RECEIPT' },
    { id: 7, name: 'EXTERNAL STOCK TRANSFER' },
    { id: 8, name: 'EXTERNAL STOCK RECEIPT' },
    { id: 9, name: 'VAN TRANSFER' },
    { id: 10, name: 'VAN RETURN' },
    { id: 11, name: 'GODOWN TRANSFER' },
    { id: 12, name: 'INGREDIENTS USED FOR PRODUCTION' },
    { id: 13, name: 'PRODUCTION SUMMARY' },
    { id: 14, name: 'EXCESS STOCK' },
    { id: 15, name: 'MISSING STOCK' },
    { id: 16, name: 'DAMAGE STOCK' }

  ];
  repDate: any;
  excsQtyTtl: number;
  mssingQtyTtl: number;
  dmgQtyTtl: number;
  country_dec: string;
  search: any[];
  base_url: string;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
    $("#report-wrapper").hide();
    this.up = this.coreService.getUserPrivilage();
    this.base_url = this.apiService.PHP_API_SERVER;
  }

  //product form search 
  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.selecTed = null;

    });
  }

  //form search select
  selectStockPrd(prd) {

    const searchval = new FormData();
    searchval.append('product_id', prd.prd_id);


    this.pageLoadingImg = true;
    this.selecTed = null;
    this.apiService.itemTrackingRep(searchval).subscribe((res: any) => {

      this.pageLoadingImg = false;
      this.selecTed = res.data;
      console.log(res);


      this.purchQtyTtl = 0;
      this.purchPrcTtl = 0;
      this.selecTed.purchases.forEach((purch: any) => {
        this.purchQtyTtl += purch.purchsub_qty;
        this.purchPrcTtl += purch.purchsub_qty * purch.purchsub_rate;
      });

      this.purchRetQtyTtl = 0;
      this.purchRetPrcTtl = 0;
      this.selecTed.purchase_returns.forEach((data: any) => {
        this.purchRetQtyTtl += data.purchretsub_qty;
        this.purchRetPrcTtl += data.price;
      });

      this.saleQtyTtl = 0;
      this.salePrcTtl = 0;
      this.selecTed.sales.forEach((data: any) => {
        this.saleQtyTtl += data.salesub_qty;
        this.salePrcTtl += data.price;
      });

      this.saleRetQtyTtl = 0;
      this.saleRetPrcTtl = 0;
      this.selecTed.sales_returns.forEach((data: any) => {
        this.saleRetQtyTtl += data.salesretsub_qty;
        this.saleRetPrcTtl += data.price;
      });

      this.transQtyTtl = 0;
      this.transPrcTtl = 0;
      this.selecTed.branch_transfer.forEach((data: any) => {
        this.transQtyTtl += data.stocktrsub_qty;
        this.transPrcTtl += data.price;
      });

      this.transRecQtyTtl = 0;
      this.transRecPrcTtl = 0;
      this.selecTed.branch_receipt.forEach((data: any) => {
        this.transRecQtyTtl += data.stocktrsub_qty;
        this.transRecPrcTtl += data.price;
      });


      this.extTransQtyTtl = 0;
      this.extTransPrcTtl = 0;
      this.selecTed.ext_stock_transfer.forEach((data: any) => {
        this.extTransQtyTtl += data.extstktrsub_qty;
        this.extTransPrcTtl += data.price;
      });

      this.extRecQtyTtl = 0;
      this.extRecPrcTtl = 0;
      this.selecTed.ext_stock_receipt.forEach((data: any) => {
        this.extRecQtyTtl += data.extstkrecsub_qty;
        this.extRecPrcTtl += data.price;
      });

      this.vanTransQtyTtl = 0;
      this.vanTransPrcTtl = 0;
      this.selecTed.van_transfer.forEach((data: any) => {
        this.vanTransQtyTtl += data.vantransub_qty;
        this.vanTransPrcTtl += data.price;
      });

      this.vanRetQtyTtl = 0;
      this.vanRetPrcTtl = 0;
      this.selecTed.van_return.forEach((data: any) => {
        this.vanRetQtyTtl += data.vantranretsub_qty;
        this.vanRetPrcTtl += data.price;
      });

      this.gdTransQtyTtl = 0;
      this.selecTed.godown_transfer.forEach((data: any) => {
        this.gdTransQtyTtl += data.gsl_qty;
      });




      this.prodQtyTtl = 0;
      this.prodPrcTtl = 0;
      this.selecTed.production_summary.forEach((data: any) => {
        this.prodQtyTtl += data.prdnsub_qty;
        this.prodPrcTtl += data.prdnsub_qty * data.prdnsub_rate;
      });


      this.prodQtyTtl1 = 0;
      this.prodPrcTtl1 = 0;
      this.selecTed.production_summary1.forEach((data: any) => {
        this.prodQtyTtl1 += data.qty;
        this.prodPrcTtl1 += data.qty * data.rate;
      });

      this.excsQtyTtl = 0;
     
      this.selecTed.excess_stock.forEach((purch: any) => {
        this.excsQtyTtl += purch.excs_qty;
        
      });

      this.mssingQtyTtl = 0;
     
      this.selecTed.missing_stock.forEach((purch: any) => {
        this.mssingQtyTtl += purch.mstk_qty;
        
      });

      this.dmgQtyTtl = 0;
     
      this.selecTed.damage_stock.forEach((purch: any) => {
        this.dmgQtyTtl += purch.damage_qty;
        
      });

      this.repDate = res.data.Date;

    });
  }

  filterSearch(formdata: { value: any; }, prd) {
    if (prd) {
      this.pageLoadingImg = true;
      this.selecTed = null;
      formdata.value.product_id = prd.prd_id;
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      }

      this.apiService.itemTrackingRep(formdata.value).subscribe((res: any) => {

        this.pageLoadingImg = false;
        this.selecTed = res.data;
        console.log(res);


        this.purchQtyTtl = 0;
        this.purchPrcTtl = 0;
        this.selecTed.purchases.forEach((purch: any) => {
          this.purchQtyTtl += purch.purchsub_qty;
          this.purchPrcTtl += purch.purchsub_qty * purch.purchsub_rate;
        });

        this.purchRetQtyTtl = 0;
        this.purchRetPrcTtl = 0;
        this.selecTed.purchase_returns.forEach((data: any) => {
          this.purchRetQtyTtl += data.purchretsub_qty;
          this.purchRetPrcTtl += data.price;
        });

        this.saleQtyTtl = 0;
        this.salePrcTtl = 0;
        this.selecTed.sales.forEach((data: any) => {
          this.saleQtyTtl += data.salesub_qty;
          this.salePrcTtl += data.price;
        });

        this.saleRetQtyTtl = 0;
        this.saleRetPrcTtl = 0;
        this.selecTed.sales_returns.forEach((data: any) => {
          this.saleRetQtyTtl += data.salesretsub_qty;
          this.saleRetPrcTtl += data.price;
        });

        this.transQtyTtl = 0;
        this.transPrcTtl = 0;
        this.selecTed.branch_transfer.forEach((data: any) => {
          this.transQtyTtl += data.stocktrsub_qty;
          this.transPrcTtl += data.price;
        });

        this.transRecQtyTtl = 0;
        this.transRecPrcTtl = 0;
        this.selecTed.branch_receipt.forEach((data: any) => {
          this.transRecQtyTtl += data.stocktrsub_qty;
          this.transRecPrcTtl += data.price;
        });


        this.extTransQtyTtl = 0;
        this.extTransPrcTtl = 0;
        this.selecTed.ext_stock_transfer.forEach((data: any) => {
          this.extTransQtyTtl += data.extstktrsub_qty;
          this.extTransPrcTtl += data.price;
        });

        this.extRecQtyTtl = 0;
        this.extRecPrcTtl = 0;
        this.selecTed.ext_stock_receipt.forEach((data: any) => {
          this.extRecQtyTtl += data.extstkrecsub_qty;
          this.extRecPrcTtl += data.price;
        });

        this.vanTransQtyTtl = 0;
        this.vanTransPrcTtl = 0;
        this.selecTed.van_transfer.forEach((data: any) => {
          this.vanTransQtyTtl += data.vantransub_qty;
          this.vanTransPrcTtl += data.price;
        });

        this.vanRetQtyTtl = 0;
        this.vanRetPrcTtl = 0;
        this.selecTed.van_return.forEach((data: any) => {
          this.vanRetQtyTtl += data.vantranretsub_qty;
          this.vanRetPrcTtl += data.price;
        });

        this.gdTransQtyTtl = 0;
        this.selecTed.godown_transfer.forEach((data: any) => {
          this.gdTransQtyTtl += data.gsl_qty;
        });




        this.prodQtyTtl = 0;
        this.prodPrcTtl = 0;
        this.selecTed.production_summary.forEach((data: any) => {
          this.prodQtyTtl += data.prdnsub_qty;
          this.prodPrcTtl += data.prdnsub_qty * data.prdnsub_rate;
        });
        this.prodQtyTtl1 = 0;
        this.prodPrcTtl1 = 0;
        this.selecTed.production_summary1.forEach((data: any) => {
          this.prodQtyTtl1 += data.qty;
          this.prodPrcTtl1 += data.qty * data.rate;
        });


        this.excsQtyTtl = 0;
     
      this.selecTed.excess_stock.forEach((purch: any) => {
        this.excsQtyTtl += purch.excs_qty;
        
      });

      this.mssingQtyTtl = 0;
     
      this.selecTed.missing_stock.forEach((purch: any) => {
        this.mssingQtyTtl += purch.mstk_qty;
        
      });

      this.dmgQtyTtl = 0;
     
      this.selecTed.damage_stock.forEach((purch: any) => {
        this.dmgQtyTtl += purch.damage_qty;
        
      });
        this.repDate = res.data.Date;
      });

    } else {
      this.selecTed = null;

    }
  }

  selectProdDetailBarcode(barcode: string) {
    if(!barcode){
      $("#br_err").text("Enter Barcode");
      return false;
    }else{
      $("#br_err").text("");
    }
    let searchval = new FormData();
    searchval.append("barcode", barcode);
    searchval.append("flag[]", "0");
    searchval.append("flag[]", "1");
    this.apiService.getProdByBarcode(searchval).subscribe((res) => {
      this.search = res['data'];
    console.log(this.search);
    
      if(!res['data']){
        $("#br_err").text("Barcode not found");
      }
      this.selectStockPrd(res['data']);
    });
  }


}
