import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductionDashComponent } from './production-dash/production-dash.component';
import { ProductionFormulaComponent } from './production-dash/production-formula/production-formula.component';
import { ProductionComponent } from './production-dash/production/production.component';
import { ProductionVoidComponent } from './production-dash/production-void/production-void.component';

const routes: Routes = [
    { path: 'production',component: ProductionDashComponent},
    { path: 'production/formula',component: ProductionFormulaComponent},
    { path: 'production/production',component: ProductionComponent},
    { path: 'production/void',component: ProductionVoidComponent},
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ProdctionRoutingModule { }