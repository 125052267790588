import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { CoreService } from '../../service/core.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {
  previewLoader: boolean;
  saleQtnData: any;
  purchaseItems: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  is_prew_description: any[];
  resultobj: any;
  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  payTypes = ['Cash','Credit','Bank / Card'];
  logoImgUrl: string;
  tempImgUrl: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    let inv_id = this.activatedRoute.snapshot.params.inv_id;
    let cmpny = this.activatedRoute.snapshot.params.cmpny;
    
    this.previewSales(inv_id,cmpny);
  }
  previewSales(id,cmpny) {
    let searchval = new FormData();
    searchval.append("sale_inv_no", id);
    searchval.append("cmpny", cmpny);
    this.previewLoader = true;
    this.apiService.getInvDet(searchval).subscribe((res: any) => {
      if (res.data) {

        // this.saleQtnData = res.data['preview'];
        // this.is_prew_description = this.saleQtnData['sales_sub'].filter(x => x.item_descrp);
        if (res.error != null) {
          this.resultobj = res.error;
          this.purchaseItems = [];
          this.saleData = {
            sales_id: null,
            sales_inv_no: null,
            error: null,
            message: null,
            data: null,
  
          };
          this.resultobj = this.coreService.getValidation(this.resultobj);
        } else {  
          this.resultobj = {};
          this.purchaseItems = res.data;
          this.saleData = res.data;
          let st = res.data.print_settings;

          this.savedTemplate = {
            title_text: st.prnt_title_text,
            color: '#000',
            email_color: '#000',
            font_size: '12',
            orientation: "P",
            paper: 'A4-P',
            m_left: '',
            m_top: '',
            m_right: '',
            m_bottom: '',
            logo: st.prnt_header_logo,
            header_type: st.prnt_header_type,
            header_img: [],
            header_all_page: false,
            header_due: false,
            sl_no: false,
            item_desc: false,
            tax_detail: false,
            tax_type: 0,
            name_type: 'N',
            footer_text: st.prnt_display_footer_text
          }
          if (st.prnt_header_logo) {
          this.logoImgUrl = this.apiService.PHP_API_SERVER+'/'+st.prnt_header_logo_img_path;
        } else {
          this.logoImgUrl = '';
        }
          
          if (st.prnt_header_type == 2) {
            this.tempImgUrl = this.apiService.PHP_API_SERVER+'/'+st.prnt_header_img_path;
          } else {
            this.tempImgUrl = '';
          }


        }
        this.previewLoader = false;
      }
    });
  }

}
