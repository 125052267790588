import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../model/user.model';
import { Product } from '../model/product.model';
import { Unit } from '../model/unit.model';
import { Feature } from '../model/feature.model';
import { Observable, Subject } from 'rxjs';
import { Catagory } from '../model/catagory.model';
import { Subcatagory } from '../model/subcatagory.model';
import { Manufacture } from '../model/manufacture.model';
import { Group } from '../model/group.model';
import { Ledger } from '../model/ledger.model';
import { Branch } from '../model/branch.model';
import { Login } from '../model/login.model';
import { Logintoken } from '../model/logintoken.model';
import { Godown } from '../model/godown.model';
import { Stockedit } from '../model/stockedit.model';
import { Batch } from '../model/batch.model';
import { Repcatagory } from '../model/report/repcatagory.model';
import { Repprddet } from '../model/report/repprddet.model';
import { Sales } from '../model/report/sales.model';

import { Supplier } from '../model/ledger/supplier.model';
import { Purchase } from '../model/purchase';
import { Reppurchsum } from '../model/report/reppurchsum.model';
import { ProductUnit } from '../model/product-unit';
import { Reppurchdet } from '../model/report/reppurchdet.model';

import { Vanline } from '../model/vanline.model';
import { Van } from '../model/van/van.model';
import { environment } from '../../environments/environment';
import { Employee } from '../model/employee.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  users: User[];
  products: Product[];
  PHP_API_LOC_SERVER1 = "http://bquickerp.com/backend";
  PHP_API_LOC_SERVER2 = "http://127.0.0.1:8000";
  PHP_API_SERVER = (environment.php_api_server) ? environment.php_api_server : this.PHP_API_LOC_SERVER1;
  // PHP_API_SERVER = this.PHP_API_LOC_SERVER1;
  token = localStorage.getItem("auth_token");
  usr_type = localStorage.getItem("usr_type");
  routeftn: any;
  // notificationChange = <any> 0;
  // public notificationChange: Observable<number>;
  public notificationChange: Subject<number> = new Subject<number>();
  public cur_lang: Subject<any> = new Subject<any>();
  public salesordernotificationChange: Subject<number> = new Subject<number>();
  public notificationBatchChange: Subject<number> = new Subject<number>();
  constructor(private httpClient: HttpClient) { }

  updateLanguage() {
      this.cur_lang.next(sessionStorage.getItem("baseLang"));
  }
  
  updatePDCNotification() {
    this.getPDCcount({ cheq_issue_date: this.formatDate(new Date()) }).subscribe((res) => {
      this.notificationChange.next(res['data']);
    });

  }
  updateSalesOrderNotification() {
    this.getSalesOrdercount({ today_date: this.formatDate(new Date()) }).subscribe((res) => {
      this.salesordernotificationChange.next(res['data']);
    });

  }

  updatebatchExNotification() {
    this.batchExCount().subscribe((res) => {
      this.notificationBatchChange.next(res['data']);
    });

  }

  readUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.PHP_API_SERVER + '/api/users', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createUser(user: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/usr_register', user, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateUser(user: any) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/edit_user_details', user, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createProduct(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/add_product', product, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  translateProductName(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/translate_product', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createUnit(unit: Unit): Observable<Unit> {
    return this.httpClient.post<Unit>(this.PHP_API_SERVER + '/api/add_unit', unit, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateUnit(unit: Unit): Observable<Unit> {
    return this.httpClient.post<Unit>(this.PHP_API_SERVER + '/api/edit_unit', unit, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readBase(id: number): Observable<Unit[]> {
    return this.httpClient.get<Unit[]>(this.PHP_API_SERVER + '/api/get_base_units/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readUnit(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/get_units', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchunits(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_units',res,{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readFeat(): Observable<Feature[]> {
    return this.httpClient.get<Feature[]>(this.PHP_API_SERVER + '/api/get_features', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readBaseunit(unit: Unit): Observable<Unit> {
    return this.httpClient.post<Unit>(this.PHP_API_SERVER + '/api/get_base_unit_details', unit, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createFeat(feat: Feature): Observable<Feature> {
    return this.httpClient.post<Feature>(this.PHP_API_SERVER + '/api/add_feature', feat, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateFeat(feat: Feature): Observable<Feature> {
    return this.httpClient.post<Feature>(this.PHP_API_SERVER + '/api/edit_feature', feat, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  createCat(catagory: Catagory): Observable<Catagory> {
    return this.httpClient.post<Catagory>(this.PHP_API_SERVER + '/api/add_category', catagory, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  readCat(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/get_categories', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchCats(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_categories',res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateCat(catagory: Catagory): Observable<Catagory> {
    return this.httpClient.post<Catagory>(this.PHP_API_SERVER + '/api/edit_category', catagory, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createSubCat(res: Subcatagory): Observable<Subcatagory> {
    return this.httpClient.post<Subcatagory>(this.PHP_API_SERVER + '/api/add_subcategory', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSubCatbyId(id: number): Observable<Subcatagory[]> {

    return this.httpClient.get<Subcatagory[]>(this.PHP_API_SERVER + '/api/get_subcategories/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readDerbyId(id: number): Observable<Unit[]> {
    return this.httpClient.get<Unit[]>(this.PHP_API_SERVER + '/api/get_drvd_units/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  updateSubCat(res: Subcatagory): Observable<Subcatagory> {
    return this.httpClient.post<Subcatagory>(this.PHP_API_SERVER + '/api/edit_subcategory', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllSubCatbyId(id: any): Observable<Subcatagory[]> {
    return this.httpClient.get<Subcatagory[]>(this.PHP_API_SERVER + '/api/get_allsubcategories/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSubCatAll(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/get_subcat_all', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createManf(res: Manufacture): Observable<Manufacture> {
    return this.httpClient.post<Manufacture>(this.PHP_API_SERVER + '/api/add_manufacturer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readManfs(): Observable<Manufacture[]> {
    return this.httpClient.get<Manufacture[]>(this.PHP_API_SERVER + '/api/get_manufacturers', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateManf(res: Manufacture): Observable<Manufacture> {
    return this.httpClient.post<Manufacture>(this.PHP_API_SERVER + '/api/edit_manufacturer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readProd(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.PHP_API_SERVER + '/api/get_products', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateProd(prod: any): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/edit_product', prod, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  deleteProduct(prod: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/delete_product', prod, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  deleteAddBarcode(prod): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/delete_add_barcode', prod, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProdById(id: number): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.PHP_API_SERVER + '/api/get_prod_detail/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProdUnits(res: FormData): Observable<ProductUnit> {
    return this.httpClient.post<ProductUnit>(this.PHP_API_SERVER + '/api/get_prod_units', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  updateProdUnit(prod: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/edit_prod_units', prod, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateProdFeat(prod: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/edit_prod_features', prod, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProdByName(res: FormData): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/get_search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProdByName1(res: FormData): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/get_search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getProdByBarcode(res: FormData): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/get_search_barcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getGroupbyName(res: FormData): Observable<Group> {
    return this.httpClient.post<Group>(this.PHP_API_SERVER + '/api/get_all_ledgroup', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLedByName(res: FormData): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/search_ledger', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLedDetailsByName(res: FormData): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/search_ledger_detail', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getUserByName(res: FormData): Observable<User> {
    return this.httpClient.post<User>(this.PHP_API_SERVER + '/api/search_users', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getManfbyName(res: FormData): Observable<Manufacture> {
    return this.httpClient.post<Manufacture>(this.PHP_API_SERVER + '/api/search_manufacturers', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCatbyName(res: FormData): Observable<Catagory> {
    return this.httpClient.post<Catagory>(this.PHP_API_SERVER + '/api/search_categories', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBatchbyName(res: FormData): Observable<Batch> {
    return this.httpClient.post<Batch>(this.PHP_API_SERVER + '/api/stock_search/batch', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBranchByName(res: FormData): Observable<Branch> {
    return this.httpClient.post<Branch>(this.PHP_API_SERVER + '/api/search_branch', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBatchByName(res: FormData): Observable<Branch> {
    return this.httpClient.post<Branch>(this.PHP_API_SERVER + '/api/stock_search/all_batch', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCountry(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_country', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLength(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_length', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getUnitByName(res: FormData): Observable<Unit> {
    return this.httpClient.post<Unit>(this.PHP_API_SERVER + '/api/search_units', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getGodownByName(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/search_godown', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getUnit(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/stock/get_unit', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getGodownList(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/godowns_list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSubcatByName(res: FormData): Observable<Subcatagory> {
    return this.httpClient.post<Subcatagory>(this.PHP_API_SERVER + '/api/search_subcat', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readPriGrp(): Observable<Group[]> {
    return this.httpClient.get<Group[]>(this.PHP_API_SERVER + '/api/get_primary_ledgroup', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getEditledGrp(res: FormData): Observable<Subcatagory> {
    return this.httpClient.post<Subcatagory>(this.PHP_API_SERVER + '/api/get_edit_ledgroup', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  readAllGrp(): Observable<Group[]> {
    return this.httpClient.get<Group[]>(this.PHP_API_SERVER + '/api/get_ledgroup', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createGroup(res: Group): Observable<Group> {
    return this.httpClient.post<Group>(this.PHP_API_SERVER + '/api/add_ledgroup', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateGroup(res: Group): Observable<Group> {
    return this.httpClient.post<Group>(this.PHP_API_SERVER + '/api/edit_ledgroup', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createLedger(res: Ledger): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/add_ledger', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createLedgerPopup(res: Ledger): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/add_ledger_popup', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateLedger(res: Ledger): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/edit_ledger', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  deleteLedger(res: Ledger): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/delete_ledger', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  createBranch(res: Branch): Observable<Branch> {
    res.branch_open_date = this.formatDate(res.branch_open_date);
    return this.httpClient.post<Branch>(this.PHP_API_SERVER + '/api/add_branch', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateBranch(res): Observable<Branch> {
    res.branch_open_date = this.formatDate(res.branch_open_date);
    return this.httpClient.post<Branch>(this.PHP_API_SERVER + '/api/edit_branch', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  disableBranch(res): Observable<Branch> {
    res.branch_open_date = this.formatDate(res.branch_open_date);
    return this.httpClient.post<Branch>(this.PHP_API_SERVER + '/api/branch_disable', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  login(res: Login): Observable<Login> {
    return this.httpClient.post<Login>(this.PHP_API_SERVER + '/api/login', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      })
    });
  }

  getInvDet(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/get_inv_det', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      })
    });
  }


  LogOut(): Observable<Logintoken[]> {
    return this.httpClient.get<Logintoken[]>(this.PHP_API_SERVER + '/api/logout', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  readUsrTypes(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.PHP_API_SERVER + '/api/get_user_types', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readsubUsrTypes(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.PHP_API_SERVER + '/api/get_sub_users', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readAllBranch(): Observable<Branch[]> {
    return this.httpClient.get<Branch[]>(this.PHP_API_SERVER + '/api/get_branches', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createGodown(res: Godown): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/godown/add', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readBaseUnits(): Observable<Unit[]> {
    return this.httpClient.get<Unit[]>(this.PHP_API_SERVER + '/api/get_base_units', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllBranches(): Observable<Branch[]> {
    return this.httpClient.get<Branch[]>(this.PHP_API_SERVER + '/api/get_branches', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getAllBranchesforTransfer(): Observable<Branch[]> {
    return this.httpClient.get<Branch[]>(this.PHP_API_SERVER + '/api/get_branches_data', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateGodown(res: Godown): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/godown/edit', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllPrdLoactions(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/listAll_prd_location',res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getStockbyName(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getOpStockbyName(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock_search/product', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateStock(res): Observable<Stockedit> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/update', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateStockMultiBranch(res): Observable<Stockedit> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/update_multi_br', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addOpStock(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/open_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readCatReport(res: FormData): Observable<Repcatagory> {
    return this.httpClient.post<Repcatagory>(this.PHP_API_SERVER + '/api/product_report/category', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  transferGodown(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/godown_transfer/add', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPrdRepkbyName(res: FormData): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/product_report/details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  prodSum(res: Repprddet): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/product_report/summary', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  prodSumPage(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/product_report/summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  prodSumWithstock(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/product_report/stock_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  ManSumPage(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/product_report/manufacture_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockSumPage(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/stock_report/summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  createProductBase(product: any): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/new_product', product, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createProductUnit(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/add_derived', product, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  editProductUnit(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/edit_derived', product, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createProductFeat(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/add_features', product, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  createProductStock(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/add_stock_rates', product, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  OpStokcSum(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/stock_report/opening_stock_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockRepPeriod(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/stock_report/by_period?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createSupplier(res: Supplier): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/add_supplier', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateSupplier(res: Supplier): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/edit_supplier', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchSupplier(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/search_supplier', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchPurchases(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/search_purchases', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getRebatePurchase(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/get_rebate_purchase', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  // getPurchasebyName(res: FormData): Observable<Stockedit> {
  //   return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock_search/purchase', res, {
  //     headers: new HttpHeaders({
  //       'Access-Control-Allow-Origin': '*',
  //       Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
  //       cmptoken: localStorage.getItem('cmptoken')
  //     })
  //   });
  // }

  addPurchase(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/purchase', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addQuickPurchase(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/quick_purchase/add_quick_purchase', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addGoodsReceipt(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/goods_receipts/add_goods_receipt', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  saveTempPurchase(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/purchase/temp_purchase', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  saveTmpExternalStockTransfer(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/externalstocktransfer/temp_transfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getsaveTempPurchase(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/purchase/list_tpurchases', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getsaveTempExtStock(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/externalstocktransfer/list_tstocktransfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getTempPurchase(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/open_temp_purchase', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getTempExStockDraft(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/externalstocktransfer/open_temp_ex_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  removeFromDraft(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/removeDraft', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  removeFromExStockDraft(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/externalstocktransfer/remove_temp_transfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getpurchaseDetails(res: FormData): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/purchase_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getGoodsReceiptDetails(res: FormData): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/goods_receipts/goods_rec_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  
  getSubTotal(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/purchase/item_sum', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  voidPurchase(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/purchase_void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getOpStockbyNameRem(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/list_opening_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getUnAsignedStock(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/list_unasigned_van_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  assignStockToVan(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/assign_stock_to_van', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  remOpStock(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/opening_stock_remoove', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  manfDetailReport(res: FormData): Observable<Manufacture> {
    return this.httpClient.post<Manufacture>(this.PHP_API_SERVER + '/api/product_report/manufacture_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchase(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/get_purch_num', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getGoodsRecNo(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/goods_receipts/get_goods_rec_num', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseSummary(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/purchase_report/summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  purchaseDeatils(res: FormData, pageno: number): Observable<Reppurchdet> {
    return this.httpClient.post<Reppurchdet>(this.PHP_API_SERVER + '/api/purchase_preview' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseSummaryReport(res: FormData): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/purchase_report/summary', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseReturnSummary(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/purchase_report/return_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseInvDueummary(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_report/purch_inv_due_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  goodsRecieptReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_report/goods_reciept_rep?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  suppAgingRpt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_report/supp_aging?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  custMovmentRpt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/cust_movmnt?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseReturnProductSummary(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/purchase_report/return_product?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  ListVanline(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/vanline/list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addNewVanLine(Vanline: Vanline): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/vanline/add', Vanline, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateVanLine(Vanline: Vanline): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/vanline/update', Vanline, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  changeStatus(Vanline: Vanline): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/vanline/activate', { 'vanline_id': Vanline }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  changevanStatus(Vanline: Vanline): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/van/activate', { 'van_id': Vanline }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  ListVan(res: FormData, pageno: number): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van/list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addNewVan(Van: Van): Observable<Van> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/van/add', Van, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateVan(Van: Van): Observable<Van> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/van/update', Van, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  changeVanStatus(Vanid: Van): Observable<Van> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/van/activate', { 'van_id': Vanid }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getVanlinesName(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vanline/search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  godownSumPage(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/godown_report/summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  godownProduct(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/godown_report/product?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  godownTrans(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/godown_report/transaction?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  godownTransDetailed(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/godown_report/detailed?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  purchaseInvoiceRep(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_report/purchase_based?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  prodInvoiceRep(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_report/product_based?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  getNxtVanTranId(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/getNxtVanTranId', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getNxtVanRetTranId(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/getNxtVanRetTranId', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchVans(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van/search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchReceiptProducts(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/externalstockreceipt/search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  searchVanProducts(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van_transfer/search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanTransfer(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van_transfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanTransferEdit(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_transfer_edit', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readVanTransfers(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/van_transfer/report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  readVanTransfersItemReport(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van_transfer/item_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  vanSalereport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_sale_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  readVanTransfersInvoiceReport(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van_transfer/invoice_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createStaff(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/staff/add', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listAllStaff(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/staff/list_all', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  listAllActiveStaff(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/staff/list_all_active', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  changeStaffStatus(id): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/staff/change_status', { 'st_id': id }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  ListAllVan(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van/list_all', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanProducts(res: FormData, vanid: number): Observable<Van> {
    return this.httpClient.get<Van>(this.PHP_API_SERVER + '/api/van/list_products?vanid=' + vanid, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanAllProducts(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van/vanAll_products', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  readVanTransfersReporteByDate(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van_transfer/by_period?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  vanTransferReturn(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/transfer_return', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  ListAllVanReturns(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/van_transfer_return/report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updatePurchaseNote(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/update_purch_note', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getPurchasebyBcode(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock_search/purchasebcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getTranDetails(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/tran_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getTranReturnDetails(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/tran_return_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  voidVanTransfer(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/vantran_void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  voidVanTransferReturn(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/vantranreturn_void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProdByNamePrret(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_stock_products', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseReturn(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase/return', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchaseRetbyBcode(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_stock_barcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchaseReturn(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/get_purch_ret_num', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getpurchaseRetDetails(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/list_purchase_return', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidPurchaseRet(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/void_purchase_return', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listPurchaseReturns(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/list_all_purchase_returns?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  productionPreview(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/production/preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCustomers(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/list?page=' + curPage + '&per_page=' + perPage, {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  filterCustomer(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/filter_cust?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  filterLedger(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgers/filter_ledger?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanCalendarRpt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_calendar?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllVanlines(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vanline/list_all', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getAllPriceGroup(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/price_group/list_all', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createCustomer(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createCostCenter(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/addcostcenter', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateCostCenter(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/updatecostcenter', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createCostCenterSub(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/addcostcenterSub', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updatedCostCenterSub(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/updatecostcenterSub', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getCostCenter(data): Observable<any> {
      return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/list', data, {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  createCustOrSup(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createSup(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/add_sup', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateCustomer(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getCustomer(id): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/item', { cust_id: id }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchCustomer(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchBarcode(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/search_barcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchByPrdode(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/search_by_prdcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchInvNumber(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/search_inv', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  geProdctnId(id): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/next_production_id', { cust_id: id }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  gebranchTranId(id): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/next_tran_id', { cust_id: id }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  voidProduction(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/production/void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchasebyUean(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock_search/purchaseuean', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseAndreturn(res: FormData, pageno: number): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/purchase_report/purchase_return_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  expSumm(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/stock_expiry_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getUsersList(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/users_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBranchList(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/branch_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  changeUserStatus(Vanline: Vanline): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/user/activate', { 'user_id': Vanline }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  createUnitList(product,addBarcode = []): Observable<any> {
    product.addBarcodes = JSON.stringify(addBarcode);
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/add_list_product', product, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createFeatList(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.PHP_API_SERVER + '/api/add_list_feature', product, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanProductsReturn(res: FormData, vanid: number): Observable<Van> {
    return this.httpClient.get<Van>(this.PHP_API_SERVER + '/api/van/list_return_product', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesSummary(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  delveryChallanRpt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/delivery_challan_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesOrderRpt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/sales_order_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  creditNoteRpt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/cr_note_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  debitNoteRpt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/dr_note_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseOrderRpt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_report/purchase_order_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  rebateReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_report/purchase_rebate_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  cashCounter(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/cash_counter', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getCounterSetting(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/get_counter_setting', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesandRetunSummary(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/all_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  InvoicedueSummary(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/due_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  SalesAgentReport(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/sales_agent_wise_report', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  InvoicedueSummary2(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/aging?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  salesProductInvoice(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/product_invoice?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  salesrepbyserialno(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/salesrep_serial?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  BatchStock(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/batch_report/batch_wise_stock?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  batchStockRepPeriod(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/batch_report/by_period?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

 

  delChallanItemWise(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/delivery_challan_rpt_itemwise?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesProductReport(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/product_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  salesCustomerReport(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/customer_product_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  returnedProductReport(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/return_product_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchVanProductsReturn(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van/list_return_product', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBranchData(res: FormData): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/branch_data', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  recieptPaymentCount(res: FormData): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/branch_recipt_paymnt_count', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getCompanyData(res: FormData): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/company_data', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  updateCompany(res): Observable<Branch> {
    res.cmp_finyear_start = this.formatDate(res.cmp_finyear_start);
    res.cmp_finyear_end = this.formatDate(res.cmp_finyear_end);
    return this.httpClient.post<Branch>(this.PHP_API_SERVER + '/api/update_company', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllAccount(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_cashinhand_bank_ledger', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllBankAccount(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/bank_ledgers', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getAllNonAccountLedgres(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_non_acc_ledger', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllNonAccountLedgers(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_non_acc_ledgers', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCustomerLedgres(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_customer_ledger', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchLedgerByAccGroup(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_acc_ledger_by_grp', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')

      })
    });
  }

  listLedger(pageno): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/list_leadgers?page=' + pageno, {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listSupplier(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/show_suppliers', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createReceipt(data): Observable<any> {
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createCurrency(data): Observable<any> {
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/currency/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createServiceType(data): Observable<any> {
    // data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/service_type/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createFreightType(data): Observable<any> {
    // data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/freight_type/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createPDC(res:FormData): Observable<any> {
    // data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/add', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  submitPDC(res:any): Observable<any> {
    res.cheq_issue_date = this.formatDate(res.cheq_issue_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/submit_cheque', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  bouncePDC(res:any): Observable<any> {
    // data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/bounced_cheque', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  cancelPDC(res:any): Observable<any> {
    // data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/cancelled_cheque', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createInterReceipt(data): Observable<any> {
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/inter_add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateReceipt(data): Observable<any> {
    // data = this.updateDateEntry(data);
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateCurrency(data): Observable<any> {
    // data = this.updateDateEntry(data);
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/currency/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateServiceType(data): Observable<any> {
    
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/service_type/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateFreightType(data): Observable<any> {
    
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/freight_type/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updatePDC(data:FormData): Observable<any> {
    // // data = this.updateDateEntry(data);
    // data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getQtybyName(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/search_rates', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getQtybyNameNew(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/search_rates_new', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBatchSellRate(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_batch_sell_rate', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getQtybyBarcode(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/search_rates_barcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getQtybyBarcodeNew(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/search_rates_barcode_new', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateQty(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/update_qty', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  selBranchviseQty(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/get_unit_rates_branchvise', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  batchUnitRate(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/batch_unit_rate', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBranchesWithUnits(res: FormData): Observable<Branch> {
    return this.httpClient.post<Branch>(this.PHP_API_SERVER + '/api/get_branches_unit', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getBatches(res: FormData): Observable<Branch> {
    return this.httpClient.post<Branch>(this.PHP_API_SERVER + '/api/stock/get_batches', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  selBranchviseUnits(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/update_sales_rates', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  removePriceGropPrd(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/remove_pricegroup_prd', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesInv(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/sales_invoice', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesInvData(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/sales_invoice_data', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesInvBranch(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/sales_invoice_bybinv', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getStockBranches(): Observable<Branch[]> {
    return this.httpClient.get<Branch[]>(this.PHP_API_SERVER + '/api/get_stock_branches', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getGodownBatchStock(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_godown_batch_stock', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  

  getBatch(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_batch_stock', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  searchLedgerList(res: FormData, pageno): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/search_ledger_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchIDELedgerList(res: FormData, pageno): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/creditNote/search_ide_ledger_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  searchIDEDELedgerList(res: FormData, pageno): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/debitNote/search_idede_ledger_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchSupplierList(res: FormData, pageno): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/search_supplier_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchCustomerList(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/customer/search_cust_list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCustomerBalance(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/get-balance', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSupplierBalance(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_supplier_balance', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCustomerInvoice(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/get-pending-invoice', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getSupplierInvoice(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/supplier/get_supplier_pending_invoice', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createDueReceipt(data): Observable<any> {
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/due_rec_add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createDuePayment(data): Observable<any> {
    data.pay_date = this.formatDate(data.pay_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/due_payment_add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateDueReceipt(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/due_rec_update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateDuePayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/due_payment_update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllDueReceipt(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/due_rec_list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  getAllWalletDueReceipt(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/due_rec_list_by_wallet?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  voidDueReceipt(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/void_due_rec', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  voidDuePayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/void_due_payment', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  voidWalletDueReceipt(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/void_due_rec_by_wallet', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  getAllDuePayment(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/due_payment_list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  voidWalletDuePayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/void_due_payment_by_wallet', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  getAllWalletDuePayment(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/due_payment_list_by_wallet?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  branchTransfer(res): Observable<Van> {
    res.tran_date = this.formatDate(res.tran_date);
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/branchtransfer/transfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockSet(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/stock/stock_set', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  listReceivedTransfers(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/branchtransfer/transfer_received?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listTransfers(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/branchtransfer/transfer_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  listBarcode(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/viewdetails', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  addProfile(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/addprofile', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editProfile(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/editprofile', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  editItem(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/edititem', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editBcode(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/editbarcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editPrice(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/editprice', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  editCurr(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/editcurr', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editPrate(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/editprate', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editMfg(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/editmfg', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editExp(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/editexp', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editShop(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/barcode/editshop', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  acceptOrrejectTransfer(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/branchtransfer/updateStatus', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  transferPreview(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/branchtransfer/preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getLedgers(res: FormData): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/ledger_all', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanlines(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vanline/all', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  get_godown_tran_num(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/get_godown_tran_num', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  listSendTransfers(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/branchtransfer/send_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listSendTransfersSummary(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/branchtransfer/send_list_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listReceivedTransfersSummary(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/branchtransfer/received_list_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidTransfer(res: FormData): Observable<Vanline> {
    return this.httpClient.post<Vanline>(this.PHP_API_SERVER + '/api/branchtransfer/transfer_void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listGwnTranfer(pageno): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/godown_transfer/list?page=' + pageno, {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  setRates(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/set_rate_code', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getRates(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_rate_code', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateTransfer(res): Observable<Van> {
    res.tran_date = this.formatDate(res.tran_date);
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/branchtransfer/update_transfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createPayment(data): Observable<any> {
    data.pay_date = this.formatDate(data.pay_date);
    return this.httpClient.post(this.PHP_API_SERVER + '/api/payment/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updatePayment(data): Observable<any> {
    data.pay_date = this.formatDate(data.pay_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createInterPayment(data): Observable<any> {
    data.pay_date = this.formatDate(data.pay_date);
    return this.httpClient.post(this.PHP_API_SERVER + '/api/payment/inter_add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateInterPayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/inter_update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllPayment(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  getPaymentLog(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/log?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  getAllInterBranchPayment(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/inter_list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  getAllInterBranchReceipt(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/inter_rec_list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  voidPayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/void', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  voidPDC(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/void_pdc', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }


  voidCrNotes(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/creditNote/void_cr_notes', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  voidDrNotes(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/debitNote/void_dr_notes', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  rejectInterPayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/inter_reject', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  voidReceipt(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/void', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  getAllReceipt(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  filterReceiptLog(data, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/log?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  getReceiptLog(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/log?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  getAllCurrency(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/currency/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  getAllServiceType(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/service_type/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  getAllFreightType(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/freight_type/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  listAllCurrency(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/currency/listall', {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  
  getAllSalesAgent(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/list_sales_agents?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  searchCustCategory(res: FormData): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/customer_category/search_customer_category', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listCustCategory(res:any=''): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer_category/listall_customer_category', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllCustCategory(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer_category/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  createCustomerCategory(data): Observable<any> {
  
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer_category/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCustomerCategory(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer_category/get_item', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateCustomerCategory(data): Observable<any> {
    // data = this.updateDateEntry(data);
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer_category/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  changeSalesAgentStatus(id): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/change_sales_agent_status', { 'sa_id': id }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  filterReceipt(data, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/list?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  filterPDC(data, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/list?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }


  filterCheckedPDC(data, curPage, perPage): Observable<any> {
    // data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/datewise-pdc-list?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }


  listPdc( curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/list?page=' + curPage + '&per_page=' + perPage,{},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  listPdcByFilter(data:FormData, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/filter_list?page=' + curPage + '&per_page=' + perPage,data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  getStockbyItem(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/stock_search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getStockbyItemByIds(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/stock_search_ids', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getGodownStock(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/stock/godown_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLedgerBalance(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledger/get-ledger-balance', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSupplierCurrencyDetails(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgers/get_supplier_currency_details', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPDCcount(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/pdc_count', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  batchExCount(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/batch/expiry_notification_count', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  

  getSalesOrdercount(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_orders/get_sale_order_count', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  dateWisePDClist(data): Observable<any> {
    //  data.cheq_issue_date = this.formatDate(data.cheq_issue_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/datewise-pdc-list', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  dateWiseSOlist(data): Observable<any> {
    //  data.cheq_issue_date = this.formatDate(data.cheq_issue_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_orders/get_sale_order_pending_list', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  
  batchExlist(data): Observable<any> {
      data.ex_date = this.formatDate(data.ex_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/batch/expiry_notification',data,{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getSupBalance(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledger/get-sup-ledger-balance', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSupPurchase(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/getSuppPurchase', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  geSalesReturnId(id): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/next_return_id', { cust_id: id }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getLedgerDetailRep(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgerReport/ledger_detail_rep', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLedgerDetailRepExcel(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgerReport/ledger_detail_rep_excel', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getNextPaymentId(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/next-id', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getNextReceiptId(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/next-id', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getNextPdcId(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/next-id', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  getGodownItemwise(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/godowns_item_wise', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  addDamage(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/add_damage', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getUnitsItemwise(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/units_item_wise', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchSoldProducts(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/search_sold_product', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllBatchOfProduct(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_all_batch_of_product', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getDamageList(res: any): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/stock/list_damage', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesItemReturn(data): Observable<any> {
    data.ret_date = this.formatDate(data.ret_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_return', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllTaxCategory(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/tax-category/getall', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  createJournal(data): Observable<any> {
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/journal/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateJournal(data): Observable<any> {
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/journal/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  openPDF(formData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgerReport/ledger_detail_pdf', formData, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getNextJournalId(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/journal/next-id', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllJournal(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/journal/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }


  voidJournal(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/journal/void', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  getReceipt(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/viewReceipt', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getCurrency(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/currency/get_item', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getServiceType(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/service_type/get_item', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getFreightType(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/freight_type/get_item', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  deleteServiceType(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/service_type/void', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  deleteFreightType(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/freight_type/void', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPDCview(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/post-dated-cheques/view', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getInvoiceDueReceipt(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/viewInvoiceDueReceipt', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getInvoiceDuePayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/viewInvoiceDuePayment', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getInvoiceDuePaymentByWallet(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/viewInvoiceDuePaymentByWallet', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getInvoiceDueReceiptByWallet(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/viewInvoiceDueReceiptByWallet', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/viewPayment', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  groupSummaryReport(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/account_report/acc_group_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  trialBalanceReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/trialbalance?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProfitAndlossReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/profitandloss?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken'),
        spracs: localStorage.getItem('spracs')
      })
    });
  }

  getBalanceSheetReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/balancesheet?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken'),
        spracs: localStorage.getItem('spracs')
      })
    });
  }
  superAccess(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/superaccess', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  verificationOfBalanceReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/verificationofbalance?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  groupSummaryToatalReport(res: FormData): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/account_report/excel_data', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  listDaybook(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/account_report/dayBook?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listDialySummay(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/dialy_summary_reports?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listCustomerDet(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/customer_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchCustomerDet(data, pageno): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer_summary?page=' + pageno,data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  CustomerDet(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer_summary/cus_details', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  VouchersDet(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer_summary/vouchers_details', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchVoucherType(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_voucher_type', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesReturnList(res: FormData, pageno): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_return_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesReturnVoidList(pageno): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_return_void_list?page=' + pageno, {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  inputVatReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vat_report/input_vat_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  inputExpenseVatReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vat_report/input_expense_vat_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  DailyVatReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vat_report/day_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  outputVatReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vat_report/output_vat_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  salesVoid(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales_report/sales_void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  readbranchTransfersItemReport(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/stock_tran_report/product_based?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  readbranchTransfersItemReportAll(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/stock_tran_report/summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }





  readbranchTransfersItemReceivedReport(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/stock_tran_report/product_received?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  getSalesReturnInv(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/sales_return_invoice', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getJournal(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/journal/viewJournal', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  salesReturnVoid(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales_report/sales_return_void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchjournalList(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/journal/search_list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllBankLedgers(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_bank_ledger', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllCashAccount(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_cashInhand_ledger', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  salesReturnSummary(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/return_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getnextContraId(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/contra/next-id', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  createContra(data): Observable<any> {
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/contra/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateContra(data): Observable<any> {
    data.rec_date = this.formatDate(data.rec_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/contra/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getAllcontra(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/contra/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }


  getContra(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/contra/viewContra', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidContra(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/contra/void', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }


  getsalesReturnPrev(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_return_preview', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }




  searchDuelList(data, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/due_rec_list?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  searchWalletDuelList(data, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/due_rec_list_by_wallet?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  searchPaymentlList(data, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/due_payment_list?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  searchWalletPaymentList(data, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/due_rec_list_by_wallet?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }


  searchContraList(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/contra/search_list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  searchPaymentList(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/search', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  filterPaymenttLog(data, curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/log?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }



  ledgerList(data: FormData, pageno): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgerReport/ledger_list?page=' + pageno, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  salesItemReturnWithInv(data): Observable<any> {
    data.ret_date = this.formatDate(data.ret_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_return_inv', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateDateEntry(info) {
    Object.keys(info).forEach((k, i) => {
      if (typeof info[k] === 'object' && info[k] instanceof Date) {
        info[k] = this.formatDate(info[k]);
      }
    });
    return info;
  }

  updatePrivilages(data: FormData) {
    return this.httpClient.post<User>(this.PHP_API_SERVER + '/api/privilage/update_privilage', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  getMenuByGroup(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/menu/get_menu_by_group', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getsaleReturnTotal(res: FormData): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/get_sales_and_return_total', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getAccGroup(res: FormData): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/search_acc_group', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getUserPrivilages(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/privilage/get_user_privilages', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getUserOtherPrivilage(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/privilage/get_user_other_privilages', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updatePrivilage(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/privilage/update_privilage', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  isLedgerDeletable(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/isLedgerUsed', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  isCustomerDeletable(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/isCustomerUsed', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  isSupplierDeletable(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/ledgers/isSupplierUsed', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  DeleteLedger(res: FormData): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.PHP_API_SERVER + '/api/delete_ledger', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  DeleteCustomer(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/delete_customer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  DeleteSupplier(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgers/delete_supplier', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listGodown(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/godown/list', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  openingbalanceSummaryReport(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/account_report/opening_balance_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  addAsCustomer(res: Ledger): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/addLedgAsCustomer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  addAsSupplier(res): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/addLedgAsSupplier', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidDamage(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/void_damage', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getPurchInv(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/getoldPurchInv', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  sqlConnect(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sqlConnect', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updatePurchase(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/updatePurchase', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  // UpdateGoodsReceipt(res: FormData): Observable<any> {
  //   return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/goods_receipts/update_goods_receipt', res, {
  //     headers: new HttpHeaders({
  //       'Access-Control-Allow-Origin': '*',
  //       Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
  //       cmptoken: localStorage.getItem('cmptoken')
  //     })
  //   });
  // }

  UpdateGoodsReceipt(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/goods_receipts/update_goods_receipt', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  formatDate(date) {
    if (date) {
      const d = new Date(date);
      console.log(d);
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      return [year, month, day].join('-');
    } else {
      return '';
    }
  }
  formatTime(date) {
    const d = new Date(date);
    let hour = '' + d.getHours();
    let min = '' + d.getMinutes();
    if (hour.length < 2) {
      hour = '0' + hour;
    }
    if (min.length < 2) {
      min = '0' + min;
    }
    return [hour, min, '00'].join(':');
  }


  getLedgerPrivilages(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledger_privilage/get_ledger_privilages', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateLedgerPrivilage(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledger_privilage/update_privilage', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listGodownbyBranch(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/godown/list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  damageSummaryReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product_report/damage_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  productCommisionReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product_report/product_commision?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  productCatgoryReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product_report/product_category?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesChart(pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_report/chart', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchaseChart(pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_report/chart', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateMultiBrachStockRate(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/update_multi_branch_rates', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getTransferLedgers(res: FormData): Observable<Group> {
    return this.httpClient.post<Group>(this.PHP_API_SERVER + '/api/branch_ledgers', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  edilogSumPage(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/purchase_report/editlog?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateSettings(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/print_settings/update_templete', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSettings() {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/print_settings/get_templete', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  aging(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_outstanding_receivable', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  saleAnalysis(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_sales', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseAnalysis(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_purchase', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  topProduct(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_top_sold_prods', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getTopCustomers(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_top_customers', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getTopSuppliers(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_top_suppliers', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getTotalSale(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_total_sale', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getTotalQuantity(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_total_qty', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getTotalPurchase(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_total_purchase', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getTotalReceiptAndPayment(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_total_receipt_payment', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getAdminDashboard(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_admin_dashboard', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getTotalStock(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_total_stock', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getTotalCashinHand(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_cashinhand_total', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVat(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_vat', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getDashNotification(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_notification', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  cashCreditPurchase(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/purchase_report/cash_credit_purchase?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesAuditor(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/sales_auditor?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  branchTransferAuditor(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/stock_tran_report/branch_transfer_auditor?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  expensesReport(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/account_report/expense_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBranchbyType(res: FormData): Observable<Branch[]> {
    return this.httpClient.post<Branch[]>(this.PHP_API_SERVER + '/api/get_branches_by_type', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  bankReportAuditor(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/account_report/bank_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  detailedBankReportAuditor(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/account_report/bank_report_detailed?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  employeeReportAuditor(res: FormData, pageno: number): Observable<Reppurchsum> {
    return this.httpClient.post<Reppurchsum>(this.PHP_API_SERVER + '/api/account_report/employee_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  DailyVatSummary(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vat_report/day_summary1', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  DailyVatDetailedSummary(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/vat_report/day_summary_datewise', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllTaxCategoryIds(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/tax-category/getallcatids', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getMissingStockList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/stock/list_misiing_stock?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addtoMissingStock(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/add_missing_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesDetailAuditor(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/sales_detail_auditor?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidMissingStock(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/void_missing_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addExcessStock(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/add_excess_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getExcessStockList(res: any,pageno): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/stock/list_excess_stock?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getStockbyBarcode(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/stock_search_by_barcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getOpeningUnit(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_opening_unit', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  openingStockSearchBarcode(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_search/product_search_barcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidExcessStock(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/void_excess_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  misiingStockSummaryReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/misiing_stock_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  excessStockSummaryReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/excess_stock_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  lowStockAlert(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/low_stock_alert?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  excessStockAlert(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/excess_stock_alert?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateRateCodeEncp(data: FormData) {
    return this.httpClient.post<User>(this.PHP_API_SERVER + '/api/privilage/update_rate_code_encp_type', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLowStock(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_low_stock', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getExcessStock(): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/dashboard/get_excess_stock', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  branchTranInvBased(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_tran_report/branch_trans_inv_based?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  analyseUnusedProds(data: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/analyse_unwanted_prods',data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  analyseInactiveLedgers(data: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgers/analyse_inactive_ledgers',data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  removeUnusedProds(data: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/remove_unwanted_prods',data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  removeInactiveLedger(data: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ledgers/remove_inactive_ledgers',data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  branchReciInvBased(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_tran_report/branch_reci_inv_based?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockVerify(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/verify_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanStockVerify(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/van_verify_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  superAccessAdvancedSettings(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/privilage/check_advance_setting_access', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getStockbyGodown(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/stock/get_stock_by_godown', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getStckVrfyRefNum(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_stock_vrfy_ref_no', '',{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanStckVrfyRefNum(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/get_van_stock_vrfy_ref_no', '',{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getStckVrfyhstry(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_stock_vrfy_histry', res,{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanStckVrfyhstry(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/get_van_stock_vrfy_histry', res,{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVerifiedStockList(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/stock_vrfy_list?page=' + pageno, res,{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getVanVerifiedStockList(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/van_stock_vrfy_list?page=' + pageno, res,{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockVerifyByLog(res: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/verify_stock_by_log', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockVerificationSummary(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/stock_verification_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  purchaseDetailSummary(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/purchase_report/purchase_detail_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  unverifiedStockSummary(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/unverified_stock_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  readVanStockTracking(res: FormData, pageno: number): Observable<Vanline> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van_transfer/van_stock_tracking?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanDistributorsRep(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_report/distributors', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  vanDetailedRep(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_report/detailed', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanCustTransactionRep(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_reports/van_cust_transaction', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanStockverifyRep(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_reports/van_stock_verify_rep?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  branchDetailedRep(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_tran_report/branch_detailed_rep', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  itemTrackingRep(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/item_tracking_rep', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  itemMovementDetails(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/item_movement_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getUploadedBarcodeList(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/list_stck_barcode?page=' + pageno, res,{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  uploadBarcodeFile(res: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/upload_barcode', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBarcodeList(res: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_barcode_list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  godwnPrdoductStock(res: FormData,pageno: number) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_stock_by_gd?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchaseProduct(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock_search/get_purch_prod', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getUnitSalesRate(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/get_unit_sales_rate', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchaseProduct1(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock_search/get_purch_prod1', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  itemMovementSummaryReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/item_movement_rep?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  itemUnitWiseTarckingReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/item_unitwise_tracking?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  freeGoodsReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/free_goods_reports?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockVerificationInvBasedRep(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/stock_verifictn_inv_based?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  InvoicedueRecSummary(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/due_recipt_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  InvoiceduePaymentSummary(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/purchase_report/due_payment_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updatePurchSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_setting/update_purch_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_setting/get_purch_setiing',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateDasboardSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/dashboard_setting/update_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateAdminDasboardSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/dashboard_setting/update_admin_dashboard', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getDasboardSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/dashboard_setting/get_setiing',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/get_sales_setiing',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getVoucherSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/voucher_settings/get_voucher_setiing',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getMailSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/mail_setting/get_mail_setting',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateMailSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/mail_setting/update_mail_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanDiscountSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/get_van_discount_settings',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getMenuMaster(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/get_menu_master',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getAdminNotification(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/get_admin_notification',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateAdminNotification(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_admin_notification', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateSalesSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_sales_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateGodownSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_godown_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPriceChekerSettings(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/get_price_checker_setting',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updatePriceChekerSettings(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_price_checker_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateSalesQtnSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_sales_qtn_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateSalesOrderSettings(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_sales_order_settings', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateVoucherSettings(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/voucher_settings/update_voucher_settings', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updatePrintStyle(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_print_style', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateSOPrintStyle(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_so_print_style', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateDNPrintStyle(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_dn_print_style', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllNonCashLedgers(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_non_cash_ledgers', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCashBankBookRep(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/cash_and_bank_book', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCashBookRep(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/account_report/cash_book', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getDefaultLedgers(res: FormData): Observable<Group> {
    return this.httpClient.post<Group>(this.PHP_API_SERVER + '/api/default_ledgers', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPurchaseList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/purchase/list_purchase?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getGoodsReceiptsList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/goods_receipts/list_goods_receipts?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getPurchaseQtn(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/purchase/get_qtn_num', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addPurchQuatation(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/add_purchase_qtn', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getNextPurchaseQtn(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/purchase/get_qtn_num', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getPurchaseQtnList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/purchase/list_quatation?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidpurchaseorderitem(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase/void_purchase_order_item', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getpurchQtnDetails(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/purchase/quatation_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updatePurchaseQtn(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/updatePurchaseQtn', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLowStockProds(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.PHP_API_SERVER + '/api/get_low_stock_prods', {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProdByCat(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_prod_by_cat', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProdDetByCat(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_all_prods_by_cat?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProdDet(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_product_detail', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateMultiProds(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/update_multiple_prods', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

    addProdLocation(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/add_product_location', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  addProductCommision(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/privilage/add_product_commision', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getProdBylocation(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_product_loctn', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getGodownWiseStock(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/godown_stock/get_stock_gdnwise', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getProductStock(res: FormData): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/godown_stock/get_product_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getBranchWiseStock(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/get_branchwise_stocks', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getsaleProdDetails(res: FormData): Observable<any> {
    if(res.get('date')){
      res.set('date', this.formatDate(res.get('date')));
    }
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/get_prod_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getExTransProdDetails(res: FormData): Observable<any> {
    if(res.get('date')){
      res.set('date', this.formatDate(res.get('date')));
    }
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/externalstocktransfer/search_ext_stk_transfer_product', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockByUnit(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/get_stock_by_unit', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLastSaleToCust(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/last_inv_to_cust', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addSalesQuotation(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/add_quotation', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSaleQtRefNo(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/get_qt_ref', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  draftSalesQuotn(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/draft_quotation', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCustomerByCode(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/search_cust_code', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesDraftList(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/list_drafts', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  copyTransferRef(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/copy_ref', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  copySalesRef(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/copy_sale', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  removeSalesDraft(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/remove_drafts', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesDraft(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/get_draft_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesQtnList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/list_sales_qtn?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesQtnListWithNotes(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/list_sales_qtn_with_notes?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getSalesQtn(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/get_qtn_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateSalesQuotation(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/update_quotation', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  geteditSalesItem(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/edit_item', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesQtnPreview(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/get_qtn_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getSaleRefNo(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/get_sales_ref', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  addSales(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/add_sales', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  addCreditNotes(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/creditNote/add_credit_notes', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addDebitNotes(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/debitNote/add_debit_note', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesInvList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/list_sales_inv?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getSalesdescription(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/sales_descipt?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getSalesInvLogList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/list_edit_log?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchCrNList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/creditNote/list_cr_notes?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  searchDrNList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/debitNote/list_dr_notes?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCrNoteList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/creditNote/list_cr_notes?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getDrNoteList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/debitNote/list_dr_notes?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesInvPreview(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/get_inv_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCrNotePreview(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/creditNote/cr_note_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getDrNotePreview(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/debitNote/dr_note_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  productionVoidPreview(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/production/void_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLedByNature(res: FormData): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/search_ledger_by_nature', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addAgent(res: FormData): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/add_sales_agent', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchAgents(res: FormData): Observable<Ledger> {
    return this.httpClient.post<Ledger>(this.PHP_API_SERVER + '/api/search_sales_agents', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getAllAgents(res:any=''): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_all_sales_agents', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesDet(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/get_sale_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getsalesinvoicecopyDetails(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER +'/api/sales/get_copy_sale_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  getConvertSalesDet(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/get_convert_sale_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCrNoteDet(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/creditNote/get_cr_note_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getDrNoteDet(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/debitNote/get_dr_note_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editSales(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/edit_sales', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editCrNote(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/creditNote/edit_cr_note', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editDrNote(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/debitNote/edit_dr_note', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLogPreview(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/get_log_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getChallanNo(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/get_challan_no', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  addChallan(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/add_challan', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getDeliveryChallanList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/list_delivery_challan?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getDelChallanPreview(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/get_del_challan_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getDelChallanDet(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/get_del_challan_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  editChallan(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/edit_challan', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getLastChallanToCust(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales/last_challan_to_cust', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getChallanLogPreview(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/get_challan_log_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getEditChallanItem(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/edit_challan_item', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  importPurchaseQtn(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase_qtn_import', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getPayrollSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payroll_settings/get_setting',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updatePayrollSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payroll_settings/update_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getDepByName(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_departments', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createDep(dep: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/add_department', dep, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateDep(dep: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/update_department', dep, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getDedByName(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_deductions', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllDeductions(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/deductions/list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  changeDedStatus(id: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/deductions/activate', { 'id': id }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createDed(dep: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/add_deduction', dep, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateDed(dep: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/update_deduction', dep, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAddByName(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_additions', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getAllAdditions(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/additions/list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  changeAddStatus(id: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/additions/activate', { 'id': id }, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createAdd(dep: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/add_addition', dep, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateAdd(dep: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/update_addition', dep, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getDesByName(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_designations', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createDes(des: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/add_designations', des, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateDes(des: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/update_designations', des, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createEmployee(employee: any): Observable<Employee> {
    return this.httpClient.post<Employee>(this.PHP_API_SERVER + '/api/add_employee', employee, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getEmployeesList(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/employees_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getCompanyLeaveList(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/company_leave/list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  saveHoliday(attedance: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/company_leave/add', attedance, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  deleteLeave(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/company_leave/remove', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getEmpPayroll(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/emp/get_payroll', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getEmployeesAttendanceList(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/emp/attendance_list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  saveEmployeeAttendance(attedance: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/emp/save_attendance', attedance, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  deleteEmployee(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/remove_employee', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateEmployee(employee: any): Observable<Employee> {
    return this.httpClient.post<Employee>(this.PHP_API_SERVER + '/api/edit_employee', employee, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getDocList(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/document_list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateDoc(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/edit_doc_expiry', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getEmpReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/emp_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  importCustomersByLog(res: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/import_log', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  storeCustomersByLog(res: any) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/store_log', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchMenu(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/search_menu', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  topProducts(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/top_sold_prods_report?page=' + pageno, res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }

  topCustomer(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/top_customers_report?page=' + pageno, res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }

  topSupplier(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/top_suppliers_report?page=' + pageno, res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }
  importProductExcel(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product-import' , res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken'),
    }) });
  }
  importProductExcelNew(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product/import' , res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken'),
    }) });
  }
  getExcelRowCount(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product/get_excel_row_count' , res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken'),
    }) });
  }

  importOpeningStock(res: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/import_opening_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  storeOpeningStockExcel(res: FormData ) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/store_opening_stock_excel', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProductDetails(prd_id): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/product-details/' + prd_id, {headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }
  getBranchCashAccount(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/get_branch_cash_account', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  importSuppliersByLog(res: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/import_supplier_log', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  storeSuppliersByLog(res: any) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/customer/store_supplier_log', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  copyStockDifferentBranch(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/copy_stock_different_branch', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllTaxCategories(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/tax-category/getalltaxcat', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  createExpanseTaxPayment(data): Observable<any> {
    data.pay_date = this.formatDate(data.pay_date);
    return this.httpClient.post(this.PHP_API_SERVER + '/api/payment/addexptax', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateExpanseTaxPayment(data): Observable<any> {
    data.pay_date = this.formatDate(data.pay_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/updateexptax', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getAllExpenceTaxPayment(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/exptax_list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  gettaxSuppByName(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/exptax_supp_list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  gettaxSuppByVatNo(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/exptax_supp_by_vat_no', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getTaxPayment(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/viewTaxPayment', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  copySalesQtnToSale(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales/copy_qtn_to_sale', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateStockPurchRate(res: Stockedit): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/stock/updatepurchrate', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getTrasferSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/inter_branch_trasnfer_setting/get_setting',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updateTransferSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/inter_branch_trasnfer_setting/update_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPrdSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product_setting/get_setting',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  updatePrdSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product_setting/update_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  dlChallanSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_dl_challan_setting', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanSetting(data: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales_setting/update_van_settings', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  listExtStockTransfer(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstocktransfer/list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getNextStockTransId(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstocktransfer/next_tran_id', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllSundryCrDrLedgers(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/getsundrycrdrledgers',{}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  extStockTransfer(res): Observable<Van> {
    res.tran_date = this.formatDate(res.tran_date);
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/externalstocktransfer/transfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getExtTransferInfo(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstocktransfer/getsingleTransfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  extStockTransferUpdate(res): Observable<any> {
    res.tran_date = this.formatDate(res.tran_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstocktransfer/update', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidExtStockTransfer(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstocktransfer/void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  listExtStockReceipt(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstockreceipt/list?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getNextStockRecId(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstockreceipt/next_tran_id', {}, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  extStockReceipt(res): Observable<Van> {
    res.tran_date = this.formatDate(res.tran_date);
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/externalstockreceipt/create', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getExtReceiptInfo(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstockreceipt/getsingle', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  extStockReceiptUpdate(res): Observable<any> {
    res.tran_date = this.formatDate(res.tran_date);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstockreceipt/update', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidExtStockReceipt(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/externalstockreceipt/void', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  extTranInvBased(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ext_tran_report/ext_trans_inv_based?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  extReciInvBased(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/ext_tran_report/ext_reci_inv_based?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  extTranDet(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/ext_tran_report/ext_trans_details?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  extReciDet(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/ext_tran_report/ext_reci_details?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  expTaxPaymentReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/exptax_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  recieptReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/receipt_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getLastPurchaseOfProd(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/purchase/get_last_pruch_of_product', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getClientSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/client_settings/get_client_settings',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getClientSettingBykey(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/client_settings/get_client_setting_by_key',data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateClientSettings(data) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/client_settings/update_client_settings', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getEnbaleDisableModuleSetting(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/enable_dsable_module/get_settings',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getEnbaleDisableModuleSettingBykey(): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/enable_dsable_module/get_setting_by_key',null, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateEnbaleDisableModuleSettings(data) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/enable_dsable_module/update_settings', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPriceGroups(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/price_group/list?page=' + curPage + '&per_page=' + perPage, {},
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  createPriceGroup(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/price_group/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updatePriceGroup(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/price_group/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPriceGroupById(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/price_group/getbyid', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPriceGroupProductAndRate(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/price_group/list_all_product_and_rate', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchVanProductsExtStkTrans(res: FormData): Observable<Van> {
    return this.httpClient.post<Van>(this.PHP_API_SERVER + '/api/van_transfer/search_ext_stk_transfer', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  importSalesValidate(res: FormData) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/import_sales_validate', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  importSales(res) {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/import_sales', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  removeWriteOff(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/receipt/remove_writeOff', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  
  removePayWriteOff(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/payment/remove_writeOff', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  topSheetReport(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van/top_sheet',data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getPrdByCatId(id: any): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/getall_prd_by_cat/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  productUnitDetails(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product_unit_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  weightScaleItems(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/weight_scale_items?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getUnitByPrdId(id: any): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/getall_unit_by_prod/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  stockUnitDetails(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock_report/unit_wise_stock_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  vanStockTrackingUnitWise(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_transfer/van_stock_tracking_unitwise?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  vanItemtWiseTracking(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_report/van_itemwise_tracking?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanVisitingReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_report/van_visiting_report?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getVanStock(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/van_stocks', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }



  unitPrdtWise(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/get_unit', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanProducts(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/get_van_products?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addtoVanMissingStock(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/add_van_missing_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addtoVanExcessStock(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/add_van_excess_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchCostCenter(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/search_cc', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanMissingStockList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/list_van_misiing_stock?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  voidVanMissingStock(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/void_van_missing_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getVanExcessStockList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/list_van_excess_stock?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchCostCenterSub(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/search_ccs', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getCatByPrdId(id: any): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/getall_cat_by_prd/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidVanExcessStock(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/void_van_excess_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchCostCatgory(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/search_ccat', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  vanMissingStkRep(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_report/van_missing_stock?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  costCategoryReport(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/costcenter/cost_cat_rpt', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getProductExcessItem(res: FormData): Observable<Stockedit> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/van_stock/excess_search_product', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllCostCentId(id: any): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/get_cost_cent/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  excessVanStockSummaryReport(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/van_stock/excess_van_stock_summery?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllCostCentSubId(id: any): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER + '/api/get_cost_cent_sub/' + id, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  addSalesOrder(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales_orders/add_sales_order', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getsalecopyDetails(res: FormData): Observable<Purchase> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales_orders/get_sales_order_copy', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateSalesOrder(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales_orders/update_sales_order', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  convertToSales(res): Observable<any> {
    return this.httpClient.post<Stockedit>(this.PHP_API_SERVER + '/api/sales_orders/convert_to_sale', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getOrderSaleRefNo(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales_orders/get_order_sales_ref', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getCreditNoteRefNo(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/creditNote/get_cr_note_refNo', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getDebitNoteRefNo(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/debitNote/get_dr_note_refNo', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getMeasure(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales_orders/get_measurement', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getSalesOrderInvPreview(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales_orders/get_sales_order_preview', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getSalesOrderList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales_orders/list_sales_order?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  // getSalesOredrPreview(res: FormData): Observable<Godown> {
  //   return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales_orders/get_inv_preview', res, {
  //     headers: new HttpHeaders({
  //       'Access-Control-Allow-Origin': '*',
  //       Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
  //       cmptoken: localStorage.getItem('cmptoken')
  //     })
  //   });
  // }

  getSalesOrderDetails(res: FormData): Observable<Godown> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/sales_orders/get_sale_order_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  salesQuotationSummary(res: FormData, pageno: number): Observable<Sales> {
    return this.httpClient.post<Sales>(this.PHP_API_SERVER + '/api/sales_report/sales_quotation_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
getWarrantyStockList(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/stock/list_warranty_stock?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
 addtoWarrantyStock(res: any): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/add_warranty_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidWarrantyStock(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/void_warranty_stock', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  voidChallan(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/void_challan', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  voidQuotation(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/void_quotation', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  voidGoodsReceipt(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/goods_receipts/void_goods_receipt', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  searchDeliverychellan(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/search_delivery_chellan', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  rate_history(res): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/sales/rate_history', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  godownRepPeriod(res: FormData, pageno: number): Observable<Repprddet> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/godown_report/by_period?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  getAllDelChallanDet(res: any): Observable<any> {
    return this.httpClient.post<Purchase>(this.PHP_API_SERVER + '/api/sales/get_all_del_challan_details', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  
  warrantyStockSummaryReport(res: any,pageno: number): Observable<any> {
    return this.httpClient.post<Godown>(this.PHP_API_SERVER + '/api/stock_report/warranty_stock_summary?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllOffer(curPage, perPage, data= {}): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/offer/list?page=' + curPage + '&per_page=' + perPage, data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }

  createOffer(data): Observable<any> {
    let sdate= this.formatDate(data.sdate);
    data.sdate = sdate + ' ' + this.formatTime(data.sdate);
    let edate= this.formatDate(data.edate);
    data.edate = edate + ' ' + this.formatTime(data.edate);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/offer/add', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  voidOffer(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/offer/void', data,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
          cmptoken: localStorage.getItem('cmptoken')
        })
      });
  }
  getPrdName(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/get_branch_rates', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  editrate(values): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/stock/update_multiple_branch_rate', values, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  getSingleOffer(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/offer/view', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  updateOffer(data): Observable<any> {
    let sdate= this.formatDate(data.sdate);
    data.sdate = sdate + ' ' + this.formatTime(data.sdate);
    let edate= this.formatDate(data.edate);
    data.edate = edate + ' ' + this.formatTime(data.edate);
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/offer/update', data, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


  branchStock(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<Repprddet>(this.PHP_API_SERVER + '/api/product_report/branch_stock?page=' + pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }
  exportAllProducts(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product/export_all_products?page='+ pageno, res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

  getAllOpeningStockList(res: FormData, pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/list_all_opening_stock?page=' + pageno, res,{
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


getpricelist(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/api/product/get_price_list', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }

}

