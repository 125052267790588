import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { Catagory } from 'src/app/model/catagory.model';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { MatTabGroup } from '@angular/material/tabs';



@Component({
  selector: 'app-catagory',
  templateUrl: './catagory.component.html',
  styleUrls: ['./catagory.component.css']
})
export class CatagoryComponent implements OnInit {

  @ViewChild('myTabGroup', { static: false }) tabGroup: MatTabGroup;

  cat_name: any;
  cat_remarks: any;
  cat_pos = 0;
  cat_tax_cat_id: any;
  allcat: any;
  pageLoadingImg:boolean;

  selectedData: any = { cat_id: null, error: null, cat_name: null,message:null,cat_pos:null};
  result = '';
  catagories:string[];
  taxCategories = [];
  up: any;
  printOpt = [
    {id:0, value: 'None'},
    {id:1, value: 'Printer1'},
    {id:2, value: 'Printer2'},
    {id:3, value: 'Printer3'},
    {id:4, value: 'Printer4'}
  ]

  tokenPrint={
    '0': 'None',
    '1': 'Printer1',
    '2': 'Printer2',
    '3': 'Printer3',
    '4': 'Printer4',
  }

  taxcat={
    '1': 'Standard Rated @ 5%',
    '2': 'Standard Rated @ 50%',
    '3': 'Zero Rated',
    '5': 'Standard Rated @ 15%',
  }
  cat_token_printer = 0;
  cats: any;
  total_cats: any;
  editing: boolean;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.editing=false;
    this.coreService.getToken();
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });
    this.up = this.coreService.getUserPrivilage();
  this.getAllCat();
  }

  createCat(formdata: { value: Catagory; }) {   
    this.pageLoadingImg = true;

      this.apiService.createCat(formdata.value).subscribe((catagory: Catagory) => {
        this.pageLoadingImg = false;

        if (catagory.error != null) {        
          this.result = catagory.error;  
          console.log(this.result);     
        }
        else {
        this.coreService.createfunct(formdata,this.result,catagory.message);
        }

      });
    }

  updateCat(formdata: { value: Catagory; })
  {  
    this.pageLoadingImg = true;

  // if(formdata.value.cat_pos){
  // (formdata.value.cat_pos.isChecked === true )? 0 : 1; }          
    this.apiService.updateCat(formdata.value).subscribe((catagory: Catagory) => {
      this.pageLoadingImg = false;


      if (catagory.error != null) {
        console.log(catagory.error);
        this.result = catagory.error;
      }
      else {
        this.editing=false;
        // $("#editcatf").hide();   
        this.coreService.updatefunct(formdata,this.result,catagory.message);            
      this.catagories=[];
      }

    });

    this.getAllCat();
  }
 
  //form search
    searchCat(search: string)
{
 let searchval = new FormData();      
      searchval.append("cat_name", search);    
       this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.catagories = res['data'];                
       });
       $('.prev-list').show(); 
}


 //form search
 searchForm(search: string)
 {
  let searchval = new FormData();      
       searchval.append("cat_name", search);    
        this.apiService.getCatbyName(searchval).subscribe((res) => {
         this.catagories = res['data'];                
        });
        $('.prev-list').hide(); 
 }

//Remove Name Suggestions

removeName()
{
  this.catagories = [];
  $('.prev-list').hide(); 
}

  selectCat(cat: Catagory) {
    if(cat){
    this.result = '';
    this.editing=true;
    // $("#editcatf").show();
    this.selectedData = cat; 
    } 
    this.catagories = []; 
  }

  tabClick(tab: { index: number; }) {   
    if(tab.index==1)  
  this.result = ''; 
}



getAllCat(){

  this.apiService.readCat().subscribe((res) => {
    this.cats = res['cat'];   
    this.total_cats = res['total_cat'];   
             
   });
}

editCat(cat){
  this.editing=true;
  this.result='';
  this.tabGroup.selectedIndex = 1;
  this.allcat=cat;
  this.selectCat(cat); 
  
  // $('#editunitf_new').show();
}

searchInp(keyword) {
  let searchval = new FormData();
  if (keyword)
    searchval.append("keyword", keyword);
  this.apiService.searchCats(searchval).subscribe((res) => {
    this.cats = res['cat'];      
    this.total_cats = res['total_cat'];
  });
}

}



