import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { CoreService } from '../../service/core.service';
@Component({
  selector: 'app-hrdash',
  templateUrl: './hrdash.component.html',
  styleUrls: ['./hrdash.component.css']
})
export class HrdashComponent implements OnInit {

  up: any;
  userType: any;
  cmpny: any;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.cmpny = this.coreService.getUserData('cmpny');

    this.coreService.getToken(); 
  }

}
