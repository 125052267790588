import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rebate-report',
  templateUrl: './rebate-report.component.html',
  styleUrls: ['./rebate-report.component.css']
})
export class RebateReportComponent implements OnInit {
  filter_types_delv_date = [
    { id: 't', name: 'Today'},
    { id: 'ld', name: 'Last Day'},
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  Status = [
    { id: '0', name: 'Pending' },
    { id: '1', name: 'converted' },

  ];

  status1 = {
    '0': 'Pending',
    '1': 'converted',
  };
  fileName= 'rebate_details.xlsx';
  supplierAll: any;
  alldata: any;
  total_res: any;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
   pgend: boolean;
  slnum: number;
  period_type_d: string;
  pageFrom: number;
  exportLoader: boolean;
  expalldata: any;
  exptotal_res: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  repDate: any;
  cur_lang: string;
  country_dec: string;
  detailed: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {
    
    // this.pgEnd(this.curpage, this.lastpage);
    // this.pgStart(this.curpage);
    this.period_type_d = 't';
    let searchval = new FormData();
    searchval.append("period_type_d", 't');

    this.apiService.rebateReport(searchval,1).subscribe((res:any) => {
      this.alldata = res['data']['data']['data'];
      this.total_res =  res.data;
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pageFrom = res['data']['data']['from'];
      this.repDate = res.data.data.Date;
      console.log(this.total_res);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.branch_display_name = this.coreService.getUserData('branch_disp_name');
      this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    });
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
  
  

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }


  pageNext(formdata: { value: any; }, pageno: any) {

   let searchval = new FormData();
    searchval.append("pageno", pageno);
   if (formdata.value.period_type === 'c') {
  
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    
  }
  formdata.value.export = '';
    this.apiService.rebateReport(formdata.value, pageno).subscribe((res:any) => {
      this.alldata = res['data']['data']['data'];
      this.total_res =  res.data;
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pageFrom = res['data']['data']['from'];
      this.repDate = res.data.data.Date;
      console.log(this.total_res);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });


    


  }


  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }
 

  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


  exportEXCL(expformdata: { value: any; }){
    this.exportLoader = true;
    console.log(expformdata.value);
    
      // debugger;
      expformdata.value.export = 'export';
      

    this.apiService.rebateReport(expformdata.value, 1).subscribe((res: any) => {


      this.expalldata = res['data']['data']['data'];
      this.exptotal_res =  res.data;
      this.repDate = res.data.data.Date;

      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);


    });



    }

// pdf download
generatePdf(expformdata: { value: any; }) {

  this.exportLoader = true;
    console.log(expformdata.value);
    
      // debugger;
      expformdata.value.export = 'export';
      

    this.apiService.rebateReport(expformdata.value, 1).subscribe((res: any) => {


      this.expalldata = res['data']['data']['data'];
      this.exptotal_res =  res.data;
      this.repDate = res.data.data.Date;

      const heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('Purchase.purch') + '\n' + this.translate.instant('Purchase.ref_no'),
        this.translate.instant('transaction.Branch_Ref_No'),
        this.translate.instant('Purchase.sup_inv_no'),
        this.translate.instant('Table.status'),
        this.translate.instant('Common.date'),
        this.translate.instant('Table.description'),
        this.translate.instant('HOME.purchase_Amount'),
        this.translate.instant('Purchase.vat'),
        this.translate.instant('Purchase.Rebate_Amount')
      ];

      if(this.detailed){
        heads.splice(7,0, this.translate.instant('Table.qty'))
      }
    
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
    
        this.expalldata.forEach((item,i)=>{
          const arr=[
            i+1,
            item.purch_id,
            item.purch_id2,
            item.purch_inv_no,
            this.status1[item.purch_rbt_amt_convert_to_debit],
            this.datePipe.transform(item.date, 'dd/MM/yyyy'),
            item.supp_name,
            this.currencyPipe.transform(item.purch_amount, '', '', this.country_dec),
            this.currencyPipe.transform(item.purch_tax, '', '', this.country_dec),
            this.currencyPipe.transform(item.purch_rebate_amounts, '', '', this.country_dec)
          ]

          if(this.detailed){
            arr.splice(7,0, ' ')
          }

          data.push(arr)

          if(this.detailed){
            item.items.forEach(item1=>{
              const arr1=[
                '',
                '',
                '',
                '',
                '',
                '',
                item1.prd_name,
                this.currencyPipe.transform(item1.purchsub_qty, '', '', '1.1-1') + ' ' + item1.unit_name,
                this.currencyPipe.transform(item1.purchsub_rate, '', '', this.country_dec),
                this.currencyPipe.transform(item1.purchsub_tax, '', '', this.country_dec),
                this.currencyPipe.transform(item1.purchsub_rebate_amount, '', '', this.country_dec)
              ]

              data.push(arr1)
            })
          }

        })
    
        let doc = this.excelService.getPdfObj();
    
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
    
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('HOME.rebate_report')}`,  valueXPos, headerHeight + 15);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.total_purch_amt, '', '', this.country_dec),  valueXPos, headerHeight + 20);
        
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.Total_VAT_Amount')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.total_tax, '', '', this.country_dec),  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.total_rebate_amount')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.total_rebate_amt, '', '', this.country_dec),  valueXPos, headerHeight + 30);

        doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
    
       
        doc.save(`Rebate Report.pdf`);
    
        this.exportLoader = false;

    });
  
 

  
}

  
}
