
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import * as jspdf from 'jspdf';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx';
import { CurrencyPipe } from '@angular/common';
import { ExcelService } from '../../../../../service/excel.service';


@Component({
  selector: 'app-customeraging',
  templateUrl: './customeraging.component.html',
  styleUrls: ['./customeraging.component.css']
})
export class CustomeragingComponent implements OnInit {

  @ViewChild('exportall', { static: true }) reportContent: ElementRef;
  @ViewChild('printBtn',{static: true}) printBtn: ElementRef<HTMLElement>;
  fileName = 'customer_aging.xlsx';

  pageLoadingImg: boolean;
  value1: any;
  value2: any;
  inv_val1: any;
  inv_val2: any;
  sales_val1: any;
  sales_val2: any;
  disc_val1: any;
  agents: any;

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_saldic = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_servamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];

  filter_custtyp = [
    { id: '1', name: 'Registered' },
    { id: '0', name: 'Non Registered' },

  ];
  display_mode = [
    { id: '1', name: 'Monthly' },
    { id: '2', name: 'Weekly' },

  ];


  payType = ['Cash', 'Credit'];

  godowns: any;
  salesSum: any;
  totalCat: any;
  totalProducts: any;
  sup_id: any;
  cust_id: any;
  cust_type: any;
  pay_type: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  sales_disc_filter: any;
  sales_filter: any;
  inv_filter: any;
  period_type: any;
  gd_id: any;
  agent_id: any;
  company: any;
  supplier: any;
  users: any;
  customer: any;
  added_by: any;
  sales_flags: any;
  summary: any;
  repDate: any;
  totalData: number;
  date1: any;
  date2: any;
  time1: any;
  time2: any;
  vat_reg_no: any;
  ModelData: boolean;
  show: any;
  userType: any;
  filter_branch_id: number;
  branch_all: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  Expshow: any;
  ExpsalesSum: any;
  Expsummary: any;
  total_bal_amnt: any;
  total_inv_amnt: any;
  vanline_all: any;
  vans: any;
  showfull: any;
  atot: any;
  btot: any;
  ctot: any;
  dtot: any;
  etot: any;
  ftot: any;
  opening_balnce: any;
  total_due: any;
  ExprepDate: any;
  Exptotal_bal_amnt: any;
  Expopening_balnce: any;
  Exptotal_due: any;
  Expatot: any;
  Expbtot: any;
  Expctot: any;
  Expdtot: any;
  Expetot: any;
  Expftot: any;
  Expshowfull: any;
  exportLoader: boolean;


  Prntshow: any;
  PrntsalesSum: any;
  Prntsummary: any;
  PrntrepDate: any;
  Prnttotal_bal_amnt: any;
  Prntopening_balnce: any;
  Prnttotal_due: any;
  Prntatot: any;
  Prntbtot: any;
  Prntctot: any;
  Prntdtot: any;
  Prntetot: any;
  Prntftot: any;
  Prntshowfull: any;
  exclude_zero=false;
  country_dec: string;
  display_type: any;
  van_route: any;
  van_vanline_id: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }


  searchVanlines(search: string) {
    let searchval = new FormData();
    searchval.append("vanline_name", search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      this.vanline_all = res['data'];

    });
  }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.display_type = '1';

    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.totalData = 0;
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append("display_type", '1');

    this.apiService.InvoicedueSummary2(searchval, 1).subscribe((res: Sales) => {
      this.pageLoadingImg = false;


      this.show = res['data'];



      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.opening_balnce = res['data']['opening_balnce'];
      this.total_due = res['data']['total_due'];
      this.van_route = res['data']['van_route'];

      this.atot = res['data']['atot'];
      this.btot = res['data']['btot'];
      this.ctot = res['data']['ctot'];
      this.dtot = res['data']['dtot'];
      this.etot = res['data']['etot'];
      this.ftot = res['data']['ftot'];
      this.showfull = res['data']['showall'];
      console.log(this.total_bal_amnt);

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.ModelData = false;

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.country_dec=this.coreService.setDecimalLength();
  }

  
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }


  ngAfterContentInit() {

    this.searchVans('');
    this.searchUser('');

  }
  toggleExcZero(){
    $("#exc_0").prop("checked", !$("#exc_0").prop("checked"));
    this.exclude_zero = $("#exc_0").prop("checked");
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      // console.log(  this.branch_all );
      this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    });
  }


  ngOnDestroy() {
    $('.close').click();

  }

  pageNext(formdata: { value: any; }, newform: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    formdata.value.datewise = newform.value.datewise;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.InvoicedueSummary2(formdata.value, pageno).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){
      this.show = res['data'];



      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.opening_balnce = res['data']['opening_balnce'];
      this.total_due = res['data']['total_due'];
      this.van_route = res['data']['van_route'];
      this.atot = res['data']['atot'];
      this.btot = res['data']['btot'];
      this.ctot = res['data']['ctot'];
      this.dtot = res['data']['dtot'];
      this.etot = res['data']['etot'];
      this.ftot = res['data']['ftot'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      // console.log(this.totalData);
      this.showfull = res['data']['showall'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // }
    });

  }



  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }


  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
      console.log(this.customer);
    });

  }
  searchCustomeralias(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
      console.log(this.customer);
    });

  }


  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res['data'];
    });

  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchAgent(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.agents = res['data'];
    });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 0, 'gd_name': 'All' });
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
      this.vans.push({ "van_id": 'All', 'van_name': 'All' });

    });

  }



  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  export() {
    // const doc = new jspdf('p', 'pt', 'A4');
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const content = this.reportContent.nativeElement;

    // doc.fromHTML(content.innerHTML, 5, 5, {
    //   'width': 1000,
    //   'elementHandlers': specialElementHandlers
    // });
    // doc.setFontSize(5);
    // doc.save('asdfghj' + '.pdf');
  }




  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  saveAndPrint(Expformdata: { value: any; }) {

    // debugger;
    this.exportLoader = true;
    Expformdata.value.exclude_zero = this.exclude_zero;
    Expformdata.value.datewise = true;
    Expformdata.value.export = 'export';
    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
      Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
      Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
    }
    this.apiService.InvoicedueSummary2(Expformdata.value, 1).subscribe((res: Sales) => {
      // if(res['data']['data']){
      this.Prntshow = res['data'];

      this.Prntsummary = res['data']['data'];
      this.PrntrepDate = res['data'].Date;
      this.Prnttotal_bal_amnt = res['data']['total_bal_amnt'];
      this.Prntopening_balnce = res['data']['opening_balnce'];
      this.Prnttotal_due = res['data']['total_due'];
      this.van_route = res['data']['van_route'];
      this.Prntatot = res['data']['atot'];
      this.Prntbtot = res['data']['btot'];
      this.Prntctot = res['data']['ctot'];
      this.Prntdtot = res['data']['dtot'];
      this.Prntetot = res['data']['etot'];
      this.Prntftot = res['data']['ftot'];
      this.Prntshowfull = res['data']['showall'];

      setTimeout(() => {
        this.exportLoader = false;
        let el: HTMLElement = this.printBtn.nativeElement;
        el.click();
    }, 2000);
      Expformdata.value.export = '';


    });



  }


  exportEXCL(Expformdata: { value: any; }) {

    // debugger;
    this.exportLoader = true;

    Expformdata.value.datewise = true;
    Expformdata.value.export = 'export';
    Expformdata.value.exclude_zero = this.exclude_zero;
    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
      Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
      Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
    }
    this.apiService.InvoicedueSummary2(Expformdata.value, 1).subscribe((res: Sales) => {
      // if(res['data']['data']){
      this.Expshow = res['data'];

      this.Expsummary = res['data']['data'];
      this.ExprepDate = res['data'].Date;
      this.Exptotal_bal_amnt = res['data']['total_bal_amnt'];
      this.Expopening_balnce = res['data']['opening_balnce'];
      this.Exptotal_due = res['data']['total_due'];
      this.van_route = res['data']['van_route'];
      this.Expatot = res['data']['atot'];
      this.Expbtot = res['data']['btot'];
      this.Expctot = res['data']['ctot'];
      this.Expdtot = res['data']['dtot'];
      this.Expetot = res['data']['etot'];
      this.Expftot = res['data']['ftot'];
      this.Expshowfull = res['data']['showall'];

      // }


      setTimeout(() => {
        this.exportexcel();
      }, 3000);

      Expformdata.value.export = '';


    });



  }

// pdf download
generatePdf(Expformdata: { value: any; }) {

      // debugger;
      this.exportLoader = true;

      Expformdata.value.datewise = true;
      Expformdata.value.export = 'export';
      Expformdata.value.exclude_zero = this.exclude_zero;
      if (Expformdata.value.period_type === 'c') {
        Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
        Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
        Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
        Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
      }
      this.apiService.InvoicedueSummary2(Expformdata.value, 1).subscribe((res: Sales) => {
        // if(res['data']['data']){
        this.Expshow = res['data'];
  
        this.Expsummary = res['data']['data'];
        this.ExprepDate = res['data'].Date;
        this.Exptotal_bal_amnt = res['data']['total_bal_amnt'];
        this.Expopening_balnce = res['data']['opening_balnce'];
        this.Exptotal_due = res['data']['total_due'];
        this.van_route = res['data']['van_route'];
        this.Expatot = res['data']['atot'];
        this.Expbtot = res['data']['btot'];
        this.Expctot = res['data']['ctot'];
        this.Expdtot = res['data']['dtot'];
        this.Expetot = res['data']['etot'];
        this.Expftot = res['data']['ftot'];
        this.Expshowfull = res['data']['showall'];
  
        console.log(this.summary);
        
        // }

        const heads = [
          this.translate.instant('Table.sl_no'),
          this.translate.instant('Common.Ledger_name'),
          this.translate.instant('Common.vanroute'),
          this.translate.instant('Sales.total_due_amount')
        ];

        if(this.showfull){
          heads.splice(3,0, this.translate.instant('Sales.no_due_yet'))

          if(this.display_type==2){
            heads.splice(4,0,'1-7' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(5,0,'8-15' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(6,0,'16-22' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(7,0,'23-39' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(8,0,'30+' + ' ' + this.translate.instant('HOME.days'))
          }else{
            heads.splice(4,0,'1-30' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(5,0,'31-60' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(6,0,'60-90' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(7,0,'91-120' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(8,0,'120+' + ' ' + this.translate.instant('HOME.days'))
          }

        }

        if(this.agent_id){
          heads.splice(3,0, this.translate.instant('Common.Sales_Agent'))
        }
      
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
      
          if(this.show != ''){

            this.Expsummary.forEach((item,i)=>{

              const arr = [
                ((this.curpage-1)*30)+i+1,
                item.vanline,
                this.currencyPipe.transform(item.duetot, '', '', this.country_dec)
              ];

              if(item.ledger){
                arr.splice(1,0, item.ledger.ledger_name)
              }

              if(item.agent){
                arr.splice(3,0, item.agent.ledger_name)
              }

              if(this.showfull){
                  arr.splice(3,0, this.currencyPipe.transform(item.a, '', '', this.country_dec)),
                  arr.splice(4,0, this.currencyPipe.transform(item.b, '', '', this.country_dec)),
                  arr.splice(5,0, this.currencyPipe.transform(item.c, '', '', this.country_dec)),
                  arr.splice(6,0, this.currencyPipe.transform(item.d, '', '', this.country_dec)),
                  arr.splice(7,0, this.currencyPipe.transform(item.e, '', '', this.country_dec)),
                  arr.splice(8,0, this.currencyPipe.transform(item.f, '', '', this.country_dec))
              }

              data.push(arr)
            })

        }
      
          let doc = this.excelService.getPdfObj();
      
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
      
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
      
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
      
          if(this.ExprepDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
          }
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Sales.customer_aging_report')}`,  valueXPos, headerHeight + 15);

          if(this.van_vanline_id){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.vanroute')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + this.van_route,  valueXPos, headerHeight + 20);
          }

          if(this.inv_filter){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Due_Days')}`, lblXPos, headerHeight + 25);
            doc.setFontSize(10);
            if(this.inv_val2){
              doc.text(':  ' + this.inv_filter + ' ' + this.inv_val2 + ' ' + 'Days',  valueXPos, headerHeight + 25);
            } else {
              doc.text(':  ' + this.inv_filter + ' ' + this.inv_val1+ ' ' + 'Days',  valueXPos, headerHeight + 25);
            }
          }

          if(this.showfull){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.no_due_yet')}`, lblXPos, headerHeight + 30);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.Expatot, '', '', this.country_dec),  valueXPos, headerHeight + 30);

            if(this.display_type==2){
              doc.setFontSize(10);
              doc.text('1-7' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 35);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expbtot, '', '', this.country_dec),  valueXPos, headerHeight + 35);

              doc.setFontSize(10);
              doc.text('8-15' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 40);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expctot, '', '', this.country_dec),  valueXPos, headerHeight + 40);

              doc.setFontSize(10);
              doc.text('16-22' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 45);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expdtot, '', '', this.country_dec),  valueXPos, headerHeight + 45);

              doc.setFontSize(10);
              doc.text('23-29' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 50);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expetot, '', '', this.country_dec),  valueXPos, headerHeight + 50);

              doc.setFontSize(10);
              doc.text('30+' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 55);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expftot, '', '', this.country_dec),  valueXPos, headerHeight + 55);

            } else {
              doc.setFontSize(10);
              doc.text('1-30' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 35);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expbtot, '', '', this.country_dec),  valueXPos, headerHeight + 35);

              doc.setFontSize(10);
              doc.text('31-60' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 40);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expctot, '', '', this.country_dec),  valueXPos, headerHeight + 40);

              doc.setFontSize(10);
              doc.text('61-90' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 45);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expdtot, '', '', this.country_dec),  valueXPos, headerHeight + 45);

              doc.setFontSize(10);
              doc.text('91-120' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 50);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expetot, '', '', this.country_dec),  valueXPos, headerHeight + 50);

              doc.setFontSize(10);
              doc.text('120+' + ' ' + `${this.translate.instant('HOME.days')}`, lblXPos, headerHeight + 55);
              doc.setFontSize(10);
              doc.text(':  ' + this.currencyPipe.transform(this.Expftot, '', '', this.country_dec),  valueXPos, headerHeight + 55);

            }
            
          }

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.total_due_amount')}`, lblXPos, headerHeight + 60);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.total_due, '', '', this.country_dec),  valueXPos, headerHeight + 60);
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 65, lblXPos, heads, data, null);
      
         
          doc.save(`Customer Aging Report.pdf`);
  
        Expformdata.value.export = '';
  
        this.exportLoader = false;
  
      });
  
}

}
