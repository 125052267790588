import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rep-ext-transfer',
  templateUrl: './rep-ext-transfer.component.html',
  styleUrls: ['./rep-ext-transfer.component.css']
})
export class RepExtTransferComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
