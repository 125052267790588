import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-van-topsheet-rep',
  templateUrl: './van-topsheet-rep.component.html',
  styleUrls: ['./van-topsheet-rep.component.css']
})
export class VanTopsheetRepComponent implements OnInit {
  
  fileName= 'van_top_sheet.xlsx';
  pageLoadingImg:boolean;
  van_id: any;
  res: any;
  vans:string[];
  filter: any;
  base_qty: any;
  sales_der_net_qty: any;
  van_trans_net_qty: any;
  sales_net_qty: any;
  van_trans_der_net_qty: any;
  balance_qty: any;
  balance_der_qty: any;
  van_data: any;
  period_type: string;
  cash_sale_sum: any;
  cash_sale_ret_sum: any;
  collection_rcvd: any;
  other_expns: any;
  balance_cash: any;
  repDate: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  error: void;
  credit_sale_sum: any;
  total_sale: any;
  country_dec: string;
  round_of_total: any;


  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
   
    this.country_dec=this.coreService.setDecimalLength();
    this.period_type='t';
    this.pageLoadingImg=true;
    const searchval = new FormData();
    
    searchval.append('period_type', 't');

    this.apiService.topSheetReport(searchval).subscribe((res) => {
      // this.van_trans_qty =  res.vantransub_qty;
      this.pageLoadingImg = false;

      this.sales_der_net_qty =  res['sales_der_net_qty'];
      this.sales_net_qty =  res['sales_net_qty'];
      this.van_trans_der_net_qty =  res['van_trans_der_net_qty'];
      this.van_trans_net_qty =  res['van_trans_net_qty'];
      this.balance_qty =  res['balance_qty'];
      this.balance_der_qty =  res['balance_der_qty'];
      this.base_qty= res['sum'];
      this.van_data=res['van_data'];
      this.other_expns=res['other_expenses'];
      this.cash_sale_sum =  res['cash_sale_sum'];
      this.credit_sale_sum =  res['credit_sale_sum'];
      this.cash_sale_ret_sum =  res['cash_sale_ret_sum'];
      this.round_of_total =  res['round_of_total'];
      this.balance_cash=res['balance_cash'];
      this.total_sale=res['total_sale'];
      this.repDate=res['Date'];
      
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }
  topSheet(formdata: { value: any; }){
    // if(!this.van_id){
    //   $("#error").text("Select a van");
    //   return false; 
    // }

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.pageLoadingImg = true;

  this.apiService.topSheetReport(formdata.value).subscribe((res) => {
    // this.van_trans_qty =  res.vantransub_qty;
    this.pageLoadingImg = false;
    

    this.sales_der_net_qty =  res['sales_der_net_qty'];
    this.sales_net_qty =  res['sales_net_qty'];
    this.van_trans_der_net_qty =  res['van_trans_der_net_qty'];
    this.van_trans_net_qty =  res['van_trans_net_qty'];
    this.balance_qty =  res['balance_qty'];
    this.balance_der_qty =  res['balance_der_qty'];
    this.cash_sale_sum =  res['cash_sale_sum'];
    this.credit_sale_sum =  res['credit_sale_sum'];
    this.cash_sale_ret_sum =  res['cash_sale_ret_sum'];
    this.round_of_total =  res['round_of_total'];
    this.collection_rcvd =  res['collection_rcvd'];
    // this.other_expns =  res['other_expns'];
    this.base_qty= res['sum'];
    this.van_data=res['van_data'];
    this.other_expns=res['other_expenses'];
    this.balance_cash=res['balance_cash'];
    this.total_sale=res['total_sale'];
    this.repDate=res['Date'];
    // this.error=res.error;
    console.log(this.error);
    
  
  });
  }

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];


  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.vans = res['data'];
    });

  }

  exportEXCL(){
    this.exportexcel();
  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }
  
}
