import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';
import { Chart } from 'chart.js';
import { ApiService } from '../../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rep-purchase',
  templateUrl: './rep-purchase.component.html',
  styleUrls: ['./rep-purchase.component.css']
})
export class RepPurchaseComponent implements OnInit {
  userType : any;
  up: any;
  chart: any;
  chartData: any;
  cmpny: any;
  constructor(private coreService: CoreService,private apiService: ApiService,private translate: TranslateService,) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType =  this.coreService.getUserData('user_type');
    this.apiService.getPurchaseChart(1).subscribe((res) => {
   
      this.chartData = res.data;
      let temp_max = this.chartData.sales;
      let temp_min = this.chartData.return;
     let weatherDates = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October','November','December'];
     const ctx = document.getElementById("chart") as HTMLCanvasElement;
     this.chart = new Chart(ctx, {
       type: 'line',
   
       data: {
         
         labels: weatherDates,
         datasets: [
           {
             data: temp_max,
             label:this.translate.instant('HOME.purchase_Amount'),
              borderColor: "#008000",
             fill: false
           },
           {
             data: temp_min,
             label: this.translate.instant('HOME.purchase_rtn_Amount'),
             borderColor: "#e15d11",
             fill: false
           },
         ]
       },
       options: {
         title: {
           display: true,
           text:this.translate.instant('HOME.purchase_and_purch_rtn_Amount'), 
       },
         tooltips: {
           mode: 'index',
           
       },
         legend: {
           
           display: true,
           
         },
         scales: {
           xAxes: [{
             display: true
             
           }],

           
           yAxes: [{
             
             display: true,
             ticks: {
               suggestedMin: 50,
               suggestedMax: 100
           }
           }],
         },
         layout: {
           padding: {
               left: 50,
               right: 0,
               top: 0,
               bottom: 0
           }
       }
       },
 
     });
    });
  }

}
