import { Component, OnInit, Input } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-without-header-footer-print',
  templateUrl: './without-header-footer-print.component.html',
  styleUrls: ['./without-header-footer-print.component.css']
})
export class WithoutHeaderFooterPrintComponent implements OnInit {
  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  country_dec: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
  }

}
