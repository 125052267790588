import { Component,  ElementRef, OnInit, ViewChild  } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-expiry-notification',
  templateUrl: './expiry-notification.component.html',
  styleUrls: ['./expiry-notification.component.css']
})
export class ExpiryNotificationComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  public date: Date;

  totalItem = 0;
  curpage = 1;
  lastpage = 1;
  info: any;
  from: any;
  printError = []; errObjArr = {
    dateErr: null, bncAmntErr: null, descrptErr: null,
  };
  nextId = '';
  model: any;
  perPage = 20;
  pageLoadingImg: boolean;
  isSubmitInProg: boolean;
  base_url: string;
  pdcImgUrl: string;
  country_dec: string;

  ex_date = "";
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,) { }

   ngOnInit() {

    this.batchExplist();
    this.base_url = this.apiService.PHP_API_SERVER;
    this.country_dec=this.coreService.setDecimalLength();
  }

  validateAndSubmit(form) {
    this.printError = [];
    if (this.printError.length <= 0) {

      if (confirm('Do you wish to continue?')) {
        this.isSubmitInProg = true;
        let callFunction = '';

      }
    }
  }

  batchExplist() {
    this.pageLoadingImg=true;
    this.apiService.batchExlist({ ex_date: this.ex_date }).subscribe((res) => {
      this.pageLoadingImg=false;
      // console.log("ex");
       console.log(res);
      this.info = res;
      this.from = 1;
    });
  }
}
