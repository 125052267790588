import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-van-stock-verify',
  templateUrl: './van-stock-verify.component.html',
  styleUrls: ['./van-stock-verify.component.css']
})
export class VanStockVerifyComponent implements OnInit {

  printError = []; errObjArr = {
    date: null, prd_id: null,
     avl_stk: null, van:null,unt:null
  };

  errObjArrLog = {
    date: null, prd_id: null,
     avl_stk: null,van:null,unt:null
  };
  vsv_date: Date;
  vans: any;
 
  stkprdsel: any;
  stkpageLoadingImg: boolean;
  noStkTxtShow: boolean;
  stock: {
    prd_name: string,
    prd_barcode: any,
    prd_ean: any,
    qty: any,
    prd_id: number,
    branch_stock_id: number,
    stock_id: number
  };
  avl_stk: any;
  diff_stk: any;
  stkprd_all: any;
  units_all: any;
  van_id: any;
  unit: any;
  sel_unit: any;
  unit_base_qty: any;
  stock_uniit: any;
  add_missing:boolean=false;
  add_excess:boolean=false;
  trans_stocks = [];
  resDup: any;
  slno = 0;
  pageLoadingImg: boolean;
  stck_vrfy_ref: any;
  sv_notes: string;
  stockVerfdList: any;
  curpage: any;
  lastpage: any;
  from: any;
  slnum: number;
  pgend: boolean;
  pgstart: boolean;
  stck_vrfy_hstry: any;
  barcodesearch: boolean=false;
  rqd_qty: number;
  count: number;
  cmpny: any;
  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal) { }

  ngOnInit() {
    this.cmpny = this.coreService.getUserData('cmpny');
    this.getStckVrfyRefNum();
    this.vsv_date = new Date();
    this.count = 0;
    this.searchVans('');
    this.stock = {
      prd_name: '',
      prd_barcode: '',
      prd_ean: '',
      qty: 0,
      prd_id: 0,
      branch_stock_id: 0,
      stock_id: 0
    };
    this.stck_vrfy_hstry = null;
    this.getVanVerifiedStockList(1);
  }


  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];


    });

  }



  searchStkPrdBarcode(barcode) {
    this.stkpageLoadingImg = true;
    this.barcodesearch=true;
    let searchval = new FormData();
    searchval.append("barcode", barcode);
    // searchval.append('stk_stat', '1');
    this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
      this.stkprd_all = res.data;
      this.stkprdsel = res.data;
      if (this.stkprd_all) {
        this.getUnits();
        this.selectVanStk(this.van_id,this.stkprdsel);
       
      } else {
        // this.reFreshAll();
      }

    });
  }

  searchStkPrd(search: string ) {

    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append("van_id", this.van_id);
    searchval.append('stk_stat', '1');
    this.apiService.getProductExcessItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  selectStockPrd(stkprdsel) {
   
    if (stkprdsel) {
      
      this.getUnits();
      this.stkpageLoadingImg = true;
      this.selectVanStk(this.van_id,stkprdsel);

    } else {
    
      this.stkpageLoadingImg = false;
      
    }

  }

  getUnits() {
    const searchval = new FormData();
    searchval.append("prd_id",this.stkprdsel.prd_id);
    this.apiService.unitPrdtWise(searchval).subscribe((res) => {
      this.units_all = res['data'];

    });

  }
  selectVanStk(van_id,prdId) {

    
    // old Code  
    if (van_id && prdId ) {
    let searchval = new FormData();
    searchval.append("prd_id", prdId.prd_id);
    searchval.append("van_id", van_id);
    // this.unit_qty = 0;

    this.apiService.getVanStock(searchval).subscribe((res) => {
      this.stkpageLoadingImg = false;
      if(res['data']&&res['data'].length){
        this.stock=res['data'][0];
     
        
        this.stock_uniit=res['data'][0];
        this.stock.qty = res['data'][0]['vanstock_qty'];

        if(this.stock_uniit.prd_default_unit_id){
          this.unit=this.stock_uniit.prd_default_unit_id;
        }else{
          this.unit=this.stock_uniit.prd_base_unit_id;
        }

        if(this.barcodesearch){
          this.unit=prdId.produnit_unit_id;
        
          
        }

        this.barcodesearch=false;
        
          this.selectUnits(this.unit);
        
        
        this.getVanStckVrfyHistory(prdId.prd_id,van_id);
      }
      else{
        this.stock = {
          prd_name: '',
          prd_barcode: '',
          prd_ean: '',
          qty: 0,
          prd_id: 0,
          branch_stock_id: 0,
          stock_id: 0
        };
      }
      



    });
    }
  }

  selectUnits(unitid: any) {
    if (unitid) {
      this.sel_unit = this.units_all.find(x => x.unit_id === unitid).unit_id;
      this.unit_base_qty = this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
      this.stock.prd_ean = this.units_all.find(x => x.unit_id === unitid).produnit_ean_barcode;
     
      
      // this.unit_name = this.units_all.find(x => x.unit_id === unitid).unit_name;
      this.stock.qty = this.stock_uniit.vanstock_qty / this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
     this.avl_stk='';
     this.diff_stk = '';
    }
  }
  selectVanStk1(van_id,prdId) {

    if(van_id){
      this.stkpageLoadingImg = true;
      const searchval = new FormData();
      searchval.append("van_id",van_id);
      this.apiService.vanProducts(searchval,1).subscribe((res) => {
        this.trans_stocks=res['data'];
        this.count = res['count'];
        this.from=res.from;
        this.curpage = res.current_page;  
        this.lastpage= res.last_page; 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
        this.stkpageLoadingImg = false;
      });
    }
    // old Code  
    // if (van_id && prdId ) {
    // let searchval = new FormData();
    // searchval.append("prd_id", prdId.prd_id);
    // searchval.append("van_id", van_id);
    // // this.unit_qty = 0;

    // this.apiService.getVanStock(searchval).subscribe((res) => {
    //   this.stkpageLoadingImg = false;
    //   if(res['data']&&res['data'].length){
    //     this.stock=res['data'][0];
     
        
    //     this.stock_uniit=res['data'][0];
    //     this.stock.qty = res['data'][0]['vanstock_qty'];

    //     if(this.stock_uniit.prd_default_unit_id){
    //       this.unit=this.stock_uniit.prd_default_unit_id;
    //     }else{
    //       this.unit=this.stock_uniit.prd_base_unit_id;
    //     }

    //     if(this.barcodesearch){
    //       this.unit=prdId.produnit_unit_id;
        
          
    //     }

    //     this.barcodesearch=false;
        
    //       this.selectUnits(this.unit);
        
        
    //     this.getVanStckVrfyHistory(prdId.prd_id,van_id);
    //   }
    //   else{
    //     this.stock = {
    //       prd_name: '',
    //       prd_barcode: '',
    //       prd_ean: '',
    //       qty: 0,
    //       prd_id: 0,
    //       branch_stock_id: 0,
    //       stock_id: 0
    //     };
    //   }
      



    // });
    // }
  }

  calcStock(i) {

    this.trans_stocks[i].diff_stk=0;
    this.trans_stocks[i].vanqty=this.trans_stocks[i].req_qty;
    this.unit_base_qty = this.trans_stocks[i].units.find(x => x.unit_id === this.trans_stocks[i].unit_id).unit_base_qty;
     
    this.trans_stocks[i].vanqty=this.trans_stocks[i].vanqty/this.unit_base_qty;
    if(this.trans_stocks[i].existing_qty){
      this.trans_stocks[i].diff_stk = Number(this.trans_stocks[i].existing_qty)-Number((this.trans_stocks[i].vanqty));
    }
    
      
    if(this.trans_stocks[i].diff_stk<0){
      this.trans_stocks[i].add_missing=true;
      this.trans_stocks[i].add_excess=false;

      }else if(this.trans_stocks[i].diff_stk>0){
        this.trans_stocks[i].add_excess=true;
        this.trans_stocks[i].add_missing=false;

      }else{
        this.trans_stocks[i].add_excess=false;
        this.trans_stocks[i].add_missing=false;

      }
  }
  selectUnits1(i,unitid) {
    if(unitid){
      this.sel_unit = this.trans_stocks[i].units.find(x => x.unit_id === unitid).unit_id;
      this.unit_base_qty = this.trans_stocks[i].units.find(x => x.unit_id === unitid).unit_base_qty;

      this.calcStock(i);
    }
  }
  pageNext(formdata: { value: any; }, pageno:any){
  
    this.stkpageLoadingImg = true;
    this.apiService.vanProducts(formdata.value,pageno).subscribe((res) => {
      this.trans_stocks=res['data'];
      this.count = res['count'];
      this.from=res.from;
      this.curpage = res.current_page;  
      this.lastpage= res.last_page; 
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
      this.stkpageLoadingImg = false;   
    });
  }
  updateDiff(avl_stk) {
    if (avl_stk) {
      this.diff_stk = avl_stk - this.stock.qty;
      if(this.diff_stk < 0){
        this.add_missing = true;
        this.add_excess = false;
      }else if(this.diff_stk > 0){
        this.add_missing = false;
        this.add_excess = true;
      }else{
        this.add_missing = false;
        this.add_excess = false;
      }
   
    }
    else
      this.diff_stk = 0;

    
  }


  addToList(res: { value: any; }) {

   
    
    this.validateForm();
    if (this.printError.length <= 0) {
      // filter array
      // var prod_id_inp = res.value.prd_id;
      // var filterObj = this.trans_stocks.filter(function (e) {
      //   return e.prod_id == prod_id_inp;
      // });
      res.value.vsv_date = (res.value.vsv_date) ?
        this.apiService.formatDate(res.value.vsv_date) : res.value.vsv_date;
      // check duplicates
      this.resDup = this.coreService.checkDuplicateValOpstk(this.trans_stocks, res.value.prd_id);
      if (typeof this.resDup == 'undefined') {
        this.slno = this.slno + 1;
        this.trans_stocks.push({
          "sl_no": this.slno, "prd_id": res.value.prd_id, "prod_name": res.value.prd_name, "branch_stock_id": res.value.branch_stock_id,
          "stock_id": res.value.branch_stock_id, "stkvrfy_date": this.apiService.formatDate(res.value.vsv_date), "stkvrfy_req_qty": res.value.req_stk, "stkvrfy_existing_qty": res.value.avl_stk, "stkvrfy_diff_qty": res.value.diff_stk, "stkvrfy_notes": res.value.sv_notes, "stkvrfy_van_id": res.value.van_id,"stkvrfy_unit_id": res.value.unit,
          "purch_rate": res.value.purch_rate, "base_unit_id": res.value.prd_base_unit_id, "add_excess": res.value.add_excess, "add_missing": res.value.add_missing,
        });

       
        this.reFreshAll();

      } else {
        this.printError.push({ 'repeatErr': 'Already exists , Pleae remove from the table' });
      }
    }
  }

  validateForm() {
    this.printError = [];


    if (this.vsv_date == undefined || this.vsv_date == null) {
      this.errObjArr.date = "t";
      this.printError.push({ 'date': 'Required' });
    }
    else {
      this.errObjArr.date = "";
    }

    if (this.stkprdsel == undefined || this.stkprdsel == null) {
      this.errObjArr.prd_id = "t";
      this.printError.push({ 'prd_id': 'Required' });
    }
    else {
      this.errObjArr.prd_id = "";
    }

    if (this.van_id == undefined || this.van_id == null) {
      this.errObjArr.van = "t";
      this.printError.push({ 'van': 'Required' });
    }
    else {
      this.errObjArr.van = "";
    }

    if (this.unit == undefined || this.unit == null) {
      this.errObjArr.unt = "t";
      this.printError.push({ 'unt': 'Required' });
    }
    else {
      this.errObjArr.van = "";
    }

    if (!(this.avl_stk <= 0 || this.avl_stk >= 0)) {
      this.errObjArr.avl_stk = "t";
      this.printError.push({ 'avl_stk': 'Required' });
    }
    else {
      this.errObjArr.avl_stk = "";
    }

  }

  deleteFromList(data: any) {
    var type = data.sl_no,
      i;
    for (i = this.trans_stocks.length - 1; i >= 0; --i) {
      if (this.trans_stocks[i].sl_no == type) {
        this.trans_stocks.splice(i, 1);
      }
    }
  }

  submitVerification1(form: NgForm) {
    var cnfrm = confirm("Are You Sure To Continue ?");
    if (cnfrm) {
      if (this.trans_stocks.length > 0) {
        this.pageLoadingImg = true;
        // let searchval = new FormData();
        form.value.vsv_date = this.apiService.formatDate(form.value.vsv_date);
        // console.log(form.value);
        this.apiService.vanStockVerify(form.value).subscribe((res) => {
          this.pageLoadingImg = false;
          // console.log(res);
          if (res.message) {
            this.reFreshAll();
            this.trans_stocks = [];
            this.getStckVrfyRefNum();
            this.getVanVerifiedStockList(1);

            this.coreService.showMessage('Verified Successfully');
          } else {
            this.printError.push({ 'repeatErr': 'Error Occured While Submitting,Please try Again' });

          }

        });

      } else {
        this.printError.push({ 'repeatErr': 'Please Add Atleast One Item' });
      }
    }
  }
  submitVerification(form: NgForm) {
    var cnfrm = confirm("Are You Sure To Continue ?");
    if (cnfrm) {
      if (this.trans_stocks.length > 0) {
        this.pageLoadingImg = true;
        // let searchval = new FormData();
        form.value.prdlist = this.trans_stocks;
        // console.log(form.value);
        this.apiService.vanStockVerify(form.value).subscribe((res) => {
          this.pageLoadingImg = false;
          // console.log(res);
          if (res.message) {
            this.van_id='';
            this.reFreshAll();
            this.trans_stocks = [];
            this.getStckVrfyRefNum();
            this.getVanVerifiedStockList(1);

            this.coreService.showMessage('Verified Successfully');
          } else {
            this.printError.push({ 'repeatErr': 'Error Occured While Submitting,Please try Again' });

          }

        });

      } else {
        this.printError.push({ 'repeatErr': 'Please Add Atleast One Item' });
      }
    }
  }


  getStckVrfyRefNum() {

    this.apiService.getVanStckVrfyRefNum().subscribe((res: any) => {
      this.stck_vrfy_ref = res.ref_no;
      ;


    });

  }

  reFreshAll() {
    // this.sv_date = new Date();
    this.stkprd_all = [];
    this.stkprdsel = this.stkprd_all[0];
    this.stock = {
      prd_name: '',
      prd_barcode: '',
      prd_ean: '',
      qty: 0,
      prd_id: 0,
      branch_stock_id: 0,
      stock_id: 0
    };
    this.avl_stk = '';
    this.unit = 0;
    this.diff_stk = 0;
    this.sv_notes = '';
    // this.add_missing = false;
    // this.add_excess = false;
    this.stck_vrfy_hstry = [];
    this.stkpageLoadingImg = false;
    this.noStkTxtShow = false;

  }

  getVanVerifiedStockList(pagno) {

    let searchval = new FormData();
    this.apiService.getVanVerifiedStockList(searchval, pagno).subscribe((res) => {
      this.stockVerfdList = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  getVanStckVrfyHistory(prd_id,van_id) {
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    searchval.append("van_id", van_id);
    this.apiService.getVanStckVrfyhstry(searchval).subscribe((res: any) => {
      this.stck_vrfy_hstry = res.data;
      // console.log(this.stck_vrfy_hstry);


    });

  }
}
