import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { Ledger } from '../../../model/ledger.model';
import { Group } from '../../../model/group.model';
import { Branch } from '../../../model/branch.model';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-ledgermanage',
  templateUrl: './ledgermanage.component.html',
  styleUrls: ['./ledgermanage.component.css']
})
export class LedgermanageComponent implements OnInit {
  customer = {
    is_supplier:'',
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    op_bal_type: true,
    brch_id:0,
    usr_type:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  ledger_name: any;
  ledger_accgrp_id: any;
  ledger_branch_id: any;
  ledger_alias: any;
  ledger_code: any;
  ledger_email: any;
  ledger_mobile: any;
  ledger_due: any;
  ledger_tin: any;
  ledger_address: any;
  ledger_notes: any;
  ledgersel: any;
  sortByBal = '5';
  sortByCode = '3';
  sortByName ='1';
  pageLoadingImg: boolean;
  resultobj: any = {};
  selData: any = { ledger_id: null, error: null, ledger_name: null, message: null, };
  group_all: string[];
  ledger_all: string[];
  branch_all: string[];
  leadger: any;
  curpage: any;
  lastpage: any;
  slnum: any;
  pgend: any;
  pgstart: any;
  searchleadger: any;
  search: any;
  opening_balance: any;
  elseBlocknoend: any;
  elseBlocknostart: any;
  op_bal_type = true;
  from: any;
  id_serach: number;
  delete_status: any;
  up: any;
  isSameAddr: any;
  isSubmitInProg: boolean;
  is_supplier: any;
  customer_type: string;
  is_sup: any;
  is_cust: any;
  userType: any;
  printError: any;
  op_bal: any;
  priceGroupList = [];
  ledger_pricegrp_id = 1;
  is_cost_category = false;
  selectedCostCategory = 0;
  selectedLedger:any;
  costCenter=<any>[{
    cc_name:'',
    cc_ledger_id:0
  }];
  isCCSubmitInProg = false;
  valErrors = <any>{};
  isCCContentLoading = false;
  costcenterList: any;
  costCenterSub=<any>[{
    ccs_name:'',
    ccs_cc_id:0,
    ccs_ledger_id:0
  }];
  isCCSSubmitInProg = false;
  isCCSContentLoading = false;
  selectedCostCcenter:any;
  isccedit = false;
  isccsedit = false;
  country_dec: string;
  byName:boolean=true;
  byCode: boolean=true;
  byBal: boolean=true;
  default_ledger: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }
 
  ngOnInit() {

    
    this.up = this.coreService.getUserPrivilage();
    this.coreService.getToken();
    this.getAllGroup();
    this.search = "";
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.op_bal = 0;

    let searchval = new FormData();
    searchval.append("branch_name", this.coreService.getUserData('branch_name'));
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
    this.ledger_branch_id = this.coreService.getUserData('branch_id');
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
    this.country_dec=this.coreService.setDecimalLength();
  }

  validateAndSubmit(){
    this.isSubmitInProg = true;
    let callFunction = 'createCustomer';
    
    if(this.userType =='ADMIN'){
      this.customer.usr_type = 1;
    }else{
      this.customer.usr_type = 0;
    
    }
    this.customer.is_supplier = this.customer_type;
    this.apiService[callFunction](this.customer).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        
        this.coreService.showMessage(res.message);
        this.clearForm();
        this.resultobj = {};
      }

    });
  }
  
 

  clearForm() {
 
    this.customer = {
      is_supplier:'',
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email : '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      op_bal_type: true,
      brch_id:0,
      usr_type:0,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };
    this.isccedit = false;
    this.isccsedit = false;
  }
  updateAddress() {
    if (this.isSameAddr) {
      this.customer.del = JSON.parse(JSON.stringify(this.customer.add));
    } else {
      this.customer.del = {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      }
    }
  }
  createLedger(formgroup: { value: any; }) {
    this.pageLoadingImg = true;
    this.apiService.createLedger(formgroup.value).subscribe((res: Ledger) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      }
      else {
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
        this.ledger_pricegrp_id = 1;
      }

    });
  }
  deleteLedger(ledger_id, formgroup: { value: any; }) {
    let searchval = new FormData();
    searchval.append("ledger_id", ledger_id);
    this.apiService.DeleteLedger(searchval).subscribe((res) => {

      // console.log(res);

        
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
      

      $('#edit-ledger').hide();
      this.ledger_all = [];
      $('.close').click();
      this.listLeadger();
      this.leadger = [];

      
    });
  }



  updateLedger(formgroup: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updateLedger(formgroup.value).subscribe((res: Ledger) => {
      this.pageLoadingImg = false;


      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      }
      else {
        this.resultobj = {};
        this.coreService.updatefunct(formgroup, this.resultobj, res.message);
        $('#edit-ledger').hide();
        this.ledger_all = [];
        $('.close').click();
        this.listLeadger();
        this.leadger = [];

      }

    });
  }

  validatesupplier(list) {
   
    this.printError = [];
if(this.userType =='ADMIN'){

    if (!list.brch_id) {
      this.printError.push({ 'branch_id': 'Required' });
      $('.ledger_branch_id').css("border", "1px solid red");
    } else {
      $('.ledger_branch_id').css("border", "1px solid #dedede");
    }
  }


    // if (!list.supp_code) {
    //   this.printError.push({ 'supp_code': 'Required' });
    //   $('.supp_code').css("border", "1px solid red");
    // } else {
    //   $('.supp_code').css("border", "1px solid #dedede");
    // }

    if (!list.supp_name) {
      this.printError.push({ 'supp_name': 'Required' });
      $('.supp_name').css("border", "1px solid red");
    } else {
      $('.supp_name').css("border", "1px solid #dedede");
    }
    if (list.op_bal && list.op_bal_type == undefined) {
      this.printError.push({ 'op_bal_type': 'Required' });
      this.printError.op_bal_type =  'Select Type';
      $('.op_bal_type').css("border", "1px solid red");
      // console.log('true');
    
    } else {
      $('.op_bal_type').css("border", "1px solid #dedede");
      // this.printError.op_bal_type =  '';
      // console.log(list);

    }
// console.log(this.printError);
    // if (list.op_bal == undefined && list.op_bal_type) {
    //   this.printError.push({ 'op_bal': 'Required' });
    //   // this.printError.op_bal_type =  'Select Type';
    //   $('.op_bal').css("border", "1px solid red");
    //   // console.log('true');
    
    // } else {
    //   $('.op_bal').css("border", "1px solid #dedede");
    //   // this.printError.op_bal =  '';

    // }
    // console.log(list);
   

}
  createSupplier(formgroup: { value: any; }) {
    this.validatesupplier(formgroup.value);
    if (this.printError.length <= 0) {
      this.pageLoadingImg = true;

    this.apiService.createSupplier(formgroup.value).subscribe((res) => {
      this.pageLoadingImg=false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
      }

    });
  }
  }
  //form search  ledger
  searchLedger(search: string) {
    let searchval = new FormData();
    searchval.append("search", search);
    this.apiService.searchLedgerList(searchval, 1).subscribe((res) => {
      this.searchleadger = res['data']['data'];
      // console.log(this.searchleadger);
    });
    $('.prev-list').show();
  }

  //form search  ledger
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("ledger_name", search);
    this.apiService.getLedByName(searchval).subscribe((res) => {
      this.ledger_all = res['data'];
    });
  }


  listLeadger(isCostCenter=false) {
    this.leadger = []
    let searchval = new FormData();
    searchval.append("status", '1');
    if(isCostCenter){
      searchval.append("cost_category", '1');
    }

    this.apiService.searchLedgerList(searchval, 1).subscribe((res) => {

      this.leadger = res['data']['data'];
      this.from = res['data'].from;
      // console.log(this.from);

      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }

  pageNext(search, pageno: any) {

    let searchval = new FormData();
    searchval.append("status", '1');
    searchval.append("search", search);

    this.apiService.searchLedgerList(searchval, pageno).subscribe((res) => {

      this.leadger = res['data']['data'];
      this.from = res['data']['from'];

      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;

    // console.log(this.pgstart);
  }

  //Remove Name Suggestions

  removeName() {
    this.ledger_all = [];
    $('.prev-list').hide();
  }

  //search select
  selectLedger(ledger) {

    if (ledger) {
      
      // console.log(ledger);
      this.selData = ledger;
      this.selData.op_bal_type = (this.selData.opening_balance < 0) ? true : false;
      this.selData.opening_balance_nosign = Math.abs(this.selData.opening_balance);
      this.selData.ledger_accgrp_id = Number(ledger.ledger_accgrp_id);
      this.selData.ledger_branch_id = Number(ledger.ledger_branch_id);


      let searchval = new FormData();
      this.id_serach = ledger.ledger_id;
      searchval.append("ledger_id", this.id_serach.toString());
      this.apiService.isLedgerDeletable(searchval).subscribe((res) => {
        this.delete_status = res['deletable'];
        this.default_ledger = res['default_ledger']?res['default_ledger']:0;
        this.is_sup = res['is_sup'];
        this.is_cust = res['is_cust'];
        // console.log(this.delete_status);
      });






      $('#edit-ledger').show();
    }
    this.ledger_all = [];

  }


  //form search branch
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  //get all branches
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res: Branch[]) => {
      this.branch_all = res['data'];
    });
  }


  getAllGroup() {
    this.apiService.readAllGrp().subscribe((res: Group[]) => {
      // console.log(res['data']);
      const LedgGroup = res['data'].filter((group) => group.accgrp_id !== 21);
      this.group_all = LedgGroup;
    });
  }

  searchInp(e,isCostCategory = false) {
    let searchval = new FormData();
    searchval.append("search", e);
    if(isCostCategory){
      searchval.append("cost_category", '1');
    }
    this.apiService.searchLedgerList(searchval, 1).subscribe((res) => {
      this.leadger = res['data']['data'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });

  }

  tabClick(tab) {
    this.selectedCostCategory = 0;
    if(tab.index != 2){
      this.resultobj = {};
      this.ledger_all = [];
      // this.getAllBranch();
      this.listLeadger();
    } else{
      this.listLeadger(true);
    }
  }
  getCostcenter(data){
    this.isccedit = false;
    this.selectedCostCategory = data.ledger_id;
    this.selectedLedger = data;
    this.costCenter = [{
      cc_name:'',
      cc_ledger_id:0
    }];
    this.listCostCenter();
  }

  getCC(data){
    this.costCenter = [{
      cc_id: data.cc_id,
      cc_name: data.cc_name,
      cc_ledger_id: data.cc_ledger_id
    }];
    this.isccedit = true;
  }

  addMoreCostCenter(){
    this.costCenter.push({
      cc_name:'',
      cc_ledger_id:0
    });
  }
  removeCostCenter(index){
    this.costCenter.splice(index, 1);
  }

  submitCostCenter(){
    if(this.vaidateCCForm()){

      let callFunction = 'createCostCenter';
      if (this.isccedit) {
        callFunction = 'updateCostCenter';
      }
      this.isCCSubmitInProg = true;
      this.apiService[callFunction]({costcenter:this.costCenter}).subscribe((res) => {
        // console.log(this.staff);
        this.isccedit = false;
        this.isCCSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.listCostCenter();
          $('.close').click();
          this.coreService.showMessage(res.message);
          this.costCenter = [{
            cc_name:'',
            cc_ledger_id:0
          }];
          this.resultobj = {};
        }

      });
    }
  }

  vaidateCCForm(){

    this.valErrors = {};
    let retVal = true;
    this.costCenter.forEach((element, i) => {
      if(element.cc_name == '' || element.cc_name == null){
        this.valErrors['cc_name_' + i] = {msg : 'Required'}
        retVal =  false;
      } 
      if(!this.isccedit){
        this.costCenter[i].cc_ledger_id = this.selectedCostCategory;
      }
    });  

    return retVal;
  }

  listCostCenter(){
    this.isCCContentLoading = true;
    this.apiService.getCostCenter({ledger_id:this.selectedCostCategory}).subscribe((res) => {
      this.costcenterList = res.data;
      this.isCCContentLoading = false;
    });
  }

  updatecc(data){
   
    this.isccsedit = false;
    this.costCenterSub = [{
      ccs_name:'',
      ccs_cc_id:0,
      ccs_ledger_id:0
    }];
    this.selectedCostCcenter = data;
  }
  getccs(data){
    console.log(data);
    this.isccsedit = true;
    this.costCenterSub = [{
      ccs_name: data.ccs_name,
      ccs_cc_id: data.ccs_cost_center_id,
      ccs_ledger_id: data.ccs_ledger_id,
      ccs_id: data.ccs_id
    }];
  }



  addMoreCostCenterSub(){
    this.costCenterSub.push({
      ccs_name:'',
      ccs_cc_id:0,
      ccs_ledger_id:0
    });
  }
  removeCostCenterSub(index){
    this.costCenterSub.splice(index, 1);
  }

  submitCostCenterSub(){
    if(this.vaidateCCSForm()){

      let callFunction = 'createCostCenterSub';
      if (this.isccsedit) {
        callFunction = 'updatedCostCenterSub';
      }
      this.isCCSSubmitInProg = true;
      this.apiService[callFunction]({costCenterSub:this.costCenterSub}).subscribe((res) => {
        // console.log(this.staff);
        this.isccsedit = false;
        this.isCCSSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.listCostCenter();
          $('.closeccs').click();
          this.coreService.showMessage(res.message);
          this.costCenterSub = [{
            ccs_name:'',
            ccs_cc_id:0,
            ccs_ledger_id:0
          }];
          this.resultobj = {};
        }

      });
    }
  }

  vaidateCCSForm(){

    this.valErrors = {};
    let retVal = true;
    this.costCenterSub.forEach((element, i) => {
      if(element.ccs_name == '' || element.ccs_name == null){
        this.valErrors['ccs_name_' + i] = {msg : 'Required'}
        retVal =  false;
      } 
      if(!this.isccsedit){
        this.costCenterSub[i].ccs_ledger_id = this.selectedCostCategory;
        this.costCenterSub[i].ccs_cc_id = this.selectedCostCcenter.cc_id;
      }
    });  

    return retVal;
  }

  searchLedg(formdata: { value: any; }, pageno: any){
    this.pageLoadingImg = true;
    formdata.value.sort_by_bal =this.sortByBal;
    formdata.value.sort_by_code =this.sortByCode;
    formdata.value.sort_by_name =this.sortByName;
    this.apiService.filterLedger(formdata.value,pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.leadger = res['data']['data'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  refreshData(newVal){
    this.sortByBal = newVal;
    if(newVal==6){
      this.byBal=false;
    }else{
      this.byBal=true;
    }
    
    this.sortByCode = '0';
    this.sortByName = '0';
  }
  refreshData1(newVal){
    this.sortByCode = newVal;
    if(newVal==4){
      this.byCode=false;
    }else{
      this.byCode=true;
    }
    this.sortByName = '0';
    this.sortByBal = '0';
  }
  refreshData2(newVal){
    this.sortByName = newVal;
    if(newVal==2){
      this.byName=false;
    }else{
      this.byName=true;
    }
    this.sortByCode = '0';
    this.sortByBal = '0';
  }
}
