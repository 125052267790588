import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-van-transfer-invoice',
  templateUrl: './van-transfer-invoice.component.html',
  styleUrls: ['./van-transfer-invoice.component.css']
})

export class VanTransferInvoiceComponent implements OnInit {
  fileName= 'van_transfer_invoice.xlsx';
  calc_mode: any;
  rep_type: any;
  purchaseBlock: any;
  mrpBlock: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: any;
  van_tran_filter: any;
  trans_val1: any;
  trans_val2: any;
  prd_id: any;
  cat_id: any;
  manfact_id: any;
  van_id: any;

  reportType:any;
  searchProducts: any;
  catagories: any;
  company: any;
  vans: any;
  vanPrdData: any;
  vanTransData: any;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  totalRes: any;
  slnum: number;
  data: any;
  pageLoadingImg:boolean;
  reportBy:string;
  repDate:any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;

  exp_vanTransData: any;
  exp_totalRes: any;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];


  rept_type = [
   
    { id: 2, name: 'Transfer' },
    { id: 3, name: 'Returns' },
  ];
  godown_all: any;
  subcatagories: any;
  enablePrft: boolean;
  country_dec: string;
  errorPassword: any;


  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.exportLoader = false;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    // searchval.append('rep_type', '1');
    this.totalRes = {};
    this.apiService.readVanTransfersInvoiceReport(searchval, 1).subscribe((res: any) => {
    this.pageLoadingImg = false;

     
      this.vanTransData = res.data;
      this.reportType = res.reportType;
      // this.rep_type =1;
    
      this.totalRes = res.alldetais;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;

      this.errorPassword = '';
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.transfer','Purchase.Returned','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.rept_type = [
   
        { id: 2, name: res['Common.transfer'] },
        { id: 3, name:  res['Purchase.Returned'] },
      ];
    });
  }

  ngAfterContentInit(){

    this.searchCat('');
    this.searchVans('');
    this.searchComp('');
    this.searchGdwn('');
    

  }



  ngOnDestroy(){
    $('.close').click();
 
  }



  // Loading Api to include for Filters


  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }


  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.searchProducts = res.data;
    });
  }


  // Loaders END

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      
    });
  }




  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').hide();
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.readVanTransfersInvoiceReport(formdata.value, pageno).subscribe((res: any) => {
       
        this.pageLoadingImg = false;
    $('.resultdata').show();
      
        this.vanTransData = res.data;
        this.totalRes = res.alldetais;
        this.curpage = res.current_page;
        this.lastpage = res.last_page;
        this.reportType = res.reportType;

        if(res.enblprft == true){
          this.enablePrft = true;
        }
        else{
        this.enablePrft = false;
        }
        this.errorPassword = '';
        
        this.reportBy = res.reportBy;
        this.repDate = res.Date;


        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
       
        this.slnum = 0;
        if(!res.msg){
           $('.close').trigger('click');
        }else{
           document.getElementById("filter").click();
           this.errorPassword = res.msg;
        }


      });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.filter =1;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.readVanTransfersInvoiceReport(formdata.value, 1).subscribe((res: any) => {
     
      this.exportLoader = false;    
      this.exp_vanTransData = res.data;
      this.exp_totalRes = res.alldetais;
    
      this.reportType = res.reportType;
      this.reportBy = res.reportBy;
      this.repDate = res.Date;
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
    });

  }

  print(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.filter =1;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.readVanTransfersInvoiceReport(formdata.value, 1).subscribe((res: any) => {
     
      this.exportLoader = false;    
      this.exp_vanTransData = res.data;
      this.exp_totalRes = res.alldetais;
    
      this.reportType = res.reportType;
      this.reportBy = res.reportBy;
      this.repDate = res.Date;

      // if(this.exp_vanTransData){
      //   $('#btn1').click();
      // }
     
      setTimeout(() => {
        $('#btn1').click();
        this.exportLoader = false;    
      }, 2000);
    });

  }




  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'SHOP' });
    });
  
  }


  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

}
