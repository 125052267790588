import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GodownComponent } from './godown/godown.component';
import { GodownManageComponent } from './godown/godown-manage/godown-manage.component';
import { GodownTransferComponent } from './godown-transfer/godown-transfer.component';
import { TestComponent } from './test/test.component';

const routes: Routes = [
    { path: 'godown',component: GodownComponent},
    { path: 'godown_manage',component: GodownManageComponent},
    { path: 'godown_transfer',component: GodownTransferComponent},
    { path: 'test', component : TestComponent}
       
    
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class GodownRoutingModule { }