import { Component, OnInit } from '@angular/core';
import { Group} from '../../../model/group.model';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-ledgergroup',
  templateUrl: './ledgergroup.component.html',
  styleUrls: ['./ledgergroup.component.css']
})
export class LedgergroupComponent implements OnInit {
  accgrp_type: any;
accgrp_parent: any;
accgrp_name: any;
accgrp_nature: any;
accgrpsel: any;
ledgrouptype: any;
result: any;
ledprimaryid: any;
lednature: any;

  selData: any = { accgrp_id: null, error: null, accgrp_name: null, accgrp_nature: null,message:null,accgrp_root:null};
  resultobj: any = {};
  group_primary : any[];
  group_all : string[];
  ledgrp: { accgrp_id: null; accgrp_name: null; accgrp_nature: null;};
  ledger_group : string[];
  changeBox :boolean;
  pageLoadingImg:boolean;
  group_under: any;
  group_edit: any;
  up: any;

  constructor(private apiService: ApiService,private coreService: CoreService,private translate: TranslateService) { }
  ngOnInit() { 
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.coreService.getToken(); 
    this.getPrimaryGroup();   
    this.searchUnderGrp('');
    this.up = this.coreService.getUserPrivilage();

  }

// Group Types 
 group_types = [
  { id: 1, accgrp_name: 'Primary' },
  { id: 0, accgrp_name: 'Sub Group' }
];

// Group Nature
group_nature = [
  { id: 0, name: 'Income' },
  { id: 1, name: 'Expense' },
  { id: 2, name: 'Asset' },
  { id: 3, name: 'Liability' }
];

// group_natures_old = [
//   { id: 1, name: 'Income' },
//   { id: 2, name: 'Expense' },
//   { id: 3, name: 'Asset' },
//   { id: 4, name: 'Liability' }
// ];



selectType(gtype: number)
{
  if(gtype==1)
  {
    $('#select-sub').hide(); 
    $('#select-primary').show();
    $('#grp_nature').show();
  }
  else{  
  $('#select-primary').hide();
  $('#select-sub').show(); 
  $('#grp_nature').hide();
  }

}


createGroup(formgroup: { value: any; }) {   
  this.pageLoadingImg = true;
  this.apiService.createGroup(formgroup.value).subscribe((res: Group) => {  
    this.pageLoadingImg = false; 
    if (res.error != null) {            
      this.resultobj = res.error;     
      this.resultobj = this.coreService.getValidation(this.resultobj);     
    }
    else {
      this.resultobj={};
      this.coreService.createfunct(formgroup,this.resultobj,res.message);  
      formgroup.value = [];      
    }

  });
}

updateGroup(formgroup: { value: any; }) { 
  this.pageLoadingImg = true;

  this.apiService.updateGroup(formgroup.value).subscribe((res: Group) => {
    this.pageLoadingImg = false; 


    if (res.error != null) {        
      this.resultobj = res.error;     
      this.resultobj = this.coreService.getValidation(this.resultobj);          
    }
    else {
      this.resultobj={};
      this.coreService.updatefunct(formgroup,this.resultobj,res.message);  
      $('#editgroup').hide();  
      this.group_all = []; 
      this.changeBoxFun();      
    }

  });
}


selectNature(lgroup : number)
{
 this.ledger_group = this.group_primary.find(x => x.accgrp_id === lgroup);  

}

getPrimaryGroup()
{
  this.apiService.readPriGrp().subscribe((res: Group[]) => {
    this.group_primary = res['data'];
  });
}

//form search
searchGroup(search: string)
{
 let searchval = new FormData();      
      searchval.append("accgrp_name", search);    
       this.apiService.getGroupbyName(searchval).subscribe((res) => {
        this.group_all = res['data'];                
       });
       $('.prev-list').show(); 
}

//form search
searchForm(search: string)
{
 let searchval = new FormData();      
      searchval.append("accgrp_name", search);    
       this.apiService.getGroupbyName(searchval).subscribe((res) => {
        this.group_all = res['data'];                
       });
       $('.prev-list').hide(); 
 
}

searchUnderGrp(search: string)
{
 let searchval = new FormData();      
      searchval.append("accgrp_name", search);    
       this.apiService.getGroupbyName(searchval).subscribe((res) => {
        this.group_under = res['data'];                
       });
       $('.prev-list').hide(); 
 
}

getEditledGrp(search: string)
{
 let searchval = new FormData();      
      searchval.append("accgrp_name", search);    
       this.apiService.getEditledGrp(searchval).subscribe((res) => {
        this.group_edit = res['data'];                
       });
      
 
}


//Remove Name Suggestions

removeName()
{
  this.group_all = [];
  $('.prev-list').hide(); 
}

//search select
selectGroup(ledgrp:Group)
{
  if(ledgrp){
  this.selData = ledgrp; 
  this.selData.primary_status = Number(this.selData.primary_status);
  this.selData.accgrp_nature = Number(this.selData.accgrp_nature);
  this.selData.accgrp_parent = Number(this.selData.accgrp_parent);
  $('#editgroup').show(); 
  if(this.selData.accgrp_editable==0)
  {
  $('#select-sub-edit').hide();  
  }else{
  $('#select-sub-edit').show();  
  }   
  }
  this.group_all = [];
  
}

tabClick(tab: Event) {
  this.resultobj = {}; 
  $('#editgroup').hide();
  this.changeBoxFun(); 
}

changeBoxFun()
{
  if(this.changeBox)
  this.changeBox = false;
  else
  this.changeBox = true; 
}

langChange(){
  this.translate.get(['Van.Expense', 'ACCOUNTS.assets','ACCOUNTS.Income','ACCOUNTS.Liability','ACCOUNTS.primary','ACCOUNTS.sub_group']).subscribe((res: string) => {    
   

    this.group_nature = [
      { id: 0, name: res['ACCOUNTS.Income']},
      { id: 1, name: res['Van.Expense'] },
      { id: 2, name: res['ACCOUNTS.assets'] },
      { id: 3, name: res['ACCOUNTS.Liability'] },

    ];

    this.group_types = [
      { id: 1, accgrp_name: res['ACCOUNTS.primary'] },
      { id: 0, accgrp_name: res['ACCOUNTS.sub_group'] }
    ];
    
  });

}

}

