import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';

@Component({
  selector: 'app-rep-product',
  templateUrl: './rep-product.component.html',
  styleUrls: ['./rep-product.component.css']
})
export class RepProductComponent implements OnInit {

  constructor(private coreService: CoreService) { }
  up:any;
  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();

  }

}
