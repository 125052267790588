import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-delivery-challan-rep',
  templateUrl: './delivery-challan-rep.component.html',
  styleUrls: ['./delivery-challan-rep.component.css']
})
export class DeliveryChallanRepComponent implements OnInit {
  fileName= 'delivery_challan_reoprt.xlsx';

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_saldic = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_servamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];
  customer: any;
  agents: any;
  users: any;
  pageLoadingImg: boolean;
  show: any;
  time1: Date;
  time2: Date;
  delRept: any;
  pageFrom: any;
  repDate: any;
  totalData: any;
  lastpage: any;
  curpage: any;
  pgend: boolean;
  pgstart: boolean;
  exportLoader: boolean;
  ExpdelRept: any;
  ExptotalData: any;
  branch_display_name: any;
  branch_address:any;
  branch_name: any;
  branch_display_code: any;
  branch_all: any;
  country_dec: string;
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    let searchval = new FormData();
    
    this.apiService.delveryChallanRpt(searchval, 1).subscribe((res:any) => {
      this.pageLoadingImg = false;
      if(res['data']['data']){
      
      this.delRept = res.data.data.data;
      console.log(this.delRept);
      
      // this.pdf = res.data.pdf;
      // this.summary = res.data.data;
      this.repDate = res.data.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalData = res.data.data;
      // // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      }
    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    
   
   
    if (formdata.value.period_type === 'c') {
      if(formdata.value.time1){
        this.time1 = new Date(formdata.value.time1);
      }
      if(formdata.value.time2){
        this.time2 = new Date(formdata.value.time2);
      }
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.delveryChallanRpt(formdata.value, pageno).subscribe((res:any) => {
      this.pageLoadingImg = false;
      if(res['data']['data']){
      
      this.delRept = res.data.data.data;
      console.log(this.delRept);
      
      // this.pdf = res.data.pdf;
      // this.summary = res.data.data;
      this.repDate = res.data.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalData = res.data.data;
      // // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      }
    });

  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    }
    else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    }
    else {
      this.pgstart = false;
    }
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res.data;
    });

  }

  searchAgent(search: string)
  {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.agents = res['data'];

    });
  }
  searchUser(search: string)
  {
   let searchval = new FormData();      
   searchval.append("usr_name", search);    
   this.apiService.getUserByName(searchval).subscribe((res:any) => {
        this.users = res.data;     
      });
  }


  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
    
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
        if(formdata.value.time1){
          this.time1 = new Date(formdata.value.time1);
        }
        if(formdata.value.time2){
          this.time2 = new Date(formdata.value.time2);
        }
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
        formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
      }
    this.apiService.delveryChallanRpt(formdata.value,1).subscribe((res: any) => {
        this.pageLoadingImg = false;
        
        
        this.ExpdelRept = res.data.data.data;

        this.ExptotalData = res.data.data;
        this.repDate = res.data.data.Date;



        setTimeout(() => {
        this.exportexcel();
    }, 3000);



    });



    }

    searchBranch(search: string) {
      let searchval = new FormData();
      searchval.append("branch_name", search);
      this.apiService.getBranchByName(searchval).subscribe((res:any) => {
        this.branch_all = res.data;
        // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      });
    }

    // pdf download
    generatePdf(formdata: { value: any; }) {

      this.exportLoader = true;
     
    
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
          if(formdata.value.time1){
            this.time1 = new Date(formdata.value.time1);
          }
          if(formdata.value.time2){
            this.time2 = new Date(formdata.value.time2);
          }
          formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
          formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
          formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
          formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
        }
      this.apiService.delveryChallanRpt(formdata.value,1).subscribe((res: any) => {
          this.pageLoadingImg = false;
          
          
          this.ExpdelRept = res.data.data.data;
  
          this.ExptotalData = res.data.data;
          this.repDate = res.data.data.Date;

          const heads = [
            this.translate.instant('Table.sl_no'),
            this.translate.instant('Purchase.ref_no'),
            this.translate.instant('Table.date'),
            this.translate.instant('Sales.Name_Of_The_Customer'),
            this.translate.instant('Sales.Inv_Amount'),
            this.translate.instant('Purchase.discount'),
            this.translate.instant('Table.vat_amount'),
            this.translate.instant('HOME.challan_no')
          ];
    
            const lblXPos = 10;
            const headerHeight = 15;
            const valueXPos = 75;
            const data = [];
  
            this.ExpdelRept.forEach((item,i)=>{
              data.push([
                i + 1,
                item.del_chellan_inv_no,
                this.datePipe.transform(item.date, 'dd/MM/yyyy'),
                item.del_chellan_cust_name,
                this.currencyPipe.transform(item.del_chellan_total, '', '', this.country_dec),
                this.currencyPipe.transform(item.del_chellan_discount, '', '', this.country_dec),
                this.currencyPipe.transform(item.del_chellan_tax, '', '', this.country_dec),
                item.del_chellan_branch_inv
              ])
            })
        
            let doc = this.excelService.getPdfObj();
    
            var addressLines = this.branch_address.split('\r\n');
            var addressText = addressLines.join(', ');
    
            doc.setFont('Amiri');
            doc.setFontSize(12);
            doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
            doc.setFont('Amiri');
            doc.setFontSize(9);
            doc.text(addressText, 71, headerHeight + 2);
    
            if(this.repDate){
              doc.setFontSize(10);
              doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
              doc.setFontSize(10);
              doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
            }
    
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
            doc.setFontSize(10);
            doc.text(':  ' + `${this.translate.instant('HOME.Delivery_challan_rpt')}`,  valueXPos, headerHeight + 15);
    
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.Total_No_Invoices')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + this.ExptotalData.tot_result,  valueXPos, headerHeight + 20);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.Total_Invoice_Amount')}`, lblXPos, headerHeight + 25);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.ExptotalData.tot_inv_amt, '', '', this.country_dec),  valueXPos, headerHeight + 25);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Purchase.Total_VAT_Amount')}`, lblXPos, headerHeight + 30);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.ExptotalData.tot_vat_amt, '', '', this.country_dec),  valueXPos, headerHeight + 30);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Sales.Total_Amount_Excl_VAT')}`, lblXPos, headerHeight + 35);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.ExptotalData.tot_excl_vat, '', '', this.country_dec),  valueXPos, headerHeight + 35);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.total_discount_amount')}`, lblXPos, headerHeight + 40);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.ExptotalData.tot_disc_amt, '', '', this.country_dec),  valueXPos, headerHeight + 40);
            
            doc = this.excelService.addTableToPdf(doc, headerHeight + 45, lblXPos, heads, data, null);
           
            doc.save(`Delivery Note Report.pdf`);
    
            this.exportLoader = false;
  
      });
      
    }

}
