import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-branch-detailed-rep',
  templateUrl: './branch-detailed-rep.component.html',
  styleUrls: ['./branch-detailed-rep.component.css']
})
export class BranchDetailedRepComponent implements OnInit {

  fileName = 'branch_detailed_rep.xlsx';

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  branch_display_name: any;
  repData: any;
  pageLoadingImg: boolean;
  repDate: any;
  totRes: any;
  exportLoader: boolean;
  branch_display_code: any;
  branch_address:any;
  taxCategories = [];
  prd_tax_cat_id : any;
  date1:any;
  date2:any;
  period_type :any;
  branch_all: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }


  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.country_dec=this.coreService.setDecimalLength();
    this.langChange();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
      this.prd_tax_cat_id = 5;
    });
    this.listAllBranches();

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }
  listAllBranches() {
    let searchval = new FormData();

    this.apiService.getAllBranchesforTransfer().subscribe((res) => {
      this.branch_all = res['data'];
    });

  }



  selectBranch(branch) {
    if (branch) {
      this.pageLoadingImg = true;
      const searchval = new FormData();
      searchval.append('sel_branch_id', branch);
      searchval.append('prd_tax_cat_id', this.prd_tax_cat_id);

      this.apiService.branchDetailedRep(searchval).subscribe((res) => {
        this.repData = res.data;
        this.pageLoadingImg = false;
        this.repDate = res.data.Date;
        this.totRes = res.data.totalRes;
      });

    } else {
      this.repData = [];

    }
  }


  filterSearch(formdata: { value: any; }, branch) {
    if (branch) {
      this.pageLoadingImg = true;
      const searchval = new FormData();
      formdata.value.sel_branch_id = branch;
      formdata.value.prd_tax_cat_id = this.prd_tax_cat_id;
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      }
      this.apiService.branchDetailedRep(formdata.value).subscribe((res) => {
        this.repData = res.data;
        this.pageLoadingImg = false;
        this.repDate = res.data.Date
        this.totRes = res.data.totalRes;

      });

    } else {
      this.repData = [];

    }
  }


  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('export-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  // pdf download
  generatePdf() {
  
    const heads = [
      this.translate.instant('Purchase.date'),
      this.translate.instant('Purchase.refno'),
      this.translate.instant('HOME.TYPE'),
      this.translate.instant('Purchase.notes'),
      this.translate.instant('HOME.debit'),
      this.translate.instant('HOME.credit')
    ];

      

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.repData.data.forEach(item=>{
        data.push([
          item.date,
          item.ref,
          item.type,
          item.notes,
          this.currencyPipe.transform(item.debit, '', '', this.country_dec),
          this.currencyPipe.transform(item.credit, '', '', this.country_dec)
        ])
      })

      data.push([
        '',
        '',
        '',
        this.translate.instant('HOME.total'),
        this.currencyPipe.transform(this.totRes.debit_amt, '', '', this.country_dec),
        this.currencyPipe.transform(this.totRes.credit_amt, '', '', this.country_dec)
      ])

      data.push([
        '',
        '',
        '',
        this.translate.instant('Common.Balance'),
        this.currencyPipe.transform(this.totRes.db_bal, '', '', this.country_dec),
        this.currencyPipe.transform(this.totRes.cr_bal, '', '', this.country_dec)
      ])
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Branch.branch_detailed_rep')}`,  valueXPos, headerHeight + 15);

      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
  
     
      doc.save(`Branch Detailed Report.pdf`);


    
  }

}
