import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';

@Component({
  selector: 'app-report-company',
  templateUrl: './report-company.component.html',
  styleUrls: ['./report-company.component.css']
})
export class ReportCompanyComponent implements OnInit {
  up: any;
  usr_username: any;
  cmpny: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.usr_username =  this.coreService.getUserData('usr_username');

  }

}
