import { Component, OnInit, ViewChild } from '@angular/core';
import { Catagory } from '../../../model/catagory.model';
import { Subcatagory } from '../../../model/subcatagory.model';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-subcatagory',
  templateUrl: './subcatagory.component.html',
  styleUrls: ['./subcatagory.component.css']
})
export class SubcatagoryComponent implements OnInit {
  @ViewChild('myTabGroup', { static: false }) tabGroup: MatTabGroup;
  cat_id: any;
  subcat_name: any;
  all_subcat: any;
  selectedData: any = { subcat_id: null, error: null, subcat_parent_category: null,message:null};
  result = ''; 
  catagories:[];
  subcatagories: [];
  subcat_all : string[];
  pageLoadingImg :boolean;
  up: any;
  subcat_pos = true;

  subtotal_cats: any;
  

  constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {
    this.readcatsub(); 
    this.coreService.getToken(); 
    this.up = this.coreService.getUserPrivilage();
    this.getAllSubCat();
  }


  createSubCat(formdata: { value: Subcatagory; }) {   
    this.pageLoadingImg = true;

        this.apiService.createSubCat(formdata.value).subscribe((res: Subcatagory) => {
          this.pageLoadingImg = false;

        if (res.error != null) {        
          this.result = res.error;                   
        }
        else {
          this.result='';
          this.coreService.createfunct(formdata,this.result,res.message);    
        }

      });
      }

  updateSubCat(formdata: { value: Subcatagory; })
  {
    this.pageLoadingImg = true;

    // if(formdata.value.subcat_pos){
    //   (formdata.value.subcat_pos.isChecked === true )? 0 : 1; }           
        this.apiService.updateSubCat(formdata.value).subscribe((res: Subcatagory) => {
          this.pageLoadingImg = false;

  
          if (res.error != null) {         
            this.result = res.error;
          }
          else {         
            $("#editsubcatf").hide(); 
            this.result=''; 
            this.coreService.updatefunct(formdata,this.result,res.message);            
            this.subcat_all = [];
          }
  
        });
      }

    //form search
searchSubcat(search: string)
{
 let searchval = new FormData();      
      searchval.append("subcat_name", search);    
       this.apiService.getSubcatByName(searchval).subscribe((res) => {
        this.subcat_all = res['data'];                
       });
       $('.prev-list').show(); 
}

    //form search
    searchForm(search: string)
    {
     let searchval = new FormData();      
          searchval.append("subcat_name", search);    
           this.apiService.getSubcatByName(searchval).subscribe((res) => {
            this.subcat_all = res['data'];                
           });
           $('.prev-list').hide(); 
          console.log(this.subcat_all);
    }

removeName()
{
  this.subcat_all = [];
  $('.prev-list').hide(); 
}

  selectSubCat(subcats:Subcatagory){  
    console.log(subcats); 
    if(subcats){
      this.selectedData =subcats
    }
    $("#editsubcatf").show();
  }

  tabClick(tab: { index: number; }) {   
    if(tab.index==1)
  {
  this.readcatsub()
  }
  this.result = ''; 
}

readcatsub()
{        
  this.apiService.readCat().subscribe((catagories: Catagory[]) => {
    this.catagories = catagories['cat'];  
    this.subcatagories = catagories['subcat'];
  });
}
getAllSubCat(){

  this.apiService.getSubCatAll().subscribe((res) => {
    this.subcat_all = res['data']['subcat'];   
    this.subtotal_cats = res['data']['total'];
             
   });
}

editsubCat(data){
  this.tabGroup.selectedIndex = 1;
  // this.allcat=cat;
  this.selectSubCat(data); 
}
}
