import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 


@Component({
  selector: 'app-vat-daily-summary',
  templateUrl: './vat-daily-summary.component.html',
  styleUrls: ['./vat-daily-summary.component.css']
})
export class VatDailySummaryComponent implements OnInit {

  fileName= 'daily_vat_summary.xlsx';

  userType: any;
  pageLoadingImg: boolean;
  purchaseSummry: any;
  expSummry: any;
  saleSummry: any;
  purchTotal: any;
  expTotal: any;
  saleTotal: any;
  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  detailSummaryPurchase: any;
  detailTotalPurch: any;
  start_date: any;
  start_end: any;
  display_name: any;
  display_tin: any;
  exportLoader: boolean;
  taxCategories: any;
  SelTaxcatsale: any;
  SelTaxcatPurch: any;
  detailSummarySale: any;
  detailTotalSale: any;
  branches: any;
  cmp_type: string;
  filter_cmptype = [
    { id: '1', name: 'Establishment' },
    { id: '2', name: 'Company' },

  ];
  filter_branch_id: any[];
  branch_all: any;
  usr_username: any;
  Alldet: any;
  crNoteSummry: any;
  crNoteTotal: any;
  drNoteSummry: any;
  drNoteTotal: any;
  country_dec: string;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
    this.userType = this.coreService.getUserData('user_type');
    this.readProdSumm();
    this.usr_username =  this.coreService.getUserData('usr_username');

    this.getAllTaxCategory();
    this.readVatDetails();
    this.getAllTaxCategoryIds();
  this.cmp_type = '1';

  this.changeCmpType(1);
    
  } 


  readProdSumm() {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    // searchval.append("period_type", '1');
    this.purchaseSummry = [];
    this.apiService.DailyVatSummary(searchval).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.purchaseSummry = res.data.purchase.taxCats;
      this.saleSummry = res.data.sales.taxCats;
      this.crNoteSummry = res.data.credit.taxCats;
      this.purchTotal = res.data.purchase.total;
      this.saleTotal = res.data.sales.total;
      this.crNoteTotal = res.data.credit.total;
      this.start_date = res.data.date_start;
      this.start_end = res.data.start_end;
      this.Alldet = res.data.Summary;
      this.expSummry = res.data.expense.taxCats;
      this.expTotal = res.data.expense.total;
      this.drNoteSummry = res.data.debit.taxCats;
      this.drNoteTotal = res.data.debit.total;

      this.display_name = res.data.branch.branch_display_name;
      this.display_tin = res.data.branch.branch_tin;
    });


  }


  readVatDetails() {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    this.detailSummaryPurchase = [];
    this.apiService.DailyVatDetailedSummary(searchval).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.detailSummaryPurchase = res.data.data.purchase;
      this.detailSummarySale = res.data.data.sale;
      this.detailTotalPurch = res.data.total.Purch;
      this.detailTotalSale = res.data.total.Sale;

    });


  }

  changeCmpType(cmptype) {
    let searchval = new FormData();
    searchval.append("cmp_type", cmptype);
    this.filter_branch_id = [];
    this.apiService.getBranchbyType(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      
    });
  }

  getAllTaxCategory(){
    this.apiService.getAllTaxCategory().subscribe((res: any) => {
      this.taxCategories = res.data;
    });
  }

  getAllTaxCategoryIds(){
    this.apiService.getAllTaxCategoryIds().subscribe((res: any) => {
      this.SelTaxcatPurch = res.data;
      this.SelTaxcatsale = res.data;
    });
  }
  getBranchByName(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branches = res['data'];
    });
  }

  pageNext(formdata: { value: any; }) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.purchaseSummry = [];
    this.apiService.DailyVatSummary(formdata.value).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.purchaseSummry = res.data.purchase.taxCats;
      this.expSummry = res.data.expense.taxCats;
      this.saleSummry = res.data.sales.taxCats;
      this.crNoteSummry = res.data.credit.taxCats;
      this.purchTotal = res.data.purchase.total;
      this.expTotal = res.data.expense.total;
      this.saleTotal = res.data.sales.total;
      this.crNoteTotal = res.data.credit.total;
      this.start_date = res.data.date_start;
      this.start_end = res.data.start_end;
      this.Alldet = res.data.Summary;
      this.drNoteSummry = res.data.debit.taxCats;
      this.drNoteTotal = res.data.debit.total;

      this.display_name = res.data.branch.branch_display_name;
      this.display_tin = res.data.branch.branch_tin;

    });
    
    
    
    this.detailSummaryPurchase = [];

    this.apiService.DailyVatDetailedSummary(formdata.value).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.detailSummaryPurchase = res.data.data.purchase;
      this.detailSummarySale = res.data.data.sale;
      this.detailTotalPurch = res.data.total.Purch;
      this.detailTotalSale = res.data.total.Sale;

    });

 
  }


  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('print-vatday');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

}
