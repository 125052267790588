import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
@Component({
  selector: 'app-copy-stock-different-branch',
  templateUrl: './copy-stock-different-branch.component.html',
  styleUrls: ['./copy-stock-different-branch.component.css']
})
export class CopyStockDifferentBranchComponent implements OnInit {
  branches :any;
  resultobj: any = {};
  info = {
    from_branch_id: 0,
    to_branch_id: 0,
    limit: 100,
    from_prod_id:0,
    update_avg_purch_rate:false,
    update_last_purch_rate:false
  }
  pageLoadingImg = false;
  progMessage = '';
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.getAllBranches();
  }

  getAllBranches(){
    this.apiService.getAllBranches().subscribe((branch) => {
      this.branches = branch['data'];
    });
  }
  copyStock(){
    if (confirm("Are you sure?")) {
      this.progMessage = '';
      this.resultobj = {};
      if(this.info.from_branch_id == 0){
        this.resultobj.from_branch_id = [];
        this.resultobj.from_branch_id.push('Please Select From Branch');
      } else if(this.info.to_branch_id == 0){
        this.resultobj.to_branch_id = [];
        this.resultobj.to_branch_id.push('Please Select To Branch');
      } else{
        this.pageLoadingImg = true;
        this.repeatCopy();
      }
    }
  }

  repeatCopy(){
    
    this.apiService.copyStockDifferentBranch(this.info).subscribe((res: any) => {
      if (res.error != null) {
        this.pageLoadingImg = false;
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      }
      else {
        this.resultobj = {};
        this.progMessage += '<br>'+ res.data.msg;
        if(res.data.prod_id_max != res.data.last_prod_id){
          this.info.from_prod_id = res.data.last_prod_id;
          this.repeatCopy();
        } else {
          this.pageLoadingImg = false;
          this.progMessage += '<br>Branch Stock Copy Completed';
          this.coreService.showMessage('Branch Stock Copy Completed',3000);
          this.info = {
            from_branch_id: 0,
            to_branch_id: 0,
            limit: 100,
            from_prod_id:0,
            update_avg_purch_rate:false,
            update_last_purch_rate:false
          }
        }
      }
    });
  }
}
