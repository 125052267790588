import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';


@Component({
  selector: 'app-rep-low-stock-alert',
  templateUrl: './rep-low-stock-alert.component.html',
  styleUrls: ['./rep-low-stock-alert.component.css']
})
export class RepLowStockAlertComponent implements OnInit {

  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  pageFrom: any;
  summary: any;
  repDate: any;

  qty_filter: any;


  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  products: any[];
  catagories: any;
  users: any;
  subcatagories: any;
  changeBox: any;
  branch_all: any;
  userType: any;
  country_dec: string;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }


  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.country_dec=this.coreService.setDecimalLength();
    this.userType = this.coreService.getUserData('user_type');


    let searchval = new FormData();

    this.apiService.lowStockAlert(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      // this.repDate = res.data.Date;
      this.summary = res.data.data;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;




      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });


    this.searchCat('');

  }



  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    // if (formdata.value.period_type === 'c') {
    //   formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    //   formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    // }
    this.apiService.lowStockAlert(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      // this.repDate = res.data.Date;
      this.summary = res.data.data;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;




      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }



  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }



  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
  }

  selectSubCats(catid: number) {
    if(catid){
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;
      
    });
  }else{
    this.subcatagories = '';
  }

  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  // pdf download
  generatePdf() {
  
    const heads = [
      this.translate.instant('Table.sl_no'),
      this.translate.instant('Common.prd_name'),
      this.translate.instant('Table.ean_barCode'),
      this.translate.instant('Sales.sales_price'),
      this.translate.instant('Table.qty_on_hand'),
      this.translate.instant('Product.minimum_stock'),
      this.translate.instant('Common.shortage_qty')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.summary.forEach((item,i)=>{
        data.push([
          i+1,
          item.prd_name,
          item.prd_ean,
          this.currencyPipe.transform(item.sale_rate, '', '', this.country_dec),
          this.currencyPipe.transform(item.qty_in_hand, '', '', '1.1-1'),
          this.currencyPipe.transform(item.prd_min_stock, '', '', '1.1-1'),
          this.currencyPipe.transform(item.low_stk_qty, '', '', '1.1-1')
        ])
      })
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('HOME.low_stock_alert')}`,  valueXPos, headerHeight + 10);

      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 15, lblXPos, heads, data, null);
  
     
      doc.save(`Low Stock Alert.pdf`);


    
  }


}
