
import { Component, OnInit, Inject } from '@angular/core';

import { Purchase } from '../../../../model/purchase';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';

import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { NgForm } from '@angular/forms';
import { empty } from 'rxjs';
@Component({
  selector: 'app-transfer-return-void',
  templateUrl: './transfer-return-void.component.html',
  styleUrls: ['./transfer-return-void.component.css']
})
export class TransferReturnVoidComponent implements OnInit {

  product_summary: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  tbody: any;

  resultobj: {};
  purchaseItems = [];
  purchData: any = {
    purch_id: null,
    purch_inv_no: null,
    error: null,
    message: null,
    data: null,

  };
  purchDate: Date;
  purchNote: any;
  purchId: any;
  purchTotal: any;
  tranfrom: any;
  tranto: any;
  total: any;
  tot_qty: any;
  prd_id: any;
  tran_id: number;
  vantran_purch_price: number;
  prd_barcode: String;
  vantransub_qty: number;
  vantran_purch_amount: number;
  vantransub_unit_id: any;
  default_unit: any;
  total_qty = 0;
  NgForm: any;
  total_amount = 0;
  sel_gd_id = 0;
  tranArray: any;
  sl_no = 0;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any[];
  preview_items: any[];
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  pageLoadingImg:boolean;
  country_dec: string;
  loading: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService
    , private _bottomSheet: MatBottomSheet,

  ) { }

  ngOnInit() {
    this.readVanTransfers();
    this.country_dec=this.coreService.setDecimalLength();
  }
  readVanTransfers() {
    this.loading=true;
    let searchval = new FormData();
    searchval.append("status", '0');
    this.apiService.ListAllVanReturns(searchval, 1).subscribe((res) => {
      this.loading=false;
      this.vanlines = res['data'];

      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
  getPreview(data) {


    this.preview_id = data.vantranret_id;
    this.preview_date = data.vantranret_date;
    this.preview_gd = data.gd[0].gd_name;
    this.preview_van = data.van[0].van_name;
    // this.preview_gd = data.gd;
    // this.preview_van = data.van;
    this.preview_prize = data.vantranret_price;
    this.preview_items = data.items;
 
  }
  pageNext(formdata: { value: any; }, pageno: any) {

    let searchval = new FormData();
    this.apiService.readVanTransfers(searchval, pageno).subscribe((res) => {
      this.vanlines = res['data'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }



  resetForm(form: NgForm) {
    form.reset(); // or form.resetForm();
    this.purchData = [][''];
    this.total = 0;
    this.purchaseItems = [];
  }





  // form search
  purchase_search(search: string) {

    const searchval = new FormData();
    searchval.append('purch_id', search);
    this.apiService.getTranReturnDetails(searchval).subscribe((res) => {

    
      if (res) {
     
        if (res.error != null) {
        

          this.resultobj = res.error;
          this.purchaseItems = [];
          this.purchData = {
            purch_id: null,
            purch_inv_no: null,
            error: null,
            message: null,
            data: null
          };
          this.resultobj = {};
          this.purchaseItems = [];

          this.purchId = '';
          this.purchDate = null;

          this.tranfrom = '';
          this.tranto = '';
          this.total = 0;
          this.tot_qty = 0;
          this.resultobj = this.coreService.getValidation(this.resultobj);
        } else {
          this.resultobj = {};
          this.purchaseItems = res[0].items;
          this.purchData = res[0].items;
          this.purchId = res[0].vantranret_id;
          this.purchDate = new Date(res[0].vantranret_date);

          this.tranfrom = res[0].gd[0].gd_name;
          this.tranto = res[0].van[0].van_name;
          this.total = res[0].vantranret_price;
          this.tot_qty = res[0].tot_qty;
         
        }
      } else {
        this.purchaseItems = [];
      }
      // this.purchNote = res.data.purch_note;
      // this.purchTotal = Number(res.data.purch_amount) + Number(res.data.purch_tax) + Number(res.data.purch_frieght) - Number(res.data.purch_discount);

    });
  }


  voidPurchase(purchId: any, form: NgForm) {

    var rmvitem = confirm("Are you sure you want to void this return ? ");
    if (rmvitem) {
      this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('vantranret_id', purchId);
    this.apiService.voidVanTransferReturn(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);

      } else {
        this.readVanTransfers();
        this.resetForm(form);

        this.purchData = {
          purch_id: null,
          purch_inv_no: null,
          error: null,
          message: null,
          data: null
        };

        this.purchaseItems = [];

        this.purchId = '';
        this.purchDate = null;

        this.tranfrom = '';
        this.tranto = '';
        this.total = 0;
        this.tot_qty = 0;
        this.coreService.createfunct(searchval, this.resultobj, res.message);


      }
    });

  }

  }
}
