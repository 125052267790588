import { Component, OnInit, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { Stockedit } from '../../../../model/stockedit.model';
import { Batch } from '../../../../model/batch.model';
import { CommonModule, DatePipe } from '@angular/common';
// validations
import { FormControl, Validators } from '@angular/forms';
import { InprequiredDirective } from '../../../.././directive/inprequired.directive';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: 'app-opening-stock',
  templateUrl: './opening-stock.component.html',
  styleUrls: ['./opening-stock.component.css']
})


export class OpeningStockComponent implements OnInit {
  @ViewChild('selectproductId', { static: false }) selectproductId: ElementRef;
  @ViewChild('selectQty', { static: false }) selectQty: ElementRef;

  [x: string]: any;
  @ViewChildren(InprequiredDirective) dirs;
  // validations
  date1: any;
  date2: any;
  number = new FormControl('', [Validators.required, Validators.minLength(1)]);
  selUnitQty: any;
  selUnitName: any;
  selTotalQty: any;
  selprate: any;
  serachLoading = false;
  selUnitID:any;
  gd_id=0;

  getErrorMessage() {
    return this.number.hasError('required') ? 'Required (number only)' :
      this.number.hasError('number') ? 'Required (number only)' :
        '';
  }

  selecTed: any = {
    prd_id: null, prd_name: null, message: null, rec_unit_rates: null,
    upt_unit_rates: null, unit_rates: null, units_rates: null, error: null, gdstock: null, branch_id: null,
    prd_units: null, manufacture_date: null, expiry_date: null, batch_code: null, cmp_stock_id: null, purchase_rate: null
  };
  selBatch: any = { sb_batch_code: null, sb_expiry_date: null, message: null, sb_manufacture_date: null };
  // sb_manufacture_date: any;
  // sb_expiry_date: any;
  stkprd_all: string[];
  stkbtch_all: string[];
  rec_unit_rates: any[];
  sel_unit_rates: any[];
  godown_sel: any[];
  godown_notsel: any[];
  shppqty: number;
  resultobj: {};
  error = '';
  godown_all: any[];
  batch_all: string[];
  batch_selected: string[];
  selectBtch: boolean;
  keepFocus: boolean;
  godown: number;
  op_stock_list = [];
  prd_mrp: number;
  errorobj: false;
  res: boolean;
  resDup: any;
  mrp: any;
  opqtycal: any;
  stocks: any[];
  errField: any;
  changeBox: boolean;
  batchcode: any;
  batchSellRate = <any> [];
  loadingBatchSellRate = false;
  batch_details: any[];
  checkBatch =false;


  constructor(private apiService: ApiService, private coreService: CoreService, private datePipe: DatePipe) { }

  ngOnInit() {

    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });
    this.selectBtch = false;
    this.getGodowns();
    $('#batch1').hide();
    $('#srchbatchbtn').hide();
    this.selUnitQty = 1;
    this.selUnitName = $('#unit').find(':selected').data('unitname');
    this.godown = 0;

    this.country_dec=this.coreService.setDecimalLength();
  }

  //form search stock
  searchStkPrd(search: string) {
    this.barcode = '';
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getOpStockbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      // console.log("ll");
      // console.log(this.stkprd_all);


    });
  }

  //form search batch
  searchStkBatch(search: string) {
    let searchval = new FormData();
    searchval.append('keyword', search);
    searchval.append('prd_id', this.selecTed.prd_id);
    searchval.append('like', '1');
    this.apiService.getBatchbyName(searchval).subscribe((res) => {
      this.stkbtch_all = res['data'];

    });
  }

  // for search test
  searchStkPrdtest(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getStockbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  searchStkPrdBarcode(search: string) {
  
    if(!search){
      $("#br_err").text("Enter Barcode");
      return false;
    }else{
      this.serachLoading = true;
      $("#br_err").text("");
    }
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
      this.serachLoading = false;
      if (res.data) {
        $('#entry-table').show();
        $('#sales-table').show();
        this.selecTed = res.data;
        console.log("selected");
        console.log(this.selecTed);
        
      
        this.shppqty = 0;
      this.unitId1 = res.data.produnit_unit_id
      ? res.data.produnit_unit_id
      : res.data.prd_base_unit_id;

      const searchval1 = new FormData();
      searchval1.append("unit_id", this.unitId1);
      this.apiService.getOpeningUnit(searchval1).subscribe((res2) => {
      

        this.selUnitName =res2.data.unit_name;
        this.selUnitQty = res2.data.unit_base_qty;
        this.selUnitID = res2.data.unit_id;
        this.unit =res2.data.unit_name;
      });
     



      const searchval = new FormData();
      searchval.append("prd_id", res.data.prd_id);
      searchval.append("gd_id", "1");
      searchval.append("price_group_id","1");

   

      this.apiService.getsaleProdDetails(searchval).subscribe((res1) => {
        let selProdInfo = res1["data"];
        console.log("selProdInfo");
        console.log(selProdInfo);
        this.selecTed.purchase_rate = selProdInfo.bs_prate *  this.selUnitQty;
        this.baseUnitRate =selProdInfo.bs_prate;
        this.prd_tax =selProdInfo.prd_tax;

        // this.sel_unit_rates = this.selecTed.units_rates;
        // this.godown_sel = this.selecTed.gdstock;
        this.sel_unit_rates = selProdInfo.base_unit_rate;
        this.godown_sel = this.selecTed.gdstock;
        this.selecTed.units =selProdInfo.prod_units;

        this.batchSellRate = [];
        
          selProdInfo.prod_units.forEach(element => {
            this.batchSellRate.push({rate1:'', rate2 : '', checked: false, unit_id: element.sur_unit_id})
          });
          // this.isEdit = false;
          // this.selectStockPrdSel(res['data'], content);
          // this.searchResult = '';
       
        
      });
      this.selecTed.cmp_stock_id =this.selecTed.bs_stock_id;

     
       
    

        

        $("#opstksub-btn").prop("disabled", false);
        this.getGodowns();

        this.selectQty.nativeElement.focus();
        this.selectQty.nativeElement.select();
      } else{
        $("#br_err").text("Barcode not found");
        $('#entry-table').hide();
        $('#sales-table').hide();
      }
    });
  }
  // end form search test
  //form search select
  selectStockPrd(res: Stockedit) {
    if (res) {

      console.log("this.selecTed");
      console.log(res);

      $('#entry-table').show();
      $('#sales-table').show();
      this.selecTed = res;
      this.sel_unit_rates = this.selecTed.units_rates;
      this.godown_sel = this.selecTed.gdstock
      this.shppqty = 0;
       
      console.log("this.selecTed");
      console.log(this.selecTed);
      this.unit =this.selecTed.base_unit_name;
      this.baseUnitRate =this.selecTed.purchase_rate;
      this.prd_tax =this.selecTed.prd_tax;

      $("#opstksub-btn").prop("disabled", false);
      this.getGodowns();



      this.batchSellRate = [];
      if (this.selecTed) {
        this.selecTed.units.forEach(element => {
          this.batchSellRate.push({rate1:'', rate2 : '', checked: false, unit_id: element.sur_unit_id})
        });
        // this.isEdit = false;
        // this.selectStockPrdSel(res['data'], content);
        // this.searchResult = '';
      } else {
        this.productSearchLoader = false;
        this.searchResult = 'Not found';
      }
      // compare
      // var result1 = this.godown_all;
      // var result2 = this.godown_sel;

      // var props = ['gd_id', 'gd_name'];

      // this.godown_notsel = result1.filter(function (o1) {
      //   // filter out (!) items in result2
      //   return !result2.some(function (o2) {
      //     return o1.gd_id === o2.gd_id;          // assumes unique id
      //   });
      // }).map(function (o) {
      //   // use reduce to make objects with only the required properties
      //   // and map to apply this to the filtered array as a whole
      //   return props.reduce(function (newo, name) {
      //     newo[name] = o[name];
      //     return newo;
      //   }, {});
      // });

      // compare
    }
  }

  //fomr search batch
  searchBatch(search: string) {
    var likeval = 'yes';
    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append('prd_id', this.selecTed.prd_id);
    searchval.append("like", likeval);
    this.apiService.getBatchbyName(searchval).subscribe((res) => {
      this.batch_all = res['data'];
      $('.prev-list').show();

    });

  }

  removeName() {
    $('.prev-list').hide();
  }

  //select batch  
  selectBatch(res: Batch) {
    if (res) {
      this.selBatch = res;
      this.selBatch.sb_manufacture_date = new Date(res.sb_manufacture_date);
      this.selBatch.sb_expiry_date = new Date(res.sb_expiry_date);

      $('#batch1').val('');
      this.batchcode = res.sb_batch_code;
      this.selectBtch = false;
      // console.log(this.selBatch);

      this.getExistingBatchSellRate();
    }
  }

  //hit batch
  hitBatch(event, value: string) {


    $('.prev-list').hide();
    if (event.keyCode == 13) {
      let searchval = new FormData();
      searchval.append("keyword", value);
      searchval.append('prd_id', this.selecTed.prd_id);
      this.apiService.getBatchbyName(searchval).subscribe((res) => {
        if (res['data'].length == 0) {
          var resone = confirm("Selcted batch not exists, Add Batch");
          if (resone) {
            this.selectBtch = false;
          }
        } else {
          this.batch_selected = res['data'];
          this.selectBtch = true;
        }

      });
    }
    //end if

  }


  selectFrmLst(bcode: string) {

    $('#bcode').val(bcode);
    let searchval = new FormData();
    searchval.append("keyword", bcode);
    searchval.append('prd_id', this.selecTed.prd_id);
    this.apiService.getBatchbyName(searchval).subscribe((res) => {
      $('.prev-list').hide();
      this.batch_selected = res['data'];
      this.selectBtch = true;
    });
  }

  addOpStock(form: { value: Stockedit; }) {
    this.InprequiredDirective.customDirective();
    var upt_unit_rates = [];
    var units_rates = [];
    var gdstock = [];
    var gdstockid = [];
    var gdstockidadd = [];
    var errorobj = false;
    var errorobjgd = false;
    var errorobjgdadd = false;
    //unit rates
    $('input[name="unitratechk"]:checked').each(function () {

      upt_unit_rates.push({ "unit_id": $(this).val() });
    });


    $('input.edit_unit_rate').each(function () {
      var attr_id = this.getAttribute("id");
      var unit_rt = $(this).val();

      $.each(upt_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          if (Number(unit_rt) <= 0) {
            errorobj = true;
          }
          units_rates.push({ "unit_id": attr_id, 'unit_rate': unit_rt });
        }
      });

    });

    // form.value.stkprdsel=undefined;
    form.value.unit_rates = units_rates;

    //  existing godown qty
    $('input[name="gdqtychk"]:checked').each(function () {
      gdstockid.push({ "gd_id": $(this).val() });
    });


    $('input.edit_gd_qty').each(function () {
      var gd_attrid = this.getAttribute("id");
      var gd_qty = $(this).val();
      $.each(gdstockid, function (index, value) {

        if (value.gd_id == gd_attrid) {

          if (Number(gd_qty) <= 0) {
            errorobjgd = true;
          }

          gdstock.push({ "gd_id": gd_attrid, 'quantity': gd_qty });
        }

      });

    });

    form.value.gdstock = gdstock;

    if (errorobjgd) {
      $('#error_in_gd').html('Please enter value greater than zero');
    } else {
      $('#error_in_gd').html('');
    }
    if (errorobj) {
      $('#error_in').html('Please enter value greater than zero');
    } else {
      $('#error_in').html('');
    }

    // godown add new
    //  existing godown qty
    $('input[name="gdqtychkadd"]:checked').each(function () {
      gdstockidadd.push({ "gd_id": $(this).val() });
    });


    $('input.edit_gd_qty_add').each(function () {
      var gd_attrid = this.getAttribute("id");
      var gd_qty = $(this).val();
      $.each(gdstockidadd, function (index, value) {

        if (value.gd_id == gd_attrid) {

          if (Number(gd_qty) < 0) {
            errorobjgdadd = true;
          }

          gdstock.push({ "gd_id": gd_attrid, 'quantity': gd_qty });
        }

      });

    });

    form.value.gdstock = gdstock;

    if (errorobjgdadd) {
      $('#error_in_gdadd').html('Please enter positive value');
    } else {
      $('#error_in_gdadd').html('');
    }
    if (!this.selectBtch && form.value.batch_code) {
      form.value.manufacture_date = form.value.manufacture_date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
      form.value.expiry_date = form.value.expiry_date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    }

    if (!errorobj && !errorobjgd && !errorobjgdadd) {
      this.apiService.addOpStock(form.value).subscribe((res: Stockedit) => {
        if (res.error != null) {
          this.resultobj = res.error;
          this.resultobj = this.coreService.getValidation(this.resultobj);

        }
        else {
          this.resultobj = {};
          this.coreService.createfunct(form, this.resultobj, 'Updated');
          this.stkprd_all = [];
          //this.godown_all=[];
          this.godown_sel = [];
          this.godown_notsel = [];
          this.sel_unit_rates = [];
          gdstock = [];
          errorobj = false;
          errorobjgd = false;
          $("#opstksub-btn").prop("disabled", true);
          $('#error_in').html('');
          $('#error_in_gd').html('');
          $('#ope-stk-form').hide();
        }

      });
      this.apiService.updatebatchExNotification();
    }
  }

  getGodowns() {
    let searchval = new FormData();
    searchval.append("gd_name", '');
    // this.apiService.getGodownByName(searchval).subscribe((res) => {
    //   this.godown_all = res['data'];
    //   this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });
    // });
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
    });
  }


  //  updated codes
  addOpStockList() {

    var upt_unit_rates = [];
    var unit_rates = [];
    var errorobj = false;
    var errordp = this.errValidDp(this.resDup = null);
    this.validateForm();
    
   


    // this.selUnitName = $('#unit').find(':selected').data('unitname');
    // this.selUnitQty = $('#unit').val();

    // this.selUnitName =unit.unit_name;
    // this.selUnitQty = unit.unit_base_qty;
    // this.selUnitID = unit.unit_id;
    // add unit ids
    $('input[name="unitratechk"]:checked').each(function () {

      upt_unit_rates.push({ "unit_id": $(this).val() });
    });

    //  add unit rates
    $('input.edit_unit_rate').each(function () {
      var attr_id = this.getAttribute("id");

      var unit_rt = $(this).val();



      $.each(upt_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          //  unit validation
          if (Number(unit_rt) < 0 || !Number(unit_rt)) {
            if (Number(unit_rt) === 0) {
              errorobj = false;
              $('#' + attr_id).css("border", "1px solid red");
            }
            else {
              $('#' + attr_id).css("border", "1px solid red");
              errorobj = true;
            }
            $('#' + attr_id).css("border", "1px solid red");
            errorobj = true;
          } else {
            $('#' + attr_id).css("border", "1px solid #dedede");
          }

          unit_rates.push({ "unit_id": attr_id, 'unit_rate': unit_rt });
        }
      });

    });
    this.mrp = (unit_rates[0].unit_rate);

    //  add godowns   
    var gdid = $('#gdid').val();
    if (gdid == '')
      gdid = 0;
    // validation 
    var batchcode;
    var opqtyval = $('#opqty').val();
    if ($('#batch1').val() == "") {
      batchcode = this.batchcode;
    } else {
      batchcode = $('#batch1').val();
    }
    var manufacture_date = this.selBatch.sb_manufacture_date;
    var expiry_date = this.selBatch.sb_expiry_date;
    var errorobjqty = this.errValidQty(opqtyval);
    var errorobjurate = this.errValidSr(errorobj);
    var prval = $('#prrate').val();
    this.selprate = prval;
    
    console.log("this.selprate");
    console.log(this.selprate);

    var prate = this.selprate / this.selUnitQty;
    console.log(prate);
    var errorobjprate = this.errValidPrt(prval);
    if (!errorobj)
    //  add opening stock list
    {
      // check duplicates
      this.resDup = this.coreService.checkDuplicateValOpstk(this.op_stock_list, this.selecTed.prd_id,batchcode);
      var errordp = this.errValidDp(this.resDup);

      //   if no duplicates
      if (!this.resDup && this.printError.length <= 0) {
        this.opqtycal = $('#opqty').val();
        this.selTotalQty = this.opqtycal * this.selUnitQty;
        var total_amount = this.selTotalQty * prate;
        this.op_stock_list.push({
          "prd_id": this.selecTed.prd_id, "stock_id": this.selecTed.cmp_stock_id, 'prd_name': $('#prdname').val(),
          'purchase_rate': prate, sales_rate: this.mrp, 'opening_quantity': this.selTotalQty, 'ean': $('#ean').val(),
          "gd_id": gdid, "mrp_rates": unit_rates, "total_amount": total_amount, 'purchase_rate_qty': this.selUnitQty,
          'expiry_date': expiry_date, 'manufacture_date': manufacture_date, 'batch_code': batchcode, 'selUnitName': this.selUnitName, 'seltotalqty': $('#opqty').val(),'batch_sell_rate':this.batchSellRate,'selUnitID':this.selUnitID
        });
        console.log(" this.op_stock_list");
        console.log( this.op_stock_list);
        this.stkbatchsel = [];
        this.batch1 = '';
        this.selBatch = { sb_batch_code: null, sb_expiry_date: null, message: null, sb_manufacture_date: null };
        $('#opstk-list-tble').show();
        $('#addopstk-btn').show();
        $('#sales-table').hide();
        $('#entry-table ').hide();
        $('#opqty').val('');
        console.log(this.op_stock_list);
        this.stkprd_all = [];
        if (this.changeBox)
          this.changeBox = false;
        else
          this.changeBox = true;
      }

      //   end if add list
      this.barcode ="";
    }
    this.stkprdsel = "Select Product";

    // this.selectproductId.focus();
    this.selectproductId.nativeElement.focus();
        // this.selectproductId.nativeElement.select();




  }

  selectUnit(unit) {

    console.log("unit");
    console.log(unit);
    if(unit.unit_id == undefined){
      this.selUnitID = unit.produnit_unit_id;
    }
    else{
      this.selUnitID = unit.unit_id;
    }

    console.log("this.selUnitID");
    console.log(this.selUnitID);
    // this.selUnitName = $('#unit').find(':selected').data('unitname');
    // this.selUnitQty = unit;
    // this.selUnitID = $('#unit').find(':selected').data('unitid');

    this.selUnitName =unit.unit_name;
    this.selUnitQty = unit.unit_base_qty;
    this.selUnitID = unit.unit_id;
      
    console.log("selecTed.purchase_rate");
    console.log(this.selecTed.purchase_rate);


    this.selecTed.purchase_rate = this.baseUnitRate * unit.unit_base_qty;

  }

  addBatch() {
    $('#srchbatchbtn').show();
    
    $('#addbatchbtn').hide();
    $('#stkbatchsel').hide();
    $('#batch1').show();
    this.searchExisting = false;
    // this.selBatch = { sb_batch_code: null, sb_expiry_date: null, message: null, sb_manufacture_date: null ,sb_id: 0 };
    this.getExistingBatchSellRate();

  }

  addshow() {
    $('#srchbatchbtn').hide();
    $('#addbatchbtn').show();
    $('#stkbatchsel').show();
    $('#batch1').hide();
    this.searchExisting = true;

    this.getExistingBatchSellRate();
  }
  // delete product from table
  deleteProd(data: any) {
    var remopstk = confirm("Removing Selected Product from the table");
    if (remopstk) {
      var type = data.prd_id,
        i;
      for (i = this.op_stock_list.length - 1; i >= 0; --i) {
        if (this.op_stock_list[i].prd_id == type) {
          this.op_stock_list.splice(i, 1);
        }
      }
      if (this.op_stock_list.length == 0)
        $('#addopstk-btn').hide();
    }

  }

  addOpStk(formgroup: { value: any; }) {
    this.pageLoadingImg = true;
    this.stocks = this.op_stock_list;
    formgroup.value.stocks = this.op_stock_list;
    this.apiService.addOpStock(formgroup.value).subscribe((res: Stockedit) => {
      this.pageLoadingImg = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);

      }
      else {
        this.resultobj = {};
        this.coreService.createfunct(formgroup, this.resultobj, 'Updated');
        this.stkprd_all = [];
        //this.godown_all=[];
        this.godown_sel = [];
        this.godown_notsel = [];
        this.sel_unit_rates = [];
        this.op_stock_list = [];
        $("#opstksub-btn").prop("disabled", true);
        $('#error_in').html('');
        $('#error_in_gd').html('');
        $('#ope-stk-form').hide();
        $('#sales-table').hide();
        $('#entry-table ').hide();
        $('#opstk-list-tble').hide();
        if (this.changeBox)
          this.changeBox = false;
        else
          this.changeBox = true;

      }

    });
    this.apiService.updatebatchExNotification();

    this.selectproductId.nativeElement.focus();
  }

  selectOpStockPrd(res: Stockedit) {
    if (res)
      this.selecTed = res;

      // console.log("ssss");
      // console.log(this.selecTed.batches);
    this.stkbtch_all = this.selecTed.batches;
    this.godown == 0;
    this.unit =this.selecTed.base_unit_name;
    this.baseUnitRate =this.selecTed.purchase_rate;
    this.prd_tax =this.selecTed.prd_tax;

    this.selUnitName =this.selecTed.base_unit_name;
    this.selUnitQty = this.selecTed.base_unit_base_qty;
    this.selUnitID = this.selecTed.prd_base_unit_id;

    $('#sales-table').show();
    $('#entry-table ').show();
    $('#srchbatchbtn').hide();

    this.batchSellRate = [];
    if (this.selecTed) {
      console.log("this.selecTed.units");
      console.log(this.selecTed.units);
      this.selecTed.units.forEach(element => {
        this.batchSellRate.push({rate1:'', rate2 : '', checked: false, unit_id: element.unit_id})
      });
      // this.isEdit = false;
      // this.selectStockPrdSel(res['data'], content);
      // this.searchResult = '';
      this.selectQty.nativeElement.focus();
      this.selectQty.nativeElement.select();
    } else {
      this.productSearchLoader = false;
      this.searchResult = 'Not found';
    }

  }



  errValidSr(errorobj) {
    if (errorobj == true) {
      $('#err_sr').show();
      return true;
    }
    else {
      $('#err_sr').hide();
      errorobj = false;
      return false;
    }
  }
  errValidDp(errorobj) {
    if (errorobj == true) {
      $('#err_dp').show();
      return true;
    }
    else {
      $('#err_dp').hide();
      return false;

    }
  }

  errValidQty(errFied) {
    if (Number(errFied) <= 0 || isNaN(errFied)) {
      $('#err_opqty').show();
      return true;
    } else {
      $('#err_opqty').hide();
      return false;
    }

  }

  errValidPrt(errFied) {
    if (Number(errFied) <= 0 || isNaN(errFied)) {
      $('#err_prate').show();
      return true;
    } else {
      $('#err_prate').hide();
      return false;
    }

  }


  validateForm() {

    this.printError = [];
    if ($('#opqty').val() == "" || $('#opqty').val() == undefined || $('#opqty').val() == 0) {
      this.printError.push({ 'opqtyErr': 'Required' });
      $('#opqty').css("border", "1px solid red");
    }
    else {
      $('#opqty').css("border", "1px solid #dedede");
    }
   // this.selUnitQty = $('#unit').val();
    if (this.unit == "" || this.unit == undefined) {
      this.printError.push({ 'unitqtyErr': 'Required' });
      $('#unit').css("border", "1px solid red");
    }
    else {
      $('#unit').css("border", "1px solid #dedede");

    }
    if ($('#prrate').val() == "" || $('#prrate').val() == undefined) {
      this.printError.push({ 'prateErr': 'Required' });
      $('#prrate').css("border", "1px solid red");
    }
    else {
      $('#prrate').css("border", "1px solid #dedede");
    }

    if ($('#gdsel').val() == "" || $('#prrate').val() == undefined) {
      this.printError.push({ 'prateErr': 'Required' });
      $('#gdsel').css("border", "1px solid red");
    }
    else {
      $('#gdsel').css("border", "1px solid #dedede");
    }

  }


  getExistingBatchSellRate(){
    // this.searchExisting = true;
    // this.selBatch
    // get_batch_sell_rate
    // console.log(this.selecTed);
    // console.log(this.selBatch);
    this.loadingBatchSellRate = true;
    var batch_id = (this.searchExisting && this.selBatch && this.selBatch.sb_id) ? this.selBatch.sb_id: 0;
    
    console.log("batch_iD");
    console.log(batch_id);
    this.apiService.getBatchSellRate({batch_id : batch_id,prd_id: this.selecTed.prd_id,branch_stock_id:this.selecTed.branch_stock_id}).subscribe((resp) => {
      var unitRates = resp.data;
      // this.taxListCategories = resp.data;
      // this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
      this.batchSellRate.forEach((element,i) => {	
        console.log("element");
        console.log(unitRates);
        console.log(element);
        console.log(element.unit_id);
        console.log(unitRates[element.unit_id]);
        if(unitRates[element.unit_id]){
          this.batchSellRate[i].rate1 = unitRates[element.unit_id].sur_unit_rate
          this.batchSellRate[i].rate2 = unitRates[element.unit_id].sur_unit_rate2
        }
      });
      this.loadingBatchSellRate = false;
    });
    
  }

  inc_tax(keyword,id){
    if(!isNaN(keyword)){
      let t = keyword- ((keyword * 100) / (this.prd_tax + 100));
      let p = keyword-t;
      $(".stk_mrp2_"+id).val(p);
    }
  }
  exl_tax(keyword,id){
    if(!isNaN(keyword)){
      keyword = Number (keyword);
      let t = keyword+ (keyword * this.prd_tax) / 100;
      $(".stk_mrp2_inp2_"+id).val(t);
    }
  }


}
