import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Reppurchdet } from '../../../../../model/report/reppurchdet.model';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchase-details',
  templateUrl: './purchase-details.component.html',
  styleUrls: ['./purchase-details.component.css']
})
export class PurchaseDetailsComponent implements OnInit {

  purch_id: any;
  items: any;
  curpage: any;
  lastpage: any;
  pageNext: any;
  product_summary: any;
  purch_id2: any;
  purch_date: any;
  supp_name: any;
  purch_type_name: any;
  note: any;
  remark: any;
  calc_mode: any;
  purchaseBlock: any;
  pgstart: any;
  elseBlocknostart: any;
  pgend: any;
  elseBlocknoend: any;
  total_items: any;
  total_qty: any;
  purch_amount: any;
  purch_tax: any;
  purch_frieght: any;
  purch_discount: any;
  net_amount: any;
  purchId: any;
  showdata:boolean;

  resultobj: any = {};
  purchaseItems = [];
  purchData: any = {
    purch_id: null,
    purch_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  pageLoadingImg:boolean;
  up: any;
  country_dec: string;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.country_dec=this.coreService.setDecimalLength();
 this.pageLoadingImg = false;
 this.showdata = false;
//  this.purchData.purch_flag = 1;
this.up = this.coreService.getUserPrivilage();
  }


  purchase_search(search: string) {
  
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purch_id', search);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
 
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.purchData = {
          purch_id: null,
          purch_inv_no: null,
          error: null,
          message: null,
          data: null,
          
        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.purchData = res.data;
      }


    });
  }

  // pdf download
  generatePdf() {
  
    const heads = [
      this.translate.instant('Purchase.product_name_serial_numbers'),
      this.translate.instant('HOME.PRODUCT') + '\n' + this.translate.instant('Purchase.Catgory'),
      this.translate.instant('Table.barcode'),
      this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.qty'),
      this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Common.unit_name'),
      this.translate.instant('Table.purch_rate'),
      this.translate.instant('Table.purch_price')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.purchData.items.forEach(item=>{
        data.push([
          item.prd_name,
          item.category,
          item.prd_barcode,
          this.currencyPipe.transform(item.purchased_qty, '', '', '1.1-1'),
          item.unit_purchased,
          this.currencyPipe.transform(item.purchased_rate, '', '', this.country_dec),
          this.currencyPipe.transform(item.purchase_amount, '', '', this.country_dec)
        ])
      })
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Purchase.purchases_details')}`,  valueXPos, headerHeight + 10);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.purch-refno')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + this.purchData.purch_id,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.purchase_Id')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.purchData.purch_id2,  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.purchases_date')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.datePipe.transform(this.purchData.purch_date, 'dd/MM/yyyy'),  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.Suppliers')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.purchData.supp_name,  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.supplier_inv_no')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.purchData.purch_inv_no,  valueXPos, headerHeight + 35);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.payment_type')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + this.purchData.purch_type_name,  valueXPos, headerHeight + 40);

      if(this.purchData.purch_note){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.notes')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ' + this.purchData.purch_note,  valueXPos, headerHeight + 45);
      } else {
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.notes')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ',  valueXPos, headerHeight + 45);
      }

      if(this.purchData.purch_flag==0){
        doc.setTextColor(255,0,0);
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Production.Voided')}`, lblXPos, headerHeight + 50);
      }

      doc.setTextColor(0,0,0);
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 55);
      doc.setFontSize(10);
      doc.text(':  ' + this.purchData.total_items,  valueXPos, headerHeight + 55);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.Total_Qty')}`, lblXPos, headerHeight + 60);
      doc.setFontSize(10);
      doc.text(':  ' + this.purchData.totl_purchased_qty,  valueXPos, headerHeight + 60);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.Total_Item_Price')}`, lblXPos, headerHeight + 65);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.purchData.purch_amount, '', '', this.country_dec),  valueXPos, headerHeight + 65);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.Total_Tax')}`, lblXPos, headerHeight + 70);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.purchData.purch_tax, '', '', this.country_dec),  valueXPos, headerHeight + 70);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.Freight_Amount')}`, lblXPos, headerHeight + 75);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.purchData.purch_frieght, '', '', this.country_dec),  valueXPos, headerHeight + 75);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.discount')}`, lblXPos, headerHeight + 80);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.purchData.purch_discount, '', '', this.country_dec),  valueXPos, headerHeight + 80);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.net_amount')}`, lblXPos, headerHeight + 85);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.purchData.net_amount, '', '', this.country_dec),  valueXPos, headerHeight + 85);

      doc = this.excelService.addTableToPdf(doc, headerHeight + 90, lblXPos, heads, data, null);
  
     
      doc.save(`Purchase Details.pdf`);


    
  }

}
