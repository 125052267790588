import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-service-type',
  templateUrl: './service-type.component.html',
  styleUrls: ['./service-type.component.css']
})
export class ServiceTypeComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];

  service_type = {
    src_id: '',
    src_type_name:'',
    src_type_amount:0,
    src_type_ledger_id :'',
    
  }

  isSubmitInProg = false;
  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;
  langText = {
    create: '',
    update: ''
  };
  up: any;
  selecTed: any[];
  searchleadger: any;
  cur_lang: string;
  
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
      this.up = this.coreService.getUserPrivilage();
    });
    this.getInfo(this.curpage);
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
   
  }
  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllServiceType(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data;
      this.pageLoadingImg = false;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.totalItem = res.total;
    });
  }

  createServiceType(){
    this.curForm = this.langText.create;
    this.clearForm();
  }

  searchLedger(search: string) {
    $('#report-wrapper').hide();

    const searchval = new FormData();
    searchval.append('search', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchLedgerList(searchval, 1).subscribe((res: any) => {
      this.searchleadger = res.data ? res.data.data : '';
      this.selecTed = [];
    });

  }

  clearForm() {
    this.service_type = {
      src_id: '',
      src_type_name:'',
      src_type_amount:0,
      src_type_ledger_id :'',
    };
  }

  editServiceType(src_id){
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getServiceType({src_id: src_id}).subscribe((res) => {
      console.log("hiiiiiiiiiiiiii1233");
      this.searchLedger(res.ledger.ledger_name);
      console.log(res.ledger.ledger_name);
      this.service_type = {
        src_id: res.src_id,
        src_type_name: res.src_type_name,
        src_type_amount: res.src_type_amount,
        src_type_ledger_id :res.src_type_ledger_id,
      };
    });
  }

  deleteServiceType(src_id,curpage){
    if(confirm("Do you wish to void this entry?")){
      this.apiService.deleteServiceType({src_id: src_id}).subscribe((res) => {
        this.coreService.showMessage(res.message);
      });
      this.getInfo(curpage); 
    }
  
  }

  validateAndSubmit() {
    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createServiceType';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateServiceType';
      }
      this.apiService[callFunction](this.service_type).subscribe((res) => {

        console.log("holllllllaaaaaaaa");
        console.log(res);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};
        }

      });
    }
}


}

