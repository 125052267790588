import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-mail-settings',
  templateUrl: './mail-settings.component.html',
  styleUrls: ['./mail-settings.component.css']
})
export class MailSettingsComponent implements OnInit {

  drivers = [
    { id: 1, name: 'SMTP' },
  ];
  encryption_types = [
    { id: 1, name: 'TLS' },
    { id: 2, name: 'SSH' },
  ];

  settings: any;
  pageLoadingImg: boolean;
  from_name: any;
  driver: any;
  port: any;
  encryption_type: any;
  mail: any;
  password: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.getMailSetting();
  }

  getMailSetting() {

    this.apiService.getMailSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.from_name = this.settings.ms_from_name;
        this.driver = this.settings.ms_driver;
        this.port = this.settings.ms_port;
        this.encryption_type = this.settings.ms_encryption_type;
        this.mail = this.settings.ms_mail;
        this.password = this.settings.ms_password;
      } else {
        this.driver = 1;
        this.encryption_type = 1;
      }
    });

  }
  update(formdata) {
    this.pageLoadingImg = true;
    this.apiService.updateMailSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }


}
