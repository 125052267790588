import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-purchase-dash',
  templateUrl: './purchase-dash.component.html',
  styleUrls: ['./purchase-dash.component.css']
})
export class PurchaseDashComponent implements OnInit {
  up: any;
  cmpny: any;
  settings:any;
 
  

  constructor(private coreService: CoreService,private apiService: ApiService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
 
  }

  

}
