import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { Group } from '../../../../model/group.model';
import { Ledger } from '../../../../model/ledger.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-price-group',
  templateUrl: './price-group.component.html',
  styleUrls: ['./price-group.component.css']
})
export class PriceGroupComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  price_group = {
    group_name: '',
    group_descp: '',
    prcgrp_id: 0
  };
 
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  accLoading = false;
  nextId = '';
  editFetchInprogress = false;
  up: any;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    
      this.up = this.coreService.getUserPrivilage();
    
    });
    // this.getNextReceiptId();
    this.getInfo(this.curpage);
    // this.cmpny = this.coreService.getUserData('cmpny');
    // this.userType = this.coreService.getUserData('user_type');
    // this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    // this.branch_name= this.coreService.getUserData('branch_name');
    // this.branch_display_code= this.coreService.getUserData('branch_code');
    // this.branch_address= this.coreService.getUserData('branch_address');
    // this.img_url= this.coreService.getUserData('img_url');
    // this.branch_phone= this.coreService.getUserData('branch_phone');
    // this.branch_mob= this.coreService.getUserData('branch_mob');
    // this.branch_tin= this.coreService.getUserData('branch_tin');
    // this.branch_reg_no= this.coreService.getUserData('branch_reg_no');
    // this.base_url = location.origin;
    // this.listGodowns();


  }

  // listGodowns() {
  //   let searchval = new FormData();
  //   this.apiService.getGodownList(searchval).subscribe((res) => {
  //     this.godowns = res['data'];

  //   });
  // }

  // getTemplete() {

  //   this.apiService.getSettings().subscribe((res) => {

  //     this.savedTemplate = {
  //       title_text: res.data.prnt_title_text,
  //       color: '#000',
  //       email_color: '#000',
  //       font_size: '12',
  //       orientation: "P",
  //       paper: 'A4-P',
  //       m_left: '',
  //       m_top: '',
  //       m_right: '',
  //       m_bottom: '',
  //       logo: res.data.prnt_header_logo,
  //       header_type: res.data.prnt_header_type,
  //       header_img: [],
  //       header_all_page: false,
  //       header_due: false,
  //       sl_no: false,
  //       item_desc: false,
  //       tax_detail: false,
  //       tax_type: 0,
  //       name_type: 'N',
  //       footer_text: res.data.prnt_display_footer_text
  //     }
  //     if (res.data.prnt_header_logo) {
  //       this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
  //     } else {
  //       this.logoImgUrl = '';
  //     }

  //     if (res.data.prnt_header_type == 2) {
  //       this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
  //     } else {
  //       this.tempImgUrl = '';
  //     }

  //   });
  // }

    searchInp(e) {
      let searchval = new FormData();      
      searchval.append("search", e);  
      this.pageLoadingImg = true;
      this.apiService.filterReceipt(searchval,1, this.perpage).subscribe((res) => {
        this.info = res.data.data;
        this.pageLoadingImg = false;
        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.totalItem = res.data.total;
      });
    }


  // getNextReceiptId() {
  //   this.apiService.getNextReceiptId().subscribe((res) => {
  //     this.nextId = res.data.ref_no;
  //   });
  // }

  // getAccLedgerBalance() {
  //   this.accLoading = true;
  //   this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_acc.ledger_id }).subscribe((res) => {
  //     this.accLoading = false;
  //     this.rec.rec_balance = res.data.bal_without_sign;
  //     this.rec.rec_bal_sign = res.data.bal_sign;
  //   });
  // }

  // getLedgerBalance(i) {
  //   this.rec.ledgers[i].accLoading = true;
  //   this.apiService.getLedgerBalance({ ledger_id: this.rec.ledgers[i].ledger.ledger_id }).subscribe((res) => {
  //     this.rec.ledgers[i].accLoading = false;
  //     this.rec.ledgers[i].rec_balance = res.data.bal_without_sign;
  //     this.rec.ledgers[i].rec_bal_sign = res.data.bal_sign;
  //   });
  // }

  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getPriceGroups(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  // getAllAccount() {
  //   this.apiService.getAllAccount().subscribe((res) => {
  //     this.account = res.data;
  //   });
  // }
  // getAllNonAccountLedgers() {
  //   this.apiService.getAllNonAccountLedgers().subscribe((res) => {
  //     this.nonAccount = res.data;
  //   });
  // }
  // addMore() {
  //   this.rec.ledgers.push({
  //     sub_id: '',
  //     ledger: { ledger_id : 0 },
  //     amount: 0.00,
  //     narration: '',
  //     vatIncExc: 0,
  //     vat_per: 0,
  //     vat_amount: 0,
  //     actlAmount: 0,
  //     fnlAmount: 0,
  //     rec_balance: 0,
  //     rec_bal_sign: '',
  //     accLoading: false
  //   });
  // }

  // checkTabClicked(keycode) {
  //   // console.log(keycode);
  //   if (keycode.keyCode === 9) {
  //     this.addMore();
  //   }
  // }
  // remove(index) {
  //   this.price_group.ledgers.splice(index, 1);
  //   this.updateTotoal();
  // }
  createRec(){
    this.curForm = this.langText.create;
    this.nextId = '';
    // this.getNextReceiptId();
    this.clearForm();
  }
  clearForm() {
    
    this.
    price_group = {
      group_name: '',
      group_descp: '',
      prcgrp_id: 0
    };
  }


  // voidReceipt(id){
  //   // const confrm = confirm('Do you wish to continue?');
  //   if (confirm('Do you wish to continue?')) {
  //     this.apiService.voidReceipt({rec_no: id}).subscribe((res) => {
  //       // console.log(this.staff);
  //       this.isSubmitInProg = false;
  //       if (res.error != null) {
  //         this.resultobj = res.error;
  //       } else {
  //         this.getInfo(this.curpage);
  //         this.coreService.showMessage(res.message);
  //         $('.close').click();
  //       }

  //     });
  //   }
  // }

  editPriceGroup(id){
    this.editFetchInprogress = true;
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getPriceGroupById({prcgrp_id: id}).subscribe((res) => {
      this.price_group.group_name = res.data.group_name;
      this.price_group.group_descp = res.data.group_descp;
      this.price_group.prcgrp_id = res.data.prcgrp_id;
      this.editFetchInprogress = false;
    });
  }

  // viewReceipt(id){
  //   this.recPriviewLoader = true;
    
  //     this.apiService.getReceipt({rec_no: id}).subscribe((res) => {
  //   this.recPriviewLoader = false;
       
  //     this.printPreview = res;
      
  //     // console.log(this.printPreview.rec_ttl_amount);

  //     });

  // }

  
  validateAndSubmit() {

    this.resultobj = [];

    if (this.resultobj.length <= 0) {

    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createPriceGroup';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updatePriceGroup';
      }
      this.apiService[callFunction](this.price_group).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          // this.getNextReceiptId();
          this.resultobj = {};
        }

      });
    }
  }
}


}