import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import {TranslateService} from '@ngx-translate/core';
import { numberSymbols } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-sales-form',
  templateUrl: './sales-form.component.html',
  styleUrls: ['./sales-form.component.css']
})
export class SalesFormComponent implements OnInit {

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  rec = {
    rec_no: '',
    rec_date: new Date(), 
    rec_acc: 0,
    rec_acc_balance: 0,
    rec_acc_bal_sign: '',
    rec_cust_ledger: 0,
    rec_cust_balance: 0,
    rec_cust_bal_sign: '',
    note: '',
    invs: [],
    ttl_amount: 0,
    ttl_input_amount: 0,
    ttl_due: 0,
    payType: 1
  };
  dueInv = [];
  account = [];
  nonAccount = [];
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;
  ttlAmount = 0;
  grandTtlAmount = 0;
  ttlVatAmount = 0;
  langText = {
    create: '',
    update: ''
  };

  ledgerLoading = false;
  accLoading = false;
  invLoading = false;
  pendingInvs = JSON.stringify([]);
  rowLoadingImg: any;
  nextId = '';
  up: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    });
    this.getNextReceiptId();
    this.getAllAccount();
    this.getCustomerLedgres();
    this.getInfo(this.curpage);
    this.up = this.coreService.getUserPrivilage();

    this.country_dec=this.coreService.setDecimalLength();
  }

  getNextReceiptId() {
    this.apiService.getNextReceiptId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }
  getLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_acc }).subscribe((res) => {
      this.accLoading = false;
      this.rec.rec_acc_balance = res.data.bal_without_sign;
      this.rec.rec_acc_bal_sign = res.data.bal_sign;
    });
    this.ShowInvDetails();
  }
  getBalance() {
    this.ledgerLoading = true;
    this.apiService.getCustomerBalance({ cust_ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
      this.ledgerLoading = false;
      this.rec.rec_cust_balance = res.data.balance;
      this.rec.rec_cust_bal_sign = res.data.bal_sign;
      this.ShowInvDetails();
    });

    // this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
    //   this.ledgerLoading = false;
    //   this.rec.rec_cust_balance = res.data.bal_without_sign;
    //   this.rec.rec_cust_bal_sign = res.data.bal_sign;
    // });
  }

  addInvoice(indx) {
    // this.dueInv[indx].rec_amt = this.dueInv[indx].salesduesub_inv_balance;
    this.rec.invs.push(this.dueInv[indx]);
    this.dueInv.splice(indx, 1);
    this.updateTotal();
  }

  // get sortData() {
  //   return this.dueInv.sort((a, b) => {
  //     return <any>new Date(a.salesduesub_date) - <any>new Date(b.salesduesub_date);
  //   });
  // }

  removeInvoice(indx) {
    this.dueInv.push(this.rec.invs[indx]);
    this.rec.invs.splice(indx, 1);
    this.updateTotal();
  }

  clearData(){
    this.dueInv = JSON.parse(this.pendingInvs);
    this.rec.invs = [];
    this.updateTotal();
  }
  updatePrice() {
    this.dueInv = JSON.parse(this.pendingInvs);
    this.rec.invs = [];
    if (Number(this.rec.ttl_input_amount) !== 0) {
      let balAmt = this.rec.ttl_input_amount;
      const addInx = [];
      this.dueInv.forEach((element, indx) => {
        if (balAmt > 0 ) {
          const remBal = Number(balAmt) - Number(element.salesduesub_inv_balance);
          if (remBal < 0) {
            this.dueInv[indx].rec_amt = Number(balAmt);
          }
          addInx.push(indx);
          balAmt = Number(remBal);
        }
      });
      addInx.forEach((indx, i) => {
        this.rec.invs.push(this.dueInv[indx - i]);
        this.dueInv.splice(indx - i, 1);
      });
      this.updateTotal();
    }
  }


  searchDuelList(e) {
    let searchval = new FormData();
    searchval.append("search", e);
    this.pageLoadingImg = true;
    this.apiService.searchDuelList(searchval, 1, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  updateTotal() {
    this.rec.ttl_amount = 0;
    this.rec.invs.forEach((element, indx) => {
      this.rec.ttl_amount += Number(element.rec_amt);
    });
    this.rec.ttl_due = this.rec.rec_cust_balance - this.rec.ttl_amount;
  }

  ShowInvDetails() {
    this.rec.invs = [];
    this.pendingInvs = JSON.stringify([]);
    if (this.rec.rec_cust_ledger !== 0 ) {
      this.invLoading = true;
      this.apiService.getCustomerInvoice({ cust_ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
        this.invLoading = false;
        this.pendingInvs = JSON.stringify(res.data);
        this.dueInv = res.data;
        this.updateTotal();
        // this.rec.invs = res.data;
      });
    }
  }


  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllDueReceipt(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getCustomerLedgres() {
    this.apiService.getCustomerLedgres('').subscribe((res) => {
      this.nonAccount = res.data;
    });
  }

  clearForm() {
    this.rec = {
      rec_no: '',
      rec_date: new Date(),
      rec_acc: 0,
      rec_acc_balance: 0,
      rec_acc_bal_sign: '',
      rec_cust_ledger: 0,
      rec_cust_balance: 0,
      rec_cust_bal_sign: '',
      note: '',
      invs: [],
      ttl_amount: 0,
      ttl_input_amount: 0,
      ttl_due: 0,
      payType: 1
    };
    this.dueInv = [];
    this.rec.invs = [];
  }



  validateAndSubmit() {
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createDueReceipt';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateDueReceipt';
      }
      if (this.rec.ttl_input_amount > this.rec.rec_cust_balance ) {
        this.resultobj = {ttl_input_amount: 'Invalid Amount'};
        this.coreService.showMessage('Invalid Input Amount');
        this.isSubmitInProg = false;
      } else {
        this.apiService[callFunction](this.rec).subscribe((res) => {
          // console.log(this.staff);
          this.isSubmitInProg = false;
          if (res.error != null) {
            this.resultobj = res.error;
          } else {
            this.closeModal.nativeElement.click();
            this.getInfo(this.curpage);
            this.coreService.showMessage(res.message);
            this.clearForm();
            this.resultobj = {};
            this.getNextReceiptId();
            this.getBalance();
          }

        });
      }
    }
  }

  voidDueReceipt(id){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.apiService.voidDueReceipt({rec_no: id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          this.getBalance();
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};
        }

      });
    }
  }


  public dayBetweenDates(sdate, edate = new Date()) {
    // Get 1 day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Convert both dates to milliseconds
    const sdateMs = new Date(sdate).getTime();
    const edateMs = new Date(edate).getTime();
    // Calculate the difference in milliseconds
    const differenceMs = edateMs - sdateMs;
    // Convert back to days and return
    return Math.round(differenceMs / oneDay);
  }

  public checkDueExp(dueDate) {
    const duedateMs = new Date(dueDate).getTime();
    const crrdateMs = new Date().getTime();
    const differenceMs = duedateMs - crrdateMs;
    if (differenceMs < 0) {
      return true;
    } else {
      return false;
    }
  }
}
