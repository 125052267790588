import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-company-holidays',
  templateUrl: './company-holidays.component.html',
  styleUrls: ['./company-holidays.component.css']
})
export class CompanyHolidaysComponent implements OnInit {
  show: boolean = false;
  pageLoadingImg: boolean;
  dataLoadingImg: boolean;
  rempageLoadingImg: boolean;
  date_err: any = '';
  date: any = '';
  result = '';
  holidays: any;
  leave_date: any;
  leave_note: any;
  err: any = [];
  constructor(private apiService: ApiService, private coreService: CoreService) { }
  ngOnInit() {

  }
  getHolidays() {
    $("#date_err").text('');
    if (!this.date) {
      $("#date_err").text('Select a month');
      return false;
    } else {
      this.dataLoadingImg = true;
      let searchval = new FormData();
      this.date = (this.date) ? this.apiService.formatDate(this.date) : this.date;
      searchval.append("date", this.date);
      this.apiService.getCompanyLeaveList(searchval).subscribe((res) => {
        this.show = true;
        this.holidays = res['data'];
        this.dataLoadingImg = false;
      });
    }
  }
  saveHoliday(formgroup) {

    $("#leave_date_err").text('');
    $("#leave_note_err").text('');
    if (!this.leave_date) {
      $("#leave_date_err").text('Select date');
      return false;
    }
    if (!this.leave_note) {
      $("#leave_note_err").text('Enter title');
      return false;
    }
    this.pageLoadingImg = true;
    let searchval = new FormData(formgroup);
    this.leave_date = (this.leave_date) ? this.apiService.formatDate(this.leave_date) : this.leave_date;

    searchval.append("leave_date", this.leave_date);
    this.apiService.saveHoliday(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.error != null) {
        this.result = res.error;
      }
      else {
        this.leave_date = '';
        this.leave_note = '';
        $('#addLeave').hide();
        $('.modal-backdrop').remove();
        this.getHolidays();
        this.coreService.showMessage(res.message);
        this.result = '';
      }
    });
  }
  deleteLeave(cl_id) {
    this.rempageLoadingImg = true;
    if (confirm('Do you wish to continue?')) {
      let searchval = new FormData();
      searchval.append("cl_id", cl_id);
      this.apiService.deleteLeave(searchval).subscribe((res) => {
        this.rempageLoadingImg = false;
        if (res.status) {
          this.coreService.showMessage('Successfully Removed');
          this.getHolidays();
        } else {
          this.coreService.showMessage('Something went wrong');
        }
      });
    } else {
      this.rempageLoadingImg = false;
    }
  }
}
