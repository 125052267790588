import { Component, OnInit, ViewChild } from '@angular/core';
import { Unit } from '../../../model/unit.model';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { Router } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {
  
  @ViewChild('myTabGroup', { static: false }) tabGroup: MatTabGroup;

  id: any;
  baseunit: any;
  unit_name: any;
  unit_base_qty: any;
  unit_code: any;
  unit_display: any;
  unit_remarks: any;
  unitsel: any;

  result = '';
  bunitforder : string[]; 
  baseunits: string[];
  baseunitdata : string[];
  unit_all : string[]; 
  pageLoadingImg : boolean;


 
  selectedData: any = { unit_id: null, error: null, unit_name: null, 
   unit_base_id: null,message:null,unit_type:null,unit_base_qty:null,derived_units_count:null
   ,sur_unit_id:null};
  up: any;
  units: any;
  unt_typ = {
    '0': 'Derived Unit',
    '1': 'Base Unit'
  }
  total_unit: any;
  editing: boolean;
  constructor(private apiService: ApiService,private coreService: CoreService,public router: Router,private translate: TranslateService) { }
  ngOnInit() { 
    this.editing=false;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.coreService.getToken();
    this.up = this.coreService.getUserPrivilage();
    this.getAllUnit();
  }

  createUnit(form: { value: Unit; }) {  
    this.pageLoadingImg = true;

      if (form.value.unit_type == 1) {
        form.value.unit_base_id = 0;
        form.value.unit_base_qty = 1;
      }
        this.apiService.createUnit(form.value).subscribe((unit: Unit) => {
          this.pageLoadingImg = false;

        if (unit.error != null) {        
          this.result = unit.error;        
        }
        else {
          this.result='';
          this.coreService.createfunct(form,this.result,unit.message);                
        }

      });
    
  }

  updateUnit(form: { value: Unit; }) { 
    this.pageLoadingImg = true;

    if (form.value.unit_type == 1) {
      form.value.unit_base_id = 0;
      form.value.unit_base_qty = 1;
    }
    else{
      form.value.unit_base_id = (form.value.unit_base_id ==0 )? null : form.value.unit_base_id;
    }
    this.apiService.updateUnit(form.value).subscribe((unit: Unit) => {
      this.pageLoadingImg = false;

      if (unit.error != null) {     
      this.result = unit.error;     
      }
      else {          
        this.editing=false;
        // $("#editunitf_new").hide(); 
       this.result=''; 
        this.coreService.updatefunct(form,this.result,unit.message);         
       this.unit_all = [];  
          }
    });
this.getAllUnit();

  }


  selectType(id:number) {
    if (id == 0) {
      $(".buclass").show();
      this.apiService.readBase(id).subscribe((units: Unit[]) => {      
        this.bunitforder = units['data'];
      });
      $("#uqty").prop("disabled",false); 
      
    } else {   
      $(".buclass").hide();
      $("#uqty").prop("disabled", true);   
    }
  }

  // unit types
  unit_types = [
    { id: 1, name: 'Base Unit' },
    { id: 0, name: 'Derived Unit' }
  ];

 
  // Update change unit type
  selectType_Udt() {   
    if (this.selectedData.unit_type == 0) {
      this.readBase(this.selectedData.unit_id);
      $("#editbaseunits").show();
      $("#editbuqty").prop("disabled",false);
    } else {
      $("#editbaseunits").hide();     
      $("#editbuqty").prop("disabled", true); 
    }
  }

  //form search unit
searchUnit(search: string)
{
 let searchval = new FormData();      
      searchval.append("unit_name", search);    
       this.apiService.getUnitByName(searchval).subscribe((res) => {
        this.unit_all = res['data'];   
        console.log(this.unit_all);             
       });
       $('.prev-list').show(); 
}

searchForm(search: string)
{
 let searchval = new FormData();      
      searchval.append("unit_name", search);    
       this.apiService.getUnitByName(searchval).subscribe((res) => {
        this.unit_all = res['data'];   
                 
       });
       $('.prev-list').hide(); 
}

//Remove Name Suggestions

removeName()
{
  this.unit_all = [];
  $('.prev-list').hide(); 
}

//form search select
selectUnit(res:Unit)
{
  if(res){  
  this.selectedData = res; 
  this.selectedData.unit_type = Number(this.selectedData.unit_type);
 this.selectedData.unit_base_id = Number(this.selectedData.unit_base_id);
  if(this.selectedData.unit_type==1 && this.selectedData.derived_units_count!=0)
  {
   $('#baseunitwthder').show();
  $('#editunitselect').hide();
  }else{
   $('#baseunitwthder').hide();
  $('#editunitselect').show(); 
  }
  this.editing=true;
  // $('#editunitf_new').show();    
   res.unit_base_id = this.selectedData.unit_base_id;
  this.readBase(0);

   if(this.selectedData.unit_base_id==0)
   {
    $('#editbaseunits').hide();
   $("#editbuqty").prop("disabled",true);
  }
   else
  {
    $('#editbaseunits').show();
   $("#editbuqty").prop("disabled",false);
  }

  }
  this.unit_all = [];
  
}

  tabClick(tab) {    
    this.result = ''; 
  }

   readBase(id: number)
  { 
    this.apiService.readBase(id).subscribe((base: Unit[]) => {
      this.baseunits = base['data'];      
    });
  } 

  langChange(){
    this.translate.get(['Repproduct.base_unit', 'Repproduct.dervd_unit',]).subscribe((res: string) => {    
     

      this.unit_types = [
        { id: 1, name: res['Repproduct.base_unit']},
        { id: 0, name: res['Repproduct.dervd_unit'] },
  
      ];
    });

  }
  getAllUnit(){

    this.apiService.readUnit().subscribe((res) => {
      this.units = res['data'];   
      this.total_unit = res['total_unit'];   
               
     });
  }

  searchInp(keyword) {
    let searchval = new FormData();
    if (keyword)
      searchval.append("keyword", keyword);
    this.apiService.searchunits(searchval).subscribe((res) => {
      this.units = res['data'];   
      this.total_unit = res['total_unit'];
    });
  }
  editUnit(unitid){
    this.editing=true;
    this.result='';
    this.tabGroup.selectedIndex = 1;
    this.unitsel=unitid; 
    this.selectUnit(unitid); 
    
    // $('#editunitf_new').show();
  }
}
