import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { CoreService } from '../../service/core.service';

@Component({
  selector: 'app-productdash',
  templateUrl: './productdash.component.html',
  styleUrls: ['./productdash.component.css']
})
export class ProductdashComponent implements OnInit {
  up: any;
  userType: any;
  default_item_creation: any;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.getPrdSetting();
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');

    this.coreService.getToken(); 
  }
  getPrdSetting() {
    this.apiService.getPrdSetting().subscribe((res) => {
      if (res['data']) {
        this.default_item_creation = res['data'].default_item_creation;
      } else { 
        this.default_item_creation = 1;
      }
    });
  }
}
