import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';


@Component({
  selector: 'app-ledger-list',
  templateUrl: './ledger-list.component.html',
  styleUrls: ['./ledger-list.component.css']
})
export class LedgerListComponent implements OnInit {
  pageLoadingImg: boolean;
  ledgerList: any;
  curpage: any;
  lastpage: any;
  slnum: number;
  pgend: boolean;
  pgstart: boolean;
  accgrp_all: any;
  up: any;
  branch_all: any;
  userType: any;
  exportLoader: boolean;
  fileName = 'ledger_list.xlsx';
  exp_ledgerList: any;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;

  constructor(private apiService: ApiService,private coreService: CoreService, private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();
    let searchval = new FormData();
    this.apiService.ledgerList(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
    this.ledgerList = res.data.data; 
    this.curpage = res.data.current_page  
    this.lastpage= res.data.last_page; 
    // this.totalItem = res.data.total;
    // this.totalItem = res.data.total;


    this.pgEnd(this.curpage,this.lastpage); 
    this.pgStart(this.curpage);
    this.slnum=0;

  });  


  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      // console.log(  this.branch_all );
    
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
     
    });
  }

  ngOnDestroy(){
    $('.close').click();
 
  }


  pgEnd(curr,end)
  {
  if(curr==end)
  this.pgend = true;
  else
  this.pgend = false;
  
  }
  
  pgStart(curr)
  {
  if(curr==1)
  this.pgstart = true;
  else
  this.pgstart = false;
  }


  pageNext(formdata: { value: any; }, pageno:any)
  {
  // console.log(pageno);
    this.pageLoadingImg = true;
  
  let searchval = new FormData();      
  searchval.append("pageno",pageno);
  
  this.apiService.ledgerList(formdata.value,pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
    this.ledgerList = res.data.data; 
    this.curpage = res.data.current_page  
    this.lastpage= res.data.last_page; 
    // this.totalItem = res.data.total;
    // this.totalItem = res.data.total;


    this.pgEnd(this.curpage,this.lastpage); 
    this.pgStart(this.curpage);
    this.slnum=0;

  });  
  }
  
  export(formdata: { value: any; }) {

    // debugger;
    formdata.value.export = 'export';
    this.exportLoader = true;

    this.apiService.ledgerList(formdata.value,0).subscribe((res: any) => {
      this.exportLoader = false;
    this.exp_ledgerList = res.data.data; 

    setTimeout(() => {
      this.exportexcel();
  }, 3000);
  }); 

 



  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }
  

   //product form search 
   searchAccGrp(search: string) {
    let searchval = new FormData();
    searchval.append("accgrp_name", search);
    this.apiService.getGroupbyName(searchval).subscribe((res) => {
      this.accgrp_all = res['data'];

    });
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

        // debugger;
        formdata.value.export = 'export';
        this.exportLoader = true;
    
        this.apiService.ledgerList(formdata.value,0).subscribe((res: any) => {
          this.exportLoader = false;
          this.exp_ledgerList = res.data.data; 
    
        const heads = [
          this.translate.instant('ACCOUNTS.Ledger_List')
        ];

          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
    
          this.exp_ledgerList.forEach(item=>{
           const arr=[];

           if(this.translate.currentLang == 'Arabic'){
            arr.splice(0,0, item.accgrp_name_ar)
           } else if (this.translate.currentLang == 'English'){
            arr.splice(0,0, item.accgrp_name)
           }

           data.push(arr)

           if(item.ledgers.length != 0){
            item.ledgers.forEach(item1=>{
              const arr=[];
  
              if(this.translate.currentLang == 'Arabic'){
                arr.splice(0,0,'      ' + item1.ledger_alias)
               } else if (this.translate.currentLang == 'English'){
                arr.splice(0,0,'      ' + item1.ledger_name)
               }
              
              data.push(arr)
             })
           }

           if(item.sub_groups.length != 0){
            item.sub_groups.forEach(item2=>{
              const arr=[];

              if(this.translate.currentLang == 'Arabic'){
                arr.splice(0,0,'          ' + item2.accgrp_name_ar)
               } else if (this.translate.currentLang == 'English'){
                arr.splice(0,0,'          ' + item2.accgrp_name)
               }
              
              data.push(arr)

              item2.ledgers.forEach(item3=>{
                const arr=[];

                if(this.translate.currentLang == 'Arabic'){
                  arr.splice(0,0,'               ' + item3.ledger_alias)
                 } else if (this.translate.currentLang == 'English'){
                  arr.splice(0,0,'               ' + item3.ledger_name)
                 }
                
                data.push(arr)
              })
            })
           }

          })

      
          let doc = this.excelService.getPdfObj();
    
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
    
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
    
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('ACCOUNTS.Ledger_List')}`,  valueXPos, headerHeight + 10);
    
          doc = this.excelService.addTableToPdf(doc, headerHeight + 15, lblXPos, heads, data, null);
      
         
          doc.save(`Ledger List.pdf`);

          this.exportLoader = false;

      }); 

    
  }

}
