import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ComfunctModule } from "../.././common/comfunct/comfunct.module";
import { TransactionRoutingModule } from "./transaction-routing.module";
import { TransactionDashComponent } from "./transaction-dash/transaction-dash.component";

import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
// share
import { ErpMaterialModule } from "../../material-module";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";

// translation

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxPrintModule} from 'ngx-print';
import { ReceiptComponent } from './transaction-dash/vouchers/receipt/receipt.component';
import { PaymentComponent } from './transaction-dash/vouchers/payment/payment.component';
import { JournalComponent } from './transaction-dash/vouchers/journal/journal.component';
import { ContraComponent } from './transaction-dash/vouchers/contra/contra.component';
import { VouchersComponent } from './transaction-dash/vouchers/vouchers.component';
import { BranchTransferReceiptComponent } from './transaction-dash/branch-transfer-receipt/branch-transfer-receipt.component';
import { InterBranchStockTransferComponent } from './transaction-dash/branch-transfer-receipt/inter-branch-stock-transfer/inter-branch-stock-transfer.component';
import { BranchStockReceiptComponent } from './transaction-dash/branch-transfer-receipt/branch-stock-receipt/branch-stock-receipt.component';
import { SalesComponent } from './transaction-dash/sales/sales.component';
import { SalesRetWithoutInvComponent } from './transaction-dash/sales/sales-ret-without-inv/sales-ret-without-inv.component';
import { InvoiceDueReceiptComponent } from './transaction-dash/sales/invoice-due-receipt/invoice-due-receipt.component';
import { SalesVoidComponent } from './transaction-dash/sales/sales-void/sales-void.component';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { SalesReturnVoidComponent } from './transaction-dash/sales/sales-return-void/sales-return-void.component';
import { SalesReturnComponent } from './transaction-dash/sales/sales-return/sales-return.component';
import { InterBranchReceiptComponent } from './transaction-dash/vouchers/inter-branch-receipt/inter-branch-receipt.component';
import { InterBranchPaymentComponent } from './transaction-dash/vouchers/inter-branch-payment/inter-branch-payment.component';
import { ReceiptDashComponent } from './transaction-dash/vouchers/receipt-dash/receipt-dash.component';
import { PaymentDashComponent } from './transaction-dash/vouchers/payment-dash/payment-dash.component';
import { SalesFormComponent } from './transaction-dash/sales/sales-form/sales-form.component';
import { SalesQuoatationComponent } from './transaction-dash/sales/sales-quoatation/sales-quoatation.component';
import {MatStepperModule} from '@angular/material/stepper';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule} from '@angular/common/http';
import { SalesInvoiceComponent } from './transaction-dash/sales/sales-invoice/sales-invoice.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DeliveryChallanComponent } from './transaction-dash/challan/delivery-challan/delivery-challan.component';
//QR
import { QRCodeModule } from 'angularx-qrcode';
import { QuickSaleComponent } from './transaction-dash/sales/quick-sale/quick-sale.component';
import { ExpenseTaxPaymentComponent } from './transaction-dash/vouchers/expense-tax-payment/expense-tax-payment.component';
import { ExtStockTransferComponent } from './transaction-dash/branch-transfer-receipt/ext-stock-transfer/ext-stock-transfer.component';
import { ExtStockReceiptComponent } from './transaction-dash/branch-transfer-receipt/ext-stock-receipt/ext-stock-receipt.component';
import { GeneralPrintComponent } from './transaction-dash/sales/print-templates/general-print/general-print.component';
import { ThermalPrintComponent } from './transaction-dash/sales/print-templates/thermal-print/thermal-print.component';
import { WithoutHeaderFooterPrintComponent } from './transaction-dash/sales/print-templates/without-header-footer-print/without-header-footer-print.component';
import { ClientCcdPrintComponent } from './transaction-dash/sales/print-templates/client-ccd-print/client-ccd-print.component';
import { WithHeaderFooterPrintComponent } from './transaction-dash/sales/print-templates/with-header-footer-print/with-header-footer-print.component';
import { ChequeComponent } from './transaction-dash/vouchers/cheque/cheque.component';
import { PostDatedChequeComponent } from './transaction-dash/vouchers/post-dated-cheque/post-dated-cheque.component';
import { SalesOrderComponent } from './transaction-dash/sales/sales-order/sales-order.component';
import { SalesOrderGeneralPrintComponent } from './transaction-dash/sales/print-templates/sales-order-general-print/sales-order-general-print.component';
import { InvoiceThreePrintComponent } from './transaction-dash/sales/print-templates/invoice-three-print/invoice-three-print.component';
import { QtnGeneralPrintComponent } from './transaction-dash/sales/print-templates/quotations/qtn-general-print/qtn-general-print.component';
import { QtnTemplateOnePrintComponent } from './transaction-dash/sales/print-templates/quotations/qtn-template-one-print/qtn-template-one-print.component';
import { FalconPrintComponent } from './transaction-dash/sales/print-templates/falcon-print/falcon-print.component';
import { CreditNoteComponent } from './transaction-dash/vouchers/credit-note/credit-note.component';
import { DebitNoteComponent } from './transaction-dash/debit-note/debit-note.component';
import { SalesPosComponent } from './transaction-dash/sales/sales-pos/sales-pos.component';
import { FixedHeaderFooterEveryPageComponent } from './transaction-dash/sales/print-templates/fixed-header-footer-every-page/fixed-header-footer-every-page.component';
import { FixedHeaderFooterAllPageComponent } from './transaction-dash/sales/print-templates/quotations/fixed-header-footer-all-page/fixed-header-footer-all-page.component';
import { FixedHeaderFooterComponent } from './transaction-dash/sales/print-templates/delivery_challan/fixed-header-footer/fixed-header-footer.component';
import { InvoiceDuePaymentComponent } from './transaction-dash/sales/invoice-due-payment/invoice-due-payment.component';
import { SalesRetGeneralComponent } from './transaction-dash/sales/print-templates/sales-return/sales-ret-general/sales-ret-general.component';
import { SalesRetThermalComponent } from './transaction-dash/sales/print-templates/sales-return/sales-ret-thermal/sales-ret-thermal.component';
import { TemplateFourComponent } from './transaction-dash/sales/print-templates/template-four/template-four.component';
import { QtnHeaderFooterPrintComponent } from './transaction-dash/sales/print-templates/quotations/qtn-header-footer-print/qtn-header-footer-print.component';
import { SalesOrderListComponent } from './transaction-dash/sales/sales-order-list/sales-order-list.component';
import { DotMatrixPlaneComponent } from './transaction-dash/sales/print-templates/dot-matrix-plane/dot-matrix-plane.component';
import { CommonTemplateComponent } from './transaction-dash/sales/print-templates/common-template/common-template.component';
import { SalesOrderWithHeaderFooterComponent } from './transaction-dash/sales/print-templates/sales-order-with-header-footer/sales-order-with-header-footer.component';
import { SalesOrderWithFixedHeaderFooterComponent } from './transaction-dash/sales/print-templates/sales-order-with-fixed-header-footer/sales-order-with-fixed-header-footer.component';
import { InvoiceWithoutHeaderFooterTemp1Component } from './transaction-dash/sales/print-templates/invoice-without-header-footer-temp1/invoice-without-header-footer-temp1.component';
import { InvoiceWithHeaderFooterTemp2Component } from './transaction-dash/sales/print-templates/invoice-with-header-footer-temp2/invoice-with-header-footer-temp2.component';
import { QtnTemplateTwoPrintComponent } from './transaction-dash/sales/print-templates/quotations/qtn-template-two-print/qtn-template-two-print.component';
import { InvoiceWithHeaderFooterTemp3Component } from './transaction-dash/sales/print-templates/invoice-with-header-footer-temp3/invoice-with-header-footer-temp3.component';
import { FixedHeaderFooterTemp2Component } from './transaction-dash/sales/print-templates/delivery_challan/fixed-header-footer-temp2/fixed-header-footer-temp2.component';
import { QtnFixedHeaderFooterTemp3Component } from './transaction-dash/sales/print-templates/quotations/qtn-fixed-header-footer-temp3/qtn-fixed-header-footer-temp3.component';
import { SalesOrderWithHeaderFooterTemp2Component } from './transaction-dash/sales/print-templates/sales-order-with-header-footer-temp2/sales-order-with-header-footer-temp2.component';
import { InvoiceWithoutHeaderFooterTemp3Component } from './transaction-dash/sales/print-templates/invoice-without-header-footer-temp3/invoice-without-header-footer-temp3.component';
import { SalesRetFixedHeaderFooterComponent } from './transaction-dash/sales/print-templates/sales-return/sales-ret-fixed-header-footer/sales-ret-fixed-header-footer.component';
import { RecieptTempComponent } from './transaction-dash/sales/print-templates/voucher-template/reciept-temp/reciept-temp.component';
import { DueRecTempComponent } from './transaction-dash/sales/print-templates/voucher-template/due-rec-temp/due-rec-temp.component';
import { InvoiceWithHeaderFooterTemp5Component } from './transaction-dash/sales/print-templates/invoice-with-header-footer-temp5/invoice-with-header-footer-temp5.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    TransactionDashComponent,
    ReceiptComponent,
    PaymentComponent,
    JournalComponent,
    ContraComponent,
    VouchersComponent,
    BranchTransferReceiptComponent,
    InterBranchStockTransferComponent,
    BranchStockReceiptComponent,
    SalesComponent,
    SalesRetWithoutInvComponent,
    InvoiceDueReceiptComponent,
    SalesVoidComponent,
    SalesReturnVoidComponent,
    SalesReturnComponent,
    InterBranchReceiptComponent,
    InterBranchPaymentComponent,
    ReceiptDashComponent,
    PaymentDashComponent,
    SalesFormComponent,
    SalesQuoatationComponent,
    SalesInvoiceComponent,
    DeliveryChallanComponent,
    QuickSaleComponent,
    ExpenseTaxPaymentComponent,
    ExtStockTransferComponent,
    ExtStockReceiptComponent,
    GeneralPrintComponent,
    ThermalPrintComponent,
    WithoutHeaderFooterPrintComponent,
    ClientCcdPrintComponent,
    WithHeaderFooterPrintComponent,
    ChequeComponent,
    PostDatedChequeComponent,
    SalesOrderComponent,
    SalesOrderGeneralPrintComponent,
    InvoiceThreePrintComponent,
    QtnGeneralPrintComponent,
    QtnTemplateOnePrintComponent,
    FalconPrintComponent,
    CreditNoteComponent,
    DebitNoteComponent,
    SalesPosComponent,
    FixedHeaderFooterEveryPageComponent,
    FixedHeaderFooterAllPageComponent,
    FixedHeaderFooterComponent,
    InvoiceDuePaymentComponent,
    SalesRetGeneralComponent,
    SalesRetThermalComponent,
    TemplateFourComponent,
    QtnHeaderFooterPrintComponent,
    SalesOrderListComponent,
    DotMatrixPlaneComponent,
    CommonTemplateComponent,
    SalesOrderWithHeaderFooterComponent,
    SalesOrderWithFixedHeaderFooterComponent,
    InvoiceWithoutHeaderFooterTemp1Component,
    InvoiceWithHeaderFooterTemp2Component,
    QtnTemplateTwoPrintComponent,
    InvoiceWithHeaderFooterTemp3Component,
    FixedHeaderFooterTemp2Component,
    QtnFixedHeaderFooterTemp3Component,
    SalesOrderWithHeaderFooterTemp2Component,
    InvoiceWithoutHeaderFooterTemp3Component,
    SalesRetFixedHeaderFooterComponent,
    RecieptTempComponent,
    DueRecTempComponent,
    InvoiceWithHeaderFooterTemp5Component,
    
  ],
  imports: [
    CommonModule,
    TransactionRoutingModule,
    FormsModule,
    DateInputsModule,
    NgSelectModule,
    ComfunctModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    NgxPrintModule,
    MatStepperModule,
    MatTooltipModule,
    HttpClientModule,
    AngularEditorModule,
    DragDropModule,
    MatProgressBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    QRCodeModule
  ],
  exports:[
    CommonTemplateComponent,
    RecieptTempComponent,
    DueRecTempComponent,
  ]
})


export class TransactionModule {}
