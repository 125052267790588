import {  Component, OnInit, Input } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-sales-order-general-print',
  templateUrl: './sales-order-general-print.component.html',
  styleUrls: ['./sales-order-general-print.component.css']
})
export class SalesOrderGeneralPrintComponent implements OnInit {

  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()footImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()hide_rate_colmn;
  whatsapplink: string;
  country_dec: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    //  console.log('in child');
    //  console.log(this.saleQtnData);


  }



  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {
        console.log('in child 2');
       console.log(this.saleQtnData.sales_order_sub);





      // this.saleQtnData.sales_order_sub.forEach((element,i) => {





      // });

      this.whatsapplink = 'Dear Customer ,' + '%0A%0A'
         + 'Here is your Sales Order no' + '%20' + '%2A' + this.saleQtnData.sq_branch_qt_no + '%2A' + '%20' + 'amounting in' + '%20' + '%2A' + this.saleQtnData.tot_amount + '%20' + 'SR' + '%2A' + '.'

          + '%0A%0A'+'%2A' + '_Details :-_' + '%2A' + '%0A%0A' + ''
          +  'Name' + '%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' +this.saleQtnData.sq_cust_name + '%2A' +'%0A'
          + 'Order No' + '%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' + this.saleQtnData.sq_branch_qt_no + '%2A' + '%0A'
          +'Order Date'+ '%20%20%20%20%20%20%20%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' + this.saleQtnData.sq_date + '%2A' + '%0A'
           + 'Delivery Date' + '%20%20%20%20%20%20%20%20%20' + '%3A' + '%20' +'%2A' +  this.saleQtnData.sq_valid_till +'%2A' +  '%0A'
           + 'Address' + '%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20' + '%3A' + '%20' +'%2A' +  this.saleQtnData.sq_cust_address +'%2A' +  '%0A'
           + 'Delivery Address ' + '%20%20%20' + '%3A' + '%20' +'%2A' +  this.saleQtnData.sales_odr_dlvry_addrs +'%2A' +  '%0A'
           + 'Items ' + '%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20' + '%3A' + '%20';

           this.saleQtnData.sales_order_sub.forEach((element,i) => {

             this.whatsapplink += '%2A' + element['prd_name']+'%2A' +'%2C';


           });

           this.whatsapplink += '%0A'+
          '______________________________' + '%0A%0A'
          + 'Total Bill Amount' + '%3A'  + '%20' +'%2A' +  this.saleQtnData.tot_amount + '%2A' + '%2F-' + '%0A'
          + 'Advance Amount' + '%3A'  + '%20' +'%2A' +  this.saleQtnData.sales_odr_advance_amount + '%2A' + '%2F-' + '%0A'
          + 'Balance Amount' + '%3A'  + '%20' +'%2A' +  this.saleQtnData.sales_odr_balance + '%2A' + '%2F-' + '%0A';

    }
  }

}
