import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  employees: any;
  show: boolean = false;
  pageLoadingImg: boolean;
  dataLoadingImg: boolean;
  date_err: any = '';
  date: any = new Date();
  err: any = [];
  attendance: any = [];
  attendance_ot: any = [];
  result = '';
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    // this.listEmployees();
  }
  // listEmployees() {
  //   let searchval = new FormData();
  //   this.apiService.getEmployeesList(searchval, 1).subscribe((res) => {
  //     this.show = true;
  //     this.employees = res['data']['data'];
  //   });
  // }
  getAttendance() {
    $("#date_err").text('');
    if (!this.date) {
      $("#date_err").text('Select a date');
      return false;
    } else {
      this.dataLoadingImg = true;
      let searchval = new FormData();
      this.date = (this.date) ? this.apiService.formatDate(this.date) : this.date;
      searchval.append("date", this.date);
      this.apiService.getEmployeesAttendanceList(searchval).subscribe((res) => {
        this.show = true;
        this.employees = res['data'];
        this.attendance = [];
        this.employees.forEach((element, index) => {
          if(element.attendance){
            if (typeof element.attendance.emp_at_type !== 'undefined') {
              this.attendance[element.employee_id] = element.attendance.emp_at_type;
              if(element.attendance.emp_at_ot_hrs > 0){
                this.attendance_ot[element.employee_id] = true;
              }else{
                this.attendance_ot[element.employee_id] = false;
              }
            }
          }
        });
        this.dataLoadingImg = false;
      });
    }
  }
  saveAttendance(formgroup) {
    if (!this.employees.length) {
      return false;
    }
    this.err = [];
    this.employees.forEach((element, index) => {
      if (typeof this.attendance[element.employee_id] === 'undefined') {
        this.err[element.employee_id] = true;
      }
    });
    if (this.err.length) {
      this.coreService.showMessage('Please mark attendance for all employees');
      return false;
    }

    this.pageLoadingImg = true;
    let searchval = new FormData(formgroup);
    this.date = (this.date) ? this.apiService.formatDate(this.date) : this.date;
    const at_obj = {};
    this.attendance.forEach((element, index) => {
      at_obj[index] = element;
    });
    searchval.append("date", this.date);
    searchval.append("attendance", JSON.stringify(at_obj));
    this.apiService.saveEmployeeAttendance(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.result = res.error;
      }
      else {
        this.show = false;
        this.date = '';
        this.coreService.showMessage(res.message);
        this.result = '';
      }

    });
  }
  setDet(id) {
    this.err[id] = false;
  }

}
