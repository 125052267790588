import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import * as XLSX from 'xlsx';
import { CoreService } from '../../../../service/core.service';

@Component({
  selector: 'app-import-customer',
  templateUrl: './import-customer.component.html',
  styleUrls: ['./import-customer.component.css']
})
export class ImportCustomerComponent implements OnInit {
  fileName = 'Sample Import Doccument.xlsx';
  pageLoadingImg: boolean;
  log_file: any;
  tempFilePath: any;
  resultobj: any;
  uploadList = [];

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
  }



  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.log_file = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.tempFilePath = event.target.result;
    }

  }

  uploadLogFile(form) {
      var cnfrm = confirm("Are You Sure To Continue ?");
      if (cnfrm) {
        this.pageLoadingImg = true;

        let searchval = new FormData(form);
        
        // setTimeout(() => {
        //   this.loaduploadtext = true;
        // }, 5000);
        this.apiService.importCustomersByLog(searchval).subscribe((res) => {
          this.pageLoadingImg = false;
          // setTimeout(() => {
          //   this.loaduploadtext = false;
          // }, 5000);

          if (res.error) {
            this.resultobj = res.error;
          } else {
            this.resultobj = [];
            this.uploadList = res.data;

          }

        });


      }
  }

  downloadSheet() {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  saveLog(){

    
    this.apiService.storeCustomersByLog(this.uploadList).subscribe((res) => {
      if (res.message) {
        this.coreService.showMessage(res.message);
      }
      if (res.error) {
        this.coreService.showMessage(res.error,6000);
      } else {
        this.uploadList = [];
      }
    });

  }


}
