import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { CoreService } from '../../service/core.service';

@Component({
  selector: 'app-companymaster',
  templateUrl: './companymaster.component.html',
  styleUrls: ['./companymaster.component.css']
})
export class CompanymasterComponent implements OnInit {
  curpage: number;
  usr_type:String;
  userType:any;
  up: any;
  usr_username: any;
  constructor(private apiService: ApiService,
    private coreService: CoreService) { }

  ngOnInit() {

    this.up = this.coreService.getUserPrivilage();
   
    this.userType =  this.coreService.getUserData('user_type');

  this.usr_username =  this.coreService.getUserData('usr_username');

  }

}
