import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { Stockedit} from '../../../../model/stockedit.model';
import { Branch} from '../../../../model/branch.model';

@Component({
  selector: 'app-editstock',
  templateUrl: './editstock.component.html',
  styleUrls: ['./editstock.component.css']
})
export class EditstockComponent implements OnInit {
  @Input() name: string;
selecTed: any = { prd_id: null,prd_name: null,message:null,rec_unit_rates:null,
  upt_unit_rates:null,unit_rates:null,units_rates:null,error:null,gdstock:null,branch_id:null,prd_units:null,
  manufacture_date:null, expiry_date:null,batch_code:null,cmp_stock_id:null,purchase_rate:null};
stkprd_all : string[];
rec_unit_rates:any[];
branches: [];
branchids:number[];
data_unit_rates : any[]
counter:string;
// resultobj: any {};
resultobj: any = {};
error='';
stkprdsel:any;
pageLoadingImg:boolean;
up: any;
barcode:any;
serachLoading = false;
usr_hide_purch_cost:any;
constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.getBranches();
    this.up = this.coreService.getUserPrivilage();
  }

 //form search
searchStkPrd(search: string)
{
 let searchval = new FormData();      
      searchval.append("keyword", search);    
       this.apiService.getStockbyName(searchval).subscribe((res) => {
        this.stkprd_all = res['data']; 
      
             
       });
}

searchStkPrdBarcode(search: string) {
  
  if(!search){
    $("#br_err").text("Enter Barcode");
    return false;
  }else{
    this.serachLoading = true;
    $("#br_err").text("");
  }
  let searchval = new FormData();
  searchval.append("barcode", search);
  this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
    this.serachLoading = false;
    if (res.data) {
      this.selecTed = res.data; 
      $('#editstockform').show(); 
    } else{
      $("#br_err").text("Barcode not found");
      $('#editstockform').hide();
    }
    // console.log(res);
    // this.stkprd_all = [];
    // var units_notsel = [];
    // var sel_unit_rates = [];
    // this.branch_units = [];
    // this.sel_urates = [];
    // if (typeof res['data'].prd_id !== 'undefined') {
    //   this.getBranches(res['data'].prd_id);
    //   this.selecTed = res['data'];
    //   this.sel_urates = res['data'].units_rates;
    //   sel_unit_rates = this.selecTed.units_rates;  
             
    //   this.branchids = this.selecTed.branch_id;
    //   this.prd_tax = this.selecTed.prd_tax;
    //   $('#chk_all').prop('checked',false);
    //   $('#error_in').html('');
    //   $('#editstockform').show();
     

    // }else{
    //   $("#br_err").text("Barcode not found");
    //   $('#editstockform').hide();
    // }
  });
}

//form search select
selectStockPrd(res:Stockedit)
{
  this.stkprd_all=[];
  var units_notsel=[];
  var sel_unit_rates = [];
  if(res){
  this.selecTed = res;  
 sel_unit_rates = this.selecTed.units_rates;
  this.branchids = this.selecTed.branch_id;
  $('#editstockform').show();
      // compare
      var result1 =this.selecTed.prd_units;     
     // var result2 = this.selecTed.units_rates;  
       
     //remove unwanted data
     var id = 0;
    // var sel_unit_rates_distinct= $.grep(sel_unit_rates, function(e){     
    //  return e.sur_unit_rate != id; 
    //   });
      
       var props = ['sur_unit_id','unit_name']; 

     units_notsel = result1.filter(function(o1){
          // filter out (!) items in result2
          return !sel_unit_rates.some(function(o2){
              return o1.sur_unit_id === o2.sur_unit_id;          // assumes unique id
          });
      }).map(function(o){
          // use reduce to make objects with only the required properties
          // and map to apply this to the filtered array as a whole
          return props.reduce(function(newo, name){
              newo[name] = o[name];
              return newo;
          }, {});
      });
      
           // compare
      // merge     
      $.each(units_notsel, function (index, value) {
    
     sel_unit_rates.push({ "sur_unit_id": units_notsel[index].sur_unit_id,'sur_unit_rate':0,'unit_name':units_notsel[index].unit_name,'sur_id':0});   
      });
      //merge
      this.data_unit_rates = sel_unit_rates
   
      sel_unit_rates = []; 
    


  }
} 

updateStock(form: { value: Stockedit;}){
  this.pageLoadingImg = true;
  var upt_unit_rates = [];
  var units_rates=[];
  var errorobj = false;
//   $('input[name="unitratechk"]:checked').each(function() {
    
   
//    upt_unit_rates.push({ "unit_id": $(this).val()});  
//  });


//  $('input.edit_unit_rate').each(function() {
//   var attr_id =this.getAttribute("id");
//   var unit_rt = $(this).val();
// console.log(upt_unit_rates);
//  $.each(upt_unit_rates, function (index, value) {
  
//   if(value.unit_id==attr_id)
//   {
//     if (Number(unit_rt) <= 0)
//   {  
//     errorobj = true;
//   }
//  units_rates.push({ "unit_id": attr_id,'unit_rate':unit_rt});   
//   }
//  });
  
// });


 //form.value.stkprdsel=undefined;
 form.value.unit_rates = units_rates;
 if(errorobj)
 {
  $('#error_in').html('Please enter  value greater than zero');
 }
 else{
  $('#error_in').html('');  
 }
 console.log(errorobj);
if(!errorobj)
{
 this.apiService.updateStockPurchRate(form.value,).subscribe((res: Stockedit) => {   
   this.pageLoadingImg = false;
  if (res.error != null) {
   this.resultobj = res.error;     
    this.resultobj = this.coreService.getValidation(this.resultobj); 

  }
  else {
    this.resultobj={};
    this.coreService.createfunct(form,this.resultobj,'Updated');  
    this.stkprd_all=[]; 
   $('#error_in').html(''); 
    $('#editstockform').hide();
  this.stkprdsel ="Select Product";
     
  }     

});
}
}

getBranches()
{
  this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
    this.branches = branch['data'];
  });
}

onChange(event) {
  this.counter = "Number Only"; 
}

 isNumberKey(evt)
      {
         var charCode = (evt.which) ? evt.which : evt.keyCode
         if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;

         return true;
      }


      isNumberKey1(e) {
        //if the letter is not digit then display error and don't type anything
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
           //display error message
           $("#errmsg").html("Digits Only").show().fadeOut("slow");
                  return false;
       }
      }

}
