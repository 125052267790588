import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-rate-settings',
  templateUrl: './rate-settings.component.html',
  styleUrls: ['./rate-settings.component.css']
})
export class RateSettingsComponent implements OnInit {
  codes: any;
  zero: any;
  one: any;
  two: any;
  three: any;
  four: any;
  five: any;
  seven: any;
  six: any;
  eight: any;
  nine: any;
  dot: any;
  check_array: any;
  pageLoadingImg: boolean;
  showVal: boolean;
  up: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.pageLoadingImg = false;
    this.showVal = false;
    this.up = this.coreService.getUserPrivilage();

    this.getRates();
  }

  setRates(formdata: { value: any; }) {

    this.pageLoadingImg = true;

    this.apiService.setRates(formdata.value).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.coreService.showMessage('Updated');
      this.apiService.getRates().subscribe((rescod: any) => {

        this.codes = rescod.rcd_code.split(';');
        localStorage.removeItem("rate_codes");
        this.coreService.setLocalStorage('rate_codes', JSON.stringify(this.codes));

      });

    });

  }


  getRates() {


    this.apiService.getRates().subscribe((res: any) => {
      // console.log(res);
      this.codes = res.rcd_code.split(';');
      this.check_array = res.rcd_code.split(';');


      this.zero = this.codes[0];
      this.one = this.codes[1];
      this.two = this.codes[2];
      this.three = this.codes[3];
      this.four = this.codes[4];
      this.five = this.codes[5];
      this.six = this.codes[6];
      this.seven = this.codes[7];
      this.eight = this.codes[8];
      this.nine = this.codes[9];
      this.dot = this.codes[10];

      // console.log(this.codes.split(';'));
    });

  }


  checkDuplicate(val,index){
    
    if(val){

    if(this.check_array.includes(val)){
     this.showVal = true;
      console.log('yes');

    }else{
      this.showVal = false;

  
      this.check_array[index]=val;
    }
  }else{
    this.showVal = false;

    this.check_array[index]=val;
  }
  }

}
