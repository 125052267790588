import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputnumonlyDirective } from '../.././directive/inputnumonly.directive';
import { InprequiredDirective } from '../.././directive/inprequired.directive';
import { QuantityDirective } from '../.././directive/quantity.directive';
import { AutofocusDirective } from '../../directive/autofocus.directive';
import { NumonlyDirective } from '../../directive/numonly.directive';
import { NumberDirective } from '../../directive/numbers-only.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
//date picker
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// modal
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    InputnumonlyDirective,
    InprequiredDirective,
    QuantityDirective,
    AutofocusDirective,
    NumonlyDirective,
    NumberDirective
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbModule,
    DateInputsModule
  ],
  exports:[
    InputnumonlyDirective,
    InprequiredDirective,
    AutofocusDirective,
    QuantityDirective,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbModule,
    DateInputsModule
  ]
})
export class ComfunctModule { }
