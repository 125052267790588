import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-transaction-dash',
  templateUrl: './transaction-dash.component.html',
  styleUrls: ['./transaction-dash.component.css']
})
export class TransactionDashComponent implements OnInit {
  up: any;
  userType: any;
  branch_transfer_receipt = false;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.getEnbaleDisableModuleSettingBykey();
  }
  getEnbaleDisableModuleSettingBykey() {
    this.apiService.getEnbaleDisableModuleSettingBykey().subscribe((res) => {
      if (res['data']) {      
        this.branch_transfer_receipt = (res['data']['enable_branch_transfer_receipt']) ? true: false;
      } 
    });
  }

}
