import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-four',
  templateUrl: './invoice-four.component.html',
  styleUrls: ['./invoice-four.component.css']
})
export class InvoiceFourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
