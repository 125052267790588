import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-due-rec-temp',
  templateUrl: './due-rec-temp.component.html',
  styleUrls: ['./due-rec-temp.component.css']
})
export class DueRecTempComponent implements OnInit {

  @Input()branch_display_name;
  @Input()branch_address;
  @Input()branch_phone;
  @Input()savedTemplate;
  @Input()branch_mob;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()printPreview;
  @Input()tempImgUrl;
  @Input()footImgUrl;
  @Input()logoImgUrl;
  @Input()sales_print_name;
  @Input()sales_settings;
  country_dec: any;
  up: any;
  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
  }

}
