import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import html2canvas from 'html2canvas';
// import * as jspdf from 'jspdf';
import { ExcelService } from '../../../../../service/excel.service';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-profit-and-loss',
  templateUrl: './profit-and-loss.component.html',
  styleUrls: ['./profit-and-loss.component.css']
})
export class ProfitAndLossComponent implements OnInit {

  fileName = 'profit_and_loss.xlsx';


  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  added_by: any;
  vch_type: any;
  disp_type = '1';
  vch_filter: any;
  ret_ref_filter: any;



  dayList: string[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  supplierAll: string[];
  ModelData: boolean;
  // total_purchase:number;
  total_return: any;
  total_purchase_amount: any;

  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  filter_types: any;
  filter_refnum: any;
  filter_price: any;
  filter_discount: any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType: any;
  // selectRefNo:any;
  selectPrice: any;
  // selectDiscount:any;



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'Summary' },
    { id: '2', name: 'Group wise' },
    { id: '3', name: 'Detailed' },

  ];
  filterPurchrate = [
    { id: 'last', name: 'Last Purchase Rate' },
    { id: 'avg', name: 'Avg. Purchase Rate' },
    { id: 'avg_by_date', name: 'Avg. Purchase Rate By Date' }
  ];
  repDate: any;
  voucherType: any;
  ledger_all: any;
  users: any;
  show_notes: any;
  dispnote: any;
  debitAmt: any;
  crdAmt: any;
  group_all: any;
  ledgerList: any;
  ledgerList1: any;
  total_dir_exp: any;
  total_dir_inc: any;
  total_indir_exp: any;
  total_indir_inc: any;
  reportData: any;
  diffInOpengBal: any;

  math = Math;
  disp_purch_rate = 'last';
  period_type = '';
  ExpledgerList: any;
  ExpdiffInOpengBal: any;
  Exptotal_debit: any;
  Exptotal_credit: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_all: any;
  userType: any;
  netDiff: any;
  isSuperPriv = false;
  superPassword = '';
  accLoginInProcess = false;
  loginErrMsg = '';
  filter_branch_id: any;
  logoImgUrl: any = '';
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private excelService: ExcelService,
              private translate: TranslateService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getHeaderImage();
    this.country_dec=this.coreService.setDecimalLength();
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','ACCOUNTS.Group_wise','Common.detailed','Common.last_purchase_rate','Common.average_purchase_rate','Common.avg_purchase_rate_by_date','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_purchtypes = [
        { id: '1', name: res['Common.summary'] },
        { id: '2', name: res['ACCOUNTS.Group_wise'] },
        { id: '3', name: res['Common.detailed'] },
    
      ];
      this.filterPurchrate = [
        { id: 'last', name: res['Common.last_purchase_rate'] },
        { id: 'avg', name: res['Common.average_purchase_rate'] },
        { id: 'avg_by_date', name: res['Common.avg_purchase_rate_by_date'] }
      ];
    });

  }



  ngOnDestroy() {
    $('.close').click();

  }

  checkEnterClicked(keycode) {
    // console.log(keycode);
     if (keycode.keyCode === 13) {
       this.superAccess();
     }
   }

  superAccess() {
    this.loginErrMsg = '';
    this.accLoginInProcess = true;
    this.apiService.superAccess({superPassword : this.superPassword}).subscribe((res: any) => {
      this.accLoginInProcess = false;
      if (res.status) {
        localStorage.setItem('spracs', res.passcode);
        this.isSuperPriv = true;
        this.userType = this.coreService.getUserData('user_type');
        this.getAllBranch();
        this.pageLoadingImg = true;

        const searchval = new FormData();
        this.getProfitAndLossData(searchval, 1);

        this.branch_display_name = this.coreService.getUserData('branch_disp_name');
        this.branch_name = this.coreService.getUserData('branch_name');
        this.branch_display_code = this.coreService.getUserData('branch_code');
      } else {
        this.loginErrMsg = res.msg;
      }
    });
  }

  getProfitAndLossData(searchval, pageNo) {
    this.ledgerList = [];
    this.ledgerList1 = [];
    this.total_dir_exp = 0;
    this.total_dir_inc = 0;
    this.total_indir_exp = 0;
    this.total_indir_inc = 0;
    this.netDiff = 0;
    this.pageLoadingImg = true;
    this.apiService.getProfitAndlossReport(searchval, pageNo).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      this.ledgerList1 = res.data1.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_dir_exp = res.info.total_dir_exp;
      this.total_dir_inc = res.info.total_dir_inc;
      this.total_indir_exp = res.info.total_indir_exp;
      this.total_indir_inc = res.info.total_indir_inc;
      this.netDiff = res.info.netDiff;
      // this.diffInOpengBal = (res.info.opening_bal) ? res.info.opening_bal : 0;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }



  pageNext(formdata: { value: any; }, pageno: any) {




    $('.resultdata').empty();
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('pageno', pageno);

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.getProfitAndLossData(formdata.value, pageno);
  }




  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res: any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;

      // console.log(  this.branch_all );

    });
  }
  searchBranch(search: string) {
    const searchval = new FormData();
    searchval.append('branch_name', search);
    this.apiService.getBranchByName(searchval).subscribe((res: any) => {
      this.branch_all = res.data;

    });
  }

  searchVoucherType(search: string) {
    const searchval = new FormData();
    searchval.append('vchtype_name', search);
    this.apiService.searchVoucherType(searchval).subscribe((res) => {
      this.voucherType = res.data;
    });
  }

  searchLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.getLedByName(searchval).subscribe((res: any) => {
      this.ledger_all = res.data;
    });
  }

  searchAccGroup(search: string) {
    const searchval = new FormData();
    searchval.append('name', search);
    this.apiService.getAccGroup(searchval).subscribe((res: any) => {
      this.group_all = res.data;

      console.log(this.group_all);
    });
  }



  // form search
  searchUser(search: string) {
    const searchval = new FormData();
    searchval.append('usr_name', search);
    this.apiService.getUserByName(searchval).subscribe((res: any) => {
      this.users = res.data;
    });
  }



  pgEnd(curr, end) {
    if (curr === end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr === 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  grpSummaryexport() {

  }

  openPDF() {
    $('#contentToConvert').show();

    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      // 208
      const pageHeight = 495;
      const imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/jpeg', 1.0);
      // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      // var position = 0;
      // pdf.addImage(contentDataURL, 'JPG', 0, position, imgWidth, imgHeight);

      // pdf.save('Account Group Summary Report.pdf'); // Generated PDF

    });

    $('#contentToConvert').hide();
  }

  // To get Header image which will be the part of pdf report
  getHeaderImage() {
    this.apiService.getSettings().subscribe((res) => {
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = ''; // default
      }

      if (res.data.prnt_header_type === 2) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.logoImgUrl = '';
      }
    });
  }

  mergeArr(arr1= [], arr2= []) {
    const lenArr1 = arr1.length;
    const lenArr2 = arr2.length;
    const count = lenArr1 > lenArr2 ? lenArr1 : lenArr2;
    const resArr = [];

    for (let i = 0; i < count; i++) {
      if (i < lenArr1) {
        resArr.push([...arr1[i], ...(i < lenArr2 ? arr2[i] : ['', ''])]);
      } else {
        resArr.push(['', '', ...arr2[i]]);
      }
    }
    return resArr;
  }
  exportTopdf() {
    let tbl1 = [];
    let tbl2 = [];
    const tbl3 = [];
    const tbl5 = [];
    let arrData = [];

    const headerHeight = 52;
    const lblXPos = 18;
    const valueXPos = 55;
    const heads = [
      this.translate.instant('ACCOUNTS.Particulars'),
      '',
      this.translate.instant('ACCOUNTS.Particulars'),
      ''
    ];
    let doc = this.excelService.getPdfObj();
    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text('PROFIT AND LOSS', 85, headerHeight - 2);


  //
    doc.setFontSize(10);
    doc.text('Branch', lblXPos, headerHeight + 5);
    doc.setFontSize(11);
    doc.text(`:  ${this.branch_display_name} ${this.branch_display_code}`,  valueXPos, headerHeight + 5);

    if (this.repDate) {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
      doc.text(`:  ${this.repDate.date1} To ${this.repDate.date2}`,  valueXPos, headerHeight + 10);
    }

    this.ledgerList.forEach(data => {
      if (data.accgrp_nature === 1) {
        if (data.display) {
          tbl1.push([data.accgrp_name, this.currencyPipe.transform(data.balance, '', '', this.country_dec)]);
        }
        if (this.disp_type === '3') {
          data.ledgers.forEach(subledgers => {
              if (subledgers.balance !== 0) {
                tbl1.push(['    ' + subledgers.ledger_name, this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec)]);
              }
          });
        }

        if (data.sub_groups.length !== 0 && this.disp_type !== '1') {
          data.sub_groups.forEach(sub => {
            if (sub.display) {
              tbl1.push(['    ' + sub.accgrp_name, this.currencyPipe.transform(sub.balance, '', '', this.country_dec)]);
            }
            if (this.disp_type === '3') {
              sub.ledgers.forEach(subledgers => {
                if (subledgers.balance !== 0) {
                  tbl1.push(['        ' + subledgers.ledger_name, this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec)]);
                }
              });
            }
          });
        }

      }
      // right table
      if (data.accgrp_nature === 0) {
        if (data.display) {
          tbl2.push([data.accgrp_name, this.currencyPipe.transform(data.balance, '', '', this.country_dec)]);
        }
        if (this.disp_type === '3') {
          data.ledgers.forEach(subledgers => {
              if (subledgers.balance !== 0) {
                tbl2.push([subledgers.ledger_name, this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec)]);
              }
          });
        }

        if (data.sub_groups.length !== 0 && this.disp_type !== '1') {
          data.sub_groups.forEach(sub => {
            if (sub.display) {
              tbl2.push(['    ' + sub.accgrp_name, this.currencyPipe.transform(sub.balance, '', '', this.country_dec)]);
            }
            if (this.disp_type === '3') {
              sub.ledgers.forEach(subledgers => {
                if (subledgers.balance !== 0) {
                  tbl2.push(['       ' + subledgers.ledger_name, this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec)]);
                }
              });
            }
          });
        }
      }
    });

    arrData = this.mergeArr(tbl1, tbl2);
    arrData.push(['', this.currencyPipe.transform(this.total_dir_exp, ' '), '', this.currencyPipe.transform(this.total_dir_inc, '', '', this.country_dec) ]);

    tbl1 = [];
    tbl2 = [];
    // second part vertical
    this.ledgerList1.forEach(data => {
      if (data.accgrp_nature === 1) {
        if (data.display) {
          tbl1.push([data.accgrp_name, this.currencyPipe.transform(data.balance, '', '', this.country_dec)]);
        }

        if (this.disp_type === '3') {
          data.ledgers.forEach(subledgers => {
            if (subledgers.balance) {
              tbl1.push(['    ' + subledgers.ledger_name, this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec)]);
            }
          });
        }

        if (data.sub_groups.length !== 0 && this.disp_type !== '1') {
          data.sub_groups.forEach(sub => {
            if (sub.display) {
              tbl1.push(['    ' + sub.accgrp_name, this.currencyPipe.transform(sub.balance, '', '', this.country_dec)]);
            }
            if (this.disp_type === '3') {
              sub.ledgers.forEach(subledgers => {
                if (subledgers.balance !== 0) {
                  tbl1.push(['        ' + subledgers.ledger_name, this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec)]);
                }
              });
            }
          });
        }
      }

      // right section

      if (data.accgrp_nature === 0) {
        if (data.display) {
          tbl2.push([data.accgrp_name, this.currencyPipe.transform(data.balance, '', '', this.country_dec)]);
        }

        if (this.disp_type === '3') {
          data.ledgers.forEach(subledgers => {
            if (subledgers.balance !== 0) {
              tbl2.push(['    ' + subledgers.ledger_name, this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec)]);
            }
          });
        }

        if (data.sub_groups.length !== 0 && this.disp_type !== '1') {
          data.sub_groups.forEach(sub => {
            if (sub.display) {
              tbl2.push(['    ' + sub.accgrp_name, this.currencyPipe.transform(sub.balance, '', '', this.country_dec)]);
            }
            if (this.disp_type === '3') {
              sub.ledgers.forEach(subledgers => {
                if (subledgers.balance !== 0) {
                  tbl2.push(['        ' + subledgers.ledger_name, this.currencyPipe.transform(subledgers.balance, '', '', this.country_dec)]);
                }
              });
            }
          });
        }
      }
    });
    const mergedArr = this.mergeArr(tbl1, tbl2);
    arrData = arrData.concat(mergedArr);

    if (this.netDiff >= 0) {
      tbl3.push(['Net Profitt', this.currencyPipe.transform(this.netDiff, '', '', this.country_dec), '', '']);
    } else {
      tbl3.push(['', '', 'Net Loss', this.currencyPipe.transform(this.math.abs(this.netDiff), '', '', this.country_dec)]);
    }

    tbl5.push(['Total', this.currencyPipe.transform(this.total_indir_exp, '', '', this.country_dec),
    'Total', this.currencyPipe.transform(this.total_indir_inc, '', '', this.country_dec)]);
    arrData = arrData.concat(tbl3);
    arrData = arrData.concat(tbl5);

    doc = this.excelService.addTableToPdf(doc, headerHeight + 22, lblXPos, heads, arrData, this.logoImgUrl, this.branch_display_name);
    doc.save(`profit_and_loss.pdf`);
  }



  exportAsXLSX(formdata: { value: any; }): void {
    this.apiService.groupSummaryToatalReport(formdata.value).subscribe((res: any) => {
      this.reportData = res.data;
      console.log(this.reportData);
    });
    this.excelService.exportAsExcelFile(this.reportData, 'Account Group Summary Report');
  }

  exportexcel(): void {
    /* table id is passed over here */
    const element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  export(formdata: { value: any; }) {
    this.exportexcel();
  }


}
