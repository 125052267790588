import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
@Component({
  selector: 'app-payroll-settings',
  templateUrl: './payroll-settings.component.html',
  styleUrls: ['./payroll-settings.component.css']
})
export class PayrollSettingsComponent implements OnInit {
  pageLoadingImg: boolean;
  settings: any;
  company_working_days: any;
  ot_amount_multiplier: any;
  err: boolean = false;
  constructor(private apiService: ApiService, private coreService: CoreService) { }
  ngOnInit() {
    this.getSetting();
  }
  update(formdata: { value: any; }) {
    $("#company_working_days_err").text('');
    $("#ot_amount_multiplier_err").text('');
    if (!this.company_working_days) {
      $("#company_working_days_err").text('Enter working days');
      this.err = true;
    }
    if (!this.ot_amount_multiplier) {
      $("#ot_amount_multiplier_err").text('Enter multiplier');
      this.err = true;
    }
    if (this.err) {
      return false;
    }

    this.pageLoadingImg = true;
    this.apiService.updatePayrollSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }
  getSetting() {
    this.apiService.getPayrollSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.company_working_days = this.settings.s_company_working_days;
        this.ot_amount_multiplier = this.settings.s_ot_amount_multiplier;
      }
    });
  }
}
