import { Component, OnInit, Input } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-thermal-print',
  templateUrl: './thermal-print.component.html',
  styleUrls: ['./thermal-print.component.css']
})
export class ThermalPrintComponent implements OnInit {
  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  country_dec: string;
  constructor(private coreService: CoreService) { }

  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
  }

}
