import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-van-detailed-rep',
  templateUrl: './van-detailed-rep.component.html',
  styleUrls: ['./van-detailed-rep.component.css']
})
export class VanDetailedRepComponent implements OnInit {

  fileName = 'van_detailed_rep.xlsx';

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  VanList: any;
  branch_display_name: any;
  repData: [];
  pageLoadingImg: boolean;
  repDate: any;
  totRes: any;
  exportLoader: boolean;
  branch_display_code: any;
  taxCategories = [];
  prd_tax_cat_id : any;
  date1:any;
  date2:any;
  period_type :any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService
    ) { }

  ngOnInit() {
    
  this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
  this.langChange();
    });
  this.langChange();
  this.country_dec=this.coreService.setDecimalLength();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
      this.prd_tax_cat_id = 5;
    });

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }

  selectVan(van) {
    if (van) {
      this.pageLoadingImg = true;
      const searchval = new FormData();
      searchval.append('van_ledger_id', van.van_cash_ledger_id);
      searchval.append('van_id', van.van_id);
      searchval.append('prd_tax_cat_id', this.prd_tax_cat_id);

      this.apiService.vanDetailedRep(searchval).subscribe((res) => {
        this.repData = res.data;
        this.pageLoadingImg = false;
        this.repDate = res.data.Date;
        this.totRes = res.data.totalRes;
      });

    } else {
      this.repData = [];

    }
  }


  filterSearch(formdata: { value: any; }, van) {
    if (van) {
      this.pageLoadingImg = true;
      const searchval = new FormData();
      formdata.value.van_ledger_id = van.van_cash_ledger_id;
      formdata.value.van_id = van.van_id;
      formdata.value.prd_tax_cat_id = this.prd_tax_cat_id;
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      }
      this.apiService.vanDetailedRep(formdata.value).subscribe((res) => {
        this.repData = res.data;
        this.pageLoadingImg = false;
        this.repDate = res.data.Date
        this.totRes = res.data.totalRes;

        // this.VanList.push({ "van_id": 'All', 'van_name': 'All' });
      });

    } else {
      this.repData = [];

    }
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.VanList = res['data'];
    });

  }

  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('export-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


}
