import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-inter-branch-receipt',
  templateUrl: './inter-branch-receipt.component.html',
  styleUrls: ['./inter-branch-receipt.component.css']
})
export class InterBranchReceiptComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  rec = {
    rec_no: '',
    rec_date: new Date(),
    rec_acc: { ledger_id: 0 },
    rec_balance: 0,
    rec_bal_sign: '',
    amount: 0.00,
    narration: '',
    pay_no: 0,
    branch: ''
  };
  // pay.pay_acc: any = {};
  account = [];
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  nextId = '';
  accLoading = false;
  country_dec: string;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    });
    this.getNextReceiptId();
    this.getAllAccount();
    this.getInfo(this.curpage);

    this.country_dec=this.coreService.setDecimalLength();
  }
  getNextReceiptId() {
    this.apiService.getNextReceiptId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }
  getAccLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_acc.ledger_id }).subscribe((res) => {
      this.accLoading = false;
      this.rec.rec_balance = res.data.bal_without_sign;
      this.rec.rec_bal_sign = res.data.bal_sign;
    });
  }
  rejectPayment(payNo, index) {
    this.info[index].isInprogress = true;
    this.apiService.rejectInterPayment({ pay_no: payNo }).subscribe((res) => {
      this.info[index].isInprogress = false;
      this.getInfo(this.curpage);
      this.coreService.showMessage(res.message);
    });
  }
  acceptPayment(index) {
    // this.clearForm();
    this.rec = {
      rec_no: '',
      rec_date: new Date(this.info[index].pay_date),
      rec_acc: { ledger_id: 0 },
      rec_balance: 0,
      rec_bal_sign: '',
      amount: this.info[index].pay_ttl_amount,
      narration: this.info[index].pay_note,
      pay_no: this.info[index].pay_no,
      branch: this.info[index].branch_name
    };
  }


  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.info = [];
    this.totalItem = 0;
    this.apiService.getAllInterBranchReceipt(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  // searchInp(e) {
  //   this.pageLoadingImg = true;

  //   const searchval = new FormData();
  //   searchval.append('search', e);
  //   this.apiService.searchPaymentList(searchval).subscribe((res) => {
  //     this.info = res.data.data;
  //     this.pageLoadingImg = false;
  //     this.curpage = res.data.current_page;
  //     this.lastpage = res.data.last_page;
  //     this.totalItem = res.data.total;
  //    });
  // }

  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }



  clearForm() {
    this.rec = {
      rec_no: '',
      rec_date: new Date(),
      rec_acc: { ledger_id: 0 },
      rec_balance: 0,
      rec_bal_sign: '',
      amount: 0.00,
      narration: '',
      pay_no: 0,
      branch: ''
    };
  }

  checkTabClicked(keycode) {
    console.log(keycode);
    if (keycode.keyCode === 9) {
      this.validateAndSubmit();
    }
  }

  voidPayment(id) {
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.apiService.voidPayment({ pay_no: id }).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
        }

      });
    }
  }

  validateAndSubmit() {
    this.resultobj = [];

    if (this.rec.rec_date == undefined) {
      this.resultobj.rec_date = "t";
      this.resultobj.push({ 'rec_date': 'Required' });
    }
    else {
      if (this.rec.rec_date > new Date()) {
        this.resultobj.rec_date = "t";
        this.resultobj.push({ 'rec_date': 'Required' });
      } else {
        this.resultobj.rec_date = "f";
      }
    }
    if (this.resultobj.length <= 0) {
      if (confirm('Do you wish to continue?')) {
        this.isSubmitInProg = true;
        let callFunction = '';
        if (this.curForm === this.langText.create) {
          callFunction = 'createInterReceipt';
        }
        if (this.curForm === this.langText.update) {
          callFunction = 'updateInterReceipt';
        }
        this.apiService[callFunction](this.rec).subscribe((res) => {
          // console.log(this.staff);
          this.isSubmitInProg = false;
          if (res.error != null) {
            this.resultobj = res.error;
          } else {
            this.closeModal.nativeElement.click();
            this.getInfo(this.curpage);
            this.coreService.showMessage(res.message);
            this.clearForm();
            this.getNextReceiptId();
            this.resultobj = {};
          }

        });
      }
    }
  }
}
