import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-van-itemwise-rep',
  templateUrl: './van-itemwise-rep.component.html',
  styleUrls: ['./van-itemwise-rep.component.css']
})
export class VanItemwiseRepComponent implements OnInit {
  fileName= 'van_itemWise_report.xlsx';
  vans: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  pageLoadingImg: boolean;
  curpage: number;
  lastpage: number;
  pgend: boolean;
  pgstart: boolean;
  prds: any[];
  selecTed: any=null;
  prd_id: any;
  period_type: string;
  itemwise: any;
  repDate: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.searchVans('');
    this.getVanProducts('');
    this.period_type = 't';
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }

  selectPrdct(res){
    this.pageLoadingImg = true;
    this.prd_id=res.prd_id;
    this.selecTed = null
    const searchval = new FormData();
    
    searchval.append('prd_id', res.prd_id);
    searchval.append('period_type', 't');
    

    this.apiService.vanItemtWiseTracking(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.itemwise = res.data;
      this.repDate=res.Date;
    
 
 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
 
     
     });
  }


  pageNext(formdata: { value: any; }){

    this.pageLoadingImg = true;
    formdata.value.prd_id=this.prd_id;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanItemtWiseTracking(formdata.value,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.itemwise = res.data;
      this.repDate=res.Date;
 
    
 
 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
 
     
     });
  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }


  getVanProducts(key:any) {   
   
    let searchval = new FormData();
    
      searchval.append("search", key);
    
    this.apiService.getVanAllProducts(searchval).subscribe((res) => {
      this.prds = res['data'];
      this.selecTed = null;
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }


  exportexcel(): void 
  {
    this.exportLoader = true;
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }
}
