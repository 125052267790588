import { Component, OnInit, Input } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-po-temp-one-print',
  templateUrl: './po-temp-one-print.component.html',
  styleUrls: ['./po-temp-one-print.component.css']
})
export class PoTempOnePrintComponent implements OnInit {
  @Input()branch_name;
  @Input()savedTemplate;
  @Input()logoImgUrl;
  @Input()branch_address;
  @Input()branch_phone;
  @Input()branch_mob;
  @Input()branch_reg_no;
  @Input()branch_tin;
  @Input()tempImgUrl;
  @Input()previewData;
  @Input()is_prew_description;
  country_dec: string;
  constructor( private coreService: CoreService) { }

  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
  }

}
