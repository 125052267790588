import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { ProductionService } from '../../../../../service/production.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-production-incredients',
  templateUrl: './production-incredients.component.html',
  styleUrls: ['./production-incredients.component.css']
})
export class ProductionIncredientsComponent implements OnInit {
  fileName= 'sales_summary.xlsx';
  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgstart: boolean;
  pgend: boolean;
  datas: string[];
  values: string[];
  isnoData : boolean;
  repDate:any;
  date1:any;
  date2:any;
  prate1:any;
  prate2:any;
  purchase_rate:any;
  pamount1:any;
  pamount2:any;
  elseBlocknostart:any;prod_amount:any;elseBlocknoend:any;period_type:any;prd_formula :any;incre_name:any;usr_id:any;
  filter:any;iusr_id:any;
  up: any;
  formula_all: any;
  stkprd_all: any;
  repTotAmnt: any;
  repTotQty: any;
  subcatagories: any;
  changeBox: any;
  catagories: any;
  exportLoader: boolean;
  ExpisnoData: boolean;
  Expdatas: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  ExprepDate: any;
  ExprepTotAmnt: any;
  ExprepTotQty: any;
  country_dec: string;

 constructor(private productionService: ProductionService, private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe
  ) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
  
    this.country_dec=this.coreService.setDecimalLength();
    this.readIncre(1);
    this.up = this.coreService.getUserPrivilage();
    this.listProductFormula();

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filterDate = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }

  readIncre(pageno)
  {
    this.pageLoadingImg = true;
    this.isnoData = false;
    let searchval = new FormData();
    this.productionService.prodIncr(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data']['data'];      
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data']['Date'];
      this.repTotAmnt = res['data']['tot_amnt'];
      this.repTotQty = res['data']['tot_qty'];

      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    formdata.value.export = '';
    formdata.value.filter = 1;   
    this.pageLoadingImg = true;
    this.isnoData = false;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.productionService.prodIncr(formdata.value, pageno).subscribe((res: any) => {      
      this.datas = res['data']['data']['data'];      
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data']['Date'];
      this.repTotAmnt = res['data']['tot_amnt'];
      this.repTotQty = res['data']['tot_qty'];

      this.pageLoadingImg = false;
      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }

  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  filterDate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];

   //form search
   searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

  listProductFormula() { 
    this.productionService.getAllProductionFormula(1, 5000).subscribe((res) => {
      this.formula_all = res.data.data;
    });
  }
  searchFormula(search: string) {
    const searchval = new FormData();
    searchval.append('formula_name', search);
    this.productionService.searchProductionFormula(searchval).subscribe((res) => {
      this.formula_all = res.data;
    });
  }

  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  } 


  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportEXCL(formdata: { value: any; }){
  this.exportLoader = true;
   
    // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
    formdata.value.filter = 1;   
    this.ExpisnoData = false;
    this.productionService.prodIncr(formdata.value, 1).subscribe((res: any) => {      
      this.Expdatas = res['data']['data']['data'];      
      this.ExprepDate = res['data']['Date'];
      this.ExprepTotAmnt = res['data']['tot_amnt'];
      this.ExprepTotQty = res['data']['tot_qty'];

      this.exportLoader = false;
      if(this.Expdatas.length==0)
      this.ExpisnoData = true;
      formdata.value.export = '';
      
   
    setTimeout(() => {
      this.exportexcel(); 
  }, 3000);
  
 

  });



  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
   
    // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
    formdata.value.filter = 1;   
    this.ExpisnoData = false;
    this.productionService.prodIncr(formdata.value, 1).subscribe((res: any) => {      
      this.Expdatas = res['data']['data']['data'];      
      this.ExprepDate = res['data']['Date'];
      this.ExprepTotAmnt = res['data']['tot_amnt'];
      this.ExprepTotQty = res['data']['tot_qty'];

      this.exportLoader = false;
      if(this.Expdatas.length==0)
      this.ExpisnoData = true;
      formdata.value.export = '';
      
      const heads = [
        this.translate.instant('Table.sl') + '\n' + this.translate.instant('Common.no'),
        this.translate.instant('Van.Incredient_Name'),
        this.translate.instant('Table.barcode'),
        this.translate.instant('Van.Prdn') + '\n' + this.translate.instant('Table.date'),
        this.translate.instant('Production.formula_name'),
        this.translate.instant('Table.qty') + '\n' + this.translate.instant('Van.Used'),
        this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.rate'),
        this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 35;
        const data = [];
  
        this.Expdatas.forEach((item,i)=>{
          data.push([
            i+1,
            item.product,
            item.barcode,
            this.datePipe.transform(item.pdate, 'dd/MM/yyyy'),
            item.formula,
            item.qty,
            this.currencyPipe.transform(item.purchrate, '', '', this.country_dec),
            this.currencyPipe.transform(item.qty *item.purchrate, '', '', this.country_dec)
          ])
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Van.production_ingredients')}`,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.Total_Qty')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.repTotQty, '', '', this.country_dec),  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.repTotAmnt, '', '', this.country_dec),  valueXPos, headerHeight + 25);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
    
       
        doc.save(`production ingredients.pdf`);
  
        this.exportLoader = false;

  });
  
  }

}
