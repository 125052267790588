import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-update-branch',
  templateUrl: './update-branch.component.html',
  styleUrls: ['./update-branch.component.css']
})
export class UpdateBranchComponent implements OnInit {


  // ledgerAll=[
  //   {id:1,product_name:'pepsi'},
  //   {id:1,product_name:'seven up'},
  //   {id:1,product_name:'fruity'},
  //   {id:1,product_name:'ma'}
  // ]
  stkprd_all: string[];
  stkprdsel: any;
  selecTed: boolean = false
  pageLoadingImg = false;
  pageLoading = false
  result = '';
  product_name: any;
  units: any;
  branches: any;
  item: any;
  items: any;
  branche: any;
  branch: any;
  prd_name: any;
  prd_alias: any;
  cat_name: any;
  prd_id: any;
  validate: boolean;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
  }

  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      //  this.selecTed = true; 

    });
  }

  // form search select

  selectprd(prd) {

    // this.pageLoadingImg = true;
    // this.selecTed = null;
    let searchval = new FormData();
    searchval.append("prd_id", prd.prd_id);
    this.selecTed = null;
    this.pageLoadingImg = true;
    this.apiService.getPrdName(searchval).subscribe((res: any) => {
      // this.pageLoadingImg = false;
      // this.selecTed = res.data; 
      this.pageLoadingImg = false;
      this.selecTed = true
      this.product_name = res;
      this.units = res.units;
      this.branches = res.units[0].rate;
      this.branch = res.units[0].rate
    });

  }

  update(formData) {
    this.pageLoading = true;

    const values = {
      'prd_name': this.product_name.prd_name,
      'prd_alias': this.product_name.prd_alias,
      'cat_name': this.product_name.cat_name,
      'prd_id': this.product_name.prd_id,
      'units': this.units
    }
    this.apiService.editrate(values).subscribe((res: any) => {
      this.pageLoading = false;
      if (res.error != null) {
        this.result = res.error
      }
      else {
        this.result = ''
        this.coreService.showMessage(res.message);
        this.stkprd_all = [];
        this.stkprdsel = "Select Product";
        this.selecTed = false;
        console.log(this.selecTed);
      }
    })
  }

  // validation
  isDuplicateBarcode(index: number): boolean {
    const currentBarcode = this.units[index].produnit_ean_barcode;
    if (!currentBarcode || currentBarcode.trim() === '') {
      return false;
    }
    // let validation =this.units.slice(0, index).some((unit) =>  unit.produnit_ean_barcode === currentBarcode)
    let validation = this.units.some((unit, i) => i !== index && unit.produnit_ean_barcode === currentBarcode)
    if (validation) {
      this.validate = true;
    } else {
      this.validate = false;

    }
    return this.validate;
  }


}
