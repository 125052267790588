import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchase-invoice',
  templateUrl: './purchase-invoice.component.html',
  styleUrls: ['./purchase-invoice.component.css']
})
export class PurchaseInvoiceComponent implements OnInit {
  fileName = 'purch_invoice_summary.xlsx';

  rep_type: any;
  calc_mode: any;
  purchaseBlock: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: any;
  purch_filter: any;
  purch_val: any;
  purch_val2: any;
  purch_rate_filter: any;
  purch_rate: any;
  purch_rate2: any;
  purch_qty_filter: any;
  purch_qty: any;
  purch_qty2: any;
  sup_id: any;
  prd_id: any;
  cat_id: any;
  manfact_id: any;

  purchInvSum: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  company: any;
  products: any;
  supplier: any;
  data: any;

  totalQty: number;
  purchaseAmount: any;
  total_mrp: any;
  totalProducts: any;
  totalCats: any;
  purchInvitem: any;
  pageLoadingImg: boolean;
  repDate: any;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  report_type_filter = [
    { id: 'summary', name: 'Summary' },
    { id: 'detailed', name: 'Detailed' },
    { id: 'detailed_with_mrp', name: 'Detailed With MRP' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  catagories: any;
  branch_all: any;
  userType: any;
  report_type: any;
  exportLoader: boolean;
  ExppurchInvSum: any[];
  Expreport_type: any;
  ExptotalQty: any;
  ExppurchaseAmount: any;
  Exptotal_mrp: any;
  
  ExptotalProducts: any;
  ExptotalCats: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  branch_name: any;
  godowns: any;
  gd_id:any;
  country_dec: string;
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }



  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }
  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.userType = this.coreService.getUserData('user_type');
    this.rep_type = 'summary';
    this.report_type = 'summary';
    this.getAllBranch();
    this.pageLoadingImg = true;
    const searchval = new FormData();
    this.apiService.purchaseInvoiceRep(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      // this.data = res;
      this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;

      if (res.data.allTotal) {
        this.totalQty = res.data.allTotal.total_qty;
        this.purchaseAmount = res.data.allTotal.purchase_amount;
        this.total_mrp = res.data.allTotal.mrp_amount;
        this.totalProducts = res.data.allTotal.total_products;
        this.totalCats = res.data.allTotal.total_cats;
      } else {
        this.totalQty = 0;
        this.purchaseAmount = 0;
        this.total_mrp = 0;
        this.totalProducts = 0;
        this.totalCats = 0;

      }


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.getGodownList();
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','Common.detailed','Common.detailed_with_mrp']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.report_type_filter = [
        { id: 'summary', name: res['Common.summary'] },
        { id: 'detailed', name: res['Common.detailed'] },
        { id: 'detailed_with_mrp', name: res['Common.detailed_with_mrp'] }
      ];
    });
  }

  ngOnDestroy() {
    $('.close').click();
  }

  // FILTERS


  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res['data'];
    });

  }

  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ 'gd_id': 'All', 'gd_name': 'All' })

    });
  }

  // FILTERS - End




  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;

    formdata.value.export = '';

    const searchval = new FormData();
    searchval.append('pageno', pageno);
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    this.purchInvSum = [];

    this.apiService.purchaseInvoiceRep(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;

      // this.data = res;

      this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.repDate = res.data.Date;
      this.report_type = res.data.rep_type;

      if (res.data.allTotal) {
        this.totalQty = res.data.allTotal.total_qty;
        this.purchaseAmount = res.data.allTotal.purchase_amount;
        this.total_mrp = res.data.allTotal.mrp_amount;
        this.totalProducts = res.data.allTotal.total_products;
        this.totalCats = res.data.allTotal.total_cats;
      } else {
        this.totalQty = 0;
        this.purchaseAmount = 0;
        this.total_mrp = 0;
        this.totalProducts = 0;
        this.totalCats = 0;

      }
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }



  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }



  exportEXCL(formdata: { value: any; }) {


    this.exportLoader = true;

    formdata.value.export = 'export';

    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    this.ExppurchInvSum = [];

    this.apiService.purchaseInvoiceRep(formdata.value, 1).subscribe((res: any) => {

      // this.data = res;

      this.ExppurchInvSum = res.data.data;

      this.repDate = res.data.Date;
      this.Expreport_type = res.data.rep_type;

      if (res.data.allTotal) {
        this.ExptotalQty = res.data.allTotal.total_qty;
        this.ExppurchaseAmount = res.data.allTotal.purchase_amount;
        this.Exptotal_mrp = res.data.allTotal.mrp_amount;
        this.ExptotalProducts = res.data.allTotal.total_products;
        this.ExptotalCats = res.data.allTotal.total_cats;
      } else {
        this.ExptotalQty = 0;
        this.ExppurchaseAmount = 0;
        this.Exptotal_mrp = 0;
        this.ExptotalProducts = 0;
        this.ExptotalCats = 0;

      }

      setTimeout(() => {
        this.exportexcel();
      }, 3000);



    });

  }


  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

    formdata.value.export = 'export';

    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    this.ExppurchInvSum = [];

    this.apiService.purchaseInvoiceRep(formdata.value, 1).subscribe((res: any) => {

      // this.data = res;

      this.ExppurchInvSum = res.data.data;

      this.repDate = res.data.Date;
      this.Expreport_type = res.data.rep_type;

      if (res.data.allTotal) {
        this.ExptotalQty = res.data.allTotal.total_qty;
        this.ExppurchaseAmount = res.data.allTotal.purchase_amount;
        this.Exptotal_mrp = res.data.allTotal.mrp_amount;
        this.ExptotalProducts = res.data.allTotal.total_products;
        this.ExptotalCats = res.data.allTotal.total_cats;
      } else {
        this.ExptotalQty = 0;
        this.ExppurchaseAmount = 0;
        this.Exptotal_mrp = 0;
        this.ExptotalProducts = 0;
        this.ExptotalCats = 0;

      }

      const heads = [
     
      ];

      if(this.report_type == 'detailed'){
        heads.splice(0,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.date'))
        heads.splice(1,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Purchase.refno'))
        heads.splice(2,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('HOME.Invoice_no'))
        heads.splice(3,0, this.translate.instant('Purchase.supplier'))
        heads.splice(4,0, this.translate.instant('Branch.Supplier Vat No'))
        heads.splice(5,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt'))
        heads.splice(6,0, this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Common.Tax'))
        heads.splice(7,0, this.translate.instant('HOME.PURCHASE') + '+' + this.translate.instant('Common.Tax'))
        heads.splice(8,0, this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.description'))
        heads.splice(9,0, this.translate.instant('Common.Purch'))
      } else if(this.report_type == 'summary'){
        heads.splice(0,0, this.translate.instant('Table.itm_name'))
        heads.splice(1,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Purchase.refno'))
        heads.splice(2,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.date'))
        heads.splice(3,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.rate'))
        heads.splice(4,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.qty'))
        heads.splice(5,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt'))
      } else if(this.report_type == 'detailed_with_mrp'){
        heads.splice(0,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.date'))
        heads.splice(1,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Purchase.refno'))
        heads.splice(2,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('HOME.Invoice_no'))
        heads.splice(3,0, this.translate.instant('Purchase.supplier'))
        heads.splice(4,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt'))
        heads.splice(5,0, this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Common.Tax'))
        heads.splice(6,0, this.translate.instant('HOME.PURCHASE') + '+' + this.translate.instant('Common.Tax'))
        heads.splice(7,0, this.translate.instant('HOME.PURCHASE') + '\n' + this.translate.instant('Table.description'))
        heads.splice(8,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.qty'))
        heads.splice(9,0, this.translate.instant('Table.mrp'))
        heads.splice(10,0, this.translate.instant('Table.total_mrp'))
      }

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.ExppurchInvSum.forEach(item=>{
          const arr=[]

          if(this.report_type == 'detailed'){
            arr.splice(0,0,  this.datePipe.transform(item.purchsub_date, 'dd/MM/yyyy'))
            arr.splice(1,0, item.purch_refno)
            arr.splice(2,0, item.purchase.purch_inv_no)
            arr.splice(3,0, item.purchase.supp_name)
            arr.splice(4,0, item.purchase.supp_tin)
            arr.splice(5,0, this.currencyPipe.transform(item.purchase_amount, '', '', this.country_dec))
            arr.splice(6,0, this.currencyPipe.transform(item.purchsub_tax, '', '', this.country_dec))
            arr.splice(7,0, this.currencyPipe.transform(item.purch_with_tax, '', '', this.country_dec))
            arr.splice(8,0, item.product.prd_name)
            arr.splice(9,0, this.currencyPipe.transform(item.purchsub_qty, '', '', '1.1-1'))
          } 
          else if(this.report_type == 'summary'){
            item.category.forEach(item1=>{
              const arr1=[]
              arr1.splice(0,0, item1.cat_name)
              arr1.splice(1,0, ' ')
              arr1.splice(2,0, ' ')
              arr1.splice(3,0, this.currencyPipe.transform(item1.purchsub_rate, '', '', this.country_dec))
              arr1.splice(4,0, this.currencyPipe.transform(item1.purchsub_qty, '', '', '1.1-1'))
              arr1.splice(5,0, this.currencyPipe.transform(item1.purchase_amount, '', '', this.country_dec))

              data.push(arr1)
            })

            item.products.forEach((item1,i)=>{
              const arr2=[]
              arr2.splice(0,0, i+1 + '.' + item1.item[0].prd_name)
              arr2.splice(1,0, ' ')
              arr2.splice(2,0, ' ')
              arr2.splice(3,0, this.currencyPipe.transform(item1.item[0].purchsub_rate, '', '', this.country_dec))
              arr2.splice(4,0, this.currencyPipe.transform(item1.item[0].purchsub_qty, '', '', '1.1-1'))
              arr2.splice(5,0, this.currencyPipe.transform(item1.item[0].purchase_amount, '', '', this.country_dec))

              data.push(arr2)

              item1.purchase.forEach(item2=>{
                const arr3=[]
                arr3.splice(0,0, item2.prd_barcode)
                arr3.splice(1,0, item2.purch_refno)
                arr3.splice(2,0,  this.datePipe.transform(item2.purchsub_date, 'dd/MM/yyyy'))
                arr3.splice(3,0, this.currencyPipe.transform(item2.purchsub_rate, '', '', this.country_dec))
                arr3.splice(4,0, this.currencyPipe.transform(item2.purchsub_qty, '', '', '1.1-1'))
                arr3.splice(5,0, this.currencyPipe.transform(item2.purchase_amount, '', '', this.country_dec))

                data.push(arr3)
              })

            })

          } 
          else if(this.report_type == 'detailed_with_mrp'){
            arr.splice(0,0,  this.datePipe.transform(item.purchsub_date, 'dd/MM/yyyy'))
            arr.splice(1,0, item.purch_refno)
            arr.splice(2,0, item.purchase.purch_inv_no)
            arr.splice(3,0, item.purchase.supp_name)
            arr.splice(4,0, this.currencyPipe.transform(item.purchase_amount, '', '', this.country_dec))
            arr.splice(5,0, this.currencyPipe.transform(item.purchsub_tax, '', '', this.country_dec))
            arr.splice(6,0, this.currencyPipe.transform(item.purch_with_tax, '', '', this.country_dec))
            arr.splice(7,0, item.product.prd_name)
            arr.splice(8,0, this.currencyPipe.transform(item.purchsub_qty, '', '', '1.1-1'))
            arr.splice(9,0, this.currencyPipe.transform(item.sur_unit_rate, '', '', this.country_dec))
            arr.splice(10,0, this.currencyPipe.transform(item.mrp_amount, '', '', this.country_dec))
          }

          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Purchase.Purchase_Invoice')}`,  valueXPos, headerHeight + 15);

          if(this.purchInvSum){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Table.totl_category')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + this.totalCats,  valueXPos, headerHeight + 20);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 25);
            doc.setFontSize(10);
            doc.text(':  ' + this.totalProducts,  valueXPos, headerHeight + 25);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Purchase.Total_Purchase_Qty')}`, lblXPos, headerHeight + 30);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.totalQty, '', '', '1.1-1'),  valueXPos, headerHeight + 30);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 35);
            doc.setFontSize(10);
            doc.text(':  ' + this.currencyPipe.transform(this.purchaseAmount, '', '', this.country_dec),  valueXPos, headerHeight + 35);
          }
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
    
       
        doc.save(`Purchase Invoice.pdf`);
  
        this.exportLoader = false;


    });
    
  }

}
