import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-godown-settings',
  templateUrl: './godown-settings.component.html',
  styleUrls: ['./godown-settings.component.css']
})
export class GodownSettingsComponent implements OnInit {

  duplicate_item = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  settings: any;
  godown_transfer_without_stock: any;
  pageLoadingImg: boolean;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getSalesSetting();
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.godown_transfer_without_stock = this.settings.godown_transfer_without_stock;
      } else { 
        this.godown_transfer_without_stock = 1;
      }
    });

  }
  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updateGodownSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }
  langChange(){
    this.translate.get(['Common.Enable', 'Common.Disable']).subscribe((res: string) => {    
     

      this.duplicate_item = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
  
      ];
    
    });

  }
}
