import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';

@Component({
  selector: 'app-importdash',
  templateUrl: './importdash.component.html',
  styleUrls: ['./importdash.component.css']
})
export class ImportdashComponent implements OnInit {
  userType: any;
  up: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.userType =  this.coreService.getUserData('user_type');
    this.up = this.coreService.getUserPrivilage();
  }

}
