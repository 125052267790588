import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import * as XLSX from 'xlsx';
import { CoreService } from '../../../../service/core.service';
@Component({
  selector: 'app-import-opening-stock',
  templateUrl: './import-opening-stock.component.html',
  styleUrls: ['./import-opening-stock.component.css']
})
export class ImportOpeningStockComponent implements OnInit {

  fileName = 'opening_stock_sample_document.xlsx';
  pageLoadingImg: boolean;
  savingImg: boolean;
  log_file: any;
  tempFilePath: any;
  resultobj: any;
  uploadList = [];
  skip_rate_changes:boolean=false;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() { }


  file(file) {

    this.log_file = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.tempFilePath = event.target.result;
    }

  }

  deleteProd(data: any) {
    var remopstk = confirm("Removing Selected Product from the table");
    if (remopstk) {
      var type = data.prd_id,
        i;
      for (i = this.uploadList.length - 1; i >= 0; --i) {
        if (this.uploadList[i].prd_id == type) {
          this.uploadList.splice(i, 1);
        }
      }
    }

  }

  uploadLogFile(form) {
    var cnfrm = confirm("Are You Sure To Continue ?");
    if (cnfrm) {
      this.pageLoadingImg = true;

      let searchval = new FormData(form);

      // setTimeout(() => {
      //   this.loaduploadtext = true;
      // }, 5000);
      this.apiService.importOpeningStock(searchval).subscribe((res) => {
        this.pageLoadingImg = false;
        // setTimeout(() => {
        //   this.loaduploadtext = false;
        // }, 5000);

        if (res.error) {
          this.resultobj = res.error;
        } else {
          this.resultobj = [];
          this.uploadList = res.data;

        }

      });


    }
  }


  downloadSheet() {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  saveLog(formgroup: { value: any; }) {
    formgroup.value.stocks = this.uploadList;
    this.savingImg = true;
    this.apiService.storeOpeningStockExcel(formgroup.value).subscribe((res) => {
      this.savingImg = false;
      if (res.message) {
        this.coreService.showMessage(res.message);
      }
      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);

      }
      else {
        this.uploadList = [];
      }


    });

  }

}
