import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ComfunctModule} from '../../.././common/comfunct/comfunct.module';
import { MatDialogModule} from '@angular/material';
import {NgxPrintModule} from 'ngx-print';
// forms
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// share
import {ErpMaterialModule} from '../../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient); }
import { APP_BASE_HREF} from '@angular/common';
import { AppComponent } from '../../../app.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { QuickProductComponent } from './quick-product.component';


@NgModule({
  imports: [
    CommonModule,
    ErpMaterialModule,
    BrowserAnimationsModule,
    DateInputsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    NgSelectModule,
    ComfunctModule, 
    MatDialogModule, 
    NgxPrintModule,
    FormsModule,
    ReactiveFormsModule, 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    QuickProductComponent
  ],
  exports: [
    QuickProductComponent
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '/' },DatePipe], 
  bootstrap: [AppComponent],
})
export class QuickProductModule { }
