import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { Group } from '../../../model/group.model';
import { Ledger } from '../../../model/ledger.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  promo = {
    promo_id: '',
    sdate: new Date(),
    edate: new Date(),
    promo_name: '',
    promo_desc: '',
    promo_items: <any>[{
      prdList:<any> [],
      selProd: <any>{},
      discType: <any>1,
      discVal: 0,
      discRate: 0,
      discPer: 0,
      discFinRate: 0,
      isQty:0,
      qty: 0,
      barcode: '',
      br_err: '',
      promosub_id: ''
    }]
  };
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  // pageLoadingImg1 = false;
  // isSubmitInProg1 = false;
  
  // private modalRef: NgbModalRef;
  discType=[
    {id: 1, value: "Rate"},
    {id: 2, value: "%"}
  ];
  up: any;
  selProd:any = {};
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    });
    this.up = this.coreService.getUserPrivilage();
    // this.getNextReceiptId();
    this.getInfo(this.curpage);
  }


  updateFinalRate(i){
    console.log(i);
    let ttl = this.promo.promo_items[i].selProd.bs_srate;
    // let ttl = this.promo.promo_items[i].selProd.bs_srate +
    // (this.promo.promo_items[i].selProd.bs_srate * this.promo.promo_items[i].selProd.prd_tax /100);
    //type = 1 means discount in rate
    if(this.promo.promo_items[i].discType == 1){
      this.promo.promo_items[i].discRate = this.promo.promo_items[i].selProd.bs_srate - this.promo.promo_items[i].discVal;
     
    }
     //type = 1 means discount in percentage
    if(this.promo.promo_items[i].discType == 2){
      this.promo.promo_items[i].discRate = this.promo.promo_items[i].selProd.bs_srate - (this.promo.promo_items[i].selProd.bs_srate/100 * this.promo.promo_items[i].discVal);
    }
    // x= ttl/ (1+(per/100))
    this.promo.promo_items[i].discFinRate = this.promo.promo_items[i].discRate * (1+(this.promo.promo_items[i].selProd.prd_tax/100));
    this.promo.promo_items[i].discPer = this.promo.promo_items[i].discFinRate - this.promo.promo_items[i].discRate;
  }
    searchInp(e) {
      let searchval = new FormData();      
      searchval.append("search", e);  
      this.pageLoadingImg = true;
      this.apiService.getAllOffer(1, this.perpage, searchval).subscribe((res) => {
        this.info = res.data.data;
        this.pageLoadingImg = false;
        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.totalItem = res.data.total;
      });
    }


  // getNextReceiptId() {
  //   this.apiService.getNextReceiptId().subscribe((res) => {
  //     this.nextId = res.data.ref_no;
  //   });
  // }


  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllOffer(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  showAvlStock(i){
    // console.log(prodInfo);
    this.selProd[i] = this.promo.promo_items[i].selProd.prd_id;
    this.promo.promo_items[i].prdList = [];
    this.updateFinalRate(i);

  }

  searchPrd(search: string, i) {
    if(search.length >= 2){
      let searchval = new FormData();
      searchval.append("keyword", search);
      searchval.append('stk_stat', '1');
      this.apiService.getStockbyItem(searchval).subscribe((res) => {
        let arr = [];
        for(var key in this.selProd) {
         if(this.selProd.hasOwnProperty(key)){
           arr.push(this.selProd[key]);
         }
        }
        this.promo.promo_items[i].prdList = res['data'].filter(prd=> !arr.includes(prd.prd_id)); 
      });
    } else{
      this.promo.promo_items[i].prdList = [];
    }
  }

  searchPrdBarcode(search: string, i) {
    if(!search){
      this.promo.promo_items[i].br_err = 'Enter Barcode';
    }else{
      this.promo.promo_items[i].br_err = '';
      this.promo.promo_items[i].prdList = [];
      let searchval = new FormData();
      searchval.append("barcode", search);
      this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
        if(res['data']){
          this.promo.promo_items[i].prdList.push(['data']);
          this.promo.promo_items[i].selProd = res['data'];
        } else{
          this.promo.promo_items[i].br_err = 'No product found';
          this.promo.promo_items[i].selProd = {};
        }
      });
    }
  }


  addMore() {
    console.log(this.promo.promo_items);
    this.promo.promo_items.push({
      prdList: [],
      selProd: {},
      discType: 1,
      discVal: 0,
      discRate: 0,
      discPer: 0,
      discFinRate: 0,
      isQty:0,
      qty: 0,
      barcode: '',
      br_err: '',
      promosub_id: ''
    });
  }


  remove(index) {
    this.promo.promo_items.splice(index, 1);
  }
  createRec(){
    this.curForm = this.langText.create;
    // this.getNextReceiptId();
    this.clearForm();
  }
  clearForm() {
    this.promo = {
      promo_id: '',
      sdate: new Date(),
      edate: new Date(),
      promo_name: '',
      promo_desc: '',
      promo_items: [{
        prdList: [],
        selProd: {},
        discType: 1,
        discVal: 0,
        discRate: 0,
        discPer: 0,
        discFinRate: 0,
        isQty:0,
        qty: 0,
        barcode: '',
        br_err: '',
        promosub_id: ''
      }]
    };
  }

  updateQty(i){
    if(!this.promo.promo_items[i].isQty){
      this.promo.promo_items[i].qty = 0
    }
  }

  voidOffer(id){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.apiService.voidOffer({promo_id: id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          $('.close').click();
        }

      });
    }
  }

  editOffer(promo_id){
    // this.nextId = '';
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getSingleOffer({promo_id: promo_id}).subscribe((res) => {

      this.promo.promo_id = res.promo_id;
      this.promo.sdate = new Date(res.promo_start);
      this.promo.edate = new Date(res.promo_end);
      this.promo.promo_name = res.promo_name;
      this.promo.promo_desc = res.promo_desc;
      this.promo.promo_items = [];
      this.selProd = {};
      res.particulars.forEach((element,i) => {
        
          var tmpRow = {
            selProd: element,
            // prdList: [element],
            discType: element.promosub_disc_type,
            discVal: (element.promosub_disc_type ==1 ) ? element.promosub_discount
                      : element.promosub_percentage,
            discRate: 0,
            discPer: 0,
            discFinRate: 0,
            isQty: (element.promosub_qty > 0) ? true: false,
            qty: element.promosub_qty,
            promosub_id: element.promosub_id
          }
          this.promo.promo_items.push(tmpRow);
          this.selProd[i] = tmpRow.selProd.prd_id;
          this.updateFinalRate(i);
      });
      
    });
  }

  
  validateAndSubmit() {

    this.resultobj = [];

    // if (this.promo.rec_date == undefined) {
    //   this.resultobj.rec_date = "t";
    //   this.resultobj.push({ 'rec_date': 'Required' });
    // }
    // else {
    //   if (this.promo.rec_date > new Date()) {
    //     this.resultobj.rec_date = "t";
    //     this.resultobj.push({ 'rec_date': 'Required' });
    //   } else {
    //     this.resultobj.rec_date = "f";
    //   }
    // }
    if (this.resultobj.length <= 0) {

    if (confirm('Do you wish to continue?')) {
      console.log(this.promo.promo_items);
      for(let i=0; i < this.promo.promo_items.length; i++){
        this.promo.promo_items[i].prdList = [];
      }

      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createOffer';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateOffer';
      }
      this.apiService[callFunction](this.promo).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          // this.getNextReceiptId();
          this.resultobj = {};
        }

      });
    }
  }
}



private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}


}
