import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferRoutingModule } from './offer-routing.module';

// forms
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxPrintModule} from 'ngx-print';

export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient); }
import { APP_BASE_HREF} from '@angular/common';
import { AppComponent } from '../../app.component';
import { NumonlyDirective } from '../../../app/directive/numonly.directive';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { OfferComponent } from './offer/offer.component';
import { ComfunctModule } from "../.././common/comfunct/comfunct.module";

@NgModule({
  declarations: [OfferComponent],
  imports: [
    CommonModule,
    OfferRoutingModule,
    ErpMaterialModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    DateInputsModule,
    ComfunctModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }] ,
  bootstrap: [AppComponent]
})
export class OfferModule { }
