import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-inter-branch-transfer-settings',
  templateUrl: './inter-branch-transfer-settings.component.html',
  styleUrls: ['./inter-branch-transfer-settings.component.css']
})

export class InterBranchTransferSettingsComponent implements OnInit {
  rate_types = [
    { id: 1, name: 'Sales Rate' },
    { id: 2, name: 'Purchase Rate' },
    { id: 3, name: 'Custom' },
    { id: 4, name: 'Last Transfer Rate' },
    { id: 5, name: 'Average Cost' },
  ];

  rate_taxs = [
    { id: 0, name: 'Rate Without Tax' },
    { id: 1, name: 'Rate With Tax' },
    
   
  ];
  rate_type: any;
  rate_tax =0;
  change_purch_rate : any;
  pageLoadingImg: boolean;
  settings: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getTrasferSetting();
  }
  getTrasferSetting() {

    this.apiService.getTrasferSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.rate_type = this.settings.ts_rate_type;
        this.rate_tax = this.settings.ts_tax_rate;
        this.change_purch_rate = this.settings.change_purch_rate;
      } else { 
        this.rate_type = 1;
        this.rate_tax = 0;
        this.change_purch_rate = 0;
      }
    });

  }
  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updateTransferSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  langChange(){
    this.translate.get(['Common.standard_rated_domestic','Common.zero_rated_domestic','Common.exempt_purchase','Common.imports_vat_rvs_chage_mechnsm','Common.imports_vat_paid_custms','Purchase.mrp','HOME.purchase_rate','HOME.CUSTOM','Common.last_transfer_rate','Common.imports_vat_rvs_chage_mechnsm']).subscribe((res: string) => {    
     
      this.rate_types = [
        { id: 1, name: res['Purchase.mrp']},
        { id: 2, name: res['HOME.purchase_rate'] },
        { id: 3, name: res['HOME.CUSTOM'] },
        { id: 4, name: res['Common.last_transfer_rate'] },
        { id: 5, name: res['Common.imports_vat_rvs_chage_mechnsm'] },
  
      ];

    });

  }
}
