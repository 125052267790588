import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ProductionService } from '../../../../service/production.service';

import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
// import { DatePipe } from '@angular/common';
// import { Vanline } from '../../../../model/vanline.model';
// modal
@Component({
  selector: 'app-production-formula',
  templateUrl: './production-formula.component.html',
  styleUrls: ['./production-formula.component.css']
})
export class ProductionFormulaComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  // alias: any;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};

  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};

  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};

  prodFormula = {
    formula_id: '',
    formula_name: '',
    productOut: [{
      product: null,
      qty: 0,
      percntg: 0
    }],
    productIngd: [{
      product: null,
      qty: 0,
    }],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    miscExp: <any>'',
  };

  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 2;
  totalItem = 0;
  curForm = 'Create';
  up: any;
  from: any;
  country_dec: string;
  constructor(private productionService: ProductionService, private apiService: ApiService, private coreService: CoreService) { }
  ngOnInit() {
    this.getAllSatff();
    this.getInfo(this.curpage);
    this.up = this.coreService.getUserPrivilage();

    this.country_dec=this.coreService.setDecimalLength();

  }
  ngOnDestroy() {
    this.closeModal.nativeElement.click();
  }
  clearForm() {
    this.curForm = 'Create';
    this.clearPopUp();
  }

  clearPopUp() {
    this.comsnCommAmnt = 0;
    this.resultobj = {};
    this.prodFormula = {
      formula_id: '',
      formula_name: '',
      productOut: [{
        product: null,
        qty: 0,
        percntg: 0
      }],
      productIngd: [{
        product: null,
        qty: 0,
      }],
      isProdCommsn: false,
      // 0 for all and 1 for individual productIngd: [{
      staffs: [],
      insp_staff: null,
      ttlComm: 0,
      miscExp: '',
    };

    // console.log(this.prodFormula);

    this.filterProdAll = [];
    this.selProdIds = {};
    this.filterIngrdProd = [];
    this.selIngProdIds = {};
    this.filterStaffs = [];
    this.selStaffs = {};
  }

  getSingleItem(id) {
    this.clearPopUp();
    this.prodFormula.formula_id = id;
    this.isSubmitInProg = true;
    this.curForm = 'Update';
    this.productionService.getProductionFormula(id).subscribe((res) => {
      this.isSubmitInProg = false;
      const data = res.data;
      this.prodFormula.formula_id = data.prodform_id;
      this.prodFormula.formula_name = data.prodform_name;
      this.prodFormula.isProdCommsn = (data.prodform_commission === 1) ? true : false;
      this.prodFormula.ttlComm = data.prodform_comm_amount;
      this.prodFormula.miscExp = data.prodform_misc;
      this.prodFormula.insp_staff = data.staff;
      this.prodFormula.productOut = [];
      data.prod.forEach((element, index) => {
        this.prodFormula.productOut.push({
          product: {
            prd_name: element.prd_name,
            prd_id: element.prd_id,
            unit_display: element.unit_display,
            prd_base_unit_id: element.prd_base_unit_id
          },
          qty: element.prodformprod_qty,
          percntg: element.prodformprod_perc
        });
        this.selProdIds[index] = element.prd_id;
      });
      this.filterProducts();
      this.prodFormula.productIngd = [];
      data.sub.forEach((element, index) => {
        this.prodFormula.productIngd.push({
          product: {
            prd_name: element.prd_name,
            prd_id: element.prd_id,
            unit_display: element.unit_display,
            prd_base_unit_id: element.prd_base_unit_id
          },
          qty: element.prodformsub_qty
        });
        this.selIngProdIds[index] = element.prd_id;
      });
      this.filterIngProducts();
      this.prodFormula.staffs = [];
      let isCommComsn = true;
      data.commision.forEach((element, index) => {
        this.prodFormula.staffs.push({
          staff: { ledger_id: element.ledger_id, ledger_name: element.ledger_name },
          comm: element.prodformcomm_amount
        });
        if (data.commision[index + 1]) {
          if (element.prodformcomm_amount !== data.commision[index + 1].prodformcomm_amount) {
            isCommComsn = false;
          }
        }
        this.selStaffs[index] = element.ledger_id;
      });
      this.filterStaff();
      if (isCommComsn && (this.prodFormula.staffs.length === this.staffs.length) && data.commision.length > 0) {
        this.commAll = 1;
        this.comsnCommAmnt = this.prodFormula.staffs[0].comm;
      } else {
        this.commAll = 0;
      }
    });
  }

  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.productionService.getAllProductionFormula(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.from = res.data.from;
      this.totalItem = res.data.total;
    });
  }
  searchProducts(search: string) {
    const searchval = new FormData();
    searchval.append('prod_name', search);
    searchval.append('stk_stat','1');
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prdAll = res.data;
      this.filterProducts();
    });
  }

  setValues(prodId, index) {
    if (prodId == null) {
      delete this.selProdIds[index];
    } else {
      this.selProdIds[index] = prodId;
    }
    this.filterProducts();
  }

  filterProducts() {
    this.filterProdAll = this.prdAll.filter(e => {
      if (Object.values(this.selProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }

  addMore() {
    this.prodFormula.productOut.push({
      product: null,
      qty: 0,
      percntg: 0
    });
  }

  removeItem(index) {
    this.prodFormula.productOut.splice(index, 1);
    delete this.selProdIds[index];
    this.filterProducts();
  }
  searchIngProducts(search: string) {
    const searchval = new FormData();
    searchval.append('prod_name', search);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prdIngAll = res.data;
      this.filterIngProducts();
    });
  }

  setIngValues(prodId, index) {
    if (prodId == null) {
      delete this.selIngProdIds[index];
    } else {
      this.selIngProdIds[index] = prodId;
    }
    this.filterIngProducts();
  }

  filterIngProducts() {
    this.filterIngrdProd = this.prdIngAll.filter(e => {
      if (Object.values(this.selIngProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }

  addMoreIngrd() {
    this.prodFormula.productIngd.push({
      product: null,
      qty: 0,
    });
  }

  removeIngd(index) {
    if (this.prodFormula.productIngd.length > 1) {
      this.prodFormula.productIngd.splice(index, 1);
    } else {
      // error atleast 1 item required
    }
    delete this.selIngProdIds[index];
    this.filterIngProducts();
  }

  commissionChk(value) {
    if (value === 0) {
      this.prodFormula.staffs = [];
      this.prodFormula.ttlComm = 0;
    }
  }

  setStaff(staffId, index) {
    if (staffId == null) {
      delete this.selStaffs[index];
    } else {
      this.selStaffs[index] = staffId;
    }
    this.filterStaff();
  }

  filterStaff() {
    this.filterStaffs = this.staffs.filter(e => {
      if (Object.values(this.selStaffs).indexOf(e.ledger_id) < 0) {
        return true;
      }
    });
  }

  getAllSatff() {
    this.apiService.listAllStaff().subscribe((res) => {
      this.staffs = res.data;
      this.filterStaff();
    });
  }

  addMoreStaff() {
    this.prodFormula.staffs.push({
      staff: null,
      comm: 0,
    });
  }

  removeStaff(index) {
    if (this.prodFormula.staffs.length > 1) {
      this.prodFormula.staffs.splice(index, 1);
    } else {
      // error atleast 1 item required
    }
    delete this.selStaffs[index];
    this.filterStaff();
    this.updateCommsnTtl();
  }

  showAllStaff() {
    this.selStaffs = {};
    this.prodFormula.staffs = [];
    this.staffs.forEach((element, index) => {
      this.prodFormula.staffs.push({
        staff: element,
        comm: 0,
      });
      this.setStaff(element.ledger_id, index);
    });
    this.updateCommsnTtl();
  }

  showIndivStaff() {
    this.selStaffs = {};
    this.filterStaff();
    this.prodFormula.staffs = [{
      staff: {},
      comm: 0,
    }];
  }

  updateCommsnTtl() {
    this.prodFormula.ttlComm = 0;
    this.prodFormula.staffs.forEach(element => {
      this.prodFormula.ttlComm += parseFloat(element.comm.toString());
    });
  }

  setIndvComsn() {
    // console.log(this.comsnCommAmnt);
    this.prodFormula.staffs.forEach((element, index) => {
      this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      this.prodFormula.staffs[index].comm = this.comsnCommAmnt;
    });
    this.updateCommsnTtl();
  }

  validateAndSubmit() {
    this.isSubmitInProg = true;
    let callFunction = '';
    if (this.curForm === 'Create') {
      callFunction = 'createProductFormula';
    }
    if (this.curForm === 'Update') {
      callFunction = 'updateProductFormula';
    }
    this.prodFormula.miscExp = this.prodFormula.miscExp || 0;
    this.productionService[callFunction](this.prodFormula).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.closeModal.nativeElement.click();
        this.getInfo(this.curpage);
        this.coreService.showMessage(res.message);
        this.clearPopUp();
        this.resultobj = {};
      }

    });
  }

}



