import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ExcelService } from 'src/app/service/excel.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-acc-daybook',
  templateUrl: './acc-daybook.component.html',
  styleUrls: ['./acc-daybook.component.css']
})
export class AccDaybookComponent implements OnInit {
  fileName = 'daybook.xlsx';
  payType = ['Cash', 'Credit'];
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };

  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  period_type: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  added_by: any;
  vch_type: any;
  disp_void: any;
  vch_filter: any;
  ret_ref_filter: any;



  dayList: string[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  supplierAll: string[];
  ModelData: boolean;
  // total_purchase:number;
  total_return: any;
  total_purchase_amount: any;

  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  filter_types: any;
  filter_refnum: any;
  filter_price: any;
  filter_discount: any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType: any;
  // selectRefNo:any;
  selectPrice: any;
  // selectDiscount:any;



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];
  repDate: any;
  voucherType: any;
  ledger_all: any;
  users: any;
  show_notes: any;
  dispnote: any;
  debitAmt: any;
  crdAmt: any;
  branch_all: any;
  userType: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  Exppurchsumms: any;
  Expsumms: any;
  Expdispnote: any;
  ExpdebitAmt: number;
  ExpcrdAmt: number;
  exportLoader: boolean;
  pdf: any;
  branch_address: any;
  branch_phone: any;
  branch_mob: any;
  printPreview: any;
  recPriviewLoader: boolean;

  payPriviewLoader: boolean;
  salesPriviewLoader: boolean;
  up: any;
  showdata: boolean;
  purchaseItems: any;

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: 'P',
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  };
  logoImgUrl: any = '';
  tempImgUrl: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private excelService: ExcelService,
              private translate: TranslateService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.searchSupplier('');
    this.pageLoadingImg = true;
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    const searchval = new FormData();
    searchval.append('period_type', 't');
    this.period_type = 't';
    this.getTemplete();

    this.apiService.listDaybook(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.dayList = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.repDate = res.data.Date;
      this.dispnote = res.data.show_notes;
      this.debitAmt = res.data.debit_amnt;
      this.crdAmt = res.data.credit_amnt;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

      this.ModelData = false;
    });
    this.searchVoucherType('');

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.up = this.coreService.getUserPrivilage();
    this.branch_address = this.coreService.getUserData('branch_address');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');

    this.country_dec=this.coreService.setDecimalLength();
  }

  ngOnDestroy() {
    $('.close').click();
  }


  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res: any) => {
      this.branch_all = res.data;

      // console.log(  this.branch_all );

    });
  }
  searchBranch(search: string) {
    const searchval = new FormData();
    searchval.append('branch_name', search);
    this.apiService.getBranchByName(searchval).subscribe((res: any) => {
      this.branch_all = res.data;

    });
  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.NonVoided','Production.Voided']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_purchtypes = [
        { id: '1', name: res['Common.NonVoided'] },
        { id: '0', name:  res['Production.Voided'] },
    
      ];
    });

  


  }





  pageNext(formdata: { value: any; }, pageno: any) {

    $('.resultdata').empty();
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('pageno', pageno);

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    if (formdata.value.vch_type) {
      formdata.value.vch_type = Number(formdata.value.vch_type);
    }
    formdata.value.export = '';

    this.apiService.listDaybook(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.dayList = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.repDate = res.data.Date;
      this.dispnote = res.data.show_notes;
      this.debitAmt = res.data.debit_amnt;
      this.crdAmt = res.data.credit_amnt;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

    });
  }






  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });

  }



  searchVoucherType(search: string) {
    const searchval = new FormData();
    searchval.append('vchtype_name', search);
    this.apiService.searchVoucherType(searchval).subscribe((res) => {
      this.voucherType = res.data;
    });

  }

  searchLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.getLedByName(searchval).subscribe((res: any) => {
      this.ledger_all = res.data;
    });
  }

  // form search
  searchUser(search: string) {
    const searchval = new FormData();
    searchval.append('usr_name', search);
    this.apiService.getUserByName(searchval).subscribe((res: any) => {
      this.users = res.data;
    });
  }



  pgEnd(curr, end) {
    if (curr === end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr === 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  exportexcel(): void {
    /* table id is passed over here */
    const element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  export(formdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    this.apiService.listDaybook(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Expsumms = res.data.data;
      this.Expdispnote = res.data.show_notes;
      this.ExpdebitAmt = res.data.debit_amnt;
      this.ExpcrdAmt =res.data.credit_amnt;
      // console.log(this.ExpdebitAmt);


      this.slnum = 0;


      setTimeout(() => {
        this.exportexcel();
      }, 3000);



    });



  }
  PrintButtn(formdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    this.apiService.listDaybook(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Expsumms = res.data.data;
      this.Expdispnote = res.data.show_notes;
      this.ExpdebitAmt = res.data.debit_amnt;
      this.ExpcrdAmt =res.data.credit_amnt;
      // console.log(this.ExpdebitAmt);


      this.slnum = 0;


      setTimeout(() => {
        $('#pintbtn').click();
        this.exportLoader = false;
      }, 3000);



    });



  }


  exportPdf(formdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    this.apiService.listDaybook(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Expsumms = res.data.data;
      this.pdf = res.data.pdf;
      this.Expdispnote = res.data.show_notes;
      this.ExpdebitAmt = res.data.debit_amnt;
      this.ExpcrdAmt = res.data.credit_amnt;
      // console.log(this.Expsumms);


      this.slnum = 0;


      setTimeout(() => {
        this.generatePdf();
      }, 3000);



    });



  }

  generatePdf() {



    const filename = 'DAY BOOK';

    const docDefinition = {
      pageSize: 'A4',
      content: [
        {
          text: this.branch_display_name,
          bold: true,
          fontSize: 10,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        },
        {
          text: filename,
          bold: true,
          fontSize: 12,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        },
        {
          columns: [
            [
              {
                text: (this.repDate.date1 && this.repDate.date2) ? 'Duration : ' + this.repDate.date1 + ' - ' + this.repDate.date2 : '',
                // bold: true,
                fontSize: 9,
                margin: [0, 0, 0, 5]
              },
              {
                text: 'Total Debit Amount : ' + this.ExpdebitAmt.toFixed(3),

                fontSize: 9,
                margin: [0, 0, 0, 5]
              },
              {
                text: 'Total Credit Amount : ' + this.ExpcrdAmt.toFixed(3),
                fontSize: 9,
                margin: [0, 0, 0, 5]
              }
            ],
            [
              // Document definition for Profile pic
            ]
          ],
          columnStyles: { comment: { columnWidth: 'auto' } },
          styles: {
            name: {
              fontSize: 10,
              bold: true,

            },
            header: {
              fontSize: 12,
              bold: true,
              margin: [0, 20, 0, 10],
              decoration: 'underline'
            },
            jobTitle: {
              fontSize: 14,
              bold: true,
              italics: true
            },
            tableHeader: {
              bold: true,
            }
          },
        },

        {
          layout: 'lightHorizontalLines',
          fontSize: 10,
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers

            headerRows: 1,
            styles: {
              cellPadding: 0.5,
              fontSize: 8
            },
            // startY: 30, /* if start position is fixed from top */
            tableLineColor: [0, 0, 0], // choose RGB
            tableLineWidth: 0.2, // table border width
            // widths: [ '*', 'auto', '*', '100','*','*',,'*','*','*' ,'*' ],
            widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'],
            body: this.pdf,
            bodyStyles: {
              margin: 40,
              bold: false,
              fontSize: 8,
              lineWidth: 0.1,
              lineColor: [0, 0, 0]
            },
            theme: 'grid',
            columnStyles: {
              text: {
                cellWidth: 'auto'
              }
            }
          }
        }
      ]
    };
    pdfMake.createPdf(docDefinition).download(filename + '.pdf');

    this.exportLoader = false;


  }



  // irfan

  viewContra(id) {
    this.recPriviewLoader = true;

    this.apiService.getContra({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;

      this.printPreview = res;

      // console.log(this.printPreview.rec_ttl_amount);

    });

  }
  viewJournal(id) {
    this.recPriviewLoader = true;

    this.apiService.getJournal({ ref_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;

      this.printPreview = res;


    });

  }
  viewPayment(id) {
    this.payPriviewLoader = true;

    this.apiService.getPayment({ rec_no: id }).subscribe((res) => {
      this.payPriviewLoader = false;

      this.printPreview = res;


    });

  }
  viewReceipt(id) {
    this.recPriviewLoader = true;
    this.apiService.getReceipt({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;
      this.printPreview = res;
    });
  }
  sale_search(search) {
    this.salesPriviewLoader = true;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: 'P',
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      };
      if (res.data.prnt_header_logo) {
      this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
    } else {
      this.logoImgUrl = '';
    }

      if (res.data.prnt_header_type === 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }

  exportToPdf(formdata: any) {
    formdata.value.export = 'export';

    this.exportLoader = true;
    const rows = [];
    const headerHeight = 52;
    const lblXPos = 18;
    const valueXPos = 55;

    const heads = [
      this.translate.instant('transaction.Voucher_No'),
      this.translate.instant('ACCOUNTS.Particulars'),
      this.translate.instant('transaction.Branch_Ref_No'),
      this.translate.instant('Common.Vocher_type'),
      this.translate.instant('Table.date'),
      this.translate.instant('ACCOUNTS.Debit_Amount'),
      this.translate.instant('ACCOUNTS.Credit_Amount')
    ];
    this.apiService.listDaybook(formdata.value, 1).subscribe((res: any) => {
      if (res.data && res.data.data) {
        // this.Expdispnote = res.data.show_notes;
        res.data.data.forEach((data: any) => {
          rows.push([data.vch_no, data.ledger_name, data.branch_ref_no, data.vchtype_name,
            this.datePipe.transform(data.vch_date, 'dd/MM/yyyy'),
          this.currencyPipe.transform(data.vch_in, '', '', this.country_dec), this.currencyPipe.transform(data.vch_out, '', '', this.country_dec), ]);
        });
      }


      let doc = this.excelService.getPdfObj();

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text('DAY BOOK', 85, headerHeight - 2);


    //
      doc.setFontSize(10);
      doc.text('Branch', lblXPos, headerHeight + 5);
      doc.setFontSize(11);
      doc.text(':  ' + this.branch_display_name,  valueXPos, headerHeight + 5);

      if (this.repDate) {
      doc.setFontSize(10);
      doc.text(`Period`, lblXPos, headerHeight + 10);
      doc.text(`:  ${this.repDate.date1} To ${this.repDate.date2}`,  valueXPos, headerHeight + 10);
    }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('ACCOUNTS.Total_Debit_Amount')}`, lblXPos, headerHeight + 15);
      doc.text(':  ' + this.currencyPipe.transform(this.debitAmt, '', '', this.country_dec),  valueXPos, headerHeight + 15);

      doc.text(`${this.translate.instant('ACCOUNTS.Total_Credit_Amount')}`, lblXPos, headerHeight + 20);
      doc.text(':  ' + this.currencyPipe.transform(this.crdAmt, '', '', this.country_dec),  valueXPos, headerHeight + 20);

      doc = this.excelService.addTableToPdf(doc, headerHeight + 22, lblXPos, heads, rows, this.logoImgUrl, this.branch_display_name);
      doc.save(`day_book.pdf`);
      this.exportLoader = false;

    });
    // if(this.is_notes){
    //   heads.splice(5,0, this.translate.instant('Purchase.notes'))
    // }

  }


}
