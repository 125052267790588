import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editstock',
  templateUrl: './editstock.component.html',
  styleUrls: ['./editstock.component.css']
})
export class EditstockComponent implements OnInit {
  ledger_all: any;
ledgersel: any;
manftr_comp_name: any;
manftr_comp_code: any;
unit_types: any;
selectLedger: any;
searchLedger: any;
selectType: any;
  id: any;
  manftr_comp_flags: any;
  constructor() { }

  ngOnInit() {
  }
  createManf(val) {

  }
}
