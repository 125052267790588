import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-two',
  templateUrl: './invoice-two.component.html',
  styleUrls: ['./invoice-two.component.css']
})
export class InvoiceTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
