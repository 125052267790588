import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from './service/core.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {

  constructor(private coreService: CoreService,  private router: Router,private translate: TranslateService) {
    // window.onload = function () {
      if(this.getCookie("bquick") != 'loggedin'){
        console.log('cleared token');
        localStorage.removeItem("cmptoken");
        localStorage.removeItem("auth_token");
        localStorage.removeItem("lcstg");
        localStorage.removeItem("urpg");
      }
    // }
    // window.onbeforeunload = function(e) {
    //   // console.log('Dialog text here.');
    //   localStorage.removeItem("cmptoken");
    //   localStorage.removeItem("auth_token");
    //   localStorage.removeItem("lcstg");
    //   localStorage.removeItem("urpg");
    // };
  }

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
}

}