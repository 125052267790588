import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-van-stock-tracking',
  templateUrl: './van-stock-tracking.component.html',
  styleUrls: ['./van-stock-tracking.component.css']
})
export class VanStockTrackingComponent implements OnInit {
  fileName= 'van_stock_tracking.xlsx';
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: any;
  prd_id: any;
  cat_id: any;
  manfact_id: any;
  van_id: any;

  searchProducts: any;
  catagories: any;
  company: any;
  vans: any;
  godowns: any;
  vanPrdData: any;
  vanTransData: any;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  totalRes: any;
  slnum: number;
  data: any;
  reportBy:any;
  rep_type:any;
  pageLoadingImg:boolean;
  reportType:any;
  repDate:any;


  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 't', name: 'Today' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];


  rept_type = [
    { id: 1, name: 'Transfer & Returns' },
    { id: 2, name: 'Transfer' },
    { id: 3, name: 'Returns' },
  ];



  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  from: any;
  subcatagories: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;

  exp_vanTransData: any;
  exp_totalRes: any;
  filteredData: any;
  expfilteredData: any;
  cmpny: any;
  byderived: boolean;

  constructor(private apiService: ApiService, private coreService: CoreService) { }
  ngOnInit() {

    this.cmpny = this.coreService.getUserData('cmpny');

    // if(this.cmpny=='AQE'){
    //   this.byderived=true;
    // }
    this.pageLoadingImg = true;
    const searchval = new FormData();
    // if(this.cmpny=='AQE'){
    //   searchval.append("byderived", '1');
    // }
    this.totalRes = {};
    this.company = this.searchComp('');
    this.catagories = this.searchCat('');
    this.vans = this.searchVans('');
    this.rep_type = 1;
    this.listGodowns();
    this.apiService.readVanStockTracking(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.vanTransData = res.data;
      this.filteredData = res.data.filter(data =>
        data.van_trasfer_qty != 0 || data.van_ret_qty != 0 || data.vn_excs_qty != 0 ||data.vn_mstk_qty != 0 ||data.sold_qty != 0 || data.sales_return_qty != 0
      );
      
      
      this.totalRes = res.totalDetails;
      this.reportBy = res.reportBy;
      this.reportType = res.data.reportType;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.from = res.from;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }
  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      console.log(res);
      this.godowns = res['data'];

    });

  }

  ngOnDestroy(){
    $('.close').click();
 
  }




   // Loading Api to include for Filters


   searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }


  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.searchProducts = res.data;
    });
  }


  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      
    });
  }


  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
  this.apiService.readVanStockTracking(formdata.value, pageno).subscribe((res: any) => {
    this.pageLoadingImg = false;
      
    this.vanTransData = res.data;
    this.filteredData = res.data.filter(data =>
      data.van_trasfer_qty != 0 || data.van_ret_qty != 0 || data.vn_excs_qty != 0 ||data.vn_mstk_qty != 0 ||data.sold_qty != 0 || data.sales_return_qty != 0
    );
 
      this.totalRes = res.totalDetails;
      this.reportBy = res.reportBy;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.reportType = res.data.reportType;
      this.repDate = res.Date;
      this.from = res.from;


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.readVanStockTracking(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
        
        this.exp_vanTransData = res.data;
        this.expfilteredData = res.data.filter(data =>
          data.van_trasfer_qty != 0 || data.van_ret_qty != 0 || data.vn_excs_qty != 0 ||data.vn_mstk_qty != 0 ||data.sold_qty != 0 || data.sales_return_qty != 0
        );
        this.exp_totalRes = res.totalDetails;
        this.reportBy = res.reportBy;
        this.reportType = res.data.reportType;
        this.repDate = res.data.Date;
        this.from = res.from;
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
      });
  }



  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

}

