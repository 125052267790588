import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgbDate,NgbCalendar } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-van-calender',
  templateUrl: './van-calender.component.html',
  styleUrls: ['./van-calender.component.css']
})
export class VanCalenderComponent implements OnInit {
  vans: any;
  searchProducts: any[];
  selectedDate: NgbDate;
  pageLoadingImg: boolean;
  selectedMonth: any;
  selectedYear: any;

  rept_type = [
    { id: 1, name: 'Sale & Return' },
    { id: 2, name: 'Sale' },
    { id: 3, name: 'Return' },
  ];

  payment_types = [
    { id: 2, name: 'Cash' },
    { id: 1, name: 'Credit' },

  ];
  calender_rpt: any;
  selectedDay: number;
  report_type: any;
  product: any;
  van: any;
  priceGroupList: any;
  total_sum: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private calendar: NgbCalendar) { 
    this.selectedDate = calendar.getToday();
    
  }

  ngOnInit() {
    this.searchVans('');
    this.updateSelectedMonthAndYear(this.selectedDate);

    let searchval = new FormData();
    searchval.append("month", this.selectedMonth);
    searchval.append("year", this.selectedYear);
    this.pageLoadingImg = true;
    this.apiService.vanCalendarRpt(searchval,1).subscribe((res: any) => {
    this.pageLoadingImg = false;
    this.calender_rpt=res.dates;
    this.report_type=res.report_type;
    this.total_sum=res.total_qty_sum;
    this.product=res.product;
    this.van=res.van;
  
   });

   this.apiService.getAllPriceGroup().subscribe((res) => {
    this.priceGroupList = res.data;
  });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }

  // search product
 selectProd(name: string) {

  const searchval = new FormData();
  searchval.append('prod_name', name);
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.searchProducts = res.data;
  });
}


onMonthYearChange(newDate: NgbDate) {
 
  this.updateSelectedMonthAndYear(newDate);
  
}

updateSelectedMonthAndYear(date: NgbDate) {
  this.selectedMonth = date.month;
  this.selectedYear = date.year;

  
  $('#searchItem').trigger('click');
}

pageNext(formdata: { value: any; },pageno){

  
    formdata.value.month=this.selectedMonth;
    formdata.value.year=this.selectedYear;
    this.pageLoadingImg = true;
    this.apiService.vanCalendarRpt(formdata.value,pageno).subscribe((res: any) => {
    this.pageLoadingImg = false;
    this.calender_rpt=res.dates;
    this.report_type=res.report_type;
    this.total_sum=res.total_qty_sum;
    this.product=res.product;
    this.van=res.van;
    
   });
}

}
