import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-advance-settings',
  templateUrl: './advance-settings.component.html',
  styleUrls: ['./advance-settings.component.css']
})
export class AdvanceSettingsComponent implements OnInit {
  isSuperPriv: boolean;
  loginErrMsg: string;
  accLoginInProcess: boolean;
  superPassword: any;
  userType: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.isSuperPriv = false;
  }


  superAccess() {
    this.loginErrMsg = '';
    this.accLoginInProcess = true;
    this.apiService.superAccessAdvancedSettings({ superPassword: this.superPassword }).subscribe((res: any) => {
      this.accLoginInProcess = false;
      if (res.status) {
        localStorage.setItem("spracs_adv_setting", res.passcode);
        this.isSuperPriv = true;
        this.userType = this.coreService.getUserData('user_type');
        this.coreService.showMessage('Access Granted');

      } else {
        this.loginErrMsg = res.msg;
      }
    });
  }

}
