import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-sales-agent-report',
  templateUrl: './sales-agent-report.component.html',
  styleUrls: ['./sales-agent-report.component.css']
})
export class SalesAgentReportComponent implements OnInit {
  fileName = 'sales_agent_wise_sales_summary.xlsx';
  branch_display_name: any;
  branch_address: any;

  pageLoadingImg: boolean;
  agents: any;

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  period_type: any;
  agent_id: any;
  company: any;
  summary: any;
  repDate: any;
  repTotal: any;
  totalData: number;
  date1: any;
  date2: any;
  show: any;
  exportLoader: boolean;
  branch_display_code:any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.pageLoadingImg = true;
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    const searchval = new FormData();
    searchval.append('period_type', 't');
    this.period_type = 't';
    this.apiService.SalesAgentReport(searchval).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.show = res['data'];
      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.repTotal = res['data'].total;
     
    });
  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }
  search(formdata: { value: any; }) {
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.SalesAgentReport(formdata.value).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.show = res['data'];
      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.repTotal = res['data'].total;
    });
  }

  searchAgent(search: string)
  {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.agents = res['data'];
    });
  }
  exportexcel(): void {
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;
  }

  exportEXCL(formdata: { value: any; }) {
    this.exportLoader = true;
    this.exportexcel();
  }

  // pdf download
  generatePdf() {

    console.log(this.show);
  
    const heads = [
      this.translate.instant('Table.sl_no'),
      this.translate.instant('Common.sales_agent_name'),
      this.translate.instant('Sales.Sales_Amount'),
      this.translate.instant('Common.tax'),
      this.translate.instant('Purchase.discount'),
      this.translate.instant('Table.total_amount'),
      this.translate.instant('HOME.RECEIPT'),
      this.translate.instant('Common.Cash_Sales'),
      this.translate.instant('HOME.total_collected_amnt')
    ];

      

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 30;
      const data = [];

      this.summary.forEach((item,index)=>{
        data.push([
          index + 1,
          item.usr_name,
          item.s_total,
          item.t_total,
          item.d_total,
          this.currencyPipe.transform(item.g_total, '', '', this.country_dec),
          this.currencyPipe.transform(item.r_total, '', '', this.country_dec),
          this.currencyPipe.transform(item.cash_total, '', '', this.country_dec),
          this.currencyPipe.transform(item.cash_total + item.r_total, '', '', this.country_dec),

        ])
      })

      data.push([
        '',
        this.translate.instant('HOME.total'),
        this.currencyPipe.transform(this.repTotal.grand_s_total, '', '', this.country_dec),
        this.currencyPipe.transform(this.repTotal.grand_t_total, '', '', this.country_dec),
        this.currencyPipe.transform(this.repTotal.grand_d_total, '', '', this.country_dec),
        this.currencyPipe.transform(this.repTotal.grand_g_total, '', '', this.country_dec),
        this.currencyPipe.transform(this.repTotal.grand_r_total, '', '', this.country_dec),
        this.currencyPipe.transform(this.repTotal.grand_cash_total, '', '', this.country_dec),
        this.currencyPipe.transform(this.repTotal.grand_cash_total + this.repTotal.grand_r_total, '', '', this.country_dec),
      ])
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.sales_agent_wise_sales_summary')}`,  valueXPos, headerHeight + 15);

      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
  
     
      doc.save(`Sales Agent Wise Sales And Collection Report.pdf`);


    
  }

}
