import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rep-company-det',
  templateUrl: './rep-company-det.component.html',
  styleUrls: ['./rep-company-det.component.css']
})
export class RepCompanyDetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
