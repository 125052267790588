import { Component, OnInit, Input } from '@angular/core';
import { SalesSettingsComponent } from 'src/app/module/settings/sales-settings/sales-settings.component';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
@Component({
  selector: 'app-general-print',
  templateUrl: './general-print.component.html',
  styleUrls: ['./general-print.component.css']
})
export class GeneralPrintComponent implements OnInit {
  @Input()saleQtnData;
  @Input()print_style;
  @Input()show_base_unit_in_print;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()whatsapplink;
  country_dec: any;
  crNoColor: string;
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    console.log('in child');
    console.log(this.saleQtnData);
    if(this.sales_settings.text_color){
      this.crNoColor='black';
    }else{
      this.crNoColor='green';
    }
    
  }

  ngOnChanges(changes) {
    // if (changes['saleQtnData'] && this.saleQtnData) {
    //   console.log('in child 2');
    //   console.log(this.saleQtnData);
    // }
  }
 
}
// let user =new SalesSettingsComponent();
// console.log(user.func());
