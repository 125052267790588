import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-purchase-return-product',
  templateUrl: './purchase-return-product.component.html',
  styleUrls: ['./purchase-return-product.component.css']
})
export class PurchaseReturnProductComponent implements OnInit {
  fileName = 'purchase_return_invoice_summary.xlsx';



  calc_mode: any;
  purchaseBlock: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: any;
  purch_filter: any;
  purch_val: any;
  purch_val2: any;
  purch_rate_filter: any;
  purch_rate: any;
  purch_rate2: any;
  purch_qty_filter: any;
  purch_qty: any;
  purch_qty2: any;
  sup_id: any;
  prd_id: any;
  cat_id: any;
  manfact_id: any;

  purchInvSum: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  company: any;
  products: any;
  supplier: any;
  data: any;
  pageLoadingImg:boolean;
  repDate:any;

  totalQty: number;
  purchaseAmount: any;
  totalProducts: any;
  totalCats: any;
  purchInvitem: any;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  catagories: any;
  filter_branch_id: number;
  userType: any;
  branch_all: any;
  up: any;

  report_type_filter = [
    { id: 'summary', name: 'Summary' },
    { id: 'detailed', name: 'Detailed' }
  ];
  rep_type: string;
  report_type: any;
  exportLoader: boolean;
  ExppurchInvSum: any;
  Expreport_type: any;
  ExptotalQty: any;
  ExppurchaseAmount: any;
  ExptotalProducts: any;
  ExptotalCats: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  country_dec: string;
  cur_lang: string;






  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe) { }

  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
    this.rep_type = 'summary';

    this.getAllBranch();
    this.filter_branch_id = 1;
    this.userType =  this.coreService.getUserData('user_type');
    this.searchSupplier('');
    this.searchCat('');
    this.searchComp('');
    
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('period_type', 't');
    this.apiService.purchaseReturnProductSummary(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;

      if (res.data.allTotal) {
        this.totalQty = res.data.allTotal.total_qty;
        this.purchaseAmount = res.data.allTotal.purchase_amount;
        this.totalProducts = res.data.allTotal.total_products;
        this.totalCats = res.data.allTotal.total_cats;
      } else {
        this.totalQty = 0;
        this.purchaseAmount = 0;
        this.totalProducts = 0;
        this.totalCats = 0;

      }


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    

  }



  ngOnDestroy(){
    $('.close').click();
 
  }


  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }

  // FILTERS


  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res['data'];
    });

  }

  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  // FILTERS - End



  

  pageNext(formdata: { value: any; }, pageno: any) {

  
    this.pageLoadingImg = true;
    formdata.value.export = '';

    const searchval = new FormData();
    searchval.append('pageno', pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.purchaseReturnProductSummary(formdata.value, pageno).subscribe((res: any) => {
    this.pageLoadingImg = false;

      this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.repDate = res.data.Date;
      this.report_type = res.data.rep_type;

      if (res.data.allTotal) {
        this.totalQty = res.data.allTotal.total_qty;
        this.purchaseAmount = res.data.allTotal.purchase_amount;
        this.totalProducts = res.data.allTotal.total_products;
        this.totalCats = res.data.allTotal.total_cats;
      } else {
        this.totalQty = 0;
        this.purchaseAmount = 0;
        this.totalProducts = 0;
        this.totalCats = 0;

      }

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

    });
  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }


  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  exportEXCL(formdata: { value: any; }) {

    this.exportLoader = true;

    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.purchaseReturnProductSummary(formdata.value, 1).subscribe((res: any) => {

      this.ExppurchInvSum = res.data.data;

      this.repDate = res.data.Date;
      this.Expreport_type = res.data.rep_type;

      if (res.data.allTotal) {
        this.ExptotalQty = res.data.allTotal.total_qty;
        this.ExppurchaseAmount = res.data.allTotal.purchase_amount;
        this.ExptotalProducts = res.data.allTotal.total_products;
        this.ExptotalCats = res.data.allTotal.total_cats;
      } else {
        this.ExptotalQty = 0;
        this.ExppurchaseAmount = 0;
        this.ExptotalProducts = 0;
        this.ExptotalCats = 0;

      }

      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    

    });
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.purchaseReturnProductSummary(formdata.value, 1).subscribe((res: any) => {

      this.ExppurchInvSum = res.data.data;

      this.repDate = res.data.Date;
      this.Expreport_type = res.data.rep_type;

      if (res.data.allTotal) {
        this.ExptotalQty = res.data.allTotal.total_qty;
        this.ExppurchaseAmount = res.data.allTotal.purchase_amount;
        this.ExptotalProducts = res.data.allTotal.total_products;
        this.ExptotalCats = res.data.allTotal.total_cats;
      } else {
        this.ExptotalQty = 0;
        this.ExppurchaseAmount = 0;
        this.ExptotalProducts = 0;
        this.ExptotalCats = 0;

      }

      const heads = [];

      if(this.report_type == 'detailed'){
        heads.splice(0,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.date'))
        heads.splice(1,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Purchase.refno'))
        heads.splice(2,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Purchase.refno'))
        heads.splice(3,0, this.translate.instant('Purchase.supplier'))
        heads.splice(4,0, this.translate.instant('Branch.Supplier Vat No'))
        heads.splice(5,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.amnt'))
        heads.splice(6,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Common.Tax'))
        heads.splice(7,0, this.translate.instant('Van.Return') + '+' + this.translate.instant('Common.Tax'))
        heads.splice(8,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.description'))
        heads.splice(9,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.qty'))
      } else {
        heads.splice(0,0, this.translate.instant('Table.itm_name'))
        heads.splice(1,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Purchase.refno'))
        heads.splice(2,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Purchase.refno'))
        heads.splice(3,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.date'))
        heads.splice(4,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.rate'))
        heads.splice(5,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.qty'))
        heads.splice(6,0, this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.amnt'))
      }

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.ExppurchInvSum.forEach(item=>{
          const arr=[]

          if(this.report_type == 'detailed'){
            arr.splice(0,0, this.datePipe.transform(item.purchretsub_date, 'dd/MM/yyyy'))
            arr.splice(1,0, item.purchret_id)
            arr.splice(2,0, item.purchret_refno)
            arr.splice(3,0, item.supp_name)
            arr.splice(4,0, item.supp_tin)
            arr.splice(5,0, this.currencyPipe.transform(item.purchase_amount, '', '', this.country_dec))
            arr.splice(6,0, this.currencyPipe.transform(item.purchretsub_tax, '', '', this.country_dec))
            arr.splice(7,0, this.currencyPipe.transform(item.purchret_with_tax, '', '', this.country_dec))
            arr.splice(8,0, item.product.prd_name)
            arr.splice(9,0, this.currencyPipe.transform(item.purchretsub_qty, '', '', '1.1-1'))
          } else {
            item.category.forEach(item1=>{
              const arr1=[]
              arr1.splice(0,0, item1.cat_name)
              arr1.splice(1,0, ' ')
              arr1.splice(2,0, ' ')
              arr1.splice(3,0, ' ')
              arr1.splice(4,0, ' ')
              arr1.splice(5,0, this.currencyPipe.transform(item1.purchretsub_qty, '', '', '1.1-1'))
              arr1.splice(6,0, this.currencyPipe.transform(item1.purchase_amount, '', '', this.country_dec))
              data.push(arr1)
            })

            item.products.forEach(item2=>{
              item2.item.forEach(item3=>{
                const arr2=[]
                arr2.splice(0,0, item3.prd_name)
                arr2.splice(1,0, ' ')
                arr2.splice(2,0, ' ')
                arr2.splice(3,0, ' ')
                arr2.splice(4,0, ' ')
                arr2.splice(5,0, this.currencyPipe.transform(item3.purchretsub_qty, '', '', '1.1-1'))
                arr2.splice(6,0, this.currencyPipe.transform(item3.purchase_amount, '', '', this.country_dec))
                data.push(arr2)
              })

              item2.purchase.forEach(item4=>{
                const arr3=[]
                arr3.splice(0,0, item4.prd_barcode)
                arr3.splice(1,0, item4.purchret_id)
                arr3.splice(2,0, item4.purchret_refno)
                arr3.splice(3,0, this.datePipe.transform(item4.purchretsub_date, 'dd/MM/yyyy'))
                arr3.splice(4,0, this.currencyPipe.transform(item4.purchretsub_rate, '', '', this.country_dec))
                arr3.splice(5,0, this.currencyPipe.transform(item4.purchretsub_qty, '', '', '1.1-1'))
                arr3.splice(6,0, this.currencyPipe.transform(item4.purchase_amount, '', '', this.country_dec))
                data.push(arr3)
              })

            })
          }

          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Purchase.Purchase_Retured_Product')}`,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_category')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalCats,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalProducts,  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.Total_Retured_Qty')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.totalQty, '', '', '1.1-1'),  valueXPos, headerHeight + 30);

        doc.setFontSize(10);
          doc.text(`${this.translate.instant('Purchase.Total_Retured_Amount')}`, lblXPos, headerHeight + 35);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.purchaseAmount, '', '', this.country_dec),  valueXPos, headerHeight + 35);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
    
       
        doc.save(`Purchase Returned Product.pdf`);
    
        this.exportLoader = false;

    });

    
  }

}
